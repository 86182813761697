import {
  DESCRIPTION,
  SCHEDULER,
  TYPE_DATE_START,
  TYPE_RELATIONS,
  TYPE_REQUEST,
  TYPE_TIME
} from 'constants/index';

import { TaskInfo } from '../entities/TaskInfo';

export const transformSubmitedValues = async ({
  values,
  activeFields,
  projectId,
  selectedObjectSla,
  validateValidityDateStateValues,
  getExecutorSla
}) => {
  const isValidValidityDates = validateValidityDateStateValues();
  if (!isValidValidityDates) {
    return null;
  }

  const getRelations = () => {
    let results = [];

    if (activeFields.includes(TYPE_RELATIONS)) {
      results = [...(values.relations || [])];
    }

    return results;
  };

  const slaObjectType = values?.slaObject?.type;
  const slaObjectId =
    values?.slaObject &&
    (values.slaObject?.item?.label?.id || values.slaObject?.label?.id);

  const objectExecutorSla = await getExecutorSla(slaObjectType, slaObjectId);

  const taskInfoData = new TaskInfo(
    TaskInfo.extractFormData(values, objectExecutorSla)
  );

  const baseFields = {
    title: values.title,
    dateEnd: values.dateEnd,
    responsible: values.responsible,
    kind: TYPE_REQUEST,
    hours: activeFields.includes(TYPE_TIME) ? values.hours : null,
    minutes: activeFields.includes(TYPE_TIME) ? values.minutes : null,
    approvingManager: null,
    relations: getRelations(),
    dateStart: activeFields.includes(TYPE_DATE_START) ? values.dateStart : null,
    storyPoint: +values.storyPoint || undefined,
    ...values[DESCRIPTION],
    [DESCRIPTION]: (values[DESCRIPTION] || {})[DESCRIPTION] && [
      {
        text: values[DESCRIPTION].description
      },
      ...(values[DESCRIPTION].links || [])
    ],
    ...(values[SCHEDULER]
      ? {
          schedulerConfig: {
            freqInterval: values.freqInterval,
            weekdays: values.weekdays,
            freqType: values.freqType
          }
        }
      : {}),
    taskInfo: taskInfoData.getPostData(),
    controller: values?.controller
  };

  const additionalFields = {
    location: values.location,
    locationExact: values.locationExact,
    tags: values.tags,
    initialTags: values.initialTags,
    isFromTemplate: values.isFromTemplate,
    templateId: values.templateId
  };
  const result = { ...baseFields, ...additionalFields };

  delete result.withShowDetails;
  delete result.slaObject;
  delete result.slaObjectType;

  return result;
};
