import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Layout } from 'antd';
import {
  SHOW_MAN_DAY_CONTENT_FEATURE_FLAG,
  SHOW_NEWYEAR_CONTENT_FEATURE_FLAG,
  SHOW_VALENTINE_DAY_CONTENT_FEATURE_FLAG
} from 'constants/index';
import Icon from 'components/common/icon';

import { useTranslation } from 'react-i18next';

import {
  LogoIcon,
  LogoManDayLightIcon,
  LogoNewYearLightIcon,
  LogoValentineDayLightIcon
} from 'components/common/icons';
import { LanguageSelect } from 'components/common/controls/custom-select';

import { getIsSuccess } from 'store/user';
import { getUILanguage } from 'store/ui';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

import styles from './layout-auth.module.scss';

const LayoutAuthContext = createContext({});

export const LayoutAuth = ({ className, isSelectWorkspace, children }) => {
  const localLanguage = useSelector(getUILanguage);

  const [language, setLanguage] = useState(localLanguage);
  const isSuccess = useSelector(getIsSuccess);

  const [footerContent, setFooterContent] = useState(null);

  const { i18n } = useTranslation();

  const changeLanguage = lng => {
    setLanguage(lng.value);
    i18n.changeLanguage(lng.value);
  };

  const featureIcons = [
    {
      enabled: useFeatureIsOn(SHOW_NEWYEAR_CONTENT_FEATURE_FLAG),
      icon: LogoNewYearLightIcon
    },
    {
      enabled: useFeatureIsOn(SHOW_VALENTINE_DAY_CONTENT_FEATURE_FLAG),
      icon: LogoValentineDayLightIcon
    },
    {
      enabled: useFeatureIsOn(SHOW_MAN_DAY_CONTENT_FEATURE_FLAG),
      icon: LogoManDayLightIcon
    }
  ];

  const activeIcons = featureIcons.filter(feature => feature.enabled);

  const LogoIconComponent =
    activeIcons.length === 1 ? activeIcons[0].icon : LogoIcon;

  return (
    <Layout className={classnames(styles.root, className)}>
      <Icon component={LogoIconComponent} className={styles.logo} />
      <div
        className={classnames(styles.content, {
          [styles.selectWorkspace]: isSelectWorkspace,
          [styles.notDisplayed]: isSuccess,
          [styles.withFooter]: !!footerContent
        })}
      >
        <LayoutAuthContext.Provider value={{ setFooterContent }}>
          {children}
        </LayoutAuthContext.Provider>
      </div>

      {footerContent}

      {!isSelectWorkspace && (
        <div className={styles.languageSelectWrap}>
          <LanguageSelect
            value={language}
            rootClassName={styles.languageSelect}
            onChange={changeLanguage}
          />
        </div>
      )}
    </Layout>
  );
};

LayoutAuth.propTypes = {
  className: PropTypes.string,
  isSelectWorkspace: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

LayoutAuth.defaultProps = {
  className: undefined,
  isSelectWorkspace: false,
  children: undefined
};

export const LayoutAuthFooter = ({ children }) => {
  const { setFooterContent = () => {} } = useContext(LayoutAuthContext);

  useEffect(() => {
    setFooterContent(children);

    return () => {
      setFooterContent(null);
    };
  }, [children, setFooterContent]);

  return null;
};

LayoutAuth.Footer = LayoutAuthFooter;

export default LayoutAuth;
