import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ASSET_SUBSCRIBERS_DRAWER, RELATIONS_DRAWER } from 'constants/index';

import EntityInfoButtons from 'components/common/entity-info-buttons';

import { setVisibleDrawer } from 'store/drawers';
import { changeRelations, fetchLog, fetchRelations } from 'store/assets';

import DocumentsDrawer from '../../documents';

import styles from '../details.module.scss';

const InfoButtons = ({ asset }) => {
  const dispatch = useDispatch();
  const [visibleDocuments, setVisibleDocuments] = useState(false);

  const { t } = useTranslation(['Asset', 'AssetLinks']);

  const editRelations = async ({ added, deleted }) => {
    const result = [
      ...deleted.map(d => ({ ...d, isDelete: true })),
      ...added.map(d => ({ ...d, isDelete: false }))
    ];

    if (result.length) {
      await dispatch(
        changeRelations({
          id: asset.id,
          relations: result
        })
      );

      dispatch(
        fetchLog({
          id: asset.id,
          isFetchAfterChanges: true,
          params: { limit: 10000, offset: 0 }
        })
      );
    }
  };

  const showRelationsDrawer = () =>
    dispatch(
      setVisibleDrawer({
        drawer: RELATIONS_DRAWER,
        data: {
          description: t('AssetLinksDesc', { ns: 'AssetLinks' }),
          title: t('AssetLinksHeading', { ns: 'AssetLinks' }),
          fetch: () => dispatch(fetchRelations({ id: asset.id })),
          submit: editRelations,
          entityData: { assetId: asset.id }
        }
      })
    );

  const showSubscribersDrawer = () => {
    dispatch(
      setVisibleDrawer({
        drawer: ASSET_SUBSCRIBERS_DRAWER,
        data: {
          asset
        }
      })
    );
  };

  const buttons = [
    {
      icon: 'copy',
      text: t('DocumentsBtn'),
      onClick: () => setVisibleDocuments(true)
    },
    {
      icon: 'link',
      text: t('LinksBtn'),
      onClick: showRelationsDrawer
    },
    {
      icon: 'subscribers',
      text: t('SubscribersBtn', { ns: 'Asset' }),
      onClick: showSubscribersDrawer
    }
  ].filter(b => !b.hidden);

  return (
    <>
      <EntityInfoButtons buttons={buttons} className={styles.infoButtons} />

      <DocumentsDrawer
        asset={asset}
        onClose={() => setVisibleDocuments(false)}
        visible={visibleDocuments}
      />
    </>
  );
};

export default InfoButtons;
