export default api => ({
  create: ({ queryId, workspaceId, params }) =>
    api.post(`/v1/redash/api/queries/${queryId}/results`, {
      parameters: {
        workspace_id: workspaceId,
        ...params
      },
      max_age: 0
    }),

  check: ({ jobId }) => api.get(`/v1/redash/api/jobs/${jobId}`),

  fetch: ({ queryResultId }) =>
    api.get(`/v1/redash/api/query_results/${queryResultId}`),

  fetchQuery: ({ queryId }) => api.get(`/v1/redash/api/queries/${queryId}`),

  fetchDashboard: ({ dashboardId }) =>
    api.get(`/v1/redash/api/dashboards/${dashboardId}`),

  export: ({ queryResultId, format }) =>
    api.get(`/v1/redash/api/query_results/${queryResultId}.${format}`, {
      responseType: 'arraybuffer'
    })
});
