import React from 'react';
import { Form, Tooltip, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TYPE_CONTROLLER } from 'constants/index';

// eslint-disable-next-line import/no-cycle
import {
  EmployeeSelect,
  ProjectParticipantsSelect
} from 'components/common/controls/custom-select';
import Icon from 'components/common/icon';

const ActionTypeController = ({
  getFieldDecorator,
  relatedProjectId,
  initialValue
}) => {
  const { t } = useTranslation('AddTask');

  const Select = relatedProjectId ? ProjectParticipantsSelect : EmployeeSelect;

  const label = (
    <>
      <Typography.Text style={{ color: 'inherit', marginRight: 5 }}>
        {t('Controller')}
      </Typography.Text>

      <Tooltip title={t('ControllerTip')}>
        <Icon
          type="question-circle"
          size={12}
          style={{ verticalAlign: 'unset' }}
        />
      </Tooltip>
    </>
  );

  return (
    <Form.Item label={label}>
      {getFieldDecorator(TYPE_CONTROLLER, { initialValue })(
        <Select
          projectId={relatedProjectId}
          data-qa="qa-hn225g7e3c77pbg"
          hideWorkspace
          isMulti={false}
        />
      )}
    </Form.Item>
  );
};

ActionTypeController.propTypes = {
  getFieldDecorator: PropTypes.func.isRequired,
  relatedProjectId: PropTypes.string,
  initialValue: PropTypes.object
};

ActionTypeController.defaultProps = {
  relatedProjectId: undefined,
  initialValue: undefined
};

export default ActionTypeController;
