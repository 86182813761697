import api from 'api';
import { createAction } from 'redux-actions';

import createActionThunk from '../actions-thunk';
// eslint-disable-next-line import/no-cycle
import { getUserEmployee, getWorkspaceId } from '../workspace';

export const fetchTagsLocal = createActionThunk(
  'tags/fetch',
  ({ getState, search, limit, offset, withSystem }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tags
      .fetch({ workspaceId, search, limit, offset, withSystem })
      .then(({ data }) => data);
  }
);

export const fetchTags = createActionThunk(
  'tags/fetch-tags',
  ({ getState, search, limit, offset, withSystem }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tags
      .fetch({ workspaceId, search, limit, offset, withSystem })
      .then(({ data }) => data);
  }
);

export const fetchTagsByEntity = createActionThunk(
  'tags/fetch-by-entity',
  ({ getState, entityType, entityIds }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tags
      .fetchByEntity({ workspaceId, entityType, entityIds })
      .then(({ data }) => data.data);
  }
);

export const createTag = createActionThunk(
  'tags/create-tag',
  ({ getState, tag }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);
    const employee = getUserEmployee(state);

    return api.tags
      .create({ workspaceId, tag, employeeId: employee.id })
      .then(({ data }) => data.data);
  }
);

export const addTag = createActionThunk('tags/add-tag', ({ getState, tag }) => {
  const state = getState();
  const workspaceId = getWorkspaceId(state);
  const employee = getUserEmployee(state);

  return api.tags.add({ workspaceId, tag, employeeId: employee.id });
});

export const deleteTag = createActionThunk(
  'tags/delete-tag',
  ({ getState, tag }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);
    const employee = getUserEmployee(state);

    return api.tags
      .delete({ workspaceId, tag, employeeId: employee.id })
      .then(({ data }) => data);
  }
);

export const deleteTagFromWorkspace = createActionThunk(
  'tags/delete-tag-from-workspace',
  ({ getState, tag }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);
    const employee = getUserEmployee(state);

    return api.tags
      .deleteFromWorkspace({ workspaceId, id: tag.id, employeeId: employee.id })
      .then(() => tag);
  }
);

export const replaceTag = createActionThunk(
  'tags/replace-tag',
  ({ getState, replaceData }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);
    const employee = getUserEmployee(state);

    return api.tags
      .replace({ workspaceId, replaceData, employeeId: employee.id })
      .then(({ data }) => ({
        fromTag: replaceData.fromTag,
        toTag: data
      }));
  }
);

export const editTag = createActionThunk(
  'tags/edit-tag',
  ({ getState, tag }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);
    const employee = getUserEmployee(state);

    return api.tags
      .edit({ workspaceId, tag, employeeId: employee.id })
      .then(({ data }) => ({ ...tag, ...data }));
  }
);

export const bulkDeleteTagsFromWorkspace = createActionThunk(
  'tags/bulk-delete-tags-from-workspace',
  ({ getState, tagIds }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);
    const employee = getUserEmployee(state);

    return api.tags
      .bulkDelete({ workspaceId, tagIds, employeeId: employee.id })
      .then(() => ({ tagIds }));
  }
);

export const setFilterSearch = createAction('tags/set-filter-search');

export const reFetchAllTags = createAction('tags/re-fetch-all-tags');

export const clearAllTags = createAction('tags/clear-all-tags');
