import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import { TYPE_DATE_END, TITLE, TYPE_DATE_START } from 'constants/status';
import { DATE_FORMAT, DATE_PICKER_FORMAT } from 'constants/index';

import Button from 'components/common/button';
import {
  FormDatePicker,
  validateRequired,
  FormInput,
  makeValidateDatesStartAfterEnd
} from 'components/common/hook-form';

import styles from './styles.module.scss';

const BaseForm = ({ onSubmit, isLoading, values = {}, sprints }) => {
  const { t } = useTranslation(['AddEditSprint', 'Common']);

  const { title, dateEnd, dateStart } = values;

  const formatDateValue = DATE_PICKER_FORMAT;

  const methods = useForm({
    defaultValues: {
      [TITLE]: title || '',
      [TYPE_DATE_START]: dateStart
        ? moment(dateStart, DATE_FORMAT).toDate()
        : moment().toDate(),
      [TYPE_DATE_END]: dateEnd && moment(dateEnd, DATE_FORMAT).toDate()
    }
  });

  const startDate = methods.watch(TYPE_DATE_START) || new Date();
  const endDate = methods.watch(TYPE_DATE_END);

  const submitForm = async value => {
    const sprintIsset = sprints.find(sprint => sprint.id === +value.title);

    onSubmit({
      ...value,
      sprintId: sprintIsset && sprintIsset.id
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(submitForm)}
        data-qa="qa-g26u8r9005o9tb9"
        className={styles.root}
      >
        <div className={styles.fieldsWrap} data-qa="qa-bbrp7gh4gknwr2h">
          <div className={styles.field} data-qa="qa-ns1co7min8rdh6v">
            <FormInput
              name={TITLE}
              label={t('SprintName')}
              placeholder={t('EnterSprintName')}
              rules={{
                required: validateRequired()
              }}
            />
          </div>

          <div className={styles.dates} data-qa="qa-urzdczjdwfu0fjo">
            <FormDatePicker
              name={TYPE_DATE_START}
              label={t('SprintStartDate')}
              dateFormat={formatDateValue}
              minDate={new Date()}
              maxDate={endDate}
              data-qa="qa-otfp94e1koqnswa"
              rules={{
                required: validateRequired()
              }}
              showTimeSelect={false}
              wrapperClassname={styles.datePicker}
            />

            <FormDatePicker
              name={TYPE_DATE_END}
              label={t('SprintDueDate')}
              dateFormat={formatDateValue}
              minDate={startDate || new Date()}
              wrapperClassname={styles.datePicker}
              rules={{
                required: validateRequired(),
                validate: value =>
                  makeValidateDatesStartAfterEnd({
                    start: startDate,
                    end: value,
                    checkSameDates: true,
                    checkSameFormat: 'day'
                  })
              }}
              showTimeSelect={false}
            />
          </div>
        </div>

        <Button
          className={styles.btnSteps}
          data-qa="qa-gbdfaypdw0zddyw"
          loading={isLoading}
          htmlType="submit"
          type="primary"
        >
          {t('SaveBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

export default BaseForm;
