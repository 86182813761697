export const IS_COMPANY = 'isCompany';
export const IS_INDIVIDUAL = 'isIndividual';

export const CONTACT_STATUS_UNASSEMLED = 'unassembled';
export const CONTACT_STATUS_VERIFIED = 'verified';
export const CONTACT_STATUS_ARCHIVED = 'archived';

export const CONTACT_STATUSES = {
  [CONTACT_STATUS_UNASSEMLED]: {
    value: CONTACT_STATUS_UNASSEMLED,
    label: 'NotDefinedContactStatus',
    ns: 'Contacts',
    color: 'danube'
  },
  [CONTACT_STATUS_VERIFIED]: {
    value: CONTACT_STATUS_VERIFIED,
    label: 'DefinedContactStatus',
    ns: 'Contacts',
    color: 'green'
  },
  [CONTACT_STATUS_ARCHIVED]: {
    value: CONTACT_STATUS_ARCHIVED,
    label: 'ArchiveContactStatus',
    ns: 'Contacts',
    color: 'red'
  }
};

export const CONTACT_ACTIVE_STATUSES = Object.values(CONTACT_STATUSES).filter(
  status => status.value !== CONTACT_STATUS_ARCHIVED
);

export const CONTRACTOR_FILTER_OPTIONS = [
  {
    value: null,
    ns: 'Contacts',
    label: 'AllSelected'
  },
  {
    value: false,
    ns: 'Contacts',
    label: 'CompanyType'
  },
  {
    value: true,
    ns: 'Contacts',
    label: 'IndividualType'
  }
];

export const SORTING = {
  DIRECTION: {
    ASC: 'asc',
    DESC: 'desc'
  },
  FIELD: {
    CONTACT: 'lastName',
    COMPANY: 'company'
  },
  TABLE_DIRECTION: {
    ASC: 'ascend',
    DESC: 'descend'
  }
};

export const HISTORY = 'history';
export const COMMENTS = 'comments';
export const COMMENT = 'comment';
export const TIME_LOG = 'time-log';

export const ALPHA_CODE_BELARUS = 'BY';
export const ALPHA_CODE_RUSSIA = 'RU';
export const ALPHA_CODE_KAZAKHSTAN = 'KZ';
export const ALPHA_CODE_UKRAINE = 'UA';
export const ALPHA_CODE_MOLDOVA = 'MD';

export const LEGAT_COUNTRY_ALPHA_2_CODES = [
  ALPHA_CODE_BELARUS,
  ALPHA_CODE_RUSSIA,
  ALPHA_CODE_KAZAKHSTAN,
  ALPHA_CODE_UKRAINE,
  ALPHA_CODE_MOLDOVA
];

export const checkIsLegatCountry = alpha2Code =>
  LEGAT_COUNTRY_ALPHA_2_CODES.includes(alpha2Code);

export const STATUS_IMPORT_CONTACTS_ACCEPTED = 'Accepted';
export const STATUS_IMPORT_CONTACTS_COMPLETED = 'Completed';
export const STATUS_IMPORT_CONTACTS_COMPLETED_WITH_ERRORS =
  'Completed, contains errors';
export const STATUS_IMPORT_CONTACTS_FINISHED = 'finished';

export const UPSERVICE_SENDER = 'upservice';

export const NO_NAME = {
  NS: 'Common',
  FIRST_NAME: 'NoFirstName',
  LAST_NAME: 'NoLastName'
};

export const CONTACT_SOURCE_IMPORT_OPTION = {
  label: 'ContactSourceImport',
  ns: 'Common',
  value: 'import'
};

export const CONTACT_SOURCE_MANUAL_OPTION = {
  label: 'ContactSourceManual',
  ns: 'Common',
  value: 'manual'
};

export const CHANNELS_ASSISTANT = 'channels-assistant';
