import React from 'react';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-cycle
import { FormObjectSlaSelect } from 'components/common/hook-form';

export const SlaObjectSelect = ({ contact, value, ...props }) => {
  const { t } = useTranslation(['Common', 'Requests', 'ConnectWidget']);

  const commonProps = {
    contact: contact?.value,
    value: value?.item,
    defaultType: value?.type,
    ...props
  };

  return (
    <FormObjectSlaSelect
      {...commonProps}
      name="slaObject"
      label={`${t('SLAEntity', { ns: 'Requests' })} ${t('Optional', { ns: 'Common' })}`}
    />
  );
};
