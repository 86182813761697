import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Typography from 'components/common/typography';
import Avatar from 'components/common/avatar';
import Tooltip from 'components/common/tooltip';

import getFIO, { getFullName } from 'utils/get-fio';
import useRoutesService from 'services/routes';

export const UserCardSmall = ({
  asLink,
  id,
  firstName,
  lastName,
  avatarFile,
  children,
  className,
  style,
  isActive,
  position,
  withPosition = false
}) => {
  const routes = useRoutesService({ returnUrl: true });

  return (
    <Link
      to={asLink && isActive ? routes.toEmployee(id) : ''}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        pointerEvents: asLink && isActive ? 'inherit' : 'none',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        ...style
      }}
      className={className}
    >
      <Avatar
        src={(avatarFile || {}).url}
        size={24}
        isActive={isActive}
        style={{ marginRight: 8 }}
      >
        {getFIO({ firstName, lastName })}
      </Avatar>

      <span
        style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
      >
        <Typography.Text ellipsis>
          {getFullName({ firstName, lastName })}
        </Typography.Text>
        {withPosition && position && (
          <Tooltip
            title={position?.length > 45 ? position : null}
            mouseEnterDelay={0.5}
          >
            <Typography.Text color="black-45" ellipsis>
              {position}
            </Typography.Text>
          </Tooltip>
        )}
      </span>
    </Link>
  );
};

UserCardSmall.propTypes = {
  asLink: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  position: PropTypes.string,
  avatarFile: PropTypes.object,
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
  withPosition: PropTypes.bool
};

UserCardSmall.defaultProps = {
  children: undefined,
  asLink: false,
  id: undefined,
  firstName: undefined,
  lastName: undefined,
  className: undefined,
  avatarFile: {},
  style: {},
  position: undefined,
  withPosition: false
};

export default UserCardSmall;
