import i18next from 'i18next';
import plural from 'plural-ru';

export const getPluralizedDaysText = days => {
  const singularForm = i18next.t('SungularFormDay', { ns: 'Common' });
  const genitiveForm = i18next.t('GenitiveFormDay', { ns: 'Common' });
  const pluralForm = i18next.t('PluralFormDay', { ns: 'Common' });

  return plural(days, singularForm, genitiveForm, pluralForm);
};
