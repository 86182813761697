export const channelsAssistant = api => ({
  fetch: ({ workspaceId }) => api.get(`/v1/${workspaceId}/channels-assistant/`),

  create: ({ workspaceId, data }) =>
    api.post(`/v1/${workspaceId}/channels-assistant/`, data),

  update: ({ workspaceId, id, data }) =>
    api.put(`/v1/${workspaceId}/channels-assistant/${id}/`, data),

  fetchAttachments: ({ workspaceId, id }) =>
    api.get(`/v1/${workspaceId}/channels-assistant/${id}/attachments/`),

  deleteAttachments: ({ workspaceId, id, data }) =>
    api.post(
      `/v1/${workspaceId}/channels-assistant/${id}/delete-attachments/`,
      data
    ),

  addAttachments: ({ workspaceId, id, data }) =>
    api.post(
      `/v1/${workspaceId}/channels-assistant/${id}/add-attachments/`,
      data
    )
});
