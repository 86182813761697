import { createAction } from 'redux-actions';
import { notification } from 'antd';
import api from 'api';

import { STATUS_CONNECTED } from 'constants/index';
import { TOKEN_FILES } from 'constants/api';

// eslint-disable-next-line import/no-cycle
import { COLLAPSED_SIDER } from 'components/common/layout/template/sider';

import createActionThunk from 'store/actions-thunk';
// eslint-disable-next-line import/no-cycle
import { getWorkspaceId } from 'store/workspace';

import { connectSockets, disconnectSockets } from 'socket';
import getIsDemoModeActive from 'utils/get-is-demo-mode-active';
import { clearStorageFilter } from 'hooks/common/use-filter-storage';
import sendDataToDomain, {
  TYPE_APP_AUTH_TOKEN_TRANSFER
} from 'utils/send-data-to-domain';

export const refreshAuthToken = createActionThunk('user/refresh-token', () => {
  const refresh = localStorage.getItem('refresh');
  return api.user.token.refresh(refresh).then(response => {
    const accessToken = response.data.access;

    localStorage.setItem('token', accessToken);
    localStorage.setItem('refresh', refresh);

    return accessToken;
  });
});

export const fetchCurrentUser = createActionThunk(
  'user/fetch-current-user',
  api.user.current
);

export const confirmUser = createActionThunk(
  'user/confirm-user',
  ({ uid, token, firstName, lastName, phone, promoCode }) =>
    api.user
      .confirmUser(token, uid, firstName, lastName, phone, promoCode)
      .then(({ data }) => {
        localStorage.setItem('token', data.access);
        localStorage.setItem('refresh', data.refresh);

        return data.access;
      })
);

export const completeRegistration = createActionThunk(
  'user/registration-complete',
  ({ firstName, lastName, phone, promoCode }) =>
    api.user.complete(firstName, lastName, phone, promoCode)
);

export const confirmEmail = createActionThunk(
  'user/confirm-email',
  ({ token }) =>
    api.user.confirmEmail(token).then(({ data }) => {
      localStorage.setItem('token', data.access);
      localStorage.setItem('refresh', data.refresh);

      return data.access;
    })
);

export const resendActivation = createActionThunk(
  'user/resend-activation',
  ({ email }) => api.user.resendActivation(email)
);

export const googleAuth = createActionThunk(
  `user/google-auth`,
  ({ token, register = false, isAgreePolicy, params, dispatch }) =>
    api.user
      .googleAuth({ token, register, isAgreePolicy, params })
      .then(({ data }) => {
        const { access, refresh, id } = data || {};

        const isNewUser = !!id;

        connectSockets(access);

        return dispatch(authenticate({ access, refresh, isNewUser }));
      })
);

export const addGoogleAccount = createActionThunk(
  `user/add-google-oauth2`,
  ({ token }) => api.user.addGoogleOauth({ token })
);

export const deleteGoogleAccount = createActionThunk(
  `user/delete-google-oauth2`,
  () => api.user.deleteGoogleOauth()
);

export const facebookAuth = createActionThunk(
  `user/facebook-auth`,
  ({ token, dispatch }) =>
    api.user.facebookAuth(token).then(({ data }) => {
      const { access, refresh } = data || {};

      connectSockets(access);

      return dispatch(authenticate({ access, refresh }));
    })
);

export const addFacebookAccount = createActionThunk(
  `user/add-facebook-oauth2`,
  ({ token }) =>
    api.user.addFacebookOauth(token).then(({ data }) => data.facebookUserId)
);

export const deleteFacebookAccount = createActionThunk(
  `user/delete-facebook-oauth2`,
  () => api.user.deleteFacebookOauth()
);

export const changePassword = createActionThunk(
  'user/change-password',
  ({ newPassword, password }) => api.user.changePassword(newPassword, password)
);

export const changeIsFeedbackSent = createActionThunk(
  'user/change-is-feedback-sent',
  ({ isFeedbackSent }) =>
    api.user
      .changeIsFeedbackSent({ isFeedbackSent, feedbackSentAt: new Date() })
      .then(({ data }) => data)
);

export const sendFeedback = createActionThunk('user/send-feedback', feedback =>
  api.user.sendFeedback(feedback).then(({ data }) => data)
);

export const login = createActionThunk(
  'user/login',
  ({ email, password, dispatch }) => {
    const { get, getDemo } = api.user.token;

    const getToken = getIsDemoModeActive() ? getDemo : get;

    return getToken(email, password).then(({ data }) => {
      const { access, refresh, schemaName } = data;

      connectSockets(access);

      return dispatch(authenticate({ access, refresh, schemaName }));
    });
  }
);

export const authenticate = createAction(
  'user/authenticate',
  ({ access, refresh, isNewUser, schemaName }) => {
    localStorage.setItem('token', access);
    localStorage.setItem('refresh', refresh);

    if (getIsDemoModeActive()) {
      localStorage.setItem('schemaName', schemaName);
    }

    sendDataToDomain({
      data: { token: access },
      type: TYPE_APP_AUTH_TOKEN_TRANSFER
    });

    return { access, isNewUser };
  }
);

export const registrationWithOrder = createActionThunk(
  'user/create-with-order',
  data => api.user.registrationWithOrder(data)
);

export const registration = createActionThunk(
  'user/registration',
  ({ email, phone, password, promoCode, isAgreePolicy, language, params }) =>
    api.user.registration({
      email,
      phone,
      password,
      promoCode,
      isAgreePolicy,
      language,
      params
    })
);

export const updateUser = createActionThunk('user/update-user', ({ user }) =>
  api.user.patchCurrent(user).then(({ data }) => data)
);

export const deleteUser = createActionThunk('user/delete-user', () =>
  api.user.deleteUser()
);

export const resetPassword = createActionThunk(
  'user/reset-password',
  ({ email }) => api.user.resetPassword(email)
);

export const resetPasswordConfirm = createActionThunk(
  'user/reset-password-confirm',
  ({ uid, token, newPassword }) =>
    api.user.resetPasswordConfirm(uid, token, newPassword)
);

export const changeEmail = createActionThunk(
  'user/change-email',
  ({ email, password }) => api.user.changeEmail(email, password)
);

export const updateProfile = createAction('user/update-profile');
export const changeFieldValue = createAction('user/change-field');
export const setCollapsedSider = createAction(COLLAPSED_SIDER);

export const changeIsSuccess = createAction('user/change-is-success');

export const logout = createAction('user/logout', payload => {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh');
  localStorage.removeItem(TOKEN_FILES);

  window.FB.getLoginStatus(response => {
    if (response.status === STATUS_CONNECTED) {
      window.FB.api(`${response.authResponse.userID}/permissions`, 'delete');
    }
  });

  clearStorageFilter();

  notification.destroy();

  sendDataToDomain({
    data: { token: null },
    type: TYPE_APP_AUTH_TOKEN_TRANSFER
  });

  disconnectSockets();

  if (getIsDemoModeActive()) {
    window.location.replace('https://upservice.io/');
  }

  return payload;
});

export const acceptAgreement = createActionThunk('user/accept-agreement', () =>
  api.user.acceptAgreement()
);

export const fetchOnboarding = createActionThunk(
  'user/fetch-onboarding',
  ({ getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.user.fetchOnboarding({ workspaceId }).then(({ data }) => data);
  }
);
export const updateOnboarding = createActionThunk(
  'user/update-onboarding',
  ({ getState, id, data }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.user
      .updateOnboarding({ workspaceId, id, data })
      .then(({ data: result }) => result);
  }
);

export const changeIsOnlyUserProfile = createAction(
  'user/change-is-only-profile'
);
