import React from 'react';
import { Form } from '@ant-design/compatible';
import PropTypes from 'prop-types';

import { TYPE_CO_RESPONSIBLES } from 'constants/index';

// eslint-disable-next-line import/no-cycle
import {
  EmployeeSelect,
  ProjectParticipantsSelect
} from 'components/common/controls/custom-select';

import { validateIsRequire } from 'utils/validators';

const ActionTypeCoResponsibles = ({
  getFieldDecorator,
  relatedProjectId,
  initialValue,
  isDisabled,
  label,
  ...props
}) => {
  const Select = relatedProjectId ? ProjectParticipantsSelect : EmployeeSelect;

  return (
    <Form.Item label={label} data-qa="qa-59a6eezfixqiuad">
      {getFieldDecorator(TYPE_CO_RESPONSIBLES, {
        initialValue,
        rules: [validateIsRequire()]
      })(
        <Select
          data-qa="qa-yyh59iy14r15ule"
          projectId={relatedProjectId}
          isDisabled={isDisabled}
          hideWorkspace
          isMulti
          params={{ excludeBench: false }}
          {...props}
        />
      )}
    </Form.Item>
  );
};

ActionTypeCoResponsibles.propTypes = {
  getFieldDecorator: PropTypes.func.isRequired,
  relatedProjectId: PropTypes.string,
  initialValue: PropTypes.object,
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired
};

ActionTypeCoResponsibles.defaultProps = {
  relatedProjectId: undefined,
  initialValue: {},
  isDisabled: false
};

export default ActionTypeCoResponsibles;
