import React from 'react';
import { useDispatch } from 'react-redux';

import { fetchContactsCompaniesLocal } from 'store/contacts-companies';

// eslint-disable-next-line import/no-cycle
import CustomSelect from '..';

import WorkspaceOption from '../custom-select/workspace-option';

const mapValue = company => ({
  value: company.id,
  label: company
});

export const ContactsCompaniesSelect = ({ isMulti, ...props }) => {
  const dispatch = useDispatch();

  const fetchData = params =>
    dispatch(fetchContactsCompaniesLocal(params)).then(
      ({ entries, ...res }) => ({
        ...res,
        entries: entries.map(mapValue)
      })
    );

  return (
    <CustomSelect
      isSearchable
      isMulti={isMulti}
      isClearable
      isAsync
      fetchData={fetchData}
      closeMenuOnSelect={!isMulti}
      Option={WorkspaceOption}
      {...props}
    />
  );
};

export default ContactsCompaniesSelect;
