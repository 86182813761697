import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import Icon from 'components/common/icon';

import { hexEmojiByEmojiPostfix } from './utlls';

import styles from '../components.module.scss';

export const Emoji = ({ iconProps, popoverProps, onSelect, isDisabled }) => {
  const { t } = useTranslation('CommonChat');

  const categories = useMemo(
    () => [
      {
        id: 'frequent',
        name: t('RecentlyUsed')
      },
      {
        id: 'people',
        name: t('SmileysPeople')
      },
      {
        id: 'nature',
        name: t('AnimalsNature')
      },
      {
        id: 'foods',
        name: t('FoodDrinks')
      },
      {
        id: 'activity',
        name: t('Activities')
      },
      {
        id: 'places',
        name: t('TravelsPlaces')
      },
      {
        id: 'objects',
        name: t('Objects')
      },
      {
        id: 'symbols',
        name: t('Symbols')
      }
    ],
    [t]
  );

  const { className: iconClassName, ...restIconProps } = iconProps;

  const { overlayClassName, onVisibleChange, visible, ...restPopoverProps } =
    popoverProps;

  const categoryTranslations = Object.fromEntries(
    categories.map(cat => [cat.id, cat.name])
  );

  const pickerContent = useMemo(
    () => (
      <Picker
        data={data}
        onEmojiSelect={emoji => {
          onSelect({
            ...emoji,
            unified: hexEmojiByEmojiPostfix[emoji.unified] || emoji.unified
          });
        }}
        i18n={{
          search: t('Search', { ns: 'Common' }),
          categories: {
            search: t('Search', { ns: 'Common' }),
            ...categoryTranslations
          }
        }}
        noCountryFlags
        previewPosition="none"
        skinTonePosition="none"
        maxFrequentRows={1}
        perLine={7}
        categories={categories.map(cat => cat.id)}
        theme="auto"
        set="native"
        autoFocus
        navPosition="top"
        dynamicWidth={false}
        width={256}
      />
    ),
    [categories, t, onSelect, categoryTranslations]
  );

  return (
    <Popover
      trigger={!isDisabled ? 'hover' : ''}
      placement="top"
      overlayClassName={classnames(styles.emojiPopover, overlayClassName)}
      onOpenChange={onVisibleChange}
      open={visible}
      {...restPopoverProps}
      content={pickerContent}
    >
      <Icon
        type="smile"
        size={16}
        color="black-55"
        className={classnames(styles.emojiIcon, iconClassName)}
        {...restIconProps}
      />
    </Popover>
  );
};

Emoji.propTypes = {
  onSelect: PropTypes.func.isRequired,
  iconProps: PropTypes.object,
  popoverProps: PropTypes.object,
  isDisabled: PropTypes.bool
};

Emoji.defaultProps = {
  iconProps: {},
  popoverProps: {},
  isDisabled: false
};
