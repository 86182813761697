import { useMemo } from 'react';

import { getDateEndState, getSlaSource } from '../helpers/slaDateEndHandler';

export const useDateEndChangeWithSla = ({
  executorSla,
  isFromChat,
  isCopy,
  isAsset,
  isDeal,
  defaultValues,
  isSlaRequestFormChat
}) => {
  const source = getSlaSource(isFromChat, isCopy, isAsset, isDeal);

  return useMemo(
    () =>
      getDateEndState({
        source,
        executorSla,
        defaultValues,
        isSlaRequestFormChat
      }),
    [executorSla, defaultValues]
  );
};
