import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import { STATUS_COMPLETED, STATUS_FOR_EXECUTION, TASK } from 'constants/index';

import Modal from 'components/common/modal';
import Typography from 'components/common/typography';
import Button from 'components/common/button';
import useManageSubscribers from 'components/common/subscriptions/use-manage-subscribers';
import useValidityDate from 'components/common/validity-date/use-validity-date';
import {
  validateMaxLength,
  validateMinLength,
  validateRequired
} from 'components/common/hook-form';
import FormNewEditor from 'components/common/hook-form/markdown';

import { getHasUser } from 'store/workspace';

import { getFileIds } from 'hooks/common/use-file-upload/get-file-ids';
import { useUploadingFiles } from 'hooks/common/use-file-upload/use-uploading-files';

import EditorDateEndModal from '../editor-date-end-modal';

import styles from './reason-modal.module.scss';

const REASON = 'reason';

const ReasonForm = ({ data, onSubmit, onClose, setVisibleDateEndModal }) => {
  const { handleSubmit, ...methods } = useForm({
    defaultValues: {
      reason: {
        description: '',
        fileList: []
      }
    }
  });

  const isAuthor = useSelector(state => getHasUser(state)(data.task.author.id));

  const isResponsible = useSelector(state =>
    getHasUser(state)((data.task.responsible || {}).id)
  );

  const isController = useSelector(state =>
    getHasUser(state)((data.task.controller || {}).id)
  );

  const [isLoading, setIsLoading] = useState(false);

  const fileListWatch = methods.watch(REASON).fileList;

  const isUploadingFiles = useUploadingFiles(fileListWatch);

  const { t } = useTranslation(
    ['ReasonModal', 'Errors', 'Common', data.ns].filter(Boolean)
  );

  const { subscribeToNewAttachments } = useManageSubscribers();
  const { handleChangeValidityDateForNewAttachments } = useValidityDate();

  const isDisallowRulesEditor =
    data.editorProps && data.editorProps.disallowRulesEditor;

  const submitForm = async values => {
    const { reason } = values;

    try {
      setIsLoading(true);

      const isShowDateEndModal =
        (isAuthor ||
          isResponsible ||
          isController ||
          data.task.permissions.changeTaskStatus) &&
        data.task.status === STATUS_COMPLETED &&
        data.status === STATUS_FOR_EXECUTION &&
        moment(data.task.dateEnd).isBefore(moment(new Date()));

      const preparedData = {
        id: data.task.id,
        status: data.status,
        reason: reason.description,
        reasonFileList: getFileIds(reason.fileList)
      };

      if (isShowDateEndModal) {
        setVisibleDateEndModal(preparedData);
      } else {
        await onSubmit(preparedData);
      }

      subscribeToNewAttachments(reason.fileList);
      handleChangeValidityDateForNewAttachments({
        attachments: reason.fileList,
        actionDeps: {
          entityType: TASK,
          entityId: data.task.id
        }
      });

      onClose(isShowDateEndModal);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submitForm)}>
        <FormNewEditor
          label={
            data.editorProps && t(data.editorProps.labelEditor, { ns: data.ns })
          }
          name={REASON}
          placeholder={
            data.editorProps &&
            t(data.editorProps.placeholderEditor, { ns: data.ns })
          }
          showItems={data.editorProps && data.editorProps.showItemsEditor}
          rules={{
            validate: {
              requiredDesc: value => {
                if (!isDisallowRulesEditor && !value.description.trim())
                  return validateRequired();
                return true;
              },
              maxLength: value => {
                if (
                  !isDisallowRulesEditor &&
                  value.description.trim() &&
                  value.description.length > 2000
                ) {
                  return validateMaxLength(2000).message;
                }
                return true;
              },
              minLength: value => {
                if (
                  !isDisallowRulesEditor &&
                  value.description.trim() &&
                  value.description.length < 4
                ) {
                  return validateMinLength(4).message;
                }
                return true;
              }
            }
          }}
        />

        <Button
          type="primary"
          width="expanded"
          htmlType="submit"
          loading={isLoading || isUploadingFiles}
          style={{
            display: 'block',
            marginLeft: 'auto',
            fontSize: 16,
            fontWeight: 600
          }}
          data-qa="qa-mi85lk1cno0l3cv"
        >
          {data.submitButtonText
            ? t(data.submitButtonText, { ns: data.ns })
            : t('SendBtn')}

          {isUploadingFiles && ` ${t('FileLoading', { ns: 'Common' })}`}
        </Button>
      </form>
    </FormProvider>
  );
};

export const ReasonModal = ({
  visible,
  data,
  onUpdateStatus,
  resetReorderingTasks,
  onClose
}) => {
  const [visibleDateEndModal, setVisibleDateEndModal] = useState();

  const { t } = useTranslation(['ReasonModal', data.ns].filter(Boolean));

  return (
    <>
      <Modal
        open={visible}
        destroyOnClose
        width={644}
        contentStyle={{ padding: 24 }}
        title={
          <Typography.Title
            level={2}
            style={{ marginBottom: 0, lineHeight: 1.2 }}
          >
            {data.title
              ? t(data.title, { ns: data.ns })
              : t('DeclineTaskReasonHeading')}
          </Typography.Title>
        }
        data-qa="qa-k4jictg7gmy7vo2"
        onClose={() => {
          resetReorderingTasks();
          onClose();
        }}
      >
        {data.description && (
          <Typography.Paragraph className={styles.description}>
            {t(data.description, { ns: data.ns })}
          </Typography.Paragraph>
        )}

        <ReasonForm
          data={data}
          onSubmit={onUpdateStatus}
          onClose={onClose}
          setVisibleDateEndModal={setVisibleDateEndModal}
        />
      </Modal>

      <EditorDateEndModal
        onUpdateStatus={onUpdateStatus}
        visible={visibleDateEndModal !== undefined}
        data={visibleDateEndModal}
        resetReorderingTasks={resetReorderingTasks}
        onClose={() => {
          setVisibleDateEndModal(undefined);
          onClose();
        }}
      />
    </>
  );
};

ReasonModal.propTypes = {
  visible: PropTypes.bool,
  data: PropTypes.shape({
    task: PropTypes.object,
    status: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    submitButtonText: PropTypes.string,
    editorProps: PropTypes.shape({
      labelEditor: PropTypes.string,
      placeholderEditor: PropTypes.string,
      showItemsEditor: PropTypes.object,
      disallowRulesEditor: PropTypes.bool
    }),
    ns: PropTypes.string
  }),
  onUpdateStatus: PropTypes.func,
  onClose: PropTypes.func,
  resetReorderingTasks: PropTypes.func
};

ReasonModal.defaultProps = {
  visible: false,
  data: {
    task: {},
    status: '',
    title: '',
    description: '',
    submitButtonText: '',
    editorProps: {},
    ns: undefined
  },
  onUpdateStatus: () => {},
  onClose: () => {},
  resetReorderingTasks: () => {}
};

export default ReasonModal;
