import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import { TYPE_ORDER_STATUS, TYPE_ASSET, TYPE_CHANNEL } from 'constants';

import { fetchOneLocal } from 'store/assets';
import { fetchChannelsLocal } from 'store/channels';
import { fetchOrderStatusLocal } from 'store/order-statuses';

export const useExecutorSlaFetch = () => {
  const dispatch = useDispatch();

  const fetchChannelData = useCallback(
    async (type, channelId) => {
      if (type !== TYPE_CHANNEL) return null;

      const { results } = await dispatch(fetchChannelsLocal({ id: channelId }));
      return results[0];
    },
    [dispatch]
  );

  const fetchAssetData = useCallback(
    async (type, assetId) => {
      if (type !== TYPE_ASSET) return null;

      const data = await dispatch(fetchOneLocal({ id: assetId }));
      return data;
    },
    [dispatch]
  );

  const fetchOrderStatusData = useCallback(
    async (type, orderStatusId) => {
      if (type !== TYPE_ORDER_STATUS) return null;

      const data = await dispatch(fetchOrderStatusLocal({ id: orderStatusId }));
      return data;
    },
    [dispatch]
  );

  const getExecutorSla = useCallback(
    async (type, id) => {
      if (type === TYPE_CHANNEL) {
        const channel = await fetchChannelData(type, id);
        if (!channel) return null;

        return channel?.config?.slaConfig?.isActive
          ? channel?.config?.slaConfig?.executorSla
          : null;
      }

      if (type === TYPE_ASSET) {
        const asset = await fetchAssetData(type, id);
        if (!asset) return null;

        return asset?.slaIsActive ? asset?.executorSla : null;
      }

      if (type === TYPE_ORDER_STATUS) {
        const orderStatus = await fetchOrderStatusData(type, id);
        if (!orderStatus) return null;

        return orderStatus.executorSla;
      }

      return null;
    },
    [fetchChannelData, fetchAssetData, fetchOrderStatusData]
  );

  return getExecutorSla;
};
