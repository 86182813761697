import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { HIRING } from 'constants/index';

import CancellationModal from 'components/common/modals/cancellation';
import useValidityDate from 'components/common/validity-date/use-validity-date';
import useManageSubscribers from 'components/common/subscriptions/use-manage-subscribers';

import {
  fetchOrderStatus,
  cancelOrderStatus,
  terminateOrderStatus
} from 'store/order-statuses';
import { cancelOrderStatus as cancelOrderStatusHiring } from 'store/order-statuses/hiring';

import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

const PRICE = 'WrongCostRadio';
const TERM = 'WrongDeadlinesRadio';
const CONDITION = 'ViolationOfTermsRadio';
const NON_TARGETED = 'NotTargetRadio';
const IDLE = 'NoConnectRadio';
const SPAM = 'SpamRadio';

const OPTION_NAMESPACE = 'CancelOrder';

const OPTIONS = [
  {
    label: PRICE,
    ns: OPTION_NAMESPACE,
    value: PRICE
  },
  {
    label: TERM,
    ns: OPTION_NAMESPACE,
    value: TERM
  },
  {
    label: CONDITION,
    ns: OPTION_NAMESPACE,
    value: CONDITION
  },
  {
    label: NON_TARGETED,
    ns: OPTION_NAMESPACE,
    value: NON_TARGETED
  },
  {
    label: IDLE,
    ns: OPTION_NAMESPACE,
    value: IDLE
  },
  {
    label: SPAM,
    ns: OPTION_NAMESPACE,
    value: SPAM
  }
];

const HIRING_OPTIONS = [
  {
    label: NON_TARGETED,
    ns: OPTION_NAMESPACE,
    value: NON_TARGETED
  },
  {
    label: 'NoConnectRadio',
    ns: 'Contacts',
    value: 'NoConnectRadio'
  }
];

const OrderCancellationModal = ({
  kind,
  visible,
  orderStatusId,
  onClose,
  resetReordering,
  withTerminationFlow
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation('CancelOrder');

  const { handleChangeValidityDateForNewAttachments } = useValidityDate();
  const { subscribeToNewAttachments } = useManageSubscribers();

  const onSubmit = useCallback(
    async ({ reason, fileList, attachments }) => {
      if (kind === HIRING) {
        await dispatch(
          cancelOrderStatusHiring({ id: orderStatusId, reason, fileList })
        );
      } else {
        await dispatch(
          withTerminationFlow
            ? terminateOrderStatus({ id: orderStatusId, reason, fileList })
            : cancelOrderStatus({ id: orderStatusId, reason, fileList })
        );
      }

      const fetchedOrderStatus = await dispatch(
        fetchOrderStatus({ id: orderStatusId })
      );

      subscribeToNewAttachments(attachments);
      handleChangeValidityDateForNewAttachments({ attachments });

      showNoticeMessage({
        number: fetchedOrderStatus.isLocked
          ? NOTICE_NUMBER.orderStatusRequestToContractorSended
          : NOTICE_NUMBER.orderStatusStateChanged
      });
    },
    [dispatch, kind, orderStatusId]
  );

  const closeModal = () => {
    onClose();
    resetReordering();
  };

  return (
    <CancellationModal
      description={t('CancelOrderReasonDesc')}
      visible={visible}
      options={kind === HIRING ? HIRING_OPTIONS : OPTIONS}
      onSubmit={onSubmit}
      onClose={closeModal}
      callback={onClose}
      dataTestId="orders-cancellation-modal"
    />
  );
};

OrderCancellationModal.propTypes = {
  kind: PropTypes.string,
  visible: PropTypes.bool,
  orderStatusId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  resetReordering: PropTypes.func
};

OrderCancellationModal.defaultProps = {
  visible: false,
  orderStatusId: null,
  resetReordering: () => {},
  kind: null
};

export default OrderCancellationModal;
