import React, { useEffect, useState } from 'react';
import { Radio } from 'antd';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { INVITE_DATA } from 'constants/index';

import Button from 'components/common/button';
import VatNumber from 'components/common/controls/vat-number';
import { LayoutAuth } from 'components/common/layout/template';
import Typography from 'components/common/typography';
import { Questions } from 'components/auth-view/common/questions';
import Icon from 'components/common/icon';
import {
  validateRequired,
  validateMaxLength,
  validateMinLength,
  FormCountrySelect
} from 'components/common/hook-form';
import FormInput from 'components/common/hook-form/input';

import useAnalytics from 'hooks/analytics/use-analytics';
import { useAmplitude } from 'hooks/amplitude/use-amplitude';
import { useLocalStorage } from 'hooks/common';
import useAnalyticsParams from 'hooks/analytics/use-analytics-params';

import styles from './styles.module.scss';

const CreateWorkspaceForm = ({
  data,
  className,
  isLoading,
  isCreateWorkspace,
  onSubmit
}) => {
  const amplitude = useAmplitude();

  const [inviteData] = useLocalStorage(INVITE_DATA, {});

  const [vatNumber, setVatNumber] = useState(inviteData);

  const { reachGoal, trackPageView } = useAnalytics(true);
  const { params } = useAnalyticsParams();

  const { t } = useTranslation(['AccountAlmostReady', 'Errors']);

  const methods = useForm({
    defaultValues: {
      isNotVerified: true,
      unverifiedWorkspaceCountry: null,
      unverifiedWorkspaceTitle: undefined
    }
  });

  useEffect(trackPageView, []);

  const submitForm = values => {
    const result = values.isNotVerified
      ? {
          title: values.unverifiedWorkspaceTitle,
          country: values.unverifiedWorkspaceCountry.value,
          isNotVerified: values.isNotVerified,
          vatNumber: null
        }
      : vatNumber;

    onSubmit({ vatNumber: result });

    if (isJoinWorkspace) {
      amplitude.joinRequestSentEvent({
        id: vatNumber.id,
        analyticsProperty: params
      });
    }

    reachGoal('reg_step4-1', 'registration');
  };

  // Существует два варианта воркспейса.
  // 1. Id - существующая компания, зарегистрированная в системе
  // 2. title - компания не загегистрированная в системе
  const isJoinWorkspace = !!(vatNumber && vatNumber.id);
  const hasWorkspace = !!(vatNumber && vatNumber.title);

  const isStatusLiquidation = vatNumber && vatNumber.isStatusLiquidation;
  const isNotVerifiedWatch = methods.watch('isNotVerified');
  const unverifiedWorkspaceTitleWatch = methods.watch(
    'unverifiedWorkspaceTitle'
  );
  const isNotVerified =
    isNotVerifiedWatch === undefined ? true : isNotVerifiedWatch;
  const isDisabledSubmit =
    (!isNotVerified && (!hasWorkspace || isStatusLiquidation)) ||
    (isNotVerified && !unverifiedWorkspaceTitleWatch);

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(submitForm)}
          data-qa="qa-ox30h1x1vkkonay"
          className={classnames(styles.form, className, {
            [styles.isCreateWorkspace]: isCreateWorkspace
          })}
        >
          {!isCreateWorkspace && (
            <Typography.Title
              level={1}
              className={styles.title}
              data-qa="qa-buaujnej0jkrg5j"
            >
              {t('AccountAlmostReadyHeading')}
            </Typography.Title>
          )}

          <Controller
            name="isNotVerified"
            control={methods.control}
            render={({ field }) => (
              <Radio.Group {...field} className={styles.radioGruop}>
                <Radio value className={styles.radio}>
                  {t('UnverifiedCompany')}
                </Radio>

                <Radio value={false} className={styles.radio}>
                  {t('VerifyCompany')}
                  <Icon
                    type="verified"
                    color="yellow"
                    style={{ marginLeft: 4 }}
                  />
                </Radio>
              </Radio.Group>
            )}
          />

          <div
            className={classnames({
              [styles.flexForm]: isCreateWorkspace
            })}
            data-qa="qa-a8hhk0c5czimlue"
          >
            {isNotVerified ? (
              <div className={styles.unverifiedWorkspaceWrap}>
                <FormCountrySelect
                  name="unverifiedWorkspaceCountry"
                  isSearchable
                  isClearable={false}
                  className={styles.country}
                  defaultCountry={
                    inviteData.country
                      ? { id: inviteData.country }
                      : { code: data.countryCode }
                  }
                />

                <FormInput
                  name="unverifiedWorkspaceTitle"
                  placeholder={t('NameYourCompanyProfile')}
                  itemProps={{
                    className: styles.titleCompany
                  }}
                  rules={{
                    required: validateRequired(),
                    maxLength: validateMaxLength(250),
                    minLength: validateMinLength(2)
                  }}
                />
              </div>
            ) : (
              <VatNumber
                inputGroupClassName={styles.inputGroup}
                data-qa="qa-s400x0p9z6ko9ub"
                countrySelectClassName={styles.countrySelect}
                companySelectClassName={styles.companySelect}
                companyWrapClassName={styles.companyWrap}
                isRegistration
                // defaultValue={inviteData.vatNumber || undefined}
                defaultCountry={
                  inviteData.country
                    ? { id: inviteData.country }
                    : { code: data.countryCode }
                }
                value={vatNumber}
                inviteData={inviteData}
                onChange={setVatNumber}
              />
            )}

            <div
              className={classnames(styles.btnWrap)}
              data-qa="qa-4kkhpyi8xho3158"
            >
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                disabled={isDisabledSubmit}
                className={styles.btnSubmit}
                data-qa="qa-2kuapc5cx0fo3o4"
                loading={isLoading}
              >
                {t(isJoinWorkspace && !isNotVerified ? 'JoinBtn' : 'NextBtn')}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>

      <LayoutAuth.Footer>
        <Questions isNotVerified={isNotVerified} isRegistration />
      </LayoutAuth.Footer>
    </>
  );
};

export default CreateWorkspaceForm;
