export class TaskInfo {
  constructor(data) {
    this.contact = data.contact || null;
    this.executorSla = data.executorSla || null;
    this.slaObject = data.slaObject || null;
    this.slaObjectType = data.slaObjectType || null;
  }

  getPostData() {
    return {
      contactId: this.contact.id,
      executorSla: this.executorSla,
      slaObjectId: this.slaObject.id,
      slaObjectType: this.slaObjectType
    };
  }

  static extractObjectSlaFormData(value, objectExecutorSla) {
    return {
      contact: {
        id: value?.contact?.value
      },
      executorSla: objectExecutorSla,
      slaObjectType: value?.slaObject?.type,
      slaObject: {
        id: value?.slaObject?.item?.label?.id
      }
    };
  }

  static extractFormData(value, objectExecutorSla) {
    return this.extractObjectSlaFormData(value, objectExecutorSla);
  }
}
