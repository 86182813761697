import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TYPE_MEMBERS } from 'constants/index';

import Icon from 'components/common/icon';
// eslint-disable-next-line import/no-cycle
import FormProjectParticipantsSelect from 'components/common/hook-form/select/project-participants';
import Typography from 'components/common/typography';
import Tooltip from 'components/common/tooltip';

import MembersField from '../members-field';

const ActionTypeMembers = ({ projectId, defaultValues }) => {
  const { t } = useTranslation(['AddTask', 'Requests', 'Common']);

  const label = (
    <>
      <Typography.Text
        size="small"
        style={{ color: 'inherit', marginRight: 5 }}
      >
        {t('Coexecutors')}
      </Typography.Text>

      <Tooltip title={t('CoexecutorsTip', { ns: 'Requests' })}>
        <Icon
          type="question-circle"
          size={12}
          style={{ verticalAlign: 'unset' }}
        />
      </Tooltip>
    </>
  );

  if (projectId) {
    return (
      <FormProjectParticipantsSelect
        name={TYPE_MEMBERS}
        label={label}
        projectId={projectId}
        placeholder={t('SelectMembers', { ns: 'Common' })}
        hideWorkspace
        isMulti
        allowSetYourself
      />
    );
  }

  return <MembersField defaultValues={defaultValues} label={label} />;
};

ActionTypeMembers.propTypes = {
  projectId: PropTypes.number,
  defaultValues: PropTypes.shape({ members: PropTypes.array })
};

ActionTypeMembers.defaultProps = {
  projectId: undefined,
  defaultValues: {}
};

export default ActionTypeMembers;
