export const ASSISTANT_NAME = 'assistant_name';
export const ASSISTANT_RESPONSIBLE = 'assistant_responsible';
export const ASSISTANT_ATTACHMENTS = 'assistant_attachments';

export const STEPS = [
  {
    key: ASSISTANT_NAME
  },
  {
    key: ASSISTANT_RESPONSIBLE
  },
  {
    key: ASSISTANT_ATTACHMENTS
  }
];

export const mapValueForNewFields = value => ({
  value: value.id,
  label: value
});
