export const infoPositionValue = {
  TOP: 'top',
  BOTTOM: 'bottom'
};

export const infoTextTypeValue = {
  SHORT: 'short',
  LONG: 'long'
};

export const DEFAULT_PROGRESS_BAR_VALUE_BACKGROUND =
  'linear-gradient(90deg, #3BC274 0%, #F2C94C 47.21%, #F9716C 118.02%)';

export const DEFAULT_PROGRESS_BAR_CONTAINER_BACKGROUND = 'black-95';

export const DEFAULT_EXPIRED_COLOR = 'red-70';
