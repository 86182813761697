import React from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-cycle
import { FormInput, withoutBubbling } from 'components/common/hook-form';
import Button from 'components/common/button';

import styles from './rename-file.module.scss';

export const RenameFileForm = ({ defaultValues, isLoading, onSubmit }) => {
  const { t } = useTranslation(['RenameFileModal', 'Errors']);

  const methods = useForm({ defaultValues });

  return (
    <FormProvider {...methods}>
      <form
        className={styles.form}
        onSubmit={event =>
          withoutBubbling(event, () => methods.handleSubmit(onSubmit))
        }
      >
        <FormInput
          name="name"
          autoFocus
          rules={{
            required: t('RequiredField', { ns: 'Errors' }),
            pattern: {
              value: /^(?!\s*$)/,
              message: t('StringWithSpacesOnly', { ns: 'Errors' })
            }
          }}
          itemProps={{
            className: styles.input
          }}
        />

        <Button
          type="primary"
          size="large"
          htmlType="submit"
          className={styles.button}
          loading={isLoading}
        >
          {t('SaveBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

RenameFileForm.propTypes = {
  defaultValues: PropTypes.shape({
    name: PropTypes.string
  }),
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

RenameFileForm.defaultProps = {
  defaultValues: {},
  isLoading: false
};

export default RenameFileForm;
