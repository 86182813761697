export const getTimeByEstimate = (
  estimate = 0,
  withDefaultZeroValue = false
) => {
  const defaultValue = withDefaultZeroValue ? 0 : undefined;
  const hours = estimate > 0 ? Math.floor(estimate / 60) : defaultValue;
  const minutes = estimate > 0 ? estimate % 60 : defaultValue;

  return { hours, minutes };
};

export const getHoursByTime = time => (time / 60).toFixed(1);

export default getTimeByEstimate;
