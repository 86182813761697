import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { ATTACHMENT_TYPE_ENTITY } from 'constants/index';

import Drawer from 'components/common/drawer';
import { checkChangedDates } from 'components/requests-view/utils';
import useValidityDate from 'components/common/validity-date/use-validity-date';
import useManageSubscribers from 'components/common/subscriptions/use-manage-subscribers';

import {
  editRequestFields,
  setEstimate,
  fetchAttachments
} from 'store/requests';

import transformTask from 'utils/transform-task';
import { convertToMinutes } from 'utils/convert-to-minutes';
import { getFileIds } from 'hooks/common/use-file-upload/get-file-ids';
import { showNoticeMessage } from 'services/notice';
import { useRequestDataProvider } from 'hooks/requests';

import BaseForm from './base-form';
import EditorDatesModal from './editor-dates-modal';
import { clearAccordingType } from '../creator/utils';

const RequestEditorDrawer = ({
  visible,
  taskId,
  onClose,
  callback,
  ...drawerProps
}) => {
  const dispatch = useDispatch();

  const [afterChangeVisible, setAfterChangeVisible] = useState(false);
  const [task, isLoading] = useRequestDataProvider(afterChangeVisible, taskId);
  const [visibleEditorDatesModal, setVisibleEditorDatesModal] = useState(false);

  const transformedTask = task && transformTask(task);
  const [isLoadingSend, setIsLoadingSend] = useState(false);
  const [isLoadingDeps, setIsLoadingDeps] = useState(true);

  const { t } = useTranslation(['AddTask', 'Requests', 'Toast']);

  const dispatchNewFields = async values => {
    const value = {
      id: task.id,
      data: {
        ...values,
        responsible: (values.responsible || {}).value,
        controller: (values.controller || {}).value || null,
        description: values.description || undefined,
        dateEnd:
          values.dateEnd || values.dateEnd === undefined
            ? values.dateEnd
            : null,
        dateStart:
          values.dateStart || values.dateStart === undefined
            ? values.dateStart
            : null,
        fileList: getFileIds(values.fileList)
      }
    };

    await dispatch(editRequestFields({ value }));
  };

  const dispatchEstimate = async estimation => {
    if (estimation !== transformedTask.estimation) {
      await dispatch(setEstimate({ id: transformedTask.id, estimation }));
    }
  };

  const getEstimate = values =>
    convertToMinutes({
      days: +values.days,
      hours: +values.hours,
      minutes: +values.minutes
    });

  const dispatchNewValues = async values => {
    await dispatchEstimate(getEstimate(values));

    const editedTask = await dispatchNewFields(values);
    return editedTask;
  };

  const { manageBulkSubscribers } = useManageSubscribers();
  const { handleChangeBulkValidityDates } = useValidityDate();

  const sendValues = async ({ values }) => {
    try {
      setIsLoadingSend(true);

      const resultData = clearAccordingType(values);

      const dateStart =
        task.dateStart &&
        moment(values.dateStart)
          .startOf('minute')
          .isSame(moment(task.dateStart).startOf('minute'))
          ? undefined
          : values.dateStart;

      const dateEnd = moment(values.dateEnd).isSame(task.dateEnd)
        ? undefined
        : values.dateEnd;

      const editedTask = await dispatchNewValues({
        ...resultData,
        dateStart,
        dateEnd
      });

      await manageBulkSubscribers(values.fileList);

      await handleChangeBulkValidityDates({
        fileList: values.fileList,
        isFromEditor: true
      });

      await fetchFileList();

      await checkChangedDates({
        dispatch,
        task,
        dateStart: values.dateStart,
        dateEnd: values.dateEnd,
        setVisibleModal: setVisibleEditorDatesModal
      });

      if (callback) {
        await callback(editedTask);
      }

      onClose();

      return showNoticeMessage({
        customContent: t('RequestEdited', { ns: 'Toast' })
      });
    } finally {
      setIsLoadingSend(false);
    }
  };

  const fetchFileList = () =>
    dispatch(fetchAttachments({ id: task.id, source: ATTACHMENT_TYPE_ENTITY }));

  useEffect(() => {
    const fetchDependencies = () => {
      setIsLoadingDeps(true);

      Promise.all([fetchFileList()]).finally(() => setIsLoadingDeps(false));
    };

    if (afterChangeVisible && !!(task || {}).id) {
      fetchDependencies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afterChangeVisible, (task || {}).id]);

  return (
    <>
      <Drawer
        bodyStyle={{ padding: 0 }}
        destroyOnClose
        open={visible}
        maskClosable={false}
        afterOpenChange={setAfterChangeVisible}
        title={
          <Drawer.Title>
            {t('EditRequestHeading', { ns: 'Requests' })}
          </Drawer.Title>
        }
        width={620}
        onClose={onClose}
        {...drawerProps}
      >
        <BaseForm
          onSubmit={sendValues}
          defaultValues={transformedTask}
          isLoadingAll={isLoading || isLoadingDeps}
          isLoading={isLoadingSend}
        />
      </Drawer>

      <EditorDatesModal
        open={!!visibleEditorDatesModal}
        onClose={() => setVisibleEditorDatesModal(false)}
        params={{
          id: (task || {}).id,
          dateStart: (task || {}).dateStart,
          dateEnd: (task || {}).dateEnd
        }}
      />
    </>
  );
};

export default RequestEditorDrawer;
