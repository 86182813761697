import { PAYMENT_FAILD_NOTIFICATION_TEMPLATE_IDS } from 'constants/billing';

// eslint-disable-next-line import/no-cycle
import { chatMessageNew } from 'store/operator';

import handleActions from 'utils/redux-actions';

import {
  fetchAvailableAddons,
  fetchPaymentAccount,
  fetchPaymentTransactions,
  setHasPaymentAccess,
  setIsOnlyCompanyProfile
} from './actions';

const initialState = {
  isNeedToFetchAccount: false,
  isOnlyCompanyPofile: false,

  availableAddons: [],
  transactions: {}
};

export default handleActions(
  {
    [fetchPaymentTransactions.SUCCEEDED]: (state, { payload }) => {
      state.transactions = payload;

      return state;
    },

    [fetchPaymentAccount.SUCCEEDED]: state => {
      state.isNeedToFetchAccount = false;

      return state;
    },

    [fetchAvailableAddons.SUCCEEDED]: (state, { payload }) => {
      state.availableAddons = payload.results;

      return state;
    },

    [setHasPaymentAccess]: (state, { payload }) => {
      state.hasPaymentAccess = payload;

      return state;
    },

    [setIsOnlyCompanyProfile]: (state, { payload }) => {
      state.isOnlyCompanyPofile = payload;

      return state;
    },

    [chatMessageNew]: (state, { payload }) => {
      const { message, isNotification } = payload;

      if (
        isNotification &&
        PAYMENT_FAILD_NOTIFICATION_TEMPLATE_IDS.includes(
          message.notificationData.templateUid
        )
      ) {
        state.isNeedToFetchAccount = true;
      }

      return state;
    }
  },
  initialState
);
