import React, { Suspense } from 'react';
import { Spin, notification } from 'antd';
import axios from 'axios';
import { Translation } from 'react-i18next';

import { config } from 'config';

// eslint-disable-next-line import/no-cycle
import { errorResponseHandler, requestInterception } from '../utils';
import orderStatusesApi from './order-statuses';
import hiringApi from './hiring';

const API_ROOT = `${process.env.REACT_APP_API_PROTOCOL}://${config.REACT_APP_API_URL}`;

const api = axios.create({
  baseURL: API_ROOT,
  retryCount: 3
});

const handle400 = responseData => {
  const { errors, message } = responseData; // code, errors, message

  let description = '';

  if ((errors || []).length) {
    description = errors.map(e => (
      <Translation ns="CRM">{t => t(e.reason)}</Translation>
    ));
  }

  if (!description) {
    description = message;
  }

  notification.warn({
    message: (
      <Suspense fallback={<Spin size="small" />}>
        <Translation ns="Errors">{t => t('SomethingWentWrong')}</Translation>
      </Suspense>
    ),
    description: (
      <Suspense fallback={<Spin size="small" />}>{description}</Suspense>
    )
  });
};

api.interceptors.request.use(requestInterception, error =>
  Promise.reject(error)
);

api.interceptors.response.use(
  response => response,
  error =>
    errorResponseHandler(error, api, () => handle400(error.response.data))
);

export const apiV2 = {
  orderStatuses: orderStatusesApi(api),
  hiring: hiringApi(api)
};

export default apiV2;
