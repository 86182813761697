import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Spin } from 'antd';
import differenceBy from 'lodash/differenceBy';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Drawer from 'components/common/drawer';
import Typography from 'components/common/typography';

import {
  deleteAccess,
  deleteAccessContact,
  editAccess,
  editAccessContact,
  fetchAccessContacts,
  fetchAccessEmployees,
  fetchAttachment,
  shareAccess,
  shareAccessContact
} from 'store/attachments';

import { getIsEditableFile } from 'hooks/common/use-file-upload/types';
import { showNoticeMessage } from 'services/notice';

// eslint-disable-next-line import/no-cycle
import Form from './form';

export const AccessSettingsDrawer = ({ visible, file, onClose }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation(['MyDriveAccessSettings', 'Toast']);

  const [afterVisible, setAfterVisible] = useState(false);

  const [isDrawerLoading, setIsDrawerLoading] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [fileCreatorInfo, setFileCreatorInfo] = useState(file.creator);

  const [employees, setEmployees] = useState({
    default: [],
    current: [],
    canUpdate: false
  });

  const [contacts, setContacts] = useState({
    default: [],
    current: [],
    canUpdate: false
  });

  const isEditableFile = getIsEditableFile(file);

  const fetch = async () => {
    try {
      setIsDrawerLoading(true);

      const [
        { results: accessEmployees, canUpdate: canUpdateEmployees },
        { results: accessContacts, canUpdate: canUpdateContacts },
        fileData
      ] = await Promise.all([
        dispatch(
          fetchAccessEmployees({
            fileId: file.fileId,
            params: { limit: 10000 }
          })
        ),
        dispatch(
          fetchAccessContacts({ fileId: file.fileId, params: { limit: 10000 } })
        ),
        ...(file.creator
          ? []
          : [dispatch(fetchAttachment({ fileId: file.fileId }))])
      ]);

      setEmployees({
        default: accessEmployees,
        current: accessEmployees,
        canUpdate: canUpdateEmployees
      });

      setContacts({
        default: accessContacts,
        current: accessContacts,
        canUpdate: canUpdateContacts
      });

      if (fileData) {
        setFileCreatorInfo(fileData.creator);
      }
    } finally {
      setIsDrawerLoading(false);
    }
  };

  const onSubmit = async values => {
    try {
      setIsSubmitLoading(true);

      const { employee, contact } = values;

      const results = {
        employees: [],
        contacts: []
      };

      const errors = {
        employees: [],
        contacts: []
      };

      for (const e of employee.entities) {
        // eslint-disable-next-line no-await-in-loop
        await dispatch(
          shareAccess({
            fileId: file.fileId,
            employee: {
              id: e.value,
              role: employee.role.value
            }
          })
        )
          .then(result => results.employees.push(result))
          .catch(() => errors.employees.push(e));
      }

      for (const c of contact.entities) {
        // eslint-disable-next-line no-await-in-loop
        await dispatch(
          shareAccessContact({
            fileId: file.fileId,
            contact: {
              id: c.value,
              role: contact.role.value,
              to: contact.to
            }
          })
        )
          .then(result => results.contacts.push(result))
          .catch(() => errors.contacts.push(c));
      }

      const { deleted: deletedEmployees, edited: editedEmployees } =
        getChangedUser(employees.default, employees.current);

      const { deleted: deletedContacts, edited: editedContacts } =
        getChangedUser(contacts.default, contacts.current);

      for (const e of deletedEmployees) {
        // eslint-disable-next-line no-await-in-loop
        await dispatch(deleteAccess({ employeeId: e.id, fileId: file.fileId }));
      }

      for (const c of deletedContacts) {
        // eslint-disable-next-line no-await-in-loop
        await dispatch(
          deleteAccessContact({ contactId: c.id, fileId: file.fileId })
        );
      }

      for (const e of editedEmployees) {
        // eslint-disable-next-line no-await-in-loop
        await dispatch(
          editAccess({ employeeId: e.id, fileId: file.fileId, role: e.role })
        );
      }

      for (const c of editedContacts) {
        // eslint-disable-next-line no-await-in-loop
        await dispatch(
          editAccessContact({
            contactId: c.id,
            fileId: file.fileId,
            role: c.role,
            to: c.to
          })
        );
      }

      setEmployees(prev => ({
        current: [...prev.current, ...results.employees],
        default: [...prev.current, ...results.employees]
      }));
      setContacts(prev => ({
        current: [...prev.current, ...results.contacts],
        default: [...prev.current, ...results.contacts]
      }));

      if (!errors.contacts.length && !errors.employees.length) {
        showNoticeMessage({
          customContent: t('SettingsSaved', { ns: 'Toast' })
        });
      }

      return errors;
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const onEditAccess = ({ entityId, data, isContact }) => {
    const set = isContact ? setContacts : setEmployees;
    const entities = isContact ? contacts.current : employees.current;

    const index = entities.findIndex(e => e.id === entityId);

    if (index !== -1) {
      const newEntities = [...entities];
      newEntities[index] = { ...newEntities[index], ...data };

      set(prev => ({
        ...prev,
        current: newEntities
      }));
    }
  };

  const onDeleteAccess = async ({ entityId, isContact }) => {
    const set = isContact ? setContacts : setEmployees;
    const entities = isContact ? contacts.current : employees.current;

    set(prev => ({
      ...prev,
      current: entities.filter(e => e.id !== entityId)
    }));
  };

  useEffect(() => {
    if (afterVisible && file.fileId) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afterVisible, file.fileId]);

  return (
    <Drawer
      title={
        <>
          <Drawer.Back onClick={onClose} />

          <Drawer.Title>{t('AccessSettingsHeading')}</Drawer.Title>
        </>
      }
      open={visible}
      destroyOnClose
      onClose={onClose}
      afterOpenChange={setAfterVisible}
    >
      {isDrawerLoading ? (
        <Spin />
      ) : (
        <>
          <Typography.Paragraph>{t('AccessSettingsDesc')}</Typography.Paragraph>

          <Form
            employees={employees}
            contacts={contacts}
            isEditableFile={isEditableFile}
            isLoading={isSubmitLoading}
            onSubmit={onSubmit}
            onDeleteAccess={onDeleteAccess}
            onEditAccess={onEditAccess}
            creator={fileCreatorInfo}
          />
        </>
      )}
    </Drawer>
  );
};

const getChangedUser = (defaults, currents) => {
  const edited = [];

  const deleted = differenceBy(defaults, currents, 'id');

  currents.forEach(curr => {
    const user = defaults.find(d => d.id === curr.id);

    if (
      user &&
      (user.role !== curr.role || (user.to && !moment(user.to).isSame(curr.to)))
    ) {
      edited.push({
        ...curr,
        role: {
          old: user.role,
          new: curr.role
        }
      });
    }
  });

  return {
    deleted,
    edited
  };
};

AccessSettingsDrawer.propTypes = {
  visible: PropTypes.bool,
  file: PropTypes.shape({
    id: PropTypes.number
  }),
  onClose: PropTypes.func.isRequired
};

AccessSettingsDrawer.defaultProps = {
  visible: false,
  file: {}
};

export default AccessSettingsDrawer;
