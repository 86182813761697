import handleActions from 'utils/redux-actions';

import {
  addChannelsAssistantAttachments,
  deleteChannelsAssistantAttachments,
  fetchChannelsAssistant,
  fetchChannelsAssistantAttachments,
  resetChannelsAssistant,
  updateChannelsAssistant
} from './actions';

const initialState = {
  isLoading: false,
  createdAssistant: {},
  attachments: null,
  isLoadingAttachments: false
};

export const reducer = handleActions(
  {
    [fetchChannelsAssistant.START]: state => {
      state.isLoading = true;

      return state;
    },

    [fetchChannelsAssistant.SUCCEEDED]: (state, { payload }) => {
      const [firstResult] = payload.results;
      if (firstResult) {
        state.createdAssistant = firstResult;
      }

      state.isLoading = false;

      return state;
    },

    [fetchChannelsAssistant.ENDED]: state => {
      state.isLoading = false;

      return state;
    },

    [updateChannelsAssistant.SUCCEEDED]: (state, { payload }) => {
      state.createdAssistant = payload;

      return state;
    },

    [fetchChannelsAssistantAttachments.START]: state => {
      state.isLoadingAttachments = true;

      return state;
    },

    [fetchChannelsAssistantAttachments.SUCCEEDED]: (state, { payload }) => {
      state.attachments = payload;

      return state;
    },

    [fetchChannelsAssistantAttachments.ENDED]: state => {
      state.isLoadingAttachments = false;

      return state;
    },

    [deleteChannelsAssistantAttachments.SUCCEEDED]: (state, { payload }) => {
      state.createdAssistant = payload;

      return state;
    },

    [addChannelsAssistantAttachments.SUCCEEDED]: (state, { payload }) => {
      state.createdAssistant = payload;

      return state;
    },

    [resetChannelsAssistant]: () => initialState
  },
  initialState
);
