import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DATE_PICKER_TIME_FORMAT } from 'constants/index';

import Modal from 'components/common/modal';
import FormDatePicker from 'components/common/hook-form/date-picker';
import TimeIsOverIcon from 'components/common/icons/multicolor/time-is-over';
import Icon from 'components/common/icon';
import Button from 'components/common/button';
import Typography from 'components/common/typography';

import useMinMaxTime from 'hooks/common/use-min-max-time';

import styles from './editor-date-end-modal.module.scss';

const Form = ({ isLoading, onSubmit }) => {
  const methods = useForm();

  const dateEnd = methods.watch('dateEnd');

  const [minTimeStart, maxTimeStart] = useMinMaxTime({ startDate: dateEnd });

  const { t } = useTranslation(['Deadline', 'Errors']);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormDatePicker
          label={t('TaskDueDate')}
          data-qa="qa-nx099ht6vzyrlyu"
          name="dateEnd"
          itemProps={{ style: { textAlign: 'left', marginBottom: 20 } }}
          rules={{
            required: t('RequiredField', { ns: 'Errors' })
          }}
          minDate={new Date()}
          minTime={minTimeStart}
          maxTime={maxTimeStart}
          wrapperClassname={styles.datePicker}
        />

        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          style={{ width: '100%', height: 40, fontSize: 16, fontWeight: 600 }}
          data-qa="qa-nej6s6bhc50uwjz"
        >
          {t('BackToWorkBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

Form.defaultProps = {
  isLoading: false
};

export const EditorDateEndModal = ({
  visible,
  data,
  onUpdateStatus,
  onClose,
  resetReorderingTasks
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('Deadline');

  const onSubmit = async ({ dateEnd }) => {
    try {
      setIsLoading(true);
      await onUpdateStatus({
        ...data,
        dateEnd: moment(dateEnd, DATE_PICKER_TIME_FORMAT).format()
      });
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      width={439}
      destroyOnClose
      open={visible}
      contentStyle={{ padding: '59px 100px 32px', textAlign: 'center' }}
      onClose={() => {
        onClose();
        resetReorderingTasks();
      }}
    >
      <Icon component={TimeIsOverIcon} style={{ marginBottom: 32 }} />

      <Typography.Title level={3} style={{ marginBottom: 20 }}>
        {t('DeadlineHeading')}
      </Typography.Title>

      <Typography.Text style={{ display: 'block', marginBottom: 20 }}>
        {t('DeadlineDesc')}
      </Typography.Text>

      <Form isLoading={isLoading} onSubmit={onSubmit} />
    </Modal>
  );
};

EditorDateEndModal.propTypes = {
  visible: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    reason: PropTypes.string,
    reasonFileList: PropTypes.any
  }),
  onUpdateStatus: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  resetReorderingTasks: PropTypes.func
};

EditorDateEndModal.defaultProps = {
  visible: false,
  data: {},
  resetReorderingTasks: () => {}
};

export default EditorDateEndModal;
