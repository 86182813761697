import api from 'api';
import { createAction } from 'redux-actions';

import createActionThunk from 'store/actions-thunk';
// eslint-disable-next-line import/no-cycle
import { getWorkspaceId } from 'store/workspace';

export const fetchTariffs = createActionThunk('billing/tariffs/fetch', () =>
  api.billing.fetchTariffs().then(({ data }) => ({
    ...data,
    results: data.results.sort((a, b) => a.sort - b.sort)
  }))
);

export const fetchAvailableAddons = createActionThunk(
  'billing/available-addons/fetch',
  () =>
    api.billing.fetchAvailableAddons().then(({ data }) => ({
      ...data,
      results: data.results.sort((a, b) => a.sort - b.sort)
    }))
);

export const fetchTopics = createActionThunk('billing/topics/fetch', () =>
  api.billing.fetchTopics().then(({ data }) => ({
    ...data,
    results: data.results.sort((a, b) => a.sort - b.sort)
  }))
);

export const fetchPaymentTransactions = createActionThunk(
  'billing/transactions/fetch',
  ({ getState, params }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.billing
      .fetchTransactions({ workspaceId, ...params })
      .then(({ data }) => data);
  }
);

export const retryLastPaymentTransaction = createActionThunk(
  'billing/transactions/retry-last',
  ({ getState, cardId }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.billing
      .retryLastTransaction({ workspaceId, cardId })
      .then(({ data }) => ({ workspaceId, ...data }));
  }
);

export const addPaymentSubscription = createActionThunk(
  'billing/subscriptions/add',
  ({ workspaceId, ...payload }) =>
    api.billing
      .addSubscription({ workspaceId, ...payload })
      .then(({ data }) => ({ workspaceId, ...data }))
);

export const changePaymentSubscription = createActionThunk(
  'billing/subscriptions/change',
  ({ workspaceId, ...payload }) =>
    api.billing
      .changeSubscription({ workspaceId, ...payload })
      .then(({ data }) => ({ workspaceId, ...data }))
);

export const cancelPaymentSubscription = createActionThunk(
  'billing/subscriptions/cancel',
  ({ workspaceId }) =>
    api.billing
      .cancelSubscription({ workspaceId })
      .then(({ data }) => ({ workspaceId, account: data }))
);

export const fetchPaymentCheckout = createActionThunk(
  'billing/subscriptions/checkout',
  ({ getState, ...payload }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.billing
      .fetchPaymentCheckout({ workspaceId, ...payload })
      .then(({ data }) => data);
  }
);

export const addPaymentAddon = createActionThunk(
  'billing/addons/add',
  ({ getState, addonId, count }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.billing
      .addAddon({ workspaceId, addonId, count })
      .then(({ data }) => ({ workspaceId, ...data }));
  }
);

export const deletePaymentAddon = createActionThunk(
  'billing/addons/delete',
  ({ getState, addonId }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.billing
      .deleteAddon({ workspaceId, addonId })
      .then(() => ({ workspaceId, addonId }));
  }
);

export const fetchAddonCheckout = createActionThunk(
  'billing/addons/checkout',
  ({ getState, addonId }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.billing
      .fetchAddonCheckout({ workspaceId, addonId })
      .then(({ data }) => data);
  }
);

export const fetchPaymentCards = createActionThunk(
  'billing/cards/fetch',
  ({ getState, params }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.billing
      .fetchCards({ workspaceId, params })
      .then(({ data }) => data);
  }
);

export const addPaymentCard = createActionThunk(
  'billing/cards/add',
  ({ getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.billing.addCard({ workspaceId }).then(({ data }) => data);
  }
);

export const deletePaymentCard = createActionThunk(
  'billing/cards/delete',
  ({ getState, id }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.billing.deleteCard({ workspaceId, id }).then(() => ({ id }));
  }
);

export const makePaymentCardPrimary = createActionThunk(
  'billing/cards/make-primary',
  ({ getState, id }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.billing
      .makeCardPrimary({ workspaceId, id })
      .then(({ data }) => data);
  }
);

export const fetchPaymentAccount = createActionThunk(
  'billing/account/fetch',
  ({ getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.billing
      .fetchAccount({ workspaceId })
      .then(({ data }) => ({ workspaceId, account: data }));
  }
);

export const partialUpdatePaymentAccount = createActionThunk(
  'billing/account/partial-update',
  ({ getState, account }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.billing
      .partialUpdateAccount({ workspaceId, account })
      .then(({ data }) => ({ workspaceId, account: data }));
  }
);

export const fetchPaymentCheck = createActionThunk(
  'billing/transactions/check/fetch',
  ({ getState, transactionId, isPreview }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.billing
      .fetchCheck({ workspaceId, transactionId, isPreview })
      .then(({ data }) => data);
  }
);

export const fetchPaymentAct = createActionThunk(
  'billing/transactions/act/fetch',
  ({ getState, transactionId, isPreview }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.billing
      .fetchAct({ workspaceId, transactionId, isPreview })
      .then(({ data }) => data);
  }
);

export const setHasPaymentAccess = createAction(
  'billing/set-has-payment-access'
);
export const setIsOnlyCompanyProfile = createAction(
  'billing/set-is-only-company-profile'
);
