import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';
import Icon from 'components/common/icon';

import { convertToHoursAndMinutes } from 'utils/convertToHoursAndMinutes';
import themeVariables from 'theme';

import {
  infoPositionValue,
  infoTextTypeValue,
  DEFAULT_PROGRESS_BAR_VALUE_BACKGROUND,
  DEFAULT_PROGRESS_BAR_CONTAINER_BACKGROUND,
  DEFAULT_EXPIRED_COLOR
} from './constants';

import styles from './ProgressBar.module.scss';

export const ProgressBar = ({
  valuePercentage,
  timeLeft,
  isCompleted = false,
  isOutdated = false,
  withInfo = true,
  infoPosition = infoPositionValue.TOP,
  infoTextType = infoTextTypeValue.LONG,
  progressBarValueBackground = DEFAULT_PROGRESS_BAR_VALUE_BACKGROUND,
  progressBarContainerBackground = DEFAULT_PROGRESS_BAR_CONTAINER_BACKGROUND,
  expiredColor = DEFAULT_EXPIRED_COLOR,
  customTimeLeftLabel
}) => {
  const { t } = useTranslation('Common');

  const fillerRelativePercentage = (100 / valuePercentage) * 100;

  const hasLongInfoTextType = infoTextType === infoTextTypeValue.LONG;

  const hasTopInfoPosition = infoPosition === infoPositionValue.TOP;

  const timeLeftDefaultLabel = hasLongInfoTextType
    ? t('RemainingLabelLong')
    : t('RemainingLabelShort');

  const timeLeftLabel = customTimeLeftLabel || timeLeftDefaultLabel;

  const { hours, minutes } = convertToHoursAndMinutes(timeLeft);

  const formattedMinutes = minutes === 0 ? '00' : minutes;

  return (
    <div
      role="progressbar"
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={valuePercentage}
      className={styles.root}
      style={{ rowGap: hasLongInfoTextType ? 12 : 8 }}
    >
      {withInfo && (
        <div className={styles.infoContainer}>
          <div className={styles.infoTimeLeft}>
            {hasLongInfoTextType && (
              <Icon type="clock-circle" color="black-45" size={14} />
            )}
            <Typography.Text color="black-45">{timeLeftLabel}</Typography.Text>
          </div>
          <Typography.Text color={isOutdated ? 'black-45' : 'black'}>
            {isCompleted ? (
              <Icon
                type="check-circle"
                size={14}
                color="green-60"
                theme="filled"
              />
            ) : (
              <Trans
                i18nKey="HoursAndMinutesTimeFormat"
                ns="Common"
                values={{
                  hours,
                  minutes: formattedMinutes
                }}
                components={{
                  span: <span />
                }}
              />
            )}
          </Typography.Text>
        </div>
      )}

      <div
        className={styles.progressBarContainer}
        style={{
          background: themeVariables.color[progressBarContainerBackground],
          order: hasTopInfoPosition ? 0 : -1
        }}
      >
        <div
          className={styles.progressBarFiller}
          style={{
            width: `${valuePercentage}%`
          }}
        >
          <div
            className={styles.progressBarFillerBackground}
            style={{
              width: `${fillerRelativePercentage}%`,
              background: isOutdated
                ? themeVariables.color[expiredColor]
                : progressBarValueBackground
            }}
          />
        </div>
      </div>
    </div>
  );
};
