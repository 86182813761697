import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';

import { SnoozeChatIcon } from 'components/common/icons';
import Icon from 'components/common/icon';
import Typography from 'components/common/typography';
// eslint-disable-next-line import/no-cycle
import {
  FormDatePicker,
  FormInput,
  validateMaxLength
} from 'components/common/hook-form';
import Button from 'components/common/button';

import useMinMaxTime from 'hooks/common/use-min-max-time';

import styles from './defer-dialog.module.scss';

const { Title, Text } = Typography;

const Form = ({ onSubmit, isLoading }) => {
  const methods = useForm();
  const { t } = useTranslation(['SnoozeContact', 'Errors']);

  const deferUntilWatch = methods.watch('deferUntil');
  const [minTimeStart, maxTimeStart] = useMinMaxTime({
    startDate: deferUntilWatch
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className={styles.form}>
        <Icon component={SnoozeChatIcon} />

        <Title className={styles.title}>{t('SnoozeContactHeading')}</Title>

        <Text color="black-45" className={styles.description}>
          {t('SnoozeContactDesc')}
        </Text>

        <div className={styles.formElements}>
          <FormDatePicker
            name="deferUntil"
            minDate={new Date()}
            wrapperClassname={styles.datePicker}
            rules={{
              required: t('RequiredField', { ns: 'Errors' }),
              validate: value =>
                !value || moment(value).isSameOrAfter(moment())
                  ? true
                  : t('CannotBeEarlierThanCurrentDate', {
                      ns: 'Errors'
                    })
            }}
            minTime={minTimeStart}
            maxTime={maxTimeStart}
            label={t('SnoozeDueDate')}
            placeholderText={t('ChooseDate')}
          />

          <FormInput
            name="comment"
            itemProps={{
              className: styles.input
            }}
            label={t('Comment')}
            rules={{
              maxLength: validateMaxLength(512)
            }}
            placeholder={t('EnterComment')}
          />
        </div>

        <Button
          htmlType="submit"
          size="large"
          type="primary"
          loading={isLoading}
          className={styles.button}
        >
          {t('SnoozeBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool
};

Form.defaultProps = {
  onSubmit: () => {},
  isLoading: false
};

export default Form;
