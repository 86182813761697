import { createSelector } from 'reselect';

const getReports = state => state.reports;

export const getReportsAnalysts = createSelector(
  getReports,
  reports => reports.analysts
);

export const getReportsEntries = createSelector(
  getReports,
  reports => reports.entries
);

export const getReportsIsLoading = createSelector(
  getReports,
  reports => reports.isLoading
);

export const getReportsTotalItems = createSelector(
  getReports,
  reports => reports.totalItems
);

export const getSelectedReport = createSelector(
  getReports,
  reports => reports.selected
);

export const getReportFilter = createSelector(
  getReports,
  reports => reports.filter
);

export const getSelectedVisualization = createSelector(
  getReports,
  reports => reports.selectedVisualization
)
