import api from 'api';

import { getWorkspaceId } from 'store/workspace';
import createActionThunk from 'store/actions-thunk';
import { createAction } from 'redux-actions';

export const createChannelsAssistant = createActionThunk(
  'channels-assistant/create-channels-assistant',
  ({ payload, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.channelsAssistant
      .create({ workspaceId, data: payload })
      .then(({ data }) => data);
  }
);

export const fetchChannelsAssistant = createActionThunk(
  'channels-assistant/fetch-channels-assistant',
  ({ getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.channelsAssistant
      .fetch({ workspaceId })
      .then(({ data }) => data);
  }
);

export const updateChannelsAssistant = createActionThunk(
  'channels-assistant/update-channels-assistant',
  ({ id, payload, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.channelsAssistant
      .update({ workspaceId, id, data: payload })
      .then(({ data }) => data);
  }
);

export const fetchChannelsAssistantAttachments = createActionThunk(
  'channels-assistant/fetch-channels-assistant-attachments',
  ({ getState, id }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.channelsAssistant
      .fetchAttachments({ workspaceId, id })
      .then(({ data }) => data);
  }
);

export const deleteChannelsAssistantAttachments = createActionThunk(
  'channels-assistant/delete-channels-assistant-attachments',
  ({ id, payload, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.channelsAssistant
      .deleteAttachments({ workspaceId, id, data: payload })
      .then(({ data }) => data);
  }
);

export const addChannelsAssistantAttachments = createActionThunk(
  'channels-assistant/add-channels-assistant-attachments',
  ({ id, payload, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.channelsAssistant
      .addAttachments({ workspaceId, id, data: payload })
      .then(({ data }) => data);
  }
);

export const resetChannelsAssistant = createAction(
  'channels-assistant/reset-channels-assistant'
);
