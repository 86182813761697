import axios from 'axios';
import { useSelector } from 'react-redux';
import commonApi from 'api';
import { requestInterception, errorResponseHandler } from 'api/utils';
import { handle400 } from 'api/v1';

import { getWorkspaceId } from 'store/workspace';

import { config } from 'config';

const API_ROOT = `${process.env.REACT_APP_API_PROTOCOL}://${config.REACT_APP_API_URL}`;
const FILE_ID = 'file_id';

const api = axios.create({ baseURL: API_ROOT });

const { CancelToken } = axios;
let source = CancelToken.source();

api.interceptors.request.use(requestInterception, error =>
  Promise.reject(error)
);

api.interceptors.response.use(
  response => response,
  error => errorResponseHandler(error, api, () => handle400(error.response))
);

const getPercent = (loadedSize, fileSize) => (loadedSize / fileSize) * 100;

export const useApi = () => {
  const currentWorkspaceId = useSelector(getWorkspaceId);

  const getPassword = workspaceId => {
    resetCancledToke();

    return commonApi.attachments.password(workspaceId);
  };

  // eslint-disable-next-line default-param-last
  const auth = async (workspaceId = currentWorkspaceId, userId) => {
    const { password } = await getPassword(workspaceId);

    const { data } = await api.post('/drive-storage/api/v1/auth', {
      user_id: userId,
      password,
      workspace: workspaceId
    });

    return data.access_token;
  };

  const splitFile = async ({
    file,
    initialOffset = 0,
    upload,
    onSuccess,
    onProgress
  }) => {
    const chunkSize = 1024 * 1024; // 1MB Chunk size
    const fileSize = file.size;
    let offset = initialOffset;
    let loadedSize = 0;
    let res;

    const reader = new FileReader();
    reader.onload = async e => {
      const blob = new Blob([new Uint8Array(e.target.result)], {
        type: file.type
      });

      while (offset < fileSize) {
        const currentFilePart = blob.slice(offset, offset + chunkSize);
        // eslint-disable-next-line no-await-in-loop
        res = await upload({ part: currentFilePart, offset, chunkSize });
        loadedSize += currentFilePart.size;
        onProgress({ percent: getPercent(loadedSize, fileSize) }, file);
        offset += chunkSize;
      }
      onSuccess({ id: res }, file);
    };
    reader.readAsArrayBuffer(file);
  };

  const uploadFile = ({ file, workspaceId = currentWorkspaceId }) =>
    api
      .post('/drive-storage/api/v1/upload', {
        title: file.name,
        size: file.size,
        mimetype: file.type,
        workspace: workspaceId
      })
      .then(({ data }) => data);

  const uploadPart = ({ file_id: fileId, offset, part }) =>
    api
      .post(`/drive-storage/api/v1/upload/${fileId}/${offset}`, part, {
        cancelToken: source.token
      })
      .then(({ data }) => data[FILE_ID]);

  const cancelUpload = (file, callback = () => {}) => {
    if (file.percent !== undefined && file.percent !== 100) {
      source.cancel('Operation canceled by the user.');
    }

    callback(file);
  };

  const createFile = async ({
    title,
    type,
    workspaceId = currentWorkspaceId
  }) =>
    api
      .post('/drive-storage/api/v1/files/create', {
        title,
        type,
        workspace: workspaceId
      })
      .then(({ data }) => data);

  const renameFile = async ({ uuid, name }) =>
    api
      .put(`/drive-storage/api/v1/files/${uuid}/rename`, {
        name
      })
      .then(({ data }) => data);

  const resetCancledToke = () => {
    source = CancelToken.source();
  };

  return {
    getPassword,
    auth,
    splitFile,
    uploadFile,
    uploadPart,
    cancelUpload,
    createFile,
    renameFile
  };
};
