import React from 'react';
import { Collapse } from 'antd';

import Icon from 'components/common/icon';

import styles from './collapse.module.scss';

const { Panel } = Collapse;

export const CustomCollapse = ({ children, ...props }) => (
  <Collapse
    className={styles.root}
    data-qa="qa-byuecszhn6h9uj1"
    bordered={false}
    expandIcon={panelProps => {
      if (panelProps.showArrow) {
        return (
          <Icon
            type="arrow"
            size={24}
            color="black-55"
            side={panelProps.isActive ? 'default' : 'right'}
            style={{
              marginRight: 2,
              position: 'relative',
              left: 'auto'
            }}
          />
        );
      }

      return null;
    }}
    {...props}
  >
    {children}
  </Collapse>
);

CustomCollapse.Panel = Panel;

export default CustomCollapse;
