import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-cycle
import { ChannelSelect } from 'components/common/controls/custom-select';

import FormItem from '../form-item';

export const FormChanneleSelect = ({
  rules,
  name,
  label,
  itemProps,
  ...props
}) => {
  const { control, formState } = useFormContext();

  return (
    <FormItem
      label={label}
      name={name}
      {...itemProps}
      errors={formState.errors}
    >
      <Controller
        control={control}
        rules={rules}
        render={({ field }) => <ChannelSelect {...field} {...props} />}
      />
    </FormItem>
  );
};

FormChanneleSelect.defaultProps = {
  rules: PropTypes.any,
  itemProps: PropTypes.object
};

FormChanneleSelect.defaultProps = {
  rules: {},
  itemProps: {}
};

export default FormChanneleSelect;
