import {
  ACTIVITY_TYPES,
  CHART_VISUALIZATION_TYPE,
  CONTAINS_ANY_OPTION,
  PERIODS,
  REPORT_TERMS_OPTIONS,
  VISUALIZATION_OPTIONS
} from 'constants/index';

import { combineActionsThunk } from 'store/actions-thunk';

import handleActions from 'utils/redux-actions';

import {
  addReportsAnalyst,
  clearReportEntries,
  clearReportFilter,
  deleteReportTemplate,
  deleteReportsAnalyst,
  fetchRedashDashboard,
  fetchRedashQuery,
  fetchRedashReportData,
  fetchReport,
  fetchReportTemplate,
  fetchReportTemplates,
  fetchReports,
  fetchReportsAnalysts,
  partialUpdateReportTemplate,
  setReportFilterChannelId,
  setReportFilterChannelKind,
  setReportFilterCompany,
  setReportFilterContactCompanyIsIndividual,
  setReportFilterContactFirstPageLink,
  setReportFilterContactStatus,
  setReportFilterEmployee,
  setReportFilterFunnelType,
  setReportFilterPeriod,
  setReportFilterProject,
  setReportFilterTag,
  setReportFilterTaskKind,
  setReportFilterActivityType,
  setReportFilterSprint,
  setReportFilterTaskStatus,
  setReportFilterContact,
  setReportFilterReportTerms,
  setReportFilterShowOverdueIssues,
  setReportFilterEmployee2,
  setReportFilterCurrency,
  setReportFilterHideEarlierContacts,
  setReportFilterHideConversion,
  setReportFilterGrade,
  setReportFilterEmployee3,
  setReportFilterIsFeedback,
  setReportFilterReturnedToWork,
  setReportFilterOrderTag,
  setReportFilterPrice,
  setReportFilterOperator,
  setReportSelectedVisualization,
  setReportFilterOrder,
  setReportFilterOrderStatus,
  setReportFilterOrderType
} from './actions';

export const initialReportFilter = {
  employee: [],
  employee2: [],
  employee3: [],
  period: PERIODS.find(({ isDefault }) => isDefault),
  project: [],
  company: [],
  funnelType: undefined,
  contactStatus: [],
  channelKind: [],
  channelId: [],
  contactCompanyIsIndividual: undefined,
  contactFirstPageLink: undefined,
  tag: {
    ids: [],
    condition: CONTAINS_ANY_OPTION
  },
  taskKind: [],
  sprint: undefined,
  taskStatus: [],
  contact: [],
  reportTerms: REPORT_TERMS_OPTIONS[0],
  showOverdueIssues: undefined,
  currency: [],
  hideEarlierContacts: undefined,
  hideConversion: undefined,
  grade: [],
  isFeedback: undefined,
  returnedToWork: undefined,
  orderTag: {
    ids: [],
    condition: CONTAINS_ANY_OPTION
  },
  price: '',
  activity: ACTIVITY_TYPES.map(item => ({
    ...item,
    value: item.id
  })),
  channelOperator: [],
  order: [],
  orderStatus: [],
  orderType: undefined
};

const initialState = {
  analysts: [],

  isLoading: false,
  totalItems: 0,
  entries: [],
  selectedVisualization: VISUALIZATION_OPTIONS[0],

  filter: initialReportFilter,
  selected: null
};

export default handleActions(
  {
    [combineActionsThunk(fetchReports.START, fetchReportTemplates.START)]:
      state => {
        state.isLoading = true;

        return state;
      },

    [combineActionsThunk(
      fetchReports.SUCCEEDED,
      fetchReportTemplates.SUCCEEDED
    )]: (state, { payload }) => {
      state.totalItems = payload.count;
      state.entries = [...state.entries, ...payload.results];

      return state;
    },

    [combineActionsThunk(fetchReports.ENDED, fetchReportTemplates.ENDED)]:
      state => {
        state.isLoading = false;

        return state;
      },

    [combineActionsThunk(fetchReport.SUCCEEDED, fetchReportTemplate.SUCCEEDED)]:
      (state, { payload }) => {
        if (!state.selected) {
          state.selected = {};
        }

        state.selected = payload;

        return state;
      },

    [partialUpdateReportTemplate.SUCCEEDED]: (state, { payload }) => {
      Object.keys(payload).forEach(key => {
        state.selected[key] = payload[key];
      });

      return state;
    },

    [deleteReportTemplate.SUCCEEDED]: (state, { args }) => {
      state.totalItems -= 1;
      state.entries = state.entries.filter(e => e.id !== args.id);

      return state;
    },

    [fetchReportsAnalysts.SUCCEEDED]: (state, { payload }) => {
      state.analysts = payload.results;

      return state;
    },

    [addReportsAnalyst.SUCCEEDED]: (state, { payload }) => {
      state.analysts = [...state.analysts, payload];

      return state;
    },

    [deleteReportsAnalyst.SUCCEEDED]: (state, { payload }) => {
      state.analysts = state.analysts.filter(a => a.employee.id !== payload);

      return state;
    },

    [fetchRedashReportData.SUCCEEDED]: (state, { payload }) => {
      const { queryId, data } = payload;

      if (!state.selected.data) {
        state.selected.data = {};
      }

      state.selected.data[queryId] = data;

      return state;
    },

    [fetchRedashQuery.SUCCEEDED]: (state, { payload }) => {
      state.selected.redashParameters = payload.options.parameters;
      state.selected.visualizations = payload.visualizations.map(v => {
        const visualization = {
          ...v,
          query: { id: state.selected.reportQueryId }
        };

        if (visualization.type === CHART_VISUALIZATION_TYPE) {
          return {
            ...visualization,
            type: `${visualization.type}-${visualization.options.globalSeriesType}`
          };
        }

        return visualization;
      });

      return state;
    },

    [fetchRedashDashboard.SUCCEEDED]: (state, { payload }) => {
      state.selected.redashParameters =
        payload.widgets[0].visualization.query.options.parameters;

      state.selected.visualizations = payload.widgets.map(
        ({ visualization }) => {
          if (visualization.type === CHART_VISUALIZATION_TYPE) {
            return {
              ...visualization,
              type: `${visualization.type}-${visualization.options.globalSeriesType}`
            };
          }

          return visualization;
        }
      );

      return state;
    },

    [setReportSelectedVisualization]: (state, { payload }) => {
      state.selectedVisualization = payload;

      return state;
    },

    [clearReportEntries]: state => {
      state.totalItems = 0;
      state.entries = [];

      return state;
    },

    [clearReportFilter]: state => {
      state.filter = { ...initialReportFilter };

      return state;
    },

    [setReportFilterEmployee]: (state, { payload }) => {
      state.filter.employee = payload;

      return state;
    },

    [setReportFilterEmployee2]: (state, { payload }) => {
      state.filter.employee2 = payload;

      return state;
    },

    [setReportFilterProject]: (state, { payload }) => {
      state.filter.project = payload;

      return state;
    },

    [setReportFilterPeriod]: (state, { payload }) => {
      state.filter.period = payload;

      return state;
    },

    [setReportFilterCompany]: (state, { payload }) => {
      state.filter.company = payload;

      return state;
    },

    [setReportFilterFunnelType]: (state, { payload }) => {
      state.filter.funnelType = payload;

      return state;
    },

    [setReportFilterTag]: (state, { payload }) => {
      state.filter.tag = payload;

      return state;
    },

    [setReportFilterContactStatus]: (state, { payload }) => {
      state.filter.contactStatus = payload;

      return state;
    },

    [setReportFilterChannelKind]: (state, { payload }) => {
      state.filter.channelKind = payload;

      return state;
    },

    [setReportFilterChannelId]: (state, { payload }) => {
      state.filter.channelId = payload;

      return state;
    },

    [setReportFilterContactCompanyIsIndividual]: (state, { payload }) => {
      state.filter.contactCompanyIsIndividual = payload;

      return state;
    },

    [setReportFilterContactFirstPageLink]: (state, { payload }) => {
      state.filter.contactFirstPageLink = payload;

      return state;
    },

    [setReportFilterTaskKind]: (state, { payload }) => {
      state.filter.taskKind = payload;

      return state;
    },

    [setReportFilterActivityType]: (state, { payload }) => {
      state.filter.activity = payload;

      return state;
    },

    [setReportFilterSprint]: (state, { payload }) => {
      state.filter.sprint = payload;

      if (payload) {
        state.filter.period = {
          ...state.filter.period,
          value: [payload.label.dateStart, payload.label.dateEnd]
        };
      }

      return state;
    },

    [setReportFilterTaskStatus]: (state, { payload }) => {
      state.filter.taskStatus = payload;

      return state;
    },

    [setReportFilterContact]: (state, { payload }) => {
      state.filter.contact = payload;

      return state;
    },

    [setReportFilterReportTerms]: (state, { payload }) => {
      state.filter.reportTerms = payload;

      return state;
    },

    [setReportFilterShowOverdueIssues]: (state, { payload }) => {
      state.filter.showOverdueIssues = payload;

      return state;
    },

    [setReportFilterCurrency]: (state, { payload }) => {
      state.filter.currency = payload;

      return state;
    },

    [setReportFilterHideEarlierContacts]: (state, { payload }) => {
      state.filter.hideEarlierContacts = payload;

      return state;
    },

    [setReportFilterHideConversion]: (state, { payload }) => {
      state.filter.hideConversion = payload;

      return state;
    },

    [setReportFilterGrade]: (state, { payload }) => {
      state.filter.grade = payload;

      return state;
    },

    [setReportFilterEmployee3]: (state, { payload }) => {
      state.filter.employee3 = payload;

      return state;
    },

    [setReportFilterIsFeedback]: (state, { payload }) => {
      state.filter.isFeedback = payload;

      return state;
    },

    [setReportFilterReturnedToWork]: (state, { payload }) => {
      state.filter.returnedToWork = payload;

      return state;
    },

    [setReportFilterOrderTag]: (state, { payload }) => {
      state.filter.orderTag = payload;

      return state;
    },

    [setReportFilterPrice]: (state, { payload }) => {
      state.filter.price = payload;

      return state;
    },

    [setReportFilterOperator]: (state, { payload }) => {
      state.filter.channelOperator = payload;
      return state;
    },

    [setReportFilterOrder]: (state, { payload }) => {
      state.filter.order = payload;

      return state;
    },

    [setReportFilterOrderStatus]: (state, { payload }) => {
      state.filter.orderStatus = payload;

      return state;
    },

    [setReportFilterOrderType]: (state, { payload }) => {
      state.filter.orderType = payload;

      return state;
    }
  },
  initialState
);
