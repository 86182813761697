import React, { createRef, useState } from 'react';
import { Pagination, Spin } from 'antd';
import { Document, pdfjs } from 'react-pdf';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-cycle
import Page from './page';

import styles from './pdf-item.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export const PdfItem = ({ url, ...props }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const refs = [...Array(numPages).keys()].reduce((acc, curr) => {
    acc[curr + 1] = createRef();

    return acc;
  }, {});

  const onDocumentLoadSuccess = ({ numPages: pages }) => setNumPages(pages);
  const setPage = page => {
    if (refs[page].current) {
      refs[page].current.scrollIntoView();
    }

    setPageNumber(page);
  };

  return (
    <div className={styles.root}>
      <Document
        className={styles.document}
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<Spin className={styles.spin} />}
        {...props}
      >
        {Array.from(new Array(numPages), (_, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            inputRef={refs[index + 1]}
            setPageNumber={setPageNumber}
          />
        ))}
      </Document>

      <Pagination
        current={pageNumber}
        pageSize={1}
        total={numPages}
        hideOnSinglePage
        onChange={setPage}
        className={styles.pagination}
      />
    </div>
  );
};

PdfItem.propTypes = {
  url: PropTypes.string.isRequired
};

export default PdfItem;
