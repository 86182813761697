import qs from 'qs';

import { ORDERS } from 'constants/index';

import { ORDER_STATUSES_WEB } from './order-statuses';

export default api => ({
  fetchOne: (orderId, workspaceId) =>
    api
      .get(`/v1/${workspaceId}/${ORDERS}/by-id/${orderId}/`)
      .then(({ data }) => data),

  fetch: (
    workspace,
    status,
    offset,
    limit,
    search,
    category,
    employee,
    // eslint-disable-next-line default-param-last
    dateRange = [],
    counterpart,
    isCustomer
  ) =>
    api.get(`/v1/${workspace}/${ORDERS}/`, {
      params: {
        status,
        offset,
        limit,
        search,
        category,
        employee,
        counterpart,
        date_end_gte: dateRange[0],
        date_end_lte: dateRange[1],
        isCustomer
      },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      }
    }),

  create: order => api.post(`/v1/${order.workspaceId}/${ORDERS}/`, order),

  archive: (orderId, workspace) =>
    api.delete(`/v1/${workspace}/${ORDERS}/${orderId}/`),

  updateFields: (status, workspaceId) =>
    api.patch(`/v1/${workspaceId}/${ORDER_STATUSES_WEB}/${status.id}/`, status),

  // TEMPLATES

  createTemplate: order =>
    api.post(`/v1/${order.workspaceId}/${ORDERS}/templates/`, order),

  fetchTemplates: ({ workspaceId, offset, limit }) =>
    api.get(`/v1/${workspaceId}/${ORDERS}/templates/`, {
      params: {
        limit,
        offset
      },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      }
    }),

  updateTemplate: ({ workspaceId, id, order }) =>
    api.patch(`/v1/${workspaceId}/${ORDERS}/templates/${id}/`, order),

  shareTemplate: ({ workspaceId, id, employeesIds }) =>
    api.post(`/v1/${workspaceId}/${ORDERS}/templates/${id}/share/`, {
      employeesIds
    }),

  deleteTemplate: ({ workspaceId, id }) =>
    api.delete(`/v1/${workspaceId}/${ORDERS}/templates/${id}/`),

  fetchCategories: (offset, search, level) =>
    api.get('/v1/categories/', {
      params: { offset, search, limit: 100, level }
    })
});
