import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import { Translation, useTranslation } from 'react-i18next';

import Button from 'components/common/button';
import {
  ACCEPT_THE_JOB,
  ISSUES_STATUSES
} from 'components/issues-view/constants';
import PopConfirm from 'components/common/pop-confirm';
import Typography from 'components/common/typography';

import disableActionsInExternalServices from 'utils/disable-actions-in-external-services';

import styles from './actions.module.scss';

const { Paragraph, Text } = Typography;

const Actions = ({
  status,
  handleAction,
  feedbackLink,
  isLoadingAccepted,
  workspaceId
}) => {
  const { t } = useTranslation('Task');

  const currentStatus = ISSUES_STATUSES.find(({ id }) => status === id);

  if (!currentStatus && !feedbackLink) {
    return null;
  }

  const isDisabledAction = disableActionsInExternalServices({ workspaceId });

  return (
    <>
      <Divider className={styles.divider} />

      <div className={styles.root}>
        {!feedbackLink &&
          currentStatus.actions.map(({ key, ns, mood, action }, index) => (
            <Fragment key={index}>
              {action === ACCEPT_THE_JOB && (
                <PopConfirm
                  style={{ maxWidth: 350 }}
                  onConfirm={() => handleAction(action)}
                  overlayClassName={styles.popover}
                  title={
                    <>
                      <Paragraph weight="semibold">
                        {t('AcceptTaskHeading')}
                      </Paragraph>

                      <Text>{t('AcceptTaskDesc')}</Text>
                    </>
                  }
                >
                  <Button
                    className={styles.button}
                    type="secondary"
                    mood={mood}
                    loading={isLoadingAccepted}
                    disabled={isDisabledAction}
                  >
                    <Translation ns={ns}>
                      {translate => translate(key)}
                    </Translation>
                  </Button>
                </PopConfirm>
              )}

              {action !== ACCEPT_THE_JOB && (
                <Button
                  className={styles.button}
                  type="secondary"
                  mood={mood}
                  onClick={() => handleAction(action)}
                  disabled={isDisabledAction}
                >
                  <Translation ns={ns}>
                    {translate => translate(key)}
                  </Translation>
                </Button>
              )}
            </Fragment>
          ))}

        {feedbackLink && (
          <Button
            className={styles.button}
            type="secondary"
            mood="positive"
            onClick={() => {
              window.location.href = feedbackLink;
            }}
            disabled={isDisabledAction}
          >
            {t('RateTaskBtn')}
          </Button>
        )}
      </div>
    </>
  );
};

Actions.propTypes = {
  feedbackLink: PropTypes.string,
  status: PropTypes.string.isRequired,
  handleAction: PropTypes.func.isRequired,
  isLoadingAccepted: PropTypes.bool,
  workspaceId: PropTypes.number.isRequired
};

Actions.defaultProps = {
  feedbackLink: '',
  isLoadingAccepted: false
};

export default Actions;
