import {
  GrowthBook,
  GrowthBookProvider,
  FeaturesReady
} from '@growthbook/growthbook-react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';

import { ENVIRONMENT_PROD } from 'constants/index';

import { getActiveWorkspace, getWorkspaceId } from 'store/workspace';
import { getUserCreatedAt, getUserEmail } from 'store/user';

import { config } from 'config';

const isProduction = config.REACT_APP_ENVIRONMENT === ENVIRONMENT_PROD;
const INIT_GROWTH_BOOK_ERROR = 'Failed to initialize GrowthBook';

const growthbook = new GrowthBook({
  apiHost: config.REACT_APP_GROWTHBOOK_API_HOST,
  clientKey: config.REACT_APP_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: !isProduction
});

growthbook.init({ streaming: true }).catch(error => {
  Sentry.captureException(new Error(INIT_GROWTH_BOOK_ERROR));

  // eslint-disable-next-line no-console
  console.error(`${INIT_GROWTH_BOOK_ERROR}`, error);
});

const GrowthBookClientProvider = ({ children }) => {
  const workspaceId = useSelector(getWorkspaceId);
  const workspace = useSelector(getActiveWorkspace);
  const userEmail = useSelector(getUserEmail);
  const userCreatedAt = useSelector(getUserCreatedAt);

  const { createdAt: workspaceCreatedAt } = workspace || {};

  useEffect(() => {
    growthbook.setAttributes({
      workspaceId,
      createdAt: workspaceCreatedAt,
      userCreatedAt,
      userEmail
    });
  }, [workspaceId, workspaceCreatedAt, userEmail, userCreatedAt]);

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <FeaturesReady timeout={2000}>{children}</FeaturesReady>
    </GrowthBookProvider>
  );
};

export default GrowthBookClientProvider;
