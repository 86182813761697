import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import Button from 'components/common/button';
import Modal from 'components/common/modal';
import {
  FormEmployeeSelect,
  validateRequired
} from 'components/common/hook-form';
import FormProjectParticipantsSelect from 'components/common/hook-form/select/project-participants';

import { changeResponsible, fetchRequest } from 'store/requests';

import { useAmplitude } from 'hooks/amplitude/use-amplitude';
import useRoutesService from 'services/routes';
import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

const EditorForm = ({ task = {}, onCallback }) => {
  const dispatch = useDispatch();
  const amplitude = useAmplitude();
  const routes = useRoutesService();

  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation(['TaskRoleActions', 'Errors', 'Common']);

  const { handleSubmit, ...methods } = useForm({
    defaultValues: {
      responsible: task.responsible && {
        value: (task.responsible || {}).id,
        label: task.responsible
      }
    }
  });

  const onUpdateResponsible = async values => {
    setIsLoading(true);

    try {
      await dispatch(
        changeResponsible({
          id: task.id,
          employeeId: values.responsible.value
        })
      );

      await dispatch(fetchRequest({ id: task.id })).catch(({ response }) => {
        if (response.status === 403) {
          routes.toRequests();
        }
      });

      amplitude.updateTaskEvent({
        task,
        value: {
          responsible: values.responsible.value
        }
      });

      showNoticeMessage({ number: NOTICE_NUMBER.changeOfResponsible });

      onCallback?.();
    } finally {
      setIsLoading(false);
    }
  };

  const onSave = async values => {
    await onUpdateResponsible(values);
  };

  const Select = (task.project || {}).id
    ? FormProjectParticipantsSelect
    : FormEmployeeSelect;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSave)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '16px 24px 24px'
        }}
        data-qa="qa-pa1kj1t3l3959uw"
      >
        <Select
          name="responsible"
          label={t('TaskResponsible')}
          rules={{
            validate: {
              required: value => {
                if (!value?.label) return validateRequired();
                return true;
              }
            }
          }}
          projectId={(task.project || {}).id}
          placeholder={t('ChooseResponsible', { ns: 'Common' })}
          data-qa="qa-n95e4tjai43sjx4"
          hideWorkspace
          isClearable={false}
          closeMenuOnSelect
        />

        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          style={{ marginLeft: 'auto' }}
          data-qa="qa-996ojcghexj5uxj"
        >
          {t('SaveBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

export const EditorResponsibleModal = ({ onClose, task, ...props }) => {
  const { t } = useTranslation('TaskRoleActions');

  return (
    <Modal
      width={540}
      title={t('ChangeResponsibleHeading')}
      data-qa="qa-6a4sjgbq7dme7lq"
      onClose={onClose}
      {...props}
    >
      <EditorForm task={task} onCallback={onClose} />
    </Modal>
  );
};

export default EditorResponsibleModal;
