import React from 'react';
import classnames from 'classnames';

import Modal from 'components/common/modal';

import sanitizeHTML from 'utils/sanitize-html';

import { EmailIframe } from '../email-iframe';

import styles from './FullEmailModal.module.scss';

export const FullEmailModal = ({
  nodeContent,
  htmlContent,
  subject,
  visible,
  onClose
}) => {
  const parsedHtml =
    htmlContent &&
    htmlContent
      .replace(/\\xa0/g, ' ')
      .replace(/(\\[a-z])|(\[')|('\])|(\\')|(a0)/g, '');

  return (
    <Modal
      title={subject}
      contentClassName={classnames(styles.root, {
        [styles.withoutSubject]: !subject
      })}
      wrapClassName={styles.wrap}
      centered
      open={visible}
      onClose={onClose}
      destroyOnClose
    >
      {parsedHtml ? (
        <EmailIframe content={sanitizeHTML(parsedHtml, { FORBID_TAGS: [] })} />
      ) : (
        nodeContent
      )}
    </Modal>
  );
};
