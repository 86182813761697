export const hexEmojiByEmojiPostfix = {
  '263a-fe0f': '263a',
  '2764-fe0f': '2764',
  '261d-fe0f': '261d'
};

export const getEmojiWithPostfixByHex = hexCode => {
  if (isNaN(parseInt(hexCode, 16))) {
    return hexCode;
  }

  const baseHexCode = hexEmojiByEmojiPostfix[hexCode] || hexCode;

  return baseHexCode;
};
