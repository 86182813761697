import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-cycle
import CompanySelect from 'components/contacts-view/company-select';

import FormItem from '../form-item';
import { validateCompany } from '../validators';

export const FormCompanySelect = ({
  rules,
  name,
  label,
  itemProps,
  ...props
}) => {
  const { control, formState } = useFormContext();

  return (
    <FormItem
      label={label}
      name={name}
      {...itemProps}
      errors={formState.errors}
    >
      <Controller
        control={control}
        rules={{ validate: validateCompany, ...rules }}
        render={({ field: { value, onChange } }) => (
          <CompanySelect value={value} onChange={onChange} {...props} />
        )}
      />
    </FormItem>
  );
};

FormCompanySelect.defaultProps = {
  rules: PropTypes.any,
  itemProps: PropTypes.object
};

FormCompanySelect.defaultProps = {
  rules: {},
  itemProps: {}
};

export default FormCompanySelect;
