export const IMPORT_CONTACTS_SUCCESS_MODAL = 'importContactsSuccessModal';
export const REASON_FOR_RETURN_ISSUE_MODAL = 'reasonForReturnIssueModal';
export const DEFER_DIALOG_MODAL = 'deferDialogModal';
export const CHANGE_PAYMENT_SUBSCRIPTION_MODAL =
  'changePaymentSubscriptionModal';
export const CANCEL_PAYMENT_SUBSCRIPTION_MODAL =
  'cancelPaymentSubscriptionModal';
export const ADD_PAYMENT_ADDON_MODAL = 'addPaymentAddonModal';
export const CHANGE_PAYMENT_ADMIN_MODAL = 'changePaymentAdminModal';
export const RETRY_PAYMENT_MODAL = 'retryPaymentModal';
export const DELETE_WHATSAPP_NUMBER_MODAL = 'deleteWhatsAppNumberModal';
export const AMOUNT_DETAILS_MODAL = 'amountDetailsModal';

export const PAYMENT_MODAL_QUERY_PARAM = 'paymentSuccess';

export const SERVICE_UNAVAILABLE_MODAL = 'serviceUnavailable';

export const WHATSAPP_SUCCESSFULLY_ADDED_MODAL = 'whatsAppSuccessfullyAdded';
export const REMIND_MESSAGE_MODAL = 'remindMessage';
export const REPLACE_TAG_MODAL = 'replaceTag';
export const CREATE_TAG_MODAL = 'createTag';
export const EDIT_TAG_MODAL = 'editTag';

export const EDIT_ORDER_STATUS_MODAL = 'editOrderStatus';
export const COPY_ORDER_STATUS_MODAL = 'copyOrderStatus';
export const CREATE_ORDER_STATUS_MODAL = 'createOrderStatus';
export const TERMINATE_ORDER_STATUS_MODAL = 'terminateOrderStatus';

export const ADD_VALIDITY_DATE_ATTACHMENT_MODAL = 'addValidityDateAttachment';
export const CHANGE_VALIDITY_DATE_ATTACHMENT_MODAL =
  'changeValidityDateAttachment';
export const OVERVIEW_ATTACHMENT_MODAL = 'overviewAttachment';

export const CHANGE_ASSET_VALIDITY_EXPIRATION_MODAL =
  'changeAssetValidtyExpiration';

export const CHANGE_EXPIRATION_DATE_MODAL = 'changeExpirationDate';
export const CREATE_CHANNELS_ASSISTANT_MODAL = 'createChannelsAssistant';
