import themeVariables from 'theme';

import {
  DONE_ACTION,
  GET_IN_WORK_ACTION,
  STATUS_BACKLOG,
  STATUS_CANCELLED,
  STATUS_CHECK,
  STATUS_DONE,
  STATUS_FOR_EXECUTION,
  STATUS_IN_WORK,
  STATUS_PENDING
} from './tasks';
import { TYPE_ASSET, TYPE_CHANNEL, TYPE_ORDER_STATUS } from './status';

const TO_WORK_ACTION = {
  label: 'BackTaskToWorkBtn',
  ns: 'Task',
  status: STATUS_FOR_EXECUTION,
  mood: 'negative'
};

export const REQUEST_STATUSES = [
  {
    id: STATUS_PENDING,
    label: 'UnderConsiderationTaskStatus',
    ns: 'Task',
    color: themeVariables.color.azure,
    backgroundColor: themeVariables.color.white,
    actions: [
      {
        label: 'ApproveTaskBtn',
        ns: 'Task',
        status: STATUS_FOR_EXECUTION,
        mood: 'positive'
      }
    ]
  },
  {
    id: STATUS_FOR_EXECUTION,
    label: 'ToDoTaskStatus',
    ns: 'Task',
    color: themeVariables.color['brand-40'],
    backgroundColor: themeVariables.color.white,
    actions: [GET_IN_WORK_ACTION],
    actionsMeeting: [
      {
        label: 'AcceptMeetBtn',
        ns: 'Task',
        status: STATUS_IN_WORK,
        mood: 'positive'
      }
    ]
  },
  {
    id: STATUS_IN_WORK,
    label: 'InProgressTaskStatus',
    ns: 'Task',
    color: themeVariables.color.yellow,
    backgroundColor: themeVariables.color.white,
    actions: [DONE_ACTION],
    actionsMeeting: [DONE_ACTION],
    actionsAcquaintence: [
      {
        label: 'AquaintedBtn',
        ns: 'Task',
        status: STATUS_DONE,
        mood: 'positive'
      }
    ]
  },
  {
    id: STATUS_BACKLOG,
    label: 'BacklogTaskStatus',
    ns: 'Statuses',
    color: themeVariables.color.magenta,
    backgroundColor: themeVariables.color.white,
    actions: []
  },
  {
    id: STATUS_CHECK,
    label: 'CheckTaskStatus',
    ns: 'Task',
    color: themeVariables.color['black-55'],
    backgroundColor: themeVariables.color.white,
    actions: [
      {
        label: 'CloseRequestHeading',
        ns: 'Requests',
        status: STATUS_DONE,
        mood: 'positive'
      },
      TO_WORK_ACTION
    ]
  },
  {
    id: STATUS_DONE,
    label: 'DoneTaskStatus',
    ns: 'Task',
    color: themeVariables.color.danube,
    backgroundColor: themeVariables.color.white,
    actions: [TO_WORK_ACTION],
    actionsMeeting: []
  },
  {
    id: STATUS_CANCELLED,
    label: 'CancelledTaskStatus',
    ns: 'Statuses',
    color: themeVariables.color['red-70'],
    backgroundColor: themeVariables.color.white,
    actions: [TO_WORK_ACTION]
  }
];

export const SLA_REASON_REQUEST_MODAL_PROPS = {
  title: 'CloseSLATaskHeading',
  description: 'CloseSLATaskDesc',
  submitButtonText: 'CloseTaskBtn',
  ns: 'CloseSLATask'
};

export const SLA_REASON_REQUEST_MODAL_EDITOR_PROPS = {
  labelEditor: 'CommentForClient',
  placeholderEditor: 'Placeholder',
  showItemsEditor: {
    upload: true,
    mention: false,
    emoji: true,
    topToolbar: true
  },
  disallowRulesEditor: true
};

export const SLA_REASON_REQUEST_MODAL_PRICE_PROPS = {
  title: 'TaskResultCommentDesc',
  label: 'PriceLabel',
  placeholder: 'PricePlaceholder',
  currencyLabel: 'CurrencyLabel',
  ns: 'ReasonModal'
};

export const REQUEST_FIELD_CONTACT = 'contact';
export const REQUEST_FIELD_CHANNEL = 'channel';

export default REQUEST_STATUSES;

export const SLA_TYPE_OPTIONS = [
  {
    value: TYPE_CHANNEL,
    label: 'Channel',
    ns: 'Common'
  },
  {
    value: TYPE_ASSET,
    label: 'Asset',
    ns: 'TaskLinks'
  },
  {
    value: TYPE_ORDER_STATUS,
    label: 'Order',
    ns: 'TaskLinks'
  }
];