import moment from 'moment/moment';

export const getAssetPathCategories = ({
  allCategories = [],
  currentCategoryId
}) => {
  if (!allCategories.length) {
    return [];
  }

  const currentCategory = allCategories.find(c => c.id === currentCategoryId);

  if (!currentCategory.parentId) {
    return [currentCategory];
  }

  return [
    ...getAssetPathCategories({
      allCategories,
      currentCategoryId: currentCategory.parentId
    }),
    currentCategory
  ];
};

export const isNotifyExpirationDaysMoreThanDateEnd = ({
  expirationDays,
  dateEnd
}) =>
  expirationDays &&
  moment().add(expirationDays, 'days').isAfter(moment(dateEnd));
