export const SLA_SOURCES = {
  CHAT: 'CHAT',
  COPY: 'COPY',
  ASSET: 'ASSET',
  ORDER_STATUS: 'ORDER_STATUS',
  DEFAULT: 'DEFAULT'
};

export const getSlaSource = (isFromChat, isCopy, isAsset, isDeal) => {
  if (isFromChat) return SLA_SOURCES.CHAT;
  if (isCopy) return SLA_SOURCES.COPY;
  if (isAsset) return SLA_SOURCES.ASSET;
  if (isDeal) return SLA_SOURCES.ORDER_STATUS;
  return SLA_SOURCES.DEFAULT;
};

const calculateDateEnd = executorSla => {
  if (executorSla === null) return null;
  return new Date(Date.now() + executorSla * 60 * 1000);
};

const handleChatSla = ({
  defaultValues,
  executorSla,
  isSlaRequestFormChat
}) => {
  const isDisabled = !!executorSla || isSlaRequestFormChat;

  return {
    dateEnd: calculateDateEnd(
      defaultValues?.taskInfo?.executorSla || executorSla
    ),
    isDisabled
  };
};

const handleCopySla = ({ defaultValues, executorSla }) => {
  const isDisabled =
    !!executorSla ||
    (defaultValues.taskInfo?.slaObject && defaultValues.taskInfo?.executorSla);

  return {
    dateEnd: calculateDateEnd(executorSla),
    isDisabled: isDisabled && !!executorSla
  };
};

const handleAssetSla = ({ defaultValues, executorSla }) => {
  const isDisabled =
    !!executorSla || (defaultValues?.slaObject && defaultValues?.executorSla);

  return {
    dateEnd: calculateDateEnd(executorSla),
    isDisabled
  };
};

const handleDealSla = ({ defaultValues, executorSla }) => {
  const isDisabled =
    !!executorSla || (defaultValues?.slaObject && defaultValues?.executorSla);

  return {
    dateEnd: calculateDateEnd(executorSla),
    isDisabled
  };
};

const handleDefaultSla = ({ executorSla, isSlaRequestFormChat }) => {
  const isDisabled = !!executorSla || isSlaRequestFormChat;

  return {
    dateEnd: calculateDateEnd(executorSla),
    isDisabled
  };
};

const slaStrategies = {
  [SLA_SOURCES.CHAT]: handleChatSla,
  [SLA_SOURCES.COPY]: handleCopySla,
  [SLA_SOURCES.ASSET]: handleAssetSla,
  [SLA_SOURCES.ORDER_STATUS]: handleDealSla,
  [SLA_SOURCES.DEFAULT]: handleDefaultSla
};

export const getDateEndState = ({
  source,
  executorSla,
  defaultValues,
  isSlaRequestFormChat
}) => {
  const handler = slaStrategies[source];
  if (!handler) {
    return { dateEnd: null, isDisabled: false };
  }

  return handler({ executorSla, defaultValues, isSlaRequestFormChat });
};
