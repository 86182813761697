import React, { useEffect, useState, forwardRef } from 'react';
import api from 'api';
import { useTranslation } from 'react-i18next';

import { ITEMS_PER_PAGE, ALPHA_CODE_BELARUS } from 'constants/index';

import { CountryOption } from '../custom-select/country-option';

// eslint-disable-next-line import/no-cycle
import CustomSelect from '..';

const mapValue = value => ({
  value: value.id,
  label: value
});

const fetchCountries = ({ offset, search, isRegistration }) =>
  api.countries.fetch({
    search,
    offset,
    limit: ITEMS_PER_PAGE,
    isRegistration
  });

export const CountrySelect = forwardRef(
  ({ defaultCountry, withFlags, countryCodeByPhone, ...props }, ref) => {
    const [defaultCountryValue, setDefaultCountryValue] = useState();

    const { t } = useTranslation('Common');

    const onSetDefaultValue = country => {
      const value = mapValue(country);

      setDefaultCountryValue(value);
      props.onChange(value);
    };

    useEffect(() => {
      if (defaultCountry && !props.isClearable) {
        const { id, code } = defaultCountry;

        if (id) {
          api.countries.fetchOne(id).then(onSetDefaultValue);
        } else {
          api.countries
            .fetch({
              code: code || ALPHA_CODE_BELARUS,
              offset: 0,
              limit: ITEMS_PER_PAGE,
              isRegistration: (props.params || {}).isRegistration
            })
            .then(({ results: [country] = [] }) => onSetDefaultValue(country));
        }
      }
      // eslint-disable-next-line
    }, []);

    const setCountryByPhone = () => {
      if (
        !countryCodeByPhone ||
        countryCodeByPhone.toUpperCase() === props.value.label.alpha2Code
      ) {
        return null;
      }

      return api.countries
        .fetch({ code: countryCodeByPhone })
        .then(({ results: [country] = [] }) => onSetDefaultValue(country));
    };

    useEffect(() => {
      setCountryByPhone();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryCodeByPhone]);

    const fetchData = params =>
      fetchCountries({ ...params }).then(({ results, count, ...rest }) => ({
        entries: results.map(mapValue),
        totalItems: count,
        ...rest
      }));

    return (
      <CustomSelect
        ref={ref}
        isAsync
        isSearchable
        placeholder={t('Search')}
        Option={optionProps => (
          <CountryOption {...optionProps} withFlags={withFlags} />
        )}
        fetchData={fetchData}
        showSelectedOptionsOnList={false}
        defaultValue={defaultCountryValue}
        {...props}
        onChange={(...args) => {
          props.onChange(...args);
          if (props.isClearable) {
            setDefaultCountryValue();
          }
        }}
      />
    );
  }
);

CountrySelect.defaultProps = {
  onChange: () => {},
  params: {},
  isClearable: true,
  withFlags: true
};

export default CountrySelect;
