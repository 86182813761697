import React from 'react';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import Icon from 'components/common/icon';

import styles from './IconLimitedAccessType.module.scss';

export const IconLimitedAccessType = ({ isLeftMargin, isRightMargin }) => {
  const { t } = useTranslation('Backend');

  return (
    <Tooltip title={t('TaskRestrictedAccess')} mouseEnterDelay={0.5}>
      <Icon
        type="lock"
        theme="filled"
        size={16}
        color="black-55"
        className={classnames(styles.icon, {
          [styles.isLeftMargin]: isLeftMargin,
          [styles.isRightMargin]: isRightMargin
        })}
      />
    </Tooltip>
  );
};
