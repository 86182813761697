import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-cycle
import { FormInputNumber, FormSwitch } from 'components/common/hook-form';
import Typography from 'components/common/typography';

import styles from './ExecutorSlaSettingsControl.module.scss';

const { Title, Text } = Typography;

export const ExecutorSlaSettingsControl = ({ isOnlyView, fieldsConfig }) => {
  const { t } = useTranslation(['ConnectWidget', 'Errors']);
  const { control } = useFormContext();

  const isActiveWatch = useWatch({ name: fieldsConfig.isActive, control });
  const isExecutorHoursWatch = useWatch({
    name: fieldsConfig.executorSlaHours,
    control
  });
  const isExecutorMinutesWatch = useWatch({
    name: fieldsConfig.executorSlaMinutes,
    control
  });

  return (
    <>
      <Title level={3} className={styles.title}>
        {t('SLAHeading')}
      </Title>

      <FormSwitch
        name={fieldsConfig.isActive}
        label={t('SetSLAToggle')}
        disabled={isOnlyView}
      />

      {isActiveWatch && (
        <>
          <Title level={4} className={styles.subtitle}>
            {t('PerformerSLAHeading')}
          </Title>

          <Text size="small" className={styles.text}>
            {t('PerformerSLADesc')}
          </Text>

          <div className={styles.inputWrapper}>
            <FormInputNumber
              name={fieldsConfig.executorSlaHours}
              label={t('Hours')}
              placeholder="0"
              itemProps={{ style: { width: '100%', marginBottom: 0 } }}
              maxLength={3}
              rules={{
                required:
                  !isExecutorMinutesWatch && t('RequiredField', { ns: 'Errors' })
              }}
              disabled={isOnlyView}
            />

            <FormInputNumber
              name={fieldsConfig.executorSlaMinutes}
              label={t('Minutes')}
              placeholder="0"
              itemProps={{ style: { width: '100%', marginBottom: 0 } }}
              maxLength={2}
              rules={{
                required:
                  !isExecutorHoursWatch &&
                  t('RequiredField', { ns: 'Errors' })
              }}
              disabled={isOnlyView}
            />
          </div>
        </>
      )}
    </>
  );
};
