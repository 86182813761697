import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ProgressBar as ProgressBarComponent } from 'components/common/progress-bar';
import {
  calculateLeftTimeProgress,
  checkIsTaskStatusCancelled,
  checkIsTaskStatusDone
} from 'components/requests-view/utils';

import { getUIGlobalInterval } from 'store/ui';

import { getIsTaskOutdated } from 'utils/get-is-outdated';

export const ProgressBar = ({ request, infoPosition, infoTextType }) => {
  const isCompleted = checkIsTaskStatusDone(request.status);
  const isCancelled = checkIsTaskStatusCancelled(request.status);
  const isOutdated = getIsTaskOutdated(request);

  const globalInterval = useSelector(state =>
    isCompleted || isOutdated || isCancelled ? 0 : getUIGlobalInterval(state)
  );

  const [, setGlobalInterval] = useState(0);

  useEffect(() => {
    setGlobalInterval(globalInterval);
  }, [globalInterval]);

  const [timeLeft, valuePercentage] = calculateLeftTimeProgress(request);

  if (isCancelled) {
    return null;
  }

  return (
    <ProgressBarComponent
      timeLeft={timeLeft}
      valuePercentage={valuePercentage}
      isCompleted={isCompleted}
      isOutdated={isOutdated}
      infoPosition={infoPosition}
      infoTextType={infoTextType}
    />
  );
};
