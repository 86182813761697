import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
  STATUS_NEW,
  STATUS_NEGOTIATION,
  STATUS_COMPLETED,
  STATUS_DECLINED,
  IDEA,
  STATUS_CLOSING,
  CONTACT_INFO,
  TEMPLATES,
  TASK,
  BENCH,
  INVITE_DATA,
  CHANNELS_EDITOR_DRAWER,
  INTEGRATIONS,
  CALENDAR,
  CONTACTS_IMPORT_RESULTS_DRAWER,
  STATUS_IMPORT_CONTACTS_COMPLETED_WITH_ERRORS,
  IMPORT_CONTACTS_SUCCESS_MODAL,
  TYPE_CONTACT,
  TYPE_ASSET,
  TYPE_TASK,
  STATUS_RECENT,
  TYPE_ORDER,
  OVERVIEW_ATTACHMENT_MODAL,
  DEALS,
  TYPE_REQUEST
} from 'constants/index';

import { useViewFile } from 'components/attachments-view/hooks';
import { checkPaymentAccessByRoles } from 'components/settings-view/billing/utils';

import {
  setActiveId,
  getWorkspaceId,
  getAllWorkspaces,
  fetchWorkspaces,
  checkJoining,
  updateWorkspaceUser
} from 'store/workspace';
import { setDrawerVisible } from 'store/notifications';
import { setVisibleDrawer } from 'store/drawers';
import {
  fetchContactByChatLocal,
  fetchStatusImportContacts
} from 'store/contacts';
import { setTaskData } from 'store/tasks';
import { setRequestData } from 'store/requests';

import { useModalsService } from 'services/modals';
import useRoutesService from 'services/routes';
import { useLocalStorage } from 'hooks';

import { useModalContext } from '../components/app/modals-provider';

export const useNotificationsService = () => {
  const { showModal } = useModalContext();

  const modals = useModalsService();
  const dispatch = useDispatch();
  const routes = useRoutesService();
  const routesUrl = useRoutesService({ returnUrl: true });

  const [, setInviteData] = useLocalStorage(INVITE_DATA);
  const { viewFile } = useViewFile();

  const activeWorkspaceId = useSelector(getWorkspaceId, shallowEqual);
  const workspeces = useSelector(getAllWorkspaces);

  const showChannelEditorDrawer = useCallback(
    id =>
      dispatch(
        setVisibleDrawer({ drawer: CHANNELS_EDITOR_DRAWER, data: { id } })
      ),
    [dispatch]
  );

  const showImportContactsDrawer = useCallback(() =>
    dispatch(
      setVisibleDrawer({ drawer: CONTACTS_IMPORT_RESULTS_DRAWER, data: true })
    )
  );

  const handleWorkspace = useCallback(
    async (workspaceId = activeWorkspaceId) => {
      if (workspaceId !== activeWorkspaceId) {
        if (workspeces[workspaceId]) {
          dispatch(setActiveId({ workspaceId }));
        } else {
          await dispatch(fetchWorkspaces());
          dispatch(setActiveId({ workspaceId }));
        }
      }
      return Promise.resolve(workspaceId || activeWorkspaceId);
    },
    [activeWorkspaceId, dispatch, workspeces]
  );

  // 31, 48, 78, 66, 67
  const handleNotification = useCallback(
    async ({ info, templateUid }) => {
      handleWorkspace(info.workspaceId);

      dispatch(setDrawerVisible(false));

      if (templateUid === 31) {
        await dispatch(checkJoining());
      }
    },
    [dispatch, handleWorkspace]
  );

  // 30
  const handleDismiss = useCallback(
    async ({ info }) => {
      window.location.replace(
        routesUrl.toDefaultPage({ workspaceId: info.workspaceId })
      );
    },
    [routesUrl]
  );

  // 77, 79, 80
  const handleToBilling = useCallback(
    async ({ info }) => {
      handleWorkspace(info.workspaceId);

      if (
        !workspeces[info.workspaceId] ||
        checkPaymentAccessByRoles(workspeces[info.workspaceId].user.roles)
      ) {
        routes.toBilling({
          id: info.workspaceId,
          workspaceId: info.workspaceId
        });
      }
    },
    [handleWorkspace, routes, workspeces]
  );

  // 7, 14, 42, 43, 44, 46, 47, 99, 104, 113, 114, 115, 116, 122, 123, 124, 125, 126, 121, 149, 154, 155 164, 165, 166
  // 205, 206, 208, 303, 304, 305, 400, 418, 419, 420, 421, 423, 424, 425, 427, 428, 429, 431, 434
  const handleOrderStatus = useCallback(
    ({ workspaceId: id, orderStatusId, contactId, messageUuid }) => {
      handleWorkspace(id);

      routes.toNewOrderStatus({
        contactId,
        state: {
          isFromNotification: true,
          messageUuid
        },
        orderStatusId
      });
    },
    [handleWorkspace, routes]
  );

  // 2, 4, 5
  const handleOrderDeclined = useCallback(
    ({ info }) => handleOrderStatus({ ...info, status: STATUS_DECLINED }),
    [handleOrderStatus]
  );

  // 108 new message - (orderStatusId, chatId, orderId, workspaceId)
  const handleNewMessageOrder = useCallback(
    ({ info }) =>
      handleOrderStatus({
        ...info,
        status: STATUS_NEW,
        statusId: info.orderStatusId
      }),
    [handleOrderStatus]
  );

  // 105 (marketplace)
  const handleNewMarketplace = useCallback(
    ({ info }) => {
      handleWorkspace(info.workspaceId);

      routes.toMarketplace(info);
    },
    [handleWorkspace, routes]
  );

  // 9
  const handleSelectContractor = useCallback(
    ({ info }) => handleOrderStatus({ ...info, status: STATUS_NEGOTIATION }),
    [handleOrderStatus]
  );

  // 10
  // TODO: не приходит chatId. Не можем открыть данный отклик
  const handleNewResponse = useCallback(
    ({ info }) => {
      routes.toContacts({ view: DEALS });
      return handleOrderStatus({ ...info, status: STATUS_RECENT });
    },
    [handleOrderStatus, routes]
  );

  // 76 response
  const handleOrderCompleted = useCallback(
    ({ info }) => handleOrderStatus({ ...info, status: STATUS_COMPLETED }),
    [handleOrderStatus]
  );

  // 36 order closing
  const handleOrderClosing = useCallback(
    ({ info }) => handleOrderStatus({ ...info, status: STATUS_CLOSING }),
    [handleOrderStatus]
  );

  // 45 order negotiation
  const handleOrderNegotiation = useCallback(
    ({ info }) => handleOrderStatus({ ...info, status: STATUS_NEGOTIATION }),
    [handleOrderStatus]
  );

  // 38 Create feedback
  // TODO: сейчас не отдается chatId и statusId.
  // Если chatId не будут возвращать, тогда нужно передавать значения через state
  const handleCreateFeedback = useCallback(
    ({ info }) =>
      handleOrderStatus({
        ...info,
        status: STATUS_COMPLETED,
        feedbackKey: info.toEmployee
      }),
    [handleOrderStatus]
  );

  // 19 new comment
  const handleComments = useCallback(
    ({ info }) => {
      handleWorkspace(info.workspaceId);

      const id = info.taskId || info.id;

      if (info.type === IDEA) {
        routes.toIdeas();
        modals.ideas.showIdeaDetails(id, info.commentId);
      } else if (
        info.type === TASK ||
        info.commentType === TASK ||
        !info.commentType
      ) {
        modals.tasks.showDetails({
          id,
          messageUuid: info.messageUuid,
          showCommentsTab: true
        });
      }
    },
    [handleWorkspace, modals.ideas, modals.tasks, routes]
  );

  // 27, 33, 41, 55, 7000
  const handleEmployeeInfo = useCallback(
    ({ info }) => {
      handleWorkspace(info.workspaceId);

      routes.toEmployee(info.employeeId);
    },
    [handleWorkspace, routes]
  );

  // 28
  const handleInviteEmployee = useCallback(
    ({ info }) => {
      handleWorkspace(info.workspaceId);

      routes.toOrgstructure();
      modals.team.showEmployeeCreator();
    },
    [handleWorkspace, modals.team, routes]
  );

  // 15, 415
  const handleProjects = useCallback(
    async ({ info }) => {
      await handleWorkspace(info.workspaceId);

      routes.toProjects();
    },
    [handleWorkspace, routes]
  );

  // 16, 414
  const handleProjectInvite = useCallback(
    async ({ info }) => {
      await handleWorkspace(info.workspaceId);

      routes.toProject(info.projectId);
    },
    [handleWorkspace, routes]
  );

  // 11, 21, 22, 23, 24, 34, 35, 37, 64, 101, 103, 106, 107, 109, 146, 147, 204, 309, 310, 311, 453, 501, 666, 668
  const handleTaskDetail = useCallback(
    ({ info }) => {
      handleWorkspace(info.workspaceId);

      const id = info.taskId || info.entityId;

      if (id) {
        dispatch(setTaskData({ id, values: { isLoaded: false } }));

        modals.tasks.showDetails({
          id,
          isFromNotification: true,
          showCommentsTab: true,
          messageUuid: info.messageUuid
        });
      }
    },
    [dispatch, handleWorkspace, modals.tasks]
  );

  // 701, 702, 704, 705, 706, 721, 707, 708, 709, 711, 713, 715, 716, 717, 720
  const handleRequestDetail = useCallback(
    ({ info }) => {
      handleWorkspace(info.workspaceId);

      if (info.requestId || info.entityId) {
        dispatch(
          setRequestData({ id: info.requestId, values: { isLoaded: false } })
        );

        modals.requests.showDetails({
          id: info.requestId,
          isFromNotification: true,
          showCommentsTab: true,
          messageUuid: info.messageUuid
        });
      }
    },
    [dispatch, handleWorkspace, modals.requests]
  );

  // 410
  const handleTasks = useCallback(
    ({ info }) => {
      handleWorkspace(info.workspaceId);

      routes.toTasks();
    },
    [handleWorkspace, routes]
  );

  // 120, 167
  const handleCreateWorkspace = useCallback(
    ({ info }) => {
      if (info.countryId) {
        setInviteData({ ...info, country: info.countryId });
      }

      modals.workspace.create();
    },
    [modals.workspace, setInviteData]
  );

  // 58
  const handleChangeAdmin = useCallback(
    ({ info }) => {
      handleWorkspace(info.workspaceId);

      routes.toOrgstructure();
      modals.team.showAdminChange();
    },
    [handleWorkspace, modals.team, routes]
  );

  // 65, 110, 171, 200, 510, 631, 667
  const handleOrgstructure = useCallback(
    ({ info }) => {
      handleWorkspace(info.workspaceId);

      routes.toOrgstructure();
    },
    [handleWorkspace, routes]
  );

  // 69
  const handleDismissOrderStatusesSignatory = useCallback(
    ({ info }) => {
      handleWorkspace(info.workspaceId);

      routes.toFunnelsSettings({ workspaceId: info.workspaceId });
      modals.orders.assingSignatory();
    },
    [handleWorkspace, modals.orders, routes]
  );

  // 71, 102
  const handleWorkspaceProfile = useCallback(
    async ({ info }) => {
      const workspaceId = await handleWorkspace(info.workspaceId);

      routes.toCompany({ workspaceId, id: workspaceId, view: CONTACT_INFO });
    },
    [handleWorkspace, routes]
  );

  // 100
  const handlePriceListWorkspace = useCallback(
    async ({ info }) => {
      const workspaceId = await handleWorkspace(info.workspaceId);
      routes.toFunnelsSettings({ workspaceId });
    },
    [handleWorkspace, routes]
  );

  // 129, 441
  const handleChannel = useCallback(
    async ({ info }) => {
      await handleWorkspace(info.workspaceId);

      routes.toContacts({ view: INTEGRATIONS });

      showChannelEditorDrawer(info.channelId);
    },
    [handleWorkspace, routes, showChannelEditorDrawer]
  );

  // 442
  const handleImportContacts = useCallback(
    async ({ info }) => {
      await handleWorkspace(info.workspaceId);

      const status = await dispatch(fetchStatusImportContacts());

      if (
        info.importStatus === STATUS_IMPORT_CONTACTS_COMPLETED_WITH_ERRORS &&
        status.contactImportProcessing
      ) {
        showImportContactsDrawer();
      } else {
        showModal(IMPORT_CONTACTS_SUCCESS_MODAL, {
          data: { ...info, ...status }
        });
      }
    },
    [dispatch, handleWorkspace, showImportContactsDrawer, showModal]
  );

  // 128, 131, 132, 133, 134, 138, 139, 447
  const handleChannels = useCallback(
    async ({ info }) => {
      await handleWorkspace(info.workspaceId);

      routes.toContacts({ view: INTEGRATIONS });
    },
    [handleWorkspace, routes]
  );

  // 26, 130, 135, 136, 137, 140, 142, 143, 144, 145, 148, 308, 313, 314, 438, 444
  const handleContact = useCallback(
    async ({ info }) => {
      handleWorkspace(info.workspaceId);

      let { contactId } = info;

      if (!contactId && info.channelChatUuid) {
        const contact = await dispatch(
          fetchContactByChatLocal({ roomUuid: info.channelChatUuid })
        );

        contactId = contact.id;
      }

      routes.toContact({
        id: contactId,
        state: {
          messageUuid: info.messageUuid
        }
      });
    },
    [dispatch, handleWorkspace, routes]
  );

  // 118, 119
  const handleContactCompany = useCallback(
    async ({ info }) => {
      await handleWorkspace(info.workspaceId);

      const { contactCompanyId } = info;

      routes.toContractor(contactCompanyId);
    },
    [handleWorkspace, routes]
  );

  // 191
  const handleTemplate = useCallback(
    async ({ info }) => {
      await handleWorkspace(info.workspaceId);

      routes.toTasks();
      modals.tasks.showCreator({ initialData: { activeTab: TEMPLATES } });
    },
    [handleWorkspace, modals.tasks, routes]
  );

  // 29 присоединение к воркспейс
  const handleWorkspaceJoin = useCallback(
    async ({ info, isAccepted }) => {
      if (!isAccepted) {
        return dispatch(setDrawerVisible(true));
      }

      const workspaceId = await handleWorkspace(info.workspaceId);

      setTimeout(() => {
        routes.toCompany({ id: workspaceId, workspaceId });

        if (info.position !== BENCH) {
          modals.workspace.create(info);
        }
      }, 100);

      return null;
    },
    [dispatch, handleWorkspace, modals.workspace, routes]
  );

  // 163
  const handleVerified = useCallback(
    async ({ receiverId, info }) => {
      const workspaceId = await handleWorkspace(info.workspaceId);

      await dispatch(
        updateWorkspaceUser({
          user: { id: receiverId, workspaceId, isPhoneVerified: false }
        })
      );

      routes.toSettingsSecurity();
    },
    [dispatch, handleWorkspace, routes]
  );

  // 432
  const handleReport = useCallback(
    async ({ info }) => {
      await handleWorkspace(info.workspaceId);

      routes.toReport({ id: info.reportId });
    },
    [handleWorkspace, routes]
  );

  // 435
  const handleReports = useCallback(
    async ({ info }) => {
      await handleWorkspace(info.workspaceId);

      routes.toReports();
    },
    [handleWorkspace, routes]
  );

  // 196, 197, 198, 199, 408, 409, 417, 439, 454, 455
  const handleAsset = useCallback(
    async ({ info }) => {
      await handleWorkspace(info.workspaceId);

      if (info.idDeleted) {
        return modals.assets.showDeleted({ id: info.entityId });
      }

      if (info.mimeType) {
        const id = info.attachmentId;

        return viewFile({
          id,
          fileId: id,
          mimeType: info.mimeType,
          title: info.attachmentTitle || ''
        });
      }

      return modals.assets.showDetails({ id: info.assetId || info.entityId });
    },
    [handleWorkspace, modals.assets]
  );

  // 201, 202, 203
  const handleAssets = useCallback(
    async ({ info }, state) => {
      await handleWorkspace(info.workspaceId);

      routes.toAssets(state);
    },
    [handleWorkspace, routes]
  );

  // 412, 413
  const handleCategoryAssets = useCallback(
    async ({ info }) => {
      await handleWorkspace(info.workspaceId);

      routes.toCategory({ id: info.assetCategoryId });
    },
    [handleWorkspace, routes]
  );

  // 192, 195, 306, 307
  const handleAttachment = useCallback(
    async ({ info, templateUid }) => {
      await handleWorkspace(info.workspaceId);

      const id = info.attachmentId || info.fileId;

      if ([306, 307].includes(templateUid)) {
        routes.toAttachments();
        showModal(OVERVIEW_ATTACHMENT_MODAL, {
          data: { fileId: id }
        });
        return;
      }

      viewFile({
        id,
        fileId: id,
        mimeType: info.mimeType,
        title: info.attachmentTitle || ''
      });
    },
    [handleWorkspace, viewFile, routes]
  );

  // 433
  const handleRecord = useCallback(
    async ({ info }) => {
      await handleWorkspace(info.workspaceId);

      routes.toDashboard({
        view: CALENDAR,
        state: { recordId: info.recordId }
      });
    },
    [handleWorkspace, routes]
  );

  // 401
  const handleMention = useCallback(
    notification => {
      const { info } = notification;

      if (info.orderStatusId) {
        handleOrderStatus(notification);
      } else {
        handleContact(notification);
      }
    },
    [handleContact, handleOrderStatus]
  );

  // 443, 445, 446, 722
  const handleReaction = useCallback(
    async notification => {
      const { info } = notification;
      const { entityId, entityType, workspaceId, messageUuid } = info;

      await handleWorkspace(workspaceId);

      if (entityType === TYPE_CONTACT) {
        routes.toContact({
          id: +entityId,
          state: { messageUuid }
        });
      }

      if (entityType === TYPE_ASSET) {
        modals.assets.showDetails({
          id: +entityId,
          state: { messageUuid }
        });
      }

      if (entityType === TYPE_TASK) {
        modals.tasks.showDetails({
          id: +entityId,
          messageUuid
        });
      }

      if (entityType === TYPE_REQUEST) {
        modals.requests.showDetails({
          id: +entityId,
          messageUuid
        });
      }
    },
    [handleWorkspace, modals.assets, modals.tasks, routes]
  );

  // 603
  const handleMessageReminder = useCallback(
    async notification => {
      const { info } = notification;
      const {
        workspaceId,
        entityId: messageUuid,
        sourceEntityType: entityType,
        sourceEntityId: entityId
      } = info;

      await handleWorkspace(workspaceId);

      if (entityType === TYPE_CONTACT) {
        routes.toContact({
          id: entityId,
          state: { messageUuid }
        });
      }

      if (entityType === TYPE_ASSET) {
        modals.assets.showDetails({
          id: entityId,
          state: { messageUuid }
        });
      }

      if (entityType === TYPE_TASK) {
        modals.tasks.showDetails({
          id: entityId,
          messageUuid
        });
      }

      if (entityType === TYPE_REQUEST) {
        modals.requests.showDetails({
          id: entityId,
          messageUuid
        });
      }

      if (entityType === TYPE_ORDER) {
        routes.toOrderStatusFromLog({
          orderStatusId: entityId,
          isReplace: true,
          state: {
            isFromNotification: true,
            messageUuid
          }
        });
      }
    },
    [handleWorkspace, modals.assets, modals.tasks, routes]
  );

  // 604, 605, 606, 607
  const handleDiscussion = useCallback(
    async notification => {
      const { info, uuid } = notification;
      const { workspaceId } = info;

      await handleWorkspace(workspaceId);

      if (info.entityType === TYPE_TASK) {
        handleTaskDetail(notification);
      } else {
        routes.toDashboard({
          state: { notificationId: uuid }
        });
      }
    },
    [handleWorkspace, routes]
  );

  const handle = useCallback(
    notification => {
      dispatch(setDrawerVisible(false));

      switch (notification.templateUid) {
        case 2:
        case 4:
        case 5:
          handleOrderDeclined(notification);
          break;
        case 9:
          handleSelectContractor(notification);
          break;
        case 10:
          handleNewResponse(notification);
          break;
        case 13:
        case 76:
          handleOrderCompleted(notification);
          break;
        case 15:
        case 415:
          handleProjects(notification);
          break;
        case 16:
        case 414:
          handleProjectInvite(notification);
          break;
        case 19:
          handleComments(notification);
          break;
        case 28:
          handleInviteEmployee(notification);
          break;
        case 27:
        case 33:
        case 41:
        case 55:
        case 7000:
          handleEmployeeInfo(notification);
          break;
        case 30:
          handleDismiss(notification);
          break;
        case 120:
        case 167:
          handleCreateWorkspace(notification);
          break;
        case 29:
          handleWorkspaceJoin(notification);
          break;
        case 36:
          handleOrderClosing(notification);
          break;
        case 38:
          handleCreateFeedback(notification);
          break;
        case 45:
          handleOrderNegotiation(notification);
          break;
        case 7:
        case 14:
        case 32:
        case 42:
        case 43:
        case 44:
        case 46:
        case 47:
        case 99:
        case 104:
        case 113:
        case 114:
        case 115:
        case 116:
        case 121:
        case 122:
        case 123:
        case 124:
        case 125:
        case 126:
        case 127:
        case 149:
        case 150:
        case 151:
        case 152:
        case 153:
        case 154:
        case 155:
        case 156:
        case 157:
        case 158:
        case 159:
        case 160:
        case 161:
        case 164:
        case 165:
        case 166:
        case 205:
        case 206:
        case 208:
        case 303:
        case 304:
        case 305:
        case 400:
        case 418:
        case 419:
        case 420:
        case 421:
        case 423:
        case 424:
        case 425:
        case 427:
        case 428:
        case 429:
        case 431:
        case 434:
          handleOrderStatus(notification.info);
          break;
        case 11:
        case 21:
        case 22:
        case 23:
        case 24:
        case 34:
        case 35:
        case 37:
        case 64:
        case 101:
        case 103:
        case 106:
        case 107:
        case 109:
        case 146:
        case 147:
        case 204:
        case 309:
        case 310:
        case 311:
        case 453:
        case 501:
        case 666:
        case 668:
        case 723:
          handleTaskDetail(notification);
          break;
        case 701:
        case 702:
        case 704:
        case 705:
        case 706:
        case 721:
        case 707:
        case 708:
        case 709:
        case 711:
        case 713:
        case 715:
        case 716:
        case 717:
        case 720:
        case 724:
        case 725:
          handleRequestDetail(notification);
          break;
        case 410:
          handleTasks(notification);
          break;
        case 58:
          handleChangeAdmin(notification);
          break;
        case 65:
        case 110:
        case 171:
        case 200:
        case 631:
        case 667:
        case 510:
          handleOrgstructure(notification);
          break;
        case 69:
          handleDismissOrderStatusesSignatory(notification);
          break;
        case 71:
        case 102:
          handleWorkspaceProfile(notification);
          break;
        case 31:
        case 48:
        case 66:
        case 67:
        case 78:
          handleNotification(notification);
          break;
        case 77:
        case 79:
        case 80:
          handleToBilling(notification);
          break;
        case 100:
          handlePriceListWorkspace(notification);
          break;
        case 105:
          handleNewMarketplace(notification);
          break;
        case 108:
          handleNewMessageOrder(notification);
          break;
        case 26:
        case 130:
        case 135:
        case 136:
        case 137:
        case 140:
        case 142:
        case 143:
        case 144:
        case 145:
        case 148:
        case 308:
        case 313:
        case 314:
        case 438:
        case 444:
          handleContact(notification);
          break;
        case 442:
          handleImportContacts(notification);
          break;
        case 118:
        case 119:
          handleContactCompany(notification);
          break;
        case 129:
        case 441:
          handleChannel(notification);
          break;
        case 128:
        case 131:
        case 132:
        case 133:
        case 134:
        case 138:
        case 139:
        case 447:
          handleChannels(notification);
          break;
        case 163:
          handleVerified(notification);
          break;
        case 191:
          handleTemplate(notification);
          break;
        case 192:
        case 195:
        case 306:
        case 307:
          handleAttachment(notification);
          break;
        case 196:
        case 197:
        case 198:
        case 199:
        case 408:
        case 409:
        case 417:
        case 439:
        case 454:
        case 455:
          handleAsset(notification);
          break;
        case 201:
        case 202:
          handleAssets(notification);
          break;
        case 203:
          handleAssets(notification, {
            showAssingManagers: true
          });
          break;
        case 412:
        case 413:
          handleCategoryAssets(notification);
          break;
        case 433:
          handleRecord(notification);
          break;
        case 432:
          handleReport(notification);
          break;
        case 435:
          handleReports(notification);
          break;
        case 401:
          handleMention(notification);
          break;
        case 443:
        case 445:
        case 446:
        case 722:
          handleReaction(notification);
          break;
        case 603:
          handleMessageReminder(notification);
          break;
        case 604:
        case 605:
        case 606:
        case 607:
          handleDiscussion(notification);
          break;
        default:
          dispatch(setDrawerVisible(true));
          break;
      }
    },
    [
      dispatch,
      handleOrderDeclined,
      handleSelectContractor,
      handleNewResponse,
      handleOrderCompleted,
      handleProjects,
      handleProjectInvite,
      handleComments,
      handleInviteEmployee,
      handleEmployeeInfo,
      handleDismiss,
      handleCreateWorkspace,
      handleWorkspaceJoin,
      handleOrderClosing,
      handleCreateFeedback,
      handleOrderNegotiation,
      handleOrderStatus,
      handleTaskDetail,
      handleTasks,
      handleChangeAdmin,
      handleOrgstructure,
      handleDismissOrderStatusesSignatory,
      handleWorkspaceProfile,
      handleNotification,
      handleToBilling,
      handlePriceListWorkspace,
      handleNewMarketplace,
      handleNewMessageOrder,
      handleContact,
      handleImportContacts,
      handleContactCompany,
      handleChannel,
      handleChannels,
      handleVerified,
      handleTemplate,
      handleAttachment,
      handleAsset,
      handleAssets,
      handleCategoryAssets,
      handleRecord,
      handleReport,
      handleReports,
      handleMention,
      handleReaction,
      handleMessageReminder,
      handleDiscussion,
      handleRequestDetail
    ]
  );

  return { handle };
};

export default useNotificationsService;
