import React, { forwardRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CommonOption from 'components/common/controls/custom-select/custom-select/common-option';
// eslint-disable-next-line import/no-cycle
import ChannelsCreatorDrawer from 'components/contacts-view/views/integrations/drawers/creator';
import { getFirstStep } from 'components/contacts-view/views/integrations/drawers/creator/steps/utils';

import { fetchChannelsLocal } from 'store/channels';

import CustomSelect from '../custom-select';

const mapValue = channel => ({
  value: channel.id,
  label: {
    title: channel.name,
    ...channel
  }
});

export const ChannelSelect = forwardRef(
  ({ mapEntries, value, onChange, isMulti, ...props }, ref) => {
    const dispatch = useDispatch();

    const [visibleCreator, setVisibleCreator] = useState(false);

    const { t } = useTranslation('Common');

    const addEntityButtonData = {
      title: t('ConnectChannelBtn'),
      onClick: () => setVisibleCreator(true)
    };

    const map = mapEntries || mapValue;

    const creatableCallback = values => {
      const transfromed = [values].map(map)[0];
      onChange(isMulti ? [...(value || []), transfromed] : transfromed);
    };

    const fetchData = params =>
      dispatch(
        fetchChannelsLocal({ isArchived: false, lite: true, ...params })
      ).then(({ count, results }) => ({
        totalItems: count,
        entries: results.map(map)
      }));

    return (
      <>
        <CustomSelect
          fetchData={fetchData}
          ref={ref}
          valueText={t('ChooseChannel')}
          placeholder={`${t('Search')} ${t('PluralFormChannel')}`}
          showSelectedOptionsOnList={false}
          isSearchable
          isAsync
          Option={CommonOption}
          addEntityButtonData={addEntityButtonData}
          value={value}
          onChange={onChange}
          isMulti={isMulti}
          {...props}
        />

        <ChannelsCreatorDrawer
          visible={visibleCreator}
          data={{
            type: (props.params || {}).kind,
            hideBackButton: !!(props.params || {}).kind,
            step: (props.params || {}).kind
              ? getFirstStep((props.params || {}).kind)
              : undefined,
            callback: creatableCallback
          }}
          onClose={() => setVisibleCreator(false)}
        />
      </>
    );
  }
);

export default ChannelSelect;
