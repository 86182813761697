import React, { useState } from 'react';

// eslint-disable-next-line import/no-cycle
import MediaViewer from './media-viewer';

export const MediaViewerContext = React.createContext([{}, () => {}]);

export const MediaViewerProvider = ({ children }) => {
  const [state, setState] = useState({
    visible: false,
    activeIndex: undefined,
    items: []
  });

  return (
    <MediaViewerContext.Provider value={[state, setState]}>
      <MediaViewer />

      {children}
    </MediaViewerContext.Provider>
  );
};

export default MediaViewerProvider;
