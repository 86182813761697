import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Translation } from 'react-i18next';

import Typography from 'components/common/typography';
import {
  MyDriveIcon,
  GoogleDriveNotConnectedIcon
} from 'components/common/icons';

import { useWorkspaceGoogleDriveSupport } from 'hooks/workspace/useWorkspaceGoogleDriveSupport';

// eslint-disable-next-line import/no-cycle
import { Upload as UploadLegacy } from './upload-legacy';

import styles from './upload.module.scss';

const { Paragraph, Title } = Typography;

export const Upload = props => {
  const { t } = useTranslation('Common');

  const {
    isWorkspaceOwnGoogleDriveIntegrationEnabled,
    isOwnAndCommonGoogleDriveIntegrationEnabled
  } = useWorkspaceGoogleDriveSupport();

  // Такой кейс пока будет только для Busel Capital, потом удалится
  if (isOwnAndCommonGoogleDriveIntegrationEnabled) {
    return (
      <div>
        <UploadLegacy
          {...props}
          hasWorkspaceGoogleDriveSupport={false}
          description={
            <div
              style={{
                margin: '0 auto',
                maxWidth: 228
              }}
            >
              <Title
                level="5"
                style={{
                  fontSize: 16,
                  lineHeight: 1.5,
                  marginBottom: 8
                }}
              >
                {t('CompanyName', { ns: 'Common' })}
              </Title>
              <Paragraph style={{ marginBottom: 0 }}>
                {t('DragnDropDesc', { ns: 'UploadFile' })}
              </Paragraph>
            </div>
          }
        />

        <UploadLegacy
          {...props}
          hasWorkspaceGoogleDriveSupport
          icon={GoogleDriveNotConnectedIcon}
          description={
            <div
              style={{
                margin: '0 auto',
                maxWidth: 228
              }}
            >
              <Title
                level="5"
                style={{
                  fontSize: 16,
                  lineHeight: 1.5,
                  marginBottom: 8
                }}
              >
                {t('GoogleDrive', { ns: 'Common' })}
              </Title>
              <Paragraph style={{ marginBottom: 0 }}>
                {t('DragnDropDesc', { ns: 'UploadFile' })}
              </Paragraph>
            </div>
          }
        />
      </div>
    );
  }

  return isWorkspaceOwnGoogleDriveIntegrationEnabled ? (
    <UploadLegacy
      {...props}
      hasWorkspaceGoogleDriveSupport
      icon={GoogleDriveNotConnectedIcon}
    />
  ) : (
    <UploadLegacy {...props} hasWorkspaceGoogleDriveSupport={false} />
  );
};

Upload.propTypes = {
  isOnlyUpload: PropTypes.bool,
  uploadFileServer: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

Upload.defaultProps = {
  isOnlyUpload: false,
  uploadFileServer: true,
  icon: MyDriveIcon,
  description: (
    <Paragraph className={styles.title}>
      <Translation ns="UploadFile">{t => t('DragnDropDesc')}</Translation>
    </Paragraph>
  )
};
