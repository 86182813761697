import { TYPE_CHANNEL, TYPE_ASSET, TYPE_ORDER_STATUS } from 'constants/index';

const SLA_TYPES = {
  CHAT: 'chat',
  ASSET: TYPE_ASSET,
  CHANNEL: TYPE_CHANNEL,
  ORDER_STATUS: TYPE_ORDER_STATUS,
  ORDER_STATUS_NO_SLA: 'order_status_no_sla'
};

const SLA_MESSAGE_KEYS = {
  [SLA_TYPES.CHAT]: 'RequestCreateSLAWarning',
  [SLA_TYPES.ASSET]: 'RequestDirectoryRecordSLAWarning',
  [SLA_TYPES.CHANNEL]: 'RequestSLAWarning',
  [SLA_TYPES.ORDER_STATUS]: 'RequestCreateOrderStatusSLAWarning',
  [SLA_TYPES.ORDER_STATUS_NO_SLA]: 'RequestCreateOrderStatusWarning'
};

const SLA_RULES = {
  CHAT: ({ isSlaRequestFormChat }) => isSlaRequestFormChat,

  ASSET: {
    default: ({ isAsset, defaultValues }) =>
      isAsset &&
      defaultValues?.slaObject?.executorSla &&
      defaultValues?.slaObject?.slaIsActive,

    form: ({ formSlaValue, executorSla }) =>
      formSlaValue?.type === TYPE_ASSET && executorSla
  },

  CHANNEL: {
    form: ({ formSlaValue, executorSla }) =>
      (formSlaValue?.type === TYPE_CHANNEL || !!formSlaValue?.label?.uuid) &&
      executorSla,

    copy: ({ isCopy, defaultValues, executorSla }) => {
      const hasInitialSla =
        defaultValues?.taskInfo?.slaObject &&
        defaultValues?.taskInfo?.executorSla;

      return isCopy && hasInitialSla && !!executorSla;
    }
  },

  ORDER_STATUS: {
    form: ({ isDeal, formSlaValue, executorSla }) =>
      (isDeal || formSlaValue?.type === TYPE_ORDER_STATUS) && executorSla,

    copy: ({ isCopy, defaultValues, executorSla }) => {
      const hasInitialSla =
        defaultValues?.taskInfo?.slaObject &&
        defaultValues?.taskInfo?.executorSla;

      return isCopy && hasInitialSla && !!executorSla;
    },

    noSla: ({ isDeal, formSlaValue, executorSla }) =>
      (isDeal || formSlaValue?.type === TYPE_ORDER_STATUS) && !executorSla
  }
};

export const getSlaType = params => {
  if (SLA_RULES.CHAT(params)) return SLA_TYPES.CHAT;

  if (SLA_RULES.ASSET.default(params) || SLA_RULES.ASSET.form(params))
    return SLA_TYPES.ASSET;

  if (SLA_RULES.CHANNEL.form(params) || SLA_RULES.CHANNEL.copy(params))
    return SLA_TYPES.CHANNEL;

  if (
    SLA_RULES.ORDER_STATUS.form(params) ||
    SLA_RULES.ORDER_STATUS.copy(params)
  )
    return SLA_TYPES.ORDER_STATUS;

  if (SLA_RULES.ORDER_STATUS.noSla(params))
    return SLA_TYPES.ORDER_STATUS_NO_SLA;

  return null;
};

export const getAlertMessageKey = slaType => SLA_MESSAGE_KEYS[slaType];
