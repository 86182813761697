import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import Modal from 'components/common/modal';
import Button from 'components/common/button';
import Typography from 'components/common/typography';
import { FormInputNumber } from 'components/common/hook-form';

import { update } from 'store/assets';

import { convertToHoursAndMinutes } from 'utils/convertToHoursAndMinutes';
import convertToMinutes from 'utils/convert-to-minutes';
import useAmplitude from 'hooks/amplitude/use-amplitude';

const { Text } = Typography;

const EditorForm = ({ asset, hasExecutorSla, onSubmit, isLoading }) => {
  const { t } = useTranslation(['ConnectWidget', 'Common']);

  const executorSla =
    hasExecutorSla && convertToHoursAndMinutes(asset.executorSla || 0);

  const methods = useForm({
    defaultValues: {
      slaIsActive: asset.slaIsActive,
      executorSla: {
        executorSlaHours: executorSla?.hours,
        executorSlaMinutes: executorSla?.minutes
      }
    }
  });

  const isExecutorHoursWatch = useWatch({
    name: 'executorSla.executorSlaHours',
    control: methods.control
  });
  const isExecutorMinutesWatch = useWatch({
    name: 'executorSla.executorSlaMinutes',
    control: methods.control
  });

  const handleSubmit = data => ({
    slaIsActive: true,
    executorSla: convertToMinutes({
      hours: data.executorSla.executorSlaHours,
      minutes: data.executorSla.executorSlaMinutes
    })
  });

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(v => onSubmit(handleSubmit(v)))}
        data-qa="qa-kgj3ldmdxfczot7"
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '16px 24px 24px'
        }}
      >
        <Text>{t('PerformerSLADesc')}</Text>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
            margin: '16px 0'
          }}
        >
          <FormInputNumber
            name="executorSla.executorSlaHours"
            label={t('Hours')}
            placeholder="0"
            itemProps={{ style: { width: '100%', marginBottom: 0 } }}
            maxLength={3}
            rules={{
              required:
                !isExecutorMinutesWatch && t('RequiredField', { ns: 'Errors' })
            }}
          />

          <FormInputNumber
            name="executorSla.executorSlaMinutes"
            label={t('Minutes')}
            placeholder="0"
            itemProps={{ style: { width: '100%', marginBottom: 0 } }}
            maxLength={2}
            rules={{
              required:
                !isExecutorHoursWatch && t('RequiredField', { ns: 'Errors' })
            }}
          />
        </div>

        <Button
          type="primary"
          data-qa="qa-okrl4246omkkcf2"
          htmlType="submit"
          loading={isLoading}
          style={{ marginLeft: 'auto' }}
        >
          {t('SaveBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

export const SlaChangeModal = ({ visible, onClose, isEditor, asset }) => {
  const { t } = useTranslation(['ConnectWidget', 'Common']);
  const amplitude = useAmplitude();

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const hasExecutorSla = !!(asset || {}).executorSla;

  const onSubmit = async value => {
    try {
      amplitude.editAssetEvent();

      await dispatch(
        update({
          id: asset.id,
          asset: {
            slaIsActive: value.slaIsActive,
            executorSla: value.executorSla
          },
          isFromDetails: true
        })
      );

      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={`${t('Edit', { ns: 'Common' })} ${t('PerformerSLAHeading', { ns: 'ConnectWidget' })}`}
      centered
      open={visible}
      width={508}
      onClose={onClose}
      destroyOnClose
    >
      <EditorForm
        asset={asset}
        hasExecutorSla={hasExecutorSla}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};
