import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';
import moment from 'moment';

// eslint-disable-next-line import/no-cycle
import { FormInputNumber, validateRequired } from 'components/common/hook-form';
import Button from 'components/common/button';

import { getPluralizedDaysText } from 'utils/getPluralizedDaysText';

import styles from './ExpirationNoticeForm.module.scss';

export const ExpirationNoticeForm = ({
  onSubmit,
  isLoading,
  defaultValues,
  validityDate
}) => {
  const { t } = useTranslation('Asset');

  const methods = useForm({ defaultValues });

  const expirationDaysWatch = methods.watch('expirationDays');

  const [debouncedExpirationDays, setDebouncedExpirationDays] =
    useState(expirationDaysWatch);

  useEffect(() => {
    const debouncedFn = debounce(value => {
      setDebouncedExpirationDays(value);
    }, 300);

    debouncedFn(expirationDaysWatch);

    return () => {
      debouncedFn.cancel();
    };
  }, [expirationDaysWatch]);

  const notificationBeforeExpiration = value => {
    const notificationDate = moment().add(value, 'days');

    if (!notificationDate.isSameOrBefore(moment(validityDate))) {
      return t('DaysSelectorAttention');
    }

    return true;
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className={styles.form}>
        <div>
          <FormInputNumber
            label={t('DaysSelectorSubTitle')}
            name="expirationDays"
            addonAfter={getPluralizedDaysText(debouncedExpirationDays)}
            disableZeroEntry
            isOnlyWhole
            className={styles.numberInput}
            itemProps={{
              style: { marginBottom: 0 }
            }}
            rules={{
              required: validateRequired(),
              validate: {
                notificationBeforeExpiration: value =>
                  notificationBeforeExpiration(value)
              }
            }}
          />
        </div>

        <Button
          htmlType="submit"
          type="primary"
          width="expanded"
          className={styles.submitBtn}
          loading={isLoading}
          disabled={isLoading}
        >
          {t('SaveBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};
