import React from 'react';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

import Attachment from 'components/common/attachments/attachment';

import styles from './CommentInfo.module.scss';

export const CommentInfo = ({ reason, fileList }) => {
  const { t } = useTranslation('Common');

  const getMessage = () => (
    <span className={styles.alertMessage}>
      {t('Comment', { ns: 'Common' })}:
    </span>
  );

  const getDescription = () => (
    <div className={styles.commentWrap}>
      {reason && <span className={styles.alertDescription}>{reason}</span>}

      {fileList.length > 0 && (
        <>
          {fileList.map(item => (
            <Attachment
              file={item}
              key={item.fileId}
              hideActions
              isOpenViaLink={false}
              isEditableFileDownloadOnly
              showDownloadButton
            />
          ))}
        </>
      )}
    </div>
  );

  return (
    <div className={styles.alertWrap}>
      <Alert
        message={getMessage()}
        description={getDescription()}
        type="warning"
      />
    </div>
  );
};
