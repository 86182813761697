import React, { useEffect, useRef } from 'react';

export const EmailIframe = ({ content }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    if (iframeRef.current && content) {
      const doc = iframeRef.current.contentWindow.document;
      doc.open();
      doc.write(content);
      doc.close();
    }
  }, [content]);

  return (
    <iframe
      ref={iframeRef}
      style={{ width: '100%', height: '600px', border: 'none' }}
    />
  );
};
