import { useState, useEffect } from 'react';

import { MODAL, CREATE_REQUEST } from '../../constants';
// eslint-disable-next-line import/no-cycle
import { useQueryParam } from '../common';

export const useRequestCreator = (defaultState = false) => {
  const [visible, setVisible] = useState(defaultState);

  const modal = useQueryParam(MODAL);

  useEffect(() => {
    setVisible(!!(modal === CREATE_REQUEST));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  return [visible, setVisible];
};

export default useRequestCreator;
