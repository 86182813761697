import React, { useEffect, useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { Radio, Tabs, Badge } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  DISCUSSION_STATUS_ARCHIVED,
  TIME_FORMAT,
  NOTIFICATIONS
} from 'constants/index';

import Typography from 'components/common/typography';
import Message from 'components/contacts-view/views/chats/contact-chat/message';
import Button from 'components/common/button';
import { SkeletonButton } from 'components/common/skeletons';
import Icon from 'components/common/icon';
import EntityIcon from 'components/dashboard-view/views/notifications/entity-icon';
import NotificationMessageChat from 'components/dashboard-view/views/notifications/notification-message-chat';
import {
  convertMessageToNode,
  hasContent
} from 'components/common/comments/converters';
import DiscussionActions from 'components/dashboard-view/views/notifications/discussion-actions';
import DiscussionMembers from 'components/dashboard-view/views/notifications/discussion-members';
import DiscussionCreateTask from 'components/dashboard-view/views/notifications/discussion-create-task';
import Tooltip from 'components/common/tooltip';
import { HistoryActionExpander } from 'components/common/chat/history-action-expander';

import { getCreatedDiscussionId } from 'store/discussions/selectors';

import { ExpandHistoryActionsProvider } from 'providers/expand-history-actions-provider';

import useNotificationMessage from './use-notification-message';
import useEntityLink from './use-entity-link';

import styles from './message.module.scss';

const NotificationMessage = ({ message, rootClassName, view }) => {
  const {
    entityFromNotification,
    controls,
    content,
    checkAllowReaction,
    onClick,
    onRead,
    sourceRoomUuid,
    notificationChatDisplay,
    handleNotificationChatDisplay,
    isDiscussion,
    isDisplayTime,
    isDiscussionChatOpened,
    entity
  } = useNotificationMessage({ message, view });

  const { entityLink, openInNewTab } = useEntityLink({
    entityType: entityFromNotification.destinationEntityType,
    entityId: entityFromNotification.destinationEntityId
  });

  const createdDiscussionId = useSelector(getCreatedDiscussionId);

  const { t } = useTranslation(['Dashboard', 'Task', 'Common']);

  const {
    createdAt,
    source,
    sourceChatUnreadMessagesCount,
    notificationData,
    status,
    sender,
    fileList,
    isRead
  } = message;

  const {
    isMessageReminder,
    notificationComment,
    sourceMessage = {},
    entityId
  } = notificationData;

  const isArchivedDiscussion = status === DISCUSSION_STATUS_ARCHIVED;

  const renderedContent = useMemo(() => {
    if (content && !isMessageReminder && !source) {
      return <Typography.Text>{content}</Typography.Text>;
    }

    return null;
  }, [content, isMessageReminder, source]);

  useEffect(() => {
    if (message.uuid === createdDiscussionId) {
      onClick();
    }
  }, [createdDiscussionId, message, onClick]);

  return (
    <div className={classnames(styles.root, rootClassName)}>
      <div>
        <Tooltip title={t(isRead ? 'MarkUnreadTip' : 'MarkReadTip')}>
          <Radio
            checked={!isRead}
            disabled={isArchivedDiscussion}
            className={styles.radio}
            onClick={() => onRead()}
          />
        </Tooltip>
      </div>

      <div
        className={classnames(styles.message, { [styles.isRead]: isRead })}
        onClick={onClick}
      >
        <div className={styles.header}>
          <EntityIcon
            entityType={entityFromNotification.destinationEntityType}
            isMessageReminder={isMessageReminder}
            isArchivedDiscussion={isArchivedDiscussion}
          />

          {entityFromNotification.title ? (
            <Typography.Paragraph
              color="brand-30"
              ellipsis={{ rows: 2 }}
              style={{ marginBottom: 0 }}
            >
              <Tooltip title={entityFromNotification.title}>
                {entityFromNotification.title}
              </Tooltip>
            </Typography.Paragraph>
          ) : (
            renderedContent
          )}

          <div className={styles.headerRightSide}>
            {entityLink && (
              <Tooltip title={t('OpenInANewTab', { ns: 'Common' })}>
                <Button
                  type="text"
                  mood="none"
                  onClick={event => {
                    openInNewTab(entityLink);
                    event.stopPropagation();
                  }}
                  className={styles.openNewTabBtn}
                >
                  <Icon type="open-in-new-widnow" size={20} />
                </Button>
              </Tooltip>
            )}

            {isDiscussion && <DiscussionMembers discussionId={entityId} />}

            {isDiscussion && isArchivedDiscussion && (
              <DiscussionCreateTask
                discussionId={entityId}
                sender={sender}
                fileList={fileList}
              />
            )}

            {isDisplayTime && (
              <Typography.Text color="black-55">
                {moment(createdAt).format(TIME_FORMAT)}
              </Typography.Text>
            )}

            {isDiscussionChatOpened && !isArchivedDiscussion && (
              <DiscussionActions
                discussionId={entityId}
                sender={sender}
                fileList={fileList}
              />
            )}

            {!isRead &&
              typeof sourceChatUnreadMessagesCount === 'number' &&
              sourceChatUnreadMessagesCount > 1 && (
                <div className={styles.badgeWrap}>
                  <Badge
                    count={sourceChatUnreadMessagesCount}
                    className={styles.badge}
                  />
                </div>
              )}
          </div>
        </div>

        {entityFromNotification.title && renderedContent}

        {isMessageReminder && (
          <div className={styles.reminderContent}>
            {hasContent(sourceMessage.text) ? (
              <Typography.Text>
                {t('RemindAboutMessage', { ns: 'Dashboard' })}:
                {convertMessageToNode(sourceMessage.text)}
              </Typography.Text>
            ) : (
              <Typography.Text>
                {t('RemindAboutMessage', { ns: 'Dashboard' })}.
              </Typography.Text>
            )}

            {notificationComment && (
              <Typography.Text color="black-45">
                {t('Comment', { ns: 'Common' })}: {notificationComment}
              </Typography.Text>
            )}
          </div>
        )}

        {source && (
          <div className={styles.sourceWrap} onClick={e => e.stopPropagation()}>
            {!notificationChatDisplay[sourceRoomUuid] && (
              <Message
                entity={entityFromNotification}
                entityType={entityFromNotification.type}
                destination={{
                  entityType: entityFromNotification.destinationEntityType,
                  entityId: entityFromNotification.id,
                  entity: {
                    title: entityFromNotification.entityTitle
                  }
                }}
                messages={{
                  prev: message,
                  curr: source
                }}
                rootClassName={classnames({
                  [styles.sourceWithControls]:
                    notificationChatDisplay[sourceRoomUuid]
                })}
                onClickAnswer={() => handleNotificationChatDisplay(true)}
                onClickReply={values => {
                  handleNotificationChatDisplay(true);

                  controls.onClickReply(values);
                }}
                scrollToMessage={() => {}}
                checkAllowReaction={checkAllowReaction}
                isDisplayActions={!isArchivedDiscussion}
              />
            )}

            {notificationChatDisplay[sourceRoomUuid] && (
              <>
                {isEmpty(entity) ? (
                  <SkeletonButton className={styles.skeletonControls} />
                ) : (
                  <ExpandHistoryActionsProvider>
                    <Tabs
                      activeKey="messages"
                      tabBarExtraContent={{
                        right: (
                          <div className={styles.extraTabContentWrap}>
                            {!isDiscussion && (
                              <HistoryActionExpander iconSize="small" />
                            )}

                            <Button
                              type="text"
                              className={styles.closeBtn}
                              onClick={() =>
                                handleNotificationChatDisplay(false)
                              }
                            >
                              <Icon type="close" size={16} color="black-55" />
                            </Button>
                          </div>
                        )
                      }}
                      className={styles.tabs}
                      items={[
                        {
                          key: 'messages',
                          label: (
                            <>
                              <Icon type="comment" size={16} color="black-55" />
                              <span>
                                {t('TaskMessagesTab', { ns: 'Task' })}
                              </span>
                            </>
                          ),
                          'data-qa': 'qa-c5l2lti1h1c0h44'
                        }
                      ]}
                    />

                    <NotificationMessageChat
                      entity={entity}
                      entityFromNotification={entityFromNotification}
                      isDisplayControls={!isArchivedDiscussion}
                      mentionClassName={
                        view === NOTIFICATIONS ? styles.mentionWrap : undefined
                      }
                    />
                  </ExpandHistoryActionsProvider>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

NotificationMessage.propTypes = {
  rootClassName: PropTypes.string,
  message: PropTypes.shape({
    sender: PropTypes.object,
    createdAt: PropTypes.string,
    text: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string })),
    source: PropTypes.shape({}),
    notificationData: PropTypes.object
  }).isRequired,
  view: PropTypes.string.isRequired
};

NotificationMessage.defaultProps = {
  rootClassName: undefined
};

export default NotificationMessage;
