import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Upload } from 'components/common/attachments-control/shared/upload';

import FormItem from '../form-item';

export const FormUpload = ({
  rules,
  name,
  label,
  itemProps,
  hasWorkspaceGoogleDriveSupport,
  ...props
}) => {
  const { control, formState } = useFormContext();

  return (
    <FormItem
      label={label}
      name={name}
      {...itemProps}
      errors={formState.errors}
    >
      <Controller
        control={control}
        rules={rules}
        render={({ field }) => (
          <Upload
            fileList={field.value}
            {...field}
            {...props}
            hasWorkspaceGoogleDriveSupport={hasWorkspaceGoogleDriveSupport}
            showAttachments
          />
        )}
      />
    </FormItem>
  );
};

FormUpload.defaultProps = {
  rules: PropTypes.any,
  itemProps: PropTypes.object,
  hasWorkspaceGoogleDriveSupport: PropTypes.bool
};

FormUpload.defaultProps = {
  rules: {},
  itemProps: {},
  hasWorkspaceGoogleDriveSupport: false
};
