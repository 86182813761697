import { useState, useEffect } from 'react';

import { MODAL, CREATE_TASK } from '../../constants';
// eslint-disable-next-line import/no-cycle
import { useQueryParam } from '../common';

export const useTaskCreator = (defaultState = false) => {
  const [visible, setVisible] = useState(defaultState);

  const modal = useQueryParam(MODAL);

  useEffect(() => {
    setVisible(!!(modal === CREATE_TASK));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  return [visible, setVisible];
};

export default useTaskCreator;
