import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CREATED_AT,
  SLA_TYPE_OPTIONS,
  TYPE_ASSET,
  TYPE_CHANNEL,
  TYPE_ORDER_STATUS
} from 'constants/index';

import AssetsSelect from '../assets-select';
// eslint-disable-next-line import/no-cycle
import ChannelSelect from '../channel-select';
import OrderSelect from '../order-select';
import { SlaTypeSelect } from './SlaTypeSelect';

const formatSelectValue = (value, type) => {
  if (!value) return null;

  switch (type) {
    case TYPE_ASSET:
      return {
        label: {
          ...value.label,
          title: value.label?.name || value.label?.title,
          fullPath: value.label?.extra?.fullPath || value.label?.fullPath,
          category: value.label?.extra?.categoryId
            ? { id: value.label?.extra?.categoryId }
            : { ...value.label?.category }
        },
        value: value.value
      };
    case TYPE_ORDER_STATUS:
      return {
        label: {
          ...value.label,
          orderId: value.label?.extra?.orderId || value.label?.orderId,
          title: value.label?.name || value.label?.title
        },
        value: value.value
      };
    default:
      return value;
  }
};

export const ObjectSlaSelect = ({
  value: selectValue,
  onChange,
  defaultType,
  contact,
  isDisabled,
  onOrderSelect,
  isDealsObjectSlaEnabled,
  ...props
}) => {
  const [currentType, setCurrentType] = useState(
    () => SLA_TYPE_OPTIONS.find(o => o.value === defaultType) || null
  );

  const [localValue, setLocalValue] = useState({
    [defaultType]: selectValue
      ? formatSelectValue(selectValue, defaultType)
      : null
  });

  const { t } = useTranslation(['Common', 'Requests', 'TaskLinks']);

  useEffect(() => {
    if (defaultType) {
      setCurrentType(
        SLA_TYPE_OPTIONS.find(o => o.value === defaultType) || null
      );
      setLocalValue({
        [defaultType]: selectValue
          ? formatSelectValue(selectValue, defaultType)
          : null
      });
    }
  }, [selectValue, defaultType]);

  const handleTypeChange = selectedType => {
    if (!selectedType) {
      setCurrentType(null);
      setLocalValue({});
      onChange(null);
      return;
    }

    setCurrentType(selectedType);
    setLocalValue({});
    onChange(null);
  };

  const handleValueChange = (selectedItem, type) => {
    if (!selectedItem || Object.keys(selectedItem).length === 0) {
      setLocalValue({
        [type]: null
      });
      onChange(null);
      return;
    }

    const formattedValue = formatSelectValue(selectedItem, type);
    setLocalValue({
      [type]: formattedValue
    });
    onChange({
      type,
      item: formattedValue
    });

    if (type === TYPE_ORDER_STATUS) {
      onOrderSelect?.(formattedValue.label.contractorId);
    }
  };

  const getCurrentSelect = () => {
    if (!currentType) {
      return null;
    }

    const commonProps = {
      value: localValue[currentType.value],
      onChange: selectedItem =>
        handleValueChange(selectedItem, currentType.value),
      isClearable: true,
      isDisabled,
      closeMenuOnSelect: true,
      style: { margin: '16px 0' },
      optionProps: {
        style: {
          display: 'flex',
          justifyContent: 'space-between'
        }
      }
    };

    if (currentType.value === TYPE_CHANNEL) {
      return (
        <ChannelSelect
          label={`${t('Channel', { ns: 'Common' })} ${t('Optional', { ns: 'Common' })}`}
          addEntityButtonData={null}
          params={{ contact, ordering: 'name', lite: false }}
          valueText={t('ChooseChannel')}
          {...commonProps}
        />
      );
    }

    if (currentType.value === TYPE_ASSET) {
      return (
        <AssetsSelect
          valueText={t('ChooseAsset')}
          label={`${t('Asset', { ns: 'Common' })} ${t('Optional', { ns: 'Common' })}`}
          {...commonProps}
        />
      );
    }

    if (currentType.value === TYPE_ORDER_STATUS) {
      return (
        <OrderSelect
          valueText={t('ChooseOrder')}
          params={{
            ordering: CREATED_AT,
            direction: 'DESC',
            contractorIds: contact
          }}
          label={`${t('Order', { ns: 'TaskLinks' })} ${t('Optional', { ns: 'Common' })}`}
          {...commonProps}
        />
      );
    }

    return null;
  };

  return (
    <>
      <SlaTypeSelect
        value={currentType}
        onChange={handleTypeChange}
        isDisabled={isDisabled}
        isDealsObjectSlaEnabled={isDealsObjectSlaEnabled}
        {...props}
      />

      {getCurrentSelect()}
    </>
  );
};
