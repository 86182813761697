import React from 'react';

// eslint-disable-next-line import/no-cycle
import MainForm from './main-form';
import SelectTypeStep from './choose-type-form';
import { useCreatorDrawerContext } from './creator-drawer-context/use-creator-drawer-context';

import styles from './styles.module.scss';

const StepsProvider = ({ visible: drawerVisible }) => {
  const {
    onSelectType,
    currentPage,
    handleSubmit,
    currentTaskType,
    setCurrentTaskType,
    values,
    isBacklog,
    isSprints,
    isLoading,
    isFromTemplate,
    activeTab,
    setActiveTab,
    currentTaskTemplate,
    visibleCalender,
    setVisibleCandar
  } = useCreatorDrawerContext();

  const steps = [
    {
      content: (
        <SelectTypeStep
          onSubmit={onSelectType}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          drawerVisible={drawerVisible}
          values={values}
          isSprints={isSprints}
          isBacklog={isBacklog}
        />
      )
    },
    {
      content: (
        <MainForm
          onSubmit={handleSubmit}
          currentTaskType={currentTaskType}
          setCurrentTaskType={setCurrentTaskType}
          values={values}
          isLoading={isLoading}
          isBacklog={isBacklog}
          isSprints={isSprints}
          currentTaskTemplate={currentTaskTemplate}
          isFromTemplate={isFromTemplate}
          meetingCalendar={{
            visible: visibleCalender,
            setVisible: setVisibleCandar
          }}
        />
      )
    }
  ];

  return (
    <div className={styles.stepsContainer} data-qa="qa-vwb4ymkvxm3zp55">
      <div className={styles.stepsContent} data-qa="qa-2q9sxev4qejmq8n">
        {steps[currentPage].content}
      </div>
    </div>
  );
};

export default StepsProvider;
