/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Icon from 'components/common/icon';

import {
  getIsVideo,
  getIsPDF,
  getIsImage,
  getIsAudio
} from 'hooks/common/use-file-upload/types';

import ImageItem from './image-item';
import VideoItem from './video-item';
// eslint-disable-next-line import/no-cycle
import PdfItem from './pdf-item';

import styles from './viewer.module.scss';

const VISIBLE_INDICATORS_COUNT = 8;

const KEY_CODE = {
  LEFT: 37,
  RIGTH: 39,
  ESC: 27
};

export const Viewer = ({
  showPreview,
  showIndex,
  items,
  activeIndex: index,
  prefixCls,
  onClose
}) => {
  const [activeIndex, setActiveIndex] = useState(index);
  const [rotate, setRotate] = useState(0);

  const itemControl = useCallback(
    newIndex => {
      if (newIndex === activeIndex) return;

      setActiveIndex(newIndex);
    },
    [activeIndex]
  );

  const onRotate = useCallback(side => {
    const deg = side === 'left' ? -90 : 90;

    setRotate(prev => prev + deg);
  }, []);

  const onPrev = useCallback(() => {
    const newIndex = (activeIndex + items.length - 1) % items.length;

    itemControl(newIndex);
  }, [activeIndex, itemControl, items.length]);

  const onNext = useCallback(() => {
    const newIndex = (activeIndex + 1) % items.length;

    itemControl(newIndex);
  }, [activeIndex, itemControl, items.length]);

  const onKeyDown = useCallback(
    e => {
      e.stopPropagation();

      switch (e.which || e.keyCode) {
        case KEY_CODE.LEFT:
          onPrev();
          break;
        case KEY_CODE.RIGTH:
          onNext();
          break;
        case KEY_CODE.ESC:
          onClose();
          break;
        default:
          break;
      }
    },
    [onClose, onNext, onPrev]
  );

  useEffect(() => {
    document.documentElement.addEventListener('keydown', onKeyDown);

    return () =>
      document.documentElement.removeEventListener('keydown', onKeyDown);
  }, [onKeyDown]);

  const renderIndicators = useCallback(
    list => {
      const ret = Math.round(VISIBLE_INDICATORS_COUNT / 2);
      const { length } = list;

      return list.map((item, i) => {
        const isActive = activeIndex === i;
        const itemInvisible =
          length > VISIBLE_INDICATORS_COUNT &&
          (i <
            Math.min(
              length - VISIBLE_INDICATORS_COUNT - 1,
              activeIndex - ret
            ) ||
            i > Math.max(activeIndex + ret, VISIBLE_INDICATORS_COUNT));

        return (
          <div
            key={i}
            className={classnames('indicators-item', {
              active: isActive,
              invisible: itemInvisible,
              preview: showPreview
            })}
            onClick={itemControl}
          >
            {showPreview && (
              <img className="image" src={item.url} alt={item.name} />
            )}
          </div>
        );
      });
    },
    [activeIndex, itemControl, showPreview]
  );

  const indicatorVisible = items.length > 1;

  if (!items.length) {
    return null;
  }

  const currentItem = items[activeIndex];

  return (
    <div
      className={classnames('react-image-viewer', `${prefixCls}-image-viewer`)}
    >
      <div className="close-button" onClick={onClose} />

      {(getIsPDF(currentItem) || getIsImage(currentItem)) && (
        <>
          <Icon
            type="enter"
            color="white"
            size={36}
            className={classnames(styles.rotateButton, styles.left)}
            onClick={() => onRotate('left')}
          />

          <Icon
            type="enter"
            color="white"
            size={36}
            className={classnames(styles.rotateButton, styles.right)}
            onClick={() => onRotate('right')}
          />
        </>
      )}

      {getIsPDF(currentItem) && (
        <PdfItem url={currentItem.url} rotate={rotate} />
      )}

      {getIsVideo(currentItem) && (
        <VideoItem style={currentItem?.style} url={currentItem?.url} />
      )}

      {getIsImage(currentItem) && (
        <ImageItem
          showIndex={showIndex}
          index={`${activeIndex + 1}/${items.length}`}
          image={{ ...currentItem, src: currentItem.url }}
          rotate={rotate}
        />
      )}

      {getIsAudio(currentItem) && (
        <audio
          autoPlay
          controls
          src={currentItem.url}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '60%'
          }}
        />
      )}

      {indicatorVisible && (
        <div className="direction-control-button">
          <div className="prev-button button" onClick={onPrev}>
            <div className="bar" />
          </div>
          <div className="next-button button" onClick={onNext}>
            <div className="bar" />
          </div>
          <div className="indicators">{renderIndicators(items)}</div>
        </div>
      )}
    </div>
  );
};

Viewer.propTypes = {
  showPreview: PropTypes.bool,
  activeIndex: PropTypes.number,
  items: PropTypes.array,
  showIndex: PropTypes.bool,
  prefixCls: PropTypes.string,
  onClose: PropTypes.func
};

Viewer.defaultProps = {
  showPreview: true,
  showIndex: true,
  activeIndex: 0,
  items: [],
  prefixCls: 'react-image-viewer',
  onClose: () => {}
};

export default Viewer;
