import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { groupBy, isEmpty, sortBy } from 'lodash';
import moment from 'moment';

import {
  DATE_FORMAT_FM,
  DIALOG_TYPE_LOG,
  ITEMS_PER_PAGE,
  TYPE_CONTACT
} from 'constants/index';

import { getIsMyMessage } from 'components/contacts-view/utils';
import Message from 'components/contacts-view/views/chats/contact-chat/message';
import Controls from 'components/contacts-view/views/chats/contact-chat/controls';
import useControls from 'components/contacts-view/views/chats/contact-chat/controls/use-controls';
import styles from 'components/contacts-view/views/chats/contact-chat/unified-chat.module.scss';

import { getUserEmployee } from 'store/workspace';
import {
  clearEntityChats,
  getEntityChat,
  getOperatorIsReady
} from 'store/operator';
import { getRouterState } from 'store/router';

import { useWebsocketOperatorContext } from 'providers';
import { useScrollBottom } from 'hooks';
import useVisibilityChange from 'hooks/common/use-visibility-change';
import { useExpandHistoryActionsContext } from 'providers/expand-history-actions-provider';

import { HistoryAction } from './history-action';
import { GroupedDateList, CHAT_MESSAGE } from './grouped-date-list';
import { useHistoryActions } from './history-action/hooks';

const LIMIT_FOR_SCROLL_TO_MESSAGE = 500;

// eslint-disable-next-line default-param-last
export const transformChatsToJoinedGroups = (chats = [], entityType) =>
  chats.reduce((acc, curr) => {
    acc.push(curr.uuid);

    if (entityType === TYPE_CONTACT) {
      acc.push(curr.channelUuid);
    }

    return acc;
  }, []);

const useChat = ({
  entity = {},
  destination,
  entityType,
  customMessagesSort,
  needJoin = true,
  needLeave = true,
  defaultNeedAutoScroll = true,
  sendMessageCallback
}) => {
  const dispatch = useDispatch();

  const [needAutoScroll, setNeedAutoScroll] = useState(defaultNeedAutoScroll);
  const [parent, setParent] = useState(null);
  const [searchedMessageData, setSearchedMessageData] = useState(null);

  const entityChat = useSelector(state =>
    getEntityChat(state)({ entityId: entity.id, entityType })
  );
  const isReady = useSelector(getOperatorIsReady);

  const employee = useSelector(getUserEmployee);

  const routerState = useSelector(getRouterState);

  const socket = useWebsocketOperatorContext();

  const tabActive = useVisibilityChange();

  const sortedMessages = useMemo(() => {
    let messages = entityChat.messages || [];

    // visually do not read notifications when chat is open
    messages = messages.map(m => ({
      ...m,
      isRead:
        m.tempIsRead !== null && m.tempIsRead !== undefined
          ? m.tempIsRead
          : m.isRead
    }));

    messages = sortBy(messages || [], item => Date.parse(item.createdAt));

    if (customMessagesSort) {
      messages = [...messages].sort(customMessagesSort);
    }

    return messages;
  }, [customMessagesSort, entityChat.messages]);

  const isLoading = useMemo(
    () => entityChat.isMessagesLoading,
    [entityChat.isMessagesLoading]
  );

  const hasMessages = useMemo(
    () => !!sortedMessages.length,
    [sortedMessages.length]
  );

  const messagesCount = useMemo(
    () => sortedMessages.length,
    [sortedMessages.length]
  );

  const hasMore = useMemo(
    () => !isLoading && entityChat.count > sortedMessages.length,
    [entityChat.count, isLoading, sortedMessages.length]
  );

  const [messagesRef, isBottom, scrollToBottom] = useScrollBottom({
    defaultState: false,
    dependencies: [entity.id, sortedMessages.length]
  });

  const {
    historyActionRefs,
    historyActionsState,
    isAllHistoryActionCollapsed,
    setHistoryActionsState
  } = useExpandHistoryActionsContext() || {};

  const { toggleHistoryAction, messageToHistoryActionMap } = useHistoryActions({
    messagesRef,
    sortedMessages,
    historyActionsState,
    setHistoryActionsState,
    isAllHistoryActionCollapsed,
    isLoading,
    historyActionRefs
  });

  const isJoined = useMemo(
    () =>
      (entity.chats || []).length
        ? !!(entityChat.roomUuids || []).length &&
          entityChat.roomUuids.every(uuid =>
            socket.channelGroups.map(g => g.uuid).includes(uuid)
          )
        : true,
    [entity.chats, entityChat.roomUuids, socket.channelGroups]
  );

  const controls = useControls({
    messages: sortedMessages,
    parent,
    entity,
    isMessagesLoading: entityChat.isMessagesLoading,
    destination,
    entityType,
    setParent,
    scrollToBottom,
    sendMessageCallback
  });

  const messageRefs = useMemo(
    () =>
      sortedMessages.reduce((acc, curr) => {
        acc[curr.uuid] = createRef();

        if (curr.children) {
          (curr.children || []).forEach(child => {
            acc[child.uuid] = createRef();
          });
        }

        return acc;
      }, {}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortedMessages.length]
  );

  const fetchMessages = useCallback(
    async ({
      readable,
      limit = ITEMS_PER_PAGE * 2,
      offset = sortedMessages.length,
      withSource,
      withParent,
      isRefetch,
      withAggregation,
      filters,
      cancelable
    } = {}) => {
      if (offset > entityChat.count) {
        return null;
      }

      return socket.fetchMessages({
        entityId: entity.id,
        entityType,
        chats: entity.chats,
        readable,
        limit,
        offset,
        withSource,
        withParent,
        isRefetch,
        withAggregation,
        filters,
        cancelable
      });
    },
    [
      entity.chats,
      entity.id,
      entityChat.count,
      entityType,
      socket,
      sortedMessages.length
    ]
  );

  const scrollToMessage = useCallback(
    // eslint-disable-next-line default-param-last
    async (messageUuid, behavior = 'auto', newOffset) => {
      setSearchedMessageData(null);

      if ((messageRefs[messageUuid] || {}).current) {
        messageRefs[messageUuid].current.scrollIntoView({
          block: 'center',
          behavior
        });

        messageRefs[messageUuid].current.classList.remove(
          styles.searchedMessage
        );

        return setTimeout(
          () => {
            if (messageRefs[messageUuid].current) {
              messageRefs[messageUuid].current.classList.add(
                styles.searchedMessage
              );
            }
          },

          100
        );
      }

      const historyActionRef = messageToHistoryActionMap.current[messageUuid];

      if (historyActionRef.current) {
        historyActionRef.current.scrollIntoView({
          block: 'center',
          behavior
        });

        const highlightElement = className => {
          const headerElement = historyActionRef.current.querySelector(
            '.ant-collapse-header'
          );

          if (headerElement) {
            headerElement.classList.remove(
              styles.searchedMessageInHistoryAction
            );

            setTimeout(() => {
              headerElement.classList.add(className);
            }, 100);
          }
        };

        highlightElement(styles.searchedMessageInHistoryAction);
      }

      const chat = await fetchMessages({
        limit: LIMIT_FOR_SCROLL_TO_MESSAGE,
        offset: newOffset
      });

      if (!chat || !chat.messages.length) {
        return null;
      }

      const searchedMessage = chat.messages.find(m => m.uuid === messageUuid);

      if (searchedMessage) {
        return setSearchedMessageData({
          uuid: messageUuid,
          behavior
        });
      }

      return scrollToMessage(
        messageUuid,
        behavior,
        (newOffset || sortedMessages.length) + chat.messages.length
      );
    },
    [fetchMessages, messageRefs, sortedMessages.length]
  );

  const autoScroll = useCallback(() => {
    const { messageUuid: searchedMessageUuid } = entity.messagePreview || {};

    const withoutLogAndMyMessages = sortedMessages.filter(
      msg =>
        msg.kind !== DIALOG_TYPE_LOG &&
        !getIsMyMessage(msg.payload, employee.id)
    );
    const firstUnreadedMessageUuid = (
      withoutLogAndMyMessages[
        withoutLogAndMyMessages.length - entityChat.messageNewCount
      ] || {}
    ).uuid;

    const messageUuid =
      searchedMessageUuid ||
      routerState.messageUuid ||
      firstUnreadedMessageUuid;

    if (messageUuid) {
      scrollToMessage(messageUuid);
      return setNeedAutoScroll(false);
    }

    scrollToBottom();
    return setNeedAutoScroll(false);
  }, [
    entity.messagePreview,
    sortedMessages,
    entityChat.messageNewCount,
    routerState.messageUuid,
    scrollToBottom,
    employee.id,
    scrollToMessage
  ]);

  const joinChat = useCallback(
    () =>
      socket.joinChannels(
        transformChatsToJoinedGroups(entity.chats, entityType)
      ),
    [entity.chats, entityType, socket]
  );

  const leaveChat = useCallback(() => {
    socket.leaveChannels(transformChatsToJoinedGroups(entity.chats));

    dispatch(clearEntityChats({ entityId: entity.id, entityType }));
  }, [dispatch, entity.chats, entity.id, entityType, socket]);

  const groupedMessagesByDate = groupBy(sortedMessages, item =>
    moment(item.createdAt).format(DATE_FORMAT_FM)
  );

  const handleLogGroup = ({ key, logs }) => {
    if (!historyActionRefs.current[key]) {
      historyActionRefs.current[key] = React.createRef();
    }

    logs.forEach(log => {
      messageToHistoryActionMap.current[log.uuid] =
        historyActionRefs.current[key];
    });

    const isCollapsed =
      historyActionsState?.[key] ?? isAllHistoryActionCollapsed;

    return (
      <HistoryAction
        key={key}
        ref={historyActionRefs.current[key]}
        logs={logs}
        entity={entity}
        entityType={entityType}
        destination={destination}
        sortedMessages={sortedMessages}
        isCollapsed={isCollapsed}
        toggleGroup={({ lastLog }) => toggleHistoryAction({ key, lastLog })}
      />
    );
  };

  const renderedMessages = useMemo(() => {
    if (isEmpty(entityChat) || !sortedMessages.length) {
      return null;
    }

    const combinedItems = [];
    let currentLogsGroup = [];

    sortedMessages.forEach((message, index) => {
      const isLog = message.kind === DIALOG_TYPE_LOG;
      const isNewMessage = !isLog && currentLogsGroup.length;

      if (isNewMessage) {
        const historyKey = `log-group-${currentLogsGroup[0]?.uuid}`;
        combinedItems.push(
          handleLogGroup({
            key: historyKey,
            logs: currentLogsGroup,
            sortedMessages
          })
        );
        currentLogsGroup = [];
      }

      if (isLog) {
        currentLogsGroup.push(message);
      } else {
        combinedItems.push(
          <Message
            key={message.uuid}
            ref={messageRefs[message.uuid]}
            entity={entity}
            entityType={entityType}
            destination={destination}
            createdAt={message.createdAt}
            messages={{
              prev: sortedMessages[index - 1],
              curr: message,
              next: sortedMessages[index + 1]
            }}
            onClickReply={controls.onClickReply}
            scrollToMessage={scrollToMessage}
          />
        );
      }
    });

    if (currentLogsGroup.length) {
      const finalKey = `log-group-${currentLogsGroup[0]?.uuid}`;
      combinedItems.push(
        handleLogGroup({
          key: finalKey,
          logs: currentLogsGroup,
          sortedMessages
        })
      );
    }

    return (
      <GroupedDateList
        source={CHAT_MESSAGE}
        groupedMessagesByDate={groupedMessagesByDate}
      >
        {combinedItems}
      </GroupedDateList>
    );
  }, [
    entityChat,
    sortedMessages,
    messageRefs,
    entity,
    entityType,
    destination,
    controls.onClickReply,
    scrollToMessage,
    historyActionsState
  ]);

  const renderedControls = useMemo(() => {
    if ((!entity.chats || []).length || entityChat.messages === undefined) {
      return null;
    }

    return (
      <Controls
        parentData={{
          message: parent,
          onDelete: () => setParent(null),
          onClick: messageUuid => scrollToMessage(messageUuid, 'smooth')
        }}
        controls={controls}
        entity={entity}
        typingList={entityChat.typingList}
      />
    );
  }, [
    entity,
    entityChat.messages,
    entityChat.typingList,
    parent,
    controls,
    scrollToMessage
  ]);

  useEffect(() => {
    if (
      searchedMessageData &&
      (messageRefs[searchedMessageData.uuid] || {}).current
    ) {
      scrollToMessage(searchedMessageData.uuid, searchedMessageData.behavior);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedMessageData, messageRefs.length]);

  useEffect(() => {
    if (
      needAutoScroll &&
      !entityChat.isMessagesLoading &&
      sortedMessages.length > 1 &&
      isJoined
    ) {
      autoScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    entity.id,
    entityChat.isMessagesLoading,
    sortedMessages.length,
    isJoined
  ]);

  useEffect(() => {
    setNeedAutoScroll(defaultNeedAutoScroll);
    setParent(null);
    controls.setSubject('');
    controls.privateData.onChange(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity.id]);

  useEffect(() => {
    if (entity.chats && isReady && needJoin) {
      joinChat();
    }

    return () => {
      if (entity.chats && isReady && needLeave) {
        leaveChat();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity.chats, isReady]);

  useEffect(() => {
    if (
      tabActive &&
      !entityChat.isMessagesLoading &&
      isBottom &&
      entityChat.messageNewCount
    ) {
      entity.chats.forEach(chat => {
        const lastChatMessage = sortedMessages
          .slice()
          .reverse()
          .find(message => message.roomUuid === chat.uuid);

        if (lastChatMessage) {
          socket.readMessage({
            roomUuid: lastChatMessage.roomUuid,
            messageUuid: lastChatMessage.uuid,
            channelKind: chat.channelKind,
            channelUuid: chat.channelUuid
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sortedMessages.length,
    tabActive,
    entity.id,
    isBottom,
    entityChat.messageNewCount
  ]);

  return {
    isLoading,
    messagesRef,
    hasMessages,
    renderedMessages,
    renderedControls,
    toBottom: {
      visible: !isBottom,
      hasNewMessages: !!entityChat.messageNewCount,
      onClick: scrollToBottom
    },
    fetchMessages,
    joinChannels: socket.joinChannels,
    entityChat,
    isJoined,
    messages: sortedMessages,
    hasMore,
    isReady,
    leaveChat,
    controls,
    messagesCount
  };
};

export default useChat;
