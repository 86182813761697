import { LANGUAGE } from 'i18n-config';
import moment from 'moment';

import getIso2CodeByLanguage from 'utils/get-iso-2-code-by-language';
import handleActions from 'utils/redux-actions';

import { setUILanguage, tickUIGlobalInterval } from './actions';

const initialState = {
  language: localStorage.getItem(LANGUAGE),
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  globalInterval: 0
};

export default handleActions(
  {
    [setUILanguage]: (state, { payload }) => {
      state.language = payload;

      moment.locale(getIso2CodeByLanguage({ language: payload }));

      return state;
    },
    [tickUIGlobalInterval]: state => {
      state.globalInterval += 1;

      return state;
    }
  },
  initialState
);
