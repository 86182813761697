import React from 'react';
import { Alert } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import { Link } from 'react-router-dom';

import {
  FUNNELS_SETTINGS,
  HIRING,
  TESTID_ORDERS_CREATOR_MODAL_CONTRACTOR_SELECT,
  TESTID_ORDERS_CREATOR_MODAL_DEADLINE_AT_PICKER,
  TESTID_ORDERS_CREATOR_MODAL_TITLE_INPUT,
  TYPE_CONTACT
} from 'constants/index';

import { Icon } from 'components/common/icon';
import FormFunnelTypeSelect from 'components/common/hook-form/select/funnel-type';
// eslint-disable-next-line import/no-cycle
import {
  FormAttachDocuments,
  FormDatePicker,
  FormEmployeeSelect,
  FormInput,
  FormRelationsSelect,
  LabelWithTooltip
} from 'components/common/hook-form';
import Button from 'components/common/button';

import useRoutesService from 'services/routes';

import {
  FieldNames,
  handleFunnelTypeCallback,
  getShortFormFields
} from './utils';
import FormCounterpartSelect from '../main/components/counterpart-select';

import styles from './short.module.scss';

const renderServiceNameField = ({ t }) => (
  <FormInput
    label={t('ServiceName')}
    name="title"
    placeholder={t('EnterName')}
    rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
    data-testid={TESTID_ORDERS_CREATOR_MODAL_TITLE_INPUT}
    itemProps={{ style: { marginBottom: 0 } }}
  />
);

const renderDocumentListField = ({ t, isEdit, isCopy, defaultValues }) => (
  <FormAttachDocuments
    label={
      isEdit || isCopy
        ? t('AttachDocsBtn', { ns: 'Common' })
        : t('AddFileDrawerTitle', { ns: 'AddFile' })
    }
    name="fileList"
    buttonProps={{ type: 'link', className: styles.fileButton }}
    itemProps={{ style: { marginBottom: 0 } }}
    actionsDeps={{
      entityType: TYPE_CONTACT,
      entityId: defaultValues.contractorId
    }}
  />
);

const renderDateFields = ({ t, minTimeEnd, maxTimeEnd }) => (
  <FormDatePicker
    label={t('OrderDueDate')}
    placeholderText={t('ChooseDate', { ns: 'Common' })}
    name="deadlineAt"
    minDate={new Date()}
    minTime={minTimeEnd}
    maxTime={maxTimeEnd}
    wrapperClassname={styles.datePicker}
    dataTestId={TESTID_ORDERS_CREATOR_MODAL_DEADLINE_AT_PICKER}
    itemProps={{ style: { marginBottom: 0 } }}
    rules={{
      required: t('RequiredField', { ns: 'Errors' }),
      validate: value =>
        !value || moment(value).isSameOrAfter(moment())
          ? true
          : t('CannotBeEarlierThanCurrentDate', {
              ns: 'Errors'
            })
    }}
  />
);

const renderContractorField = ({ t, isIncoming }) => (
  <FormCounterpartSelect
    label={t('Contractor')}
    name="contractor"
    rules={{
      required: t('RequiredField', { ns: 'Errors' })
    }}
    dataTestId={TESTID_ORDERS_CREATOR_MODAL_CONTRACTOR_SELECT}
    itemProps={{ style: { marginBottom: 0 } }}
    isMulti={isIncoming}
    closeMenuOnSelect={!isIncoming}
  />
);

const renderRelationsField = ({ t }) => (
  <FormRelationsSelect name="relations" label={t('Link', { ns: 'AddFile' })} />
);

const renderHiringFields = ({ t, contractorField }) => {
  const contractorHasInfo =
    contractorField &&
    contractorField.label &&
    (contractorField.label.phone || contractorField.label.email);

  return (
    <div className={styles.hiringWrapper}>
      <FormInput
        name="position"
        placeholder={t('EnterJobTitle', { ns: 'Contacts' })}
        label={t('JobTitle', { ns: 'Contacts' })}
        itemProps={{ style: { marginBottom: 0, width: '50%' } }}
        rules={{
          required: t('RequiredField', { ns: 'Errors' }),
          minLength: {
            value: 2,
            message: t('MinCheckingName', { ns: 'Errors' })
          },
          maxLength: {
            value: 256,
            message: t('ValidateMaxLength', {
              ns: 'Errors',
              value: '256'
            })
          }
        }}
      />
      <FormInput
        name="profileLink"
        placeholder={t('InsertCVLink', { ns: 'Contacts' })}
        label={t('ProfileLink', { ns: 'Contacts' })}
        itemProps={{ style: { marginBottom: 0, width: '50%' } }}
        rules={{
          required: !contractorHasInfo && t('RequiredField', { ns: 'Errors' }),
          minLength: {
            value: 2,
            message: t('MinCheckingName', { ns: 'Errors' })
          },
          maxLength: {
            value: 1000,
            message: t('ValidateMaxLength', {
              ns: 'Errors',
              value: '1000'
            })
          }
        }}
      />
    </div>
  );
};

const renderSignerField = ({ t, defaultValues, routes }) => (
  <FormEmployeeSelect
    name="signatory"
    label={
      <LabelWithTooltip
        label={
          defaultValues.kind === HIRING
            ? t('Approver', { ns: 'Contacts' })
            : t('Signer', { ns: 'Order' })
        }
        tooltip={
          <Trans
            i18nKey={
              defaultValues.kind === HIRING
                ? 'ApproverFieldTip'
                : 'SignerFieldTip'
            }
            ns="AddOrder"
            components={{
              lnk: <Link to={routes.toContacts({ view: FUNNELS_SETTINGS })} />
            }}
          />
        }
        style={{ maxWidth: 255 }}
      />
    }
    rules={{
      required: t('RequiredField', { ns: 'Errors' })
    }}
    allowSetYourself={false}
    params={{
      workspaceControllers: true
    }}
    isDisabled={defaultValues.disableChangeSignatory}
    optionProps={{ isFilters: true }}
    renderContentTop={() => {}}
    itemProps={{ style: { marginBottom: 0 } }}
  />
);

const renderFunnelTypeField = ({ t, methods, contractorField }) => (
  <FormFunnelTypeSelect
    key="funnelType"
    label={t('FunnelType', { ns: 'Contacts' })}
    name="kind"
    placeholder={t('EnterFunnelType')}
    itemProps={{ style: { marginBottom: 0 } }}
    callback={funnelType =>
      handleFunnelTypeCallback({ methods, contractorField, funnelType })
    }
  />
);

const useRenderFields = ({
  methods,
  defaultValues,
  isHiring,
  isCreate,
  isEdit,
  isCopy,
  relationsVisible,
  setRelationsVisible,
  withContractor,
  contractorField,
  isIncoming,
  minTimeEnd,
  maxTimeEnd
}) => {
  const { t } = useTranslation([
    'AddOrder',
    'Contacts',
    'AddFile',
    'Errors',
    'Order',
    'Common'
  ]);

  const routes = useRoutesService({ returnUrl: true });

  const preparedFields = getShortFormFields({
    isCreate,
    isCopy,
    isEdit,
    defaultValues,
    isHiring,
    relationsVisible,
    withContractor,
    contractorField,
    isIncoming,
    minTimeEnd,
    maxTimeEnd
  });

  const renderField = field => {
    switch (field) {
      case FieldNames.FUNNEL_TYPE:
        return renderFunnelTypeField({ t, methods, contractorField });
      case FieldNames.SERVICE_NAME:
        return renderServiceNameField({ t });
      case FieldNames.DOCUMENT_LIST:
        return renderDocumentListField({ t, isEdit, isCopy, defaultValues });
      case FieldNames.ATTACHMENTS_ALERT:
        return (
          <Alert
            type="warning"
            message={t('AttacmentsWillBeSendToContact', { ns: 'Contacts' })}
          />
        );
      case FieldNames.SIGNER:
        return renderSignerField({ t, defaultValues, routes });
      case FieldNames.CONTRACTOR:
        return renderContractorField({ t, isIncoming });
      case FieldNames.HIRING:
        return renderHiringFields({ t, contractorField });
      case FieldNames.DATE:
        return renderDateFields({ t, maxTimeEnd, minTimeEnd });
      case FieldNames.ADD_LINK:
        return (
          <Button
            key={FieldNames.ADD_LINK}
            type="link"
            size="large"
            className={styles.addBtn}
            onClick={() => setRelationsVisible(true)}
          >
            <Icon type="plus" />
            {t('AddLinkToDeal')}
          </Button>
        );
      case FieldNames.RELATIONS:
        return renderRelationsField({ t });
      default:
        return null;
    }
  };

  return preparedFields.map(renderField).filter(Boolean);
};

export default useRenderFields;
