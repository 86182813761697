import React from 'react';
import PropTypes from 'prop-types';
import { API_ROOT } from 'api/v1';

import Option from '../option';

import styles from './styles.module.scss';

const getFlagSrc = alpha2Code =>
  `${API_ROOT}/static/flags/${alpha2Code.toLowerCase()}.png`;

export const CountryOption = ({ option, className, withFlags, ...props }) => (
  <Option className={className} title={option.name} {...props}>
    <span style={{ display: 'inline-block', width: 30 }}>
      {withFlags && (
        <img
          className={styles.flag}
          src={getFlagSrc(option.alpha2Code)}
          alt={option.name}
        />
      )}
    </span>

    {option.name}
  </Option>
);

CountryOption.propTypes = {
  option: PropTypes.shape({
    name: PropTypes.string,
    alpha2Code: PropTypes.string,
    className: PropTypes.string
  }).isRequired,
  withIcon: PropTypes.bool,
  withFlags: PropTypes.bool
};

CountryOption.defaultProps = {
  withIcon: true,
  withFlags: true
};

export default CountryOption;
