import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import {
  TYPE_TASK,
  TYPE_CONTACT,
  TYPE_CONTACT_COMPANY,
  TYPE_TEMPLATE,
  TYPE_WORKSPACE,
  TYPE_CHANNEL,
  TYPE_ASSET,
  TYPE_ORDER_STATUS,
  TYPE_ORDER,
  TYPE_REQUEST
} from 'constants/index';

import Typography from 'components/common/typography';
import Icon from 'components/common/icon';
import Tooltip from 'components/common/tooltip';
import { getTaskAccessType } from 'components/tasks-view/utils';

import useRoutesService from 'services/routes';
import useModalsService from 'services/modals';

import { getTooltipTitle } from './utils/getTooltipTitle';

import styles from './entities.module.scss';

const Item = ({
  objectId,
  fullPath,
  title,
  type,
  hasAccess,
  contractorId
} = {}) => {
  const routes = useRoutesService({ returnUrl: true });
  const modals = useModalsService({ returnUrl: true });

  const { t } = useTranslation(['MyDrive', 'Filters', 'Common']);

  const tooltipTitle = getTooltipTitle({ type, title, fullPath, objectId });

  const currentTypeData = {
    [TYPE_TASK]: {
      type: t('TaskLinksFilters'),
      title: `ID ${objectId} ${title}`,
      link: `${routes.toTasks()}${modals.tasks.showDetails({ id: objectId })}`
    },
    [TYPE_REQUEST]: {
      type: t('RequestLinksFilters', { ns: 'Filters' }),
      title: `ID ${objectId} ${title}`,
      link: `${routes.toRequests()}${modals.requests.showDetails({ id: objectId })}`
    },
    [TYPE_ORDER]: {
      type: t('OrderLinksFilters'),
      title: `ID ${objectId} ${title}`,
      link: `${routes.toNewOrderStatus({
        orderStatusId: objectId,
        contactId: contractorId
      })}`
    },
    [TYPE_ORDER_STATUS]: {
      type: t('OrderLinksFilters'),
      title: `${title}`,
      link: `${routes.toNewOrderStatus({
        orderStatusId: objectId,
        contactId: contractorId
      })}`
    },
    [TYPE_CONTACT]: {
      type: t('ContactLinksFilters'),
      title: `ID ${objectId} ${title}`,
      link: routes.toContact({ id: objectId })
    },
    [TYPE_CONTACT_COMPANY]: {
      type: t('Counterparty', { ns: 'Common' }),
      title: `ID ${objectId} ${title}`,
      link: routes.toContractor(objectId)
    },
    [TYPE_TEMPLATE]: {
      type: t('Template', { ns: 'Common' }),
      title: `ID ${objectId} ${title}`
    },
    [TYPE_WORKSPACE]: {
      type: t('Company', { ns: 'Common' }),
      title: `ID ${objectId} ${title}`
    },
    [TYPE_CHANNEL]: {
      type: t('Channel', { ns: 'Common' }),
      title: `ID ${objectId} ${title}`
    },
    [TYPE_ASSET]: {
      type: t('AssetLinksFilters'),
      title: `${title} ${fullPath || ''}`,
      link: `${routes.toAssets()}${modals.assets.showDetails({ id: objectId })}`
    }
  };

  if (!currentTypeData[type]) {
    return (
      <Tooltip title={tooltipTitle}>
        <div className={styles.item}>
          <Typography.Text ellipsis>{title}</Typography.Text>
        </div>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={tooltipTitle}>
      <div className={styles.item}>
        <Typography.Text
          style={{ marginRight: 4 }}
          className={{
            [styles.noAccessEntityType]: !getTaskAccessType(hasAccess)
          }}
        >
          {currentTypeData[type].type}
        </Typography.Text>

        {currentTypeData[type].link ? (
          <Link
            to={currentTypeData[type].link}
            className={classnames(styles.link, {
              [styles.noAccess]: !getTaskAccessType(hasAccess)
            })}
          >
            {currentTypeData[type].title}
          </Link>
        ) : (
          <Typography.Text ellipsis>{title}</Typography.Text>
        )}
      </div>
    </Tooltip>
  );
};

const Menu = memo(({ items }) => (
  <div className={styles.menu}>
    {items.map(i => (
      <Item key={`${i.objectId}${i.title}`} {...i} />
    ))}
  </div>
));

export const Entities = memo(({ items, orderStatuses }) => {
  const hasOrderStatuses = items.some(item => item.type === TYPE_ORDER_STATUS);

  const [results, setResults] = useState(items);

  const transformItems = () => {
    const transformedItems = items.map(curr => {
      if (curr.type === TYPE_ORDER_STATUS) {
        const orderStatus = orderStatuses.find(os => os.id === curr.objectId);
        return { ...curr, ...(orderStatus || {}) };
      }
      return curr;
    });

    setResults(transformedItems);
  };

  useEffect(() => {
    if (hasOrderStatuses && orderStatuses) {
      transformItems();
    }
  }, [hasOrderStatuses, orderStatuses]);

  if (hasOrderStatuses && !orderStatuses) {
    return (
      <Skeleton
        active
        paragraph={false}
        title={{
          width: '100%',
          className: styles.skeletonTitle
        }}
      />
    );
  }

  return (
    <Dropdown
      disabled={results.length < 2}
      dropdownRender={() => <Menu items={results} />}
      trigger={['click']}
    >
      <div className={styles.root}>
        <Item {...results[0]} />

        {results.length > 1 && (
          <Icon
            color="black-55"
            type="arrow"
            size={16}
            className={styles.icon}
          />
        )}
      </div>
    </Dropdown>
  );
});

Entities.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      objectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      title: PropTypes.string,
      type: PropTypes.string,
      fullPath: PropTypes.string
    })
  ),
  orderStatuses: PropTypes.array
};

Entities.defaultProps = {
  items: [],
  orderStatuses: null
};

export default Entities;
