import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';

import { CHANGE_EXPIRATION_DATE_MODAL, DATE_FORMAT } from 'constants/index';

import Typography from 'components/common/typography';
import Button from 'components/common/button';
import { useModalContext } from 'components/app/modals-provider';
import Icon from 'components/common/icon';

import { getPluralizedDaysText } from 'utils/getPluralizedDaysText';

import styles from './ExpirationNotice.module.scss';

const { Paragraph, Text } = Typography;

export const ExpirationNotice = ({ asset = {} }) => {
  const { t } = useTranslation(['Asset', 'Common']);

  const { showModal } = useModalContext();

  const { expirationTaskCreatedAt, permissions, expirationDays } = asset;

  return (
    <div className={styles.root}>
      <Paragraph
        color="black-55"
        style={{
          marginBottom: 4,
          textTransform: 'uppercase',
          fontWeight: 700,
          maxWidth: 270
        }}
      >
        {t('ExpirationHeader')}
      </Paragraph>

      <div className={styles.dateWrap}>
        {expirationTaskCreatedAt ? (
          <div className={styles.taskCreatedAt}>
            <Text color="yellow-40" className={styles.notificationSent}>
              {t('NotificationSentIos')}{' '}
              {moment(expirationTaskCreatedAt).format(DATE_FORMAT)}
              <span className={styles.notificationSentIcon}>
                <Icon type="outdated" size={16} color="yellow-20" />
              </span>
            </Text>
          </div>
        ) : (
          <Text>
            {t('InDays')} {expirationDays}{' '}
            {getPluralizedDaysText(expirationDays)}
          </Text>
        )}

        {permissions.editAsset && (
          <Button
            type="link"
            style={{
              padding: 0,
              fontWeight: 400
            }}
            onClick={() =>
              showModal(CHANGE_EXPIRATION_DATE_MODAL, {
                asset
              })
            }
          >
            {t(expirationTaskCreatedAt ? 'Update' : 'Edit', { ns: 'Common' })}
          </Button>
        )}
      </div>
    </div>
  );
};
