import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';
// eslint-disable-next-line import/no-cycle
import Pagination from 'components/common/pagination';

import sanitizeHTML from 'utils/sanitize-html';

import styles from '../../table.module.scss';

const Content = ({ content }) => {
  const [index, setIndex] = useState(0);

  const { t } = useTranslation('MyDrive');

  const currentItem = content[index];

  return (
    <div className={styles.content}>
      <Typography.Text className={styles.withHighlight}>
        <span
          dangerouslySetInnerHTML={{
            __html: sanitizeHTML(currentItem.content)
          }}
        />
      </Typography.Text>

      {currentItem.pageName && (
        <Typography.Text size="small" color="black-55">
          {currentItem.pageName}
        </Typography.Text>
      )}

      {currentItem.pageNumber && (
        <Typography.Text size="small" color="black-55">
          {`${t('PageSearch')} ${currentItem.pageNumber}`}
        </Typography.Text>
      )}

      {content.length > 1 && (
        <Pagination
          currentPage={index + 1}
          totalItems={content.length}
          paginationProps={{ simple: true }}
          simplePaginationProps={{
            arrowSize: 16,
            textProps: {
              size: 'small',
              color: 'black-55',
              weight: 'regular'
            }
          }}
          rootClassName={styles.contentPagination}
          onChangePage={page => setIndex(page - 1)}
        />
      )}
    </div>
  );
};

Content.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      pageNumber: PropTypes.number,
      pageName: PropTypes.string
    })
  ).isRequired
};

export default Content;
