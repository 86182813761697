import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  STATUS_CANCELLED,
  STATUS_DONE,
  TASK_FIELD_DESCRIPTION,
  TYPE_ACQUAINTANCE,
  TYPE_AGREEMENT
} from 'constants/index';

import Typography from 'components/common/typography';
import DetailsField from 'components/common/details-field';
import { renderDescriptions } from 'components/common/comments/converters';
import { getTaskDescriptionText } from 'components/requests-view/utils';

import { useAmplitude } from 'hooks/amplitude/use-amplitude';
import { FieldEditorItem } from 'providers/field-editor';

import styles from './description-info.module.scss';

const DescriptionInfo = ({ task, transformedRequest }) => {
  const { t } = useTranslation('Task');
  const amplitude = useAmplitude();

  const isCompleted =
    task.status === STATUS_DONE || task.status === STATUS_CANCELLED;

  const hasDescription =
    task.description && task.description[0] && task.description[0].text;

  const isTypeAcquaintence = task.kind === TYPE_ACQUAINTANCE;
  const isTypeAgreement = task.kind === TYPE_AGREEMENT;

  const isExcludedType = isTypeAcquaintence || isTypeAgreement || isCompleted;

  const handleDoubleClick = () => {
    if (!hasDescription) {
      amplitude.logAddTaskDescriptionEvent('double_click_on_details');
    }
  };

  if (!hasDescription && isExcludedType) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div onDoubleClick={handleDoubleClick}>
        <FieldEditorItem
          field={TASK_FIELD_DESCRIPTION}
          className={styles.item}
          defaultValues={{
            [TASK_FIELD_DESCRIPTION]: {
              description: getTaskDescriptionText(task.description),
              fileList: transformedRequest.fileList
            }
          }}
        >
          {hasDescription ? (
            <DetailsField
              className={styles.detailsField}
              iconType="description"
              title={t('Description')}
            >
              <Typography.Paragraph style={{ margin: 0 }}>
                {renderDescriptions(task.description)}
              </Typography.Paragraph>
            </DetailsField>
          ) : (
            !isTypeAcquaintence &&
            !isTypeAgreement && (
              <DetailsField iconType="description" title={t('Description')}>
                <Typography.Text color="black-55">
                  {t('DescriptionPlaceholder')}
                </Typography.Text>
              </DetailsField>
            )
          )}
        </FieldEditorItem>
      </div>
    </div>
  );
};

DescriptionInfo.propTypes = {
  task: PropTypes.shape({
    description: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string
      })
    )
  }),
  transformedRequest: PropTypes.shape({
    fileList: PropTypes.array
  })
};

DescriptionInfo.defaultProps = {
  task: {},
  transformedRequest: {}
};

export default DescriptionInfo;
