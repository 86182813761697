import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import {
  INCOMING,
  ORDER_STATUS_ORDERING_OPTIONS,
  CREATED_AT,
  DEALS,
  ORDER_STATUS_KINDS
} from 'constants/index';

import useManageSubscribers from 'components/common/subscriptions/use-manage-subscribers';
import useValidityDate from 'components/common/validity-date/use-validity-date';

import {
  createOrderStatus,
  fetchOrderStatusContractor,
  fetchOrderStatusAttachments,
  fetchOrderStatus,
  clearOrderStatusesContactsEntries,
  setOrderStatusesFilterKind,
  setOrderStatusesOrdering
} from 'store/order-statuses';
import { getUserEmployee } from 'store/workspace';

import useRoutesService from 'services/routes';
import useAmplitude from 'hooks/amplitude/use-amplitude';

const getOrderKind = () =>
  ORDER_STATUS_KINDS.filter(x => x.value === INCOMING).map(
    ({ ns, label, value }) => ({ ns, label, value })
  );

const useCopy = ({ orderStatusId, contactId, visible, onClose }) => {
  const dispatch = useDispatch();
  const routes = useRoutesService();
  const amplitude = useAmplitude();

  const [isLoading, setIsLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [values, setValues] = useState({});

  const employee = useSelector(getUserEmployee);

  const { subscribeToNewAttachments } = useManageSubscribers();
  const { handleChangeValidityDateForNewAttachments } = useValidityDate();

  const fetchOrderData = useCallback(async () => {
    try {
      setFetchLoading(true);

      const orderStatus = await dispatch(
        fetchOrderStatus({ id: orderStatusId })
      );

      const contractor = await dispatch(
        fetchOrderStatusContractor({ contractorId: orderStatus.contractorId })
      );

      const { results: documentList } = await dispatch(
        fetchOrderStatusAttachments({
          params: { relatedOrderStatuses: orderStatusId, ignoreCache: true }
        })
      );

      setValues({
        ...orderStatus,
        contractor,
        signatoryId: null,
        signatory: null,
        description: orderStatus.content,
        fileList: documentList,
        startAt: null,
        deadlineAt: null
      });
    } finally {
      setFetchLoading(false);
    }
  }, [dispatch, orderStatusId]);

  useEffect(() => {
    if (visible) {
      fetchOrderData();
    }
    return () => setValues({});
  }, [fetchOrderData, visible]);

  const onSubmit = useCallback(
    async ({ contractor, ...newValues }) => {
      try {
        setIsLoading(true);

        const contractors = Array.isArray(contractor)
          ? contractor
          : [contractor];

        const hasOnlyOneContractor = contractors.length === 1;

        const results = await Promise.all(
          contractors.map(c => {
            const orderStatusValues = { ...newValues, contractor: c };

            amplitude.addOrderEvent({
              ...orderStatusValues,
              isCopy: true
            });

            return dispatch(createOrderStatus({ values: orderStatusValues }));
          })
        );

        routes.toNewOrderStatus({
          contactId,
          orderStatusId: results[0].identifier
        });

        if (!hasOnlyOneContractor) {
          routes.toContacts({ view: DEALS });

          dispatch(setOrderStatusesFilterKind(getOrderKind()));

          const createdAtOrdering = ORDER_STATUS_ORDERING_OPTIONS.find(
            a => a.key === CREATED_AT
          );
          dispatch(
            setOrderStatusesOrdering({
              ...createdAtOrdering,
              key: `-${createdAtOrdering.key}`
            })
          );
        }

        subscribeToNewAttachments(newValues.attachmentFileList);
        handleChangeValidityDateForNewAttachments({
          attachments: newValues.attachmentFileList
        });

        dispatch(clearOrderStatusesContactsEntries());

        onClose();
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, contactId, onClose, routes, employee]
  );

  const hasValues = !isEmpty(values) && !fetchLoading;

  return {
    isLoading,
    values,
    hasValues,
    onSubmit
  };
};

export default useCopy;
