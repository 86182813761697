import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { STATUS_COMPLETED, STATUS_CANCELLED } from 'constants/index';

import useValidityDate from 'components/common/validity-date/use-validity-date';
import useManageSubscribers from 'components/common/subscriptions/use-manage-subscribers';

import {
  fetchOrderStatus,
  fetchOrderStatusAttachments,
  editOrderStatus,
  changeOrderStatusSignatory,
  fetchOrderStatusSignatory
} from 'store/order-statuses';
import { changeResponsible, fetchTaskLocal } from 'store/tasks';

import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';
import useAmplitude from 'hooks/amplitude/use-amplitude';

const useEdit = ({ orderStatusId, onClose, visible }) => {
  const dispatch = useDispatch();
  const amplitude = useAmplitude();

  const [isLoading, setIsLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [values, setValues] = useState({});
  const [approvingTaskId, setApprovingTaskId] = useState(null);
  const [disableChangeSignatory, setDisableChangeSignatory] = useState(false);
  const [prevSignatoryId, setPrevSignatoryId] = useState(null);

  const { subscribeToNewAttachments } = useManageSubscribers();
  const { handleChangeValidityDateForNewAttachments } = useValidityDate();

  const fetchOrderData = useCallback(async () => {
    try {
      setFetchLoading(true);

      const orderStatus = await dispatch(
        fetchOrderStatus({ id: orderStatusId })
      );

      const haveSignatory = !!orderStatus.signatoryId;

      let signatoryData = {};
      if (haveSignatory) {
        setPrevSignatoryId(orderStatus.signatoryId);
        signatoryData = await dispatch(
          fetchOrderStatusSignatory({ signatoryId: orderStatus.signatoryId })
        );
      }

      const { results: documentList } = await dispatch(
        fetchOrderStatusAttachments({
          params: {
            relatedOrderStatuses: orderStatusId,
            ignoreCache: true
          }
        })
      );

      let fetchedTaskId = null;

      if (orderStatus.approvingTaskId) {
        const task = await dispatch(
          fetchTaskLocal({ id: orderStatus.approvingTaskId })
        );
        fetchedTaskId = task.id;
        setApprovingTaskId(fetchedTaskId);

        const disabledStatuses = [STATUS_COMPLETED, STATUS_CANCELLED];
        setDisableChangeSignatory(disabledStatuses.includes(task.status));
      }

      setValues({
        ...orderStatus,
        disableChangeSignatory,
        signatory: haveSignatory && signatoryData,
        description: orderStatus.content,
        fileList: documentList
      });
    } finally {
      setFetchLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableChangeSignatory, prevSignatoryId, orderStatusId]);

  useEffect(() => {
    if (visible) {
      fetchOrderData();
    }
    return () => setValues({});
  }, [fetchOrderData, visible]);

  const onSubmit = useCallback(
    async newValues => {
      try {
        setIsLoading(true);

        if (
          newValues.signatoryId &&
          newValues.signatoryId !== prevSignatoryId &&
          !disableChangeSignatory
        ) {
          await dispatch(
            changeOrderStatusSignatory({
              id: orderStatusId,
              signatoryId: newValues.signatoryId
            })
          );

          await dispatch(
            changeResponsible({
              id: approvingTaskId,
              employeeId: newValues.signatoryId
            })
          );

          await dispatch(
            fetchOrderStatusSignatory({ signatoryId: newValues.signatoryId })
          );
        }

        amplitude.editOrderEvent({
          ...newValues
        });

        await dispatch(
          editOrderStatus({
            id: orderStatusId,
            values: { ...newValues, approvingTaskId }
          })
        );

        await dispatch(fetchOrderStatus({ id: orderStatusId }));

        showNoticeMessage({ number: NOTICE_NUMBER.orderStatusUpdated });

        subscribeToNewAttachments(newValues.attachmentFileList);
        handleChangeValidityDateForNewAttachments({
          attachments: newValues.attachmentFileList
        });

        onClose();
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disableChangeSignatory, orderStatusId, approvingTaskId, prevSignatoryId]
  );

  const hasValues = !isEmpty(values) && !fetchLoading;

  return {
    isLoading,
    values,
    hasValues,
    onSubmit
  };
};

export default useEdit;
