import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { THIS_AND_NEXT } from 'constants/index';

import useUpdateEmployeeRecordStatus from 'components/dashboard-view/views/calendar/hooks/use-update-employee-record-status';

import {
  fetchTaskLocal,
  updateStatus as updateTaskStatusAction
} from 'store/tasks';
import { getUserEmployee } from 'store/workspace';
import { fetchEmployeeRecordsLocal } from 'store/calendar';

import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

// eslint-disable-next-line import/no-cycle
import Actions from './actions';
import View from '../view';

export const TaskEntityView = ({
  record,
  updateRecord,
  updateRecordInList,
  deleteRecord
}) => {
  const dispatch = useDispatch();

  const [task, setTask] = useState(undefined);
  const [parentTask, setParentTask] = useState(undefined);

  const [isLoading, setIsLoading] = useState(false);
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const currentEmployee = useSelector(getUserEmployee);

  const { updateStatus } = useUpdateEmployeeRecordStatus();

  const { relation = {}, id: recordId, dateStart, dateEnd } = record;
  const { id: reltaionId, templateId } = relation;

  const updateTaskAndRecord = useCallback(
    async taskData => {
      let result = taskData;

      if (!taskData) {
        const fetchedTask = await dispatch(fetchTaskLocal({ id: reltaionId }));
        result = fetchedTask;
      }

      if (result.author.id === currentEmployee.id) {
        const fetchedParentTask = await dispatch(
          fetchTaskLocal({ id: result.parent })
        );

        setParentTask(fetchedParentTask);
      }

      await updateRecord(recordId);

      setTask(prev => ({ ...prev, ...result }));
    },
    [currentEmployee.id, dispatch, recordId, reltaionId, updateRecord]
  );

  const changeTaskStatus = useCallback(
    async ({ status, acceptMeeting }) => {
      try {
        setIsStatusLoading(true);

        const taskData = await dispatch(
          updateTaskStatusAction({
            id: reltaionId,
            status
          })
        );

        if (acceptMeeting === THIS_AND_NEXT) {
          const { results } = await dispatch(
            fetchEmployeeRecordsLocal({
              params: {
                limit: 10000,
                objectId: templateId,
                dateStart,
                dateEnd
              }
            })
          );
          const [parentTemplateRecord] = results.filter(
            r => !r.recurringRecordId
          );

          await updateStatus({
            recordId: parentTemplateRecord.id,
            taskStatus: status
          });

          updateRecordInList(parentTemplateRecord.id);
        }

        await updateTaskAndRecord(taskData);

        showNoticeMessage({ number: NOTICE_NUMBER.taskStatusChanged });
      } finally {
        setIsStatusLoading(false);
      }
    },
    [
      dateEnd,
      dateStart,
      dispatch,
      reltaionId,
      templateId,
      updateRecordInList,
      updateStatus,
      updateTaskAndRecord
    ]
  );

  const fetch = async () => {
    try {
      setIsLoading(true);

      const fetchedTask = await dispatch(fetchTaskLocal({ id: reltaionId }));

      if (fetchedTask.author.id === currentEmployee.id) {
        const fetchedParentTask = await dispatch(
          fetchTaskLocal({ id: fetchedTask.parent })
        );

        setParentTask(fetchedParentTask);
      }

      setTask(fetchedTask);
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const renderedActions = useMemo(() => {
    if (!parentTask) {
      return () => null;
    }

    return () => (
      <Actions
        parentTask={parentTask}
        deleteRecord={deleteRecord}
        updateTaskAndRecord={updateTaskAndRecord}
      />
    );
  }, [deleteRecord, parentTask, updateTaskAndRecord]);

  useEffect(() => {
    if (!task) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View
      record={record}
      task={task}
      hasError={hasError}
      isLoading={isLoading}
      isStatusLoading={isStatusLoading}
      onChangeStatus={changeTaskStatus}
      Actions={renderedActions}
    />
  );
};

TaskEntityView.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    asset: PropTypes.object
  })
};

TaskEntityView.defaultProps = {
  data: {}
};

export default TaskEntityView;
