import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-cycle
import { CurrencySelect } from 'components/common/controls/custom-select';

import FormItem from '../form-item';

export const FormCurrencySelect = ({
  rules,
  name,
  label,
  itemProps,
  ...props
}) => {
  const { control, formState } = useFormContext();

  const transformValue = value =>
    typeof value === 'string'
      ? {
          value,
          label: value
        }
      : value;

  return (
    <FormItem
      label={label}
      name={name}
      {...itemProps}
      errors={formState.errors}
    >
      <Controller
        control={control}
        rules={rules}
        render={({ field }) => {
          const { value, ...fieldValues } = field;

          return (
            <CurrencySelect
              value={transformValue(value)}
              {...fieldValues}
              {...props}
            />
          );
        }}
      />
    </FormItem>
  );
};

FormCurrencySelect.defaultProps = {
  rules: PropTypes.any,
  itemProps: PropTypes.object
};

FormCurrencySelect.defaultProps = {
  rules: {},
  itemProps: {}
};

export default FormCurrencySelect;
