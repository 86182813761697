import React, { useEffect } from 'react';
import { Page as ReactPdfPage } from 'react-pdf';

import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// eslint-disable-next-line import/no-cycle
import { useIsInViewport } from 'hooks';

const Page = ({ pageNumber, inputRef, className, setPageNumber }) => {
  const isInViewport = useIsInViewport(inputRef);

  useEffect(() => {
    if (isInViewport && inputRef.current) {
      setPageNumber(+inputRef.current.dataset.pageNumber);
    }
  }, [inputRef, isInViewport, setPageNumber]);

  return (
    <ReactPdfPage
      renderTextLayer={false}
      className={className}
      pageNumber={pageNumber}
      inputRef={inputRef}
    />
  );
};

export default Page;
