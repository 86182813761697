import React from 'react';
import PropTypes from 'prop-types';
import { Tabs as AntdTabs } from 'antd';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-cycle
import { Create } from 'components/common/attachments-control/shared/create';

import { UploadForm } from './upload-form';

import styles from './add-file.module.scss';

const CREATE = 'create';
const UPLOAD = 'upload';

const TABS = [
  {
    key: CREATE,
    tab: 'CreateTab',
    Component: Create
  },
  {
    key: UPLOAD,
    tab: 'UploadTab',
    Component: UploadForm
  }
];

const Tabs = ({ isLoading, onSubmit }) => {
  const { t } = useTranslation('AddFile');

  const items = TABS.map(item => ({
    key: item.key,
    label: t(item.tab),
    children: <item.Component isLoading={isLoading} onSubmit={onSubmit} />,
    className: styles.item
  }));

  return (
    <AntdTabs defaultActiveKey={CREATE} className={styles.tabs} items={items} />
  );
};

Tabs.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

Tabs.defaultProps = {
  isLoading: false
};

export default Tabs;
