import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-cycle
import { FormTagsSelect } from 'components/common/hook-form';
import Typography from 'components/common/typography';
import Button from 'components/common/button';

import { replaceTag } from 'store/tags';

import styles from './replace-tag-modal.module.scss';

const Form = ({ tag, onCloseModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['Common', 'TagsEditReplaceDelete', 'Errors']);

  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm({
    defaultValues: {
      fromTag: {
        label: {
          color: tag.color,
          id: tag.id,
          name: tag.name,
          type: tag.type
        },
        value: tag.id
      }
    }
  });

  const handleClose = () => {
    methods.reset();
    onCloseModal();
  };

  const handleSubmit = async values => {
    try {
      setIsLoading(true);

      const replaceData = {
        fromTag: tag,
        toTagId: values.toTag.value
      };

      await dispatch(
        replaceTag({
          replaceData
        })
      );

      handleClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        className={styles.form}
        onSubmit={methods.handleSubmit(handleSubmit)}
      >
        <div className={styles.warningSection}>
          <Typography.Paragraph style={{ marginBottom: 0 }}>
            {t('TagWarning', { ns: 'TagsEditReplaceDelete' })}
          </Typography.Paragraph>
        </div>

        <FormTagsSelect
          label={t('ReplaceTagWithLabel', { ns: 'TagsEditReplaceDelete' })}
          name="fromTag"
          allowCreateTag={false}
          isMulti={false}
          isDisabled
        />

        <FormTagsSelect
          label={t('OnText', { ns: 'TagsEditReplaceDelete' })}
          name="toTag"
          allowCreateTag={false}
          isMulti={false}
          rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
        />

        <div className={styles.formButtons}>
          <Button type="secondary" width="full" onClick={handleClose}>
            {t('Cancel', { ns: 'Common' })}
          </Button>

          <Button
            type="primary"
            width="full"
            htmlType="submit"
            loading={isLoading}
          >
            {t('ReplaceText', { ns: 'TagsEditReplaceDelete' })}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  tag: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string,
    type: PropTypes.string,
    entityType: PropTypes.string,
    entityId: PropTypes.string,
    link: PropTypes.string
  }).isRequired
};

export default Form;
