import React from 'react';
import { Typography, Tooltip } from 'antd';
import { Form } from '@ant-design/compatible';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { RESPONSIBLE } from 'constants/index';

// eslint-disable-next-line import/no-cycle
import {
  EmployeeSelect,
  ProjectParticipantsSelect
} from 'components/common/controls/custom-select';
import Icon from 'components/common/icon';

import { validateIsRequire } from 'utils/validators';

const { Text } = Typography;

const ActionTypeResponsible = ({
  getFieldDecorator,
  relatedProjectId,
  initialValue,
  isDisabled,
  isMulti,
  ...props
}) => {
  const { t } = useTranslation('AddTask');

  const Select = relatedProjectId ? ProjectParticipantsSelect : EmployeeSelect;

  const label = (
    <>
      <Text
        style={{ color: 'inherit', marginRight: 5 }}
        data-qa="qa-pr8a8u5st2dyfs9"
      >
        {t('TaskResponsible')}
      </Text>

      {isMulti && (
        <Tooltip title={t('SeveralResponsiblesTip')}>
          <Icon
            type="question-circle"
            size={12}
            style={{ verticalAlign: 'unset' }}
          />
        </Tooltip>
      )}
    </>
  );

  return (
    <Form.Item label={label}>
      {getFieldDecorator(RESPONSIBLE, {
        initialValue,
        rules: [validateIsRequire()]
      })(
        <Select
          projectId={relatedProjectId}
          isDisabled={isDisabled}
          data-qa="qa-gx4onxeiwz9ds44"
          hideWorkspace
          isMulti={isMulti}
          params={{ excludeBench: false }}
          {...props}
        />
      )}
    </Form.Item>
  );
};

ActionTypeResponsible.propTypes = {
  getFieldDecorator: PropTypes.func.isRequired,
  relatedProjectId: PropTypes.string,
  initialValue: PropTypes.object,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool
};

ActionTypeResponsible.defaultProps = {
  relatedProjectId: undefined,
  initialValue: {},
  isDisabled: false,
  isClearable: true
};

export default ActionTypeResponsible;
