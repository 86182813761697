import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import {
  STATUS_BACKLOG,
  STATUS_CHECK,
  STATUSES_ACTIVE,
  TYPE_TASK
} from 'constants/tasks';
import { TESTID_TASKS_VIEW_TITLE } from 'constants/tests';

import Modal from 'components/common/modal';
import { FormTaskSelect } from 'components/common/hook-form';
import Button from 'components/common/button';
import Typography from 'components/common/typography';
import { ChangeAccessTypeModal } from 'components/tasks-view/components/change-access-type-modal';

import { convertToSubtask, fetchSubtaskList, fetchTask } from 'store/tasks';

import { showNoticeMessage } from 'services/notice';
import useRoutesService from 'services/routes';
import useModalsService from 'services/modals';

import styles from './task-list-modal.module.scss';

const TYPE_ESTIMATION_ERROR = 'estimation';
const TYPE_WORKLOG_ERROR = 'workLog';

const TYPES_LABOR_ERROR = {
  [TYPE_ESTIMATION_ERROR]: 'TaskAttachmentErrorText',
  [TYPE_WORKLOG_ERROR]: 'TaskAttachmentFactualErrorText'
};

const TaskListModal = ({ visible, onClose, data }) => {
  const dispatch = useDispatch();
  const methods = useForm();
  const routes = useRoutesService({ returnUrl: true });
  const modals = useModalsService({ returnUrl: true });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [openedChangeAccessModal, setOpenedChangeAccessModal] = useState(false);

  const { t } = useTranslation(['ChooseEntityActions', 'Errors', 'Task']);

  const projectId = data.project && data.project.id;
  const currentTaskAccessType = data.accessType;

  const reset = () => {
    setError(null);
    setFormValues(null);
    setOpenedChangeAccessModal(false);
    methods.reset();
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  const onSubmit = async values => {
    try {
      setIsLoading(true);

      await dispatch(
        convertToSubtask({ taskId: data.id, parentId: values.parentId.value })
      );

      await dispatch(fetchTask({ id: data.id }));

      await dispatch(fetchSubtaskList({ id: data.id }));

      handleClose();

      showNoticeMessage();
    } catch (err) {
      if (err?.response?.status === 428) {
        setError(err.response.data);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAccessCheck = async values => {
    const parentTaskAccessType = values.parentId.label.accessType;

    if (parentTaskAccessType !== currentTaskAccessType) {
      setOpenedChangeAccessModal(true);
      setFormValues(values);
    } else {
      await onSubmit(values);
    }
  };

  const filteredStatuses = [
    ...STATUSES_ACTIVE,
    { value: STATUS_BACKLOG }
  ].filter(status => status.value !== STATUS_CHECK);

  return (
    <>
      <Modal
        title={t('ChooseMainTaskHeading')}
        contentClassName={styles.root}
        width={508}
        open={visible}
        onClose={handleClose}
        centered
        afterClose={reset}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(handleAccessCheck)}
            className={styles.form}
          >
            <Alert message={t('ChooseMainTaskWarning')} type="warning" />

            <FormTaskSelect
              name="parentId"
              rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
              label={t('Task')}
              valueText={t('ChooseTask')}
              itemProps={{
                className: styles.taskSelect
              }}
              params={{
                exclude: data.id,
                project: projectId || undefined,
                status: filteredStatuses,
                kind: TYPE_TASK
              }}
              dataTestId={TESTID_TASKS_VIEW_TITLE}
            />

            {error && (
              <Typography.Text
                color="red"
                size="small"
                className={styles.errorMessage}
              >
                <Trans
                  t={t}
                  ns="Task"
                  i18nKey={TYPES_LABOR_ERROR[error.type]}
                  values={{ value: error.value }}
                  className={styles.errorMessage}
                  components={{
                    taskLink: (
                      <a
                        href={`${routes.toTasks()}${modals.tasks.showDetails({
                          id: +error.taskId
                        })}`}
                        target="_blank"
                        rel="noreferrer"
                      />
                    )
                  }}
                />
              </Typography.Text>
            )}

            <Button
              htmlType="submit"
              type="primary"
              className={styles.btn}
              size="large"
              width="expanded"
              loading={isLoading}
            >
              {t('SaveBtn')}
            </Button>
          </form>
        </FormProvider>
      </Modal>

      <ChangeAccessTypeModal
        isLoading={isLoading}
        open={openedChangeAccessModal}
        onClose={() => setOpenedChangeAccessModal(false)}
        onConfirm={() => onSubmit(formValues)}
      />
    </>
  );
};

TaskListModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object
};

TaskListModal.defaultProps = {
  visible: false,
  onClose: () => {},
  data: {}
};

export default TaskListModal;
