import React from 'react';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-cycle
import { FormInput, FormInputPassword } from 'components/common/hook-form';
import Typography from 'components/common/typography';
import Button from 'components/common/button';
import Icon from 'components/common/icon';

import ServiceSettings from './service-settings';

import styles from '../../email-service.module.scss';

const Imap = ({ onBlurEmail }) => {
  const { t } = useTranslation(['ConnectEmail', 'Errors']);

  return (
    <>
      <div className={styles.instructionContainer}>
        <div className={styles.instructionIconWrap}>
          <Icon type="exclamation-circle" size={16} color="white" />
        </div>

        <Button
          type="link"
          href="https://help.upservice.com/articles/98499-%D0%BA%D0%B0%D0%BA-%D0%BF%D0%BE%D0%B4%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D1%8C-%D0%B2%D0%B0%D1%88%D1%83-%D0%BF%D0%BE%D1%87%D1%82%D1%83-%D0%BA-upservice"
          target="_blank"
          className={styles.instructionBtn}
        >
          {t('ConnectEmailInstructionsBtn')}
        </Button>
      </div>

      <FormInput
        label={t('Email')}
        name="email"
        type="email"
        itemProps={{
          style: {
            marginBottom: 0
          },
          onBlur: onBlurEmail
        }}
        rules={{
          required: t('RequiredField', { ns: 'Errors' })
        }}
        placeholder={t('EnterEmail')}
      />

      <Typography.Text
        size="small"
        color="black-55"
        style={{ marginBottom: 16 }}
      >
        {t('EmailTip')}
      </Typography.Text>

      <FormInputPassword
        label={t('AppPassword')}
        name="password"
        rules={{
          required: t('RequiredField', { ns: 'Errors' })
        }}
        placeholder={t('EnterPassword')}
      />

      <ServiceSettings label={t('IMAPServer')} name="imap" />

      <ServiceSettings label={t('SMTPServer')} name="smtp" />
    </>
  );
};

export default Imap;
