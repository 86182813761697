import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  CONTACT_STATUS_ARCHIVED,
  CONTACT_STATUS_UNASSEMLED,
  CONTACT_STATUS_VERIFIED,
  LINK_TYPE
} from 'constants/index';

// eslint-disable-next-line import/no-cycle
import ContactCreatorDrawer from 'components/contacts-view/drawers/creator';

import { fetchContactsLocal } from 'store/contacts';

import CustomSelect from '../custom-select';
import UserOption from '../custom-select/user-option';

export const ContactSelect = ({
  value,
  isMulti,
  selectedItems,
  onChange,
  customMapValues,
  showEmail,
  ...props
}) => {
  const dispatch = useDispatch();
  const [visibleCreator, setVisibleCreator] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('Common');

  const addEntityButtonData = {
    title: t('AddContact'),
    onClick: () => setVisibleCreator(true)
  };

  const mapValues = v => ({
    value: v.id,
    label: {
      ...v,
      workspaceTitle: v.company.isIndividual ? t('Individual') : v.company.title
    }
  });

  const creatableCallback = values => {
    const transformed = customMapValues
      ? customMapValues([values])[0]
      : [values].map(mapValues)[0];

    onChange(isMulti ? [...(value || []), transformed] : transformed);

    setContacts(prevContacts => [...prevContacts, transformed]);
  };

  const fetchData = async params => {
    setIsLoading(true);

    try {
      const statusParams = {
        ...params,
        status: [
          CONTACT_STATUS_VERIFIED,
          CONTACT_STATUS_UNASSEMLED,
          CONTACT_STATUS_ARCHIVED
        ],
        isArchived: null
      };

      const { entries, ...res } = await dispatch(
        fetchContactsLocal(statusParams)
      );

      const mappedEntries = customMapValues
        ? customMapValues(entries)
        : entries.map(mapValues);

      setContacts(mappedEntries);

      return {
        ...res,
        entries: mappedEntries
      };
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <CustomSelect
        isAsync
        isSearchable
        isClearable
        showSelectedOptionsOnList={false}
        placeholder={t('SearchForContacts')}
        valueText={t('EnterYourNameEmail')}
        label={t('Contacts')}
        fetchData={fetchData}
        Option={UserOption}
        hidePosition
        optionProps={{
          withWorkspace: true,
          ellipsis: false,
          showEmail
        }}
        hiddenItems={selectedItems.map(i => i.value)}
        addEntityButtonData={addEntityButtonData}
        value={value}
        isMulti={isMulti}
        onChange={onChange}
        options={contacts}
        isLoading={isLoading}
        {...props}
        selectType={LINK_TYPE.CONTACT}
      />

      <ContactCreatorDrawer
        visible={visibleCreator}
        onClose={() => setVisibleCreator(false)}
        onCreate={creatableCallback}
      />
    </>
  );
};

ContactSelect.propTypes = {
  selectedItems: PropTypes.array,
  customMapValues: PropTypes.func,
  showEmail: PropTypes.bool
};

ContactSelect.defaultProps = {
  selectedItems: [],
  customMapValues: undefined,
  showEmail: false
};

export default ContactSelect;
