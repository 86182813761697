import { createAction } from 'redux-actions';
import api from 'api';

import { ITEMS_PER_PAGE } from 'constants/index';

import mapValue from 'utils/map-value';

import createActionThunk from '../actions-thunk';
import { getWorkspaceId } from '../workspace';
// eslint-disable-next-line import/no-cycle
import { getCalendarAssetsFilter } from '.';

const transformFetchParams = ({
  asset = [],
  category = [],
  taskResponsible = [],
  orderResponsible = [],
  ...params
}) => ({
  asset: asset.map(mapValue),
  category: category.map(mapValue),
  taskResponsible: taskResponsible.map(mapValue),
  orderResponsible: orderResponsible.map(mapValue),
  ...params
});

// ASSETS
export const createAssetRecord = createActionThunk(
  'calendar/create-asset-record',
  ({ getState, record }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.calendar
      .createAssetRecord({
        workspaceId,
        record
      })
      .then(({ data }) => data);
  }
);

export const updateAssetRecord = createActionThunk(
  'calendar/update-asset-record',
  ({ getState, recordId, record }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.calendar
      .updateAssetRecord({
        workspaceId,
        recordId,
        record
      })
      .then(({ data }) => data);
  }
);

export const deleteAssetRecord = createActionThunk(
  'calendar/delete-asset-record',
  ({ getState, id }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.calendar
      .deleteAssetRecord({
        workspaceId,
        id
      })
      .then(({ data }) => data);
  }
);

export const fetchAssetBookingDateRangeLocal = createActionThunk(
  'calendar/fetch-asset-booking-date-range-local',
  ({ getState, params }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.calendar
      .fetchAssetBookingDateRange({
        workspaceId,
        params: { limit: 10000, ...params }
      })
      .then(({ data }) => data);
  }
);

export const fetchAssetListLocal = createActionThunk(
  'calendar/fetch-asset-list-local',
  ({ getState, params }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.calendar
      .fetchAssetList({
        workspaceId,
        params: { limit: ITEMS_PER_PAGE, ...params }
      })
      .then(({ data }) => data);
  }
);

export const fetchAssetResources = createActionThunk(
  'calendar/fetch-asset-resources',
  ({ getState, params }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const filter = getCalendarAssetsFilter(state);

    const resultParams = transformFetchParams({
      limit: 10000,
      ...filter,
      ...params
    });

    return api.calendar
      .fetchAssetResources({
        workspaceId,
        ...resultParams
      })
      .then(({ data }) => data);
  }
);

export const fetchAssetRecords = createActionThunk(
  'calendar/fetch-asset-records',
  ({ getState, params }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const filter = getCalendarAssetsFilter(state);

    const resultParams = transformFetchParams({
      limit: 10000,
      ...filter,
      ...params
    });

    return api.calendar
      .fetchAssetRecords({
        workspaceId,
        ...resultParams
      })
      .then(({ data }) => data);
  }
);

export const fetchAssetTaskBookingData = createActionThunk(
  'calendar/fetch-asset-task-booking-data',
  ({ getState, id }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.calendar
      .fetchAssetTaskBookingData({
        workspaceId,
        id
      })
      .then(({ data }) => data.result);
  }
);

export const fetchAssetOrderStatusBookingData = createActionThunk(
  'calendar/fetch-asset-order-status-booking-data',
  ({ getState, id }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.calendar
      .fetchAssetOrderStatusBookingData({
        workspaceId,
        id
      })
      .then(({ data }) => data.result);
  }
);

// ASSETS FILTERS
export const clearAssetsFilter = createAction('calendar/clear-assets-filter');

export const setAssetsFilterSearch = createAction(
  'calendar/set-assets-filter-search'
);

export const setAssetsFilterTaskResponsible = createAction(
  'calendar/set-assets-filter-task-responsible'
);

export const setAssetsFilterOrderResponsible = createAction(
  'calendar/set-assets-filter-order-responsible'
);

export const setAssetsFilterCategories = createAction(
  'calendar/set-assets-filter-categories'
);

export const setFilterAsset = createAction('calendar/set-filter-asset');

// EMPLOYEES

export const fetchEmployeeRecords = createActionThunk(
  'calendar/record-attendees',
  ({ getState, params }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const resultParams = {
      ...params,
      limit: 10000,
      employee: params.employees.map(mapValue)
    };

    return api.calendar
      .fetchEmployeeRecords({
        workspaceId,
        params: resultParams
      })
      .then(({ data }) => data);
  }
);

export const fetchEmployeeRecordsLocal = createActionThunk(
  'calendar/record-attendees-local',
  ({ getState, params }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const resultParams = {
      ...params,
      employee: (params.employees || []).map(mapValue)
    };

    return api.calendar
      .fetchEmployeeRecords({
        workspaceId,
        params: resultParams
      })
      .then(({ data }) => data);
  }
);

export const fetchOneEmployeeRecord = createActionThunk(
  'calendar/record-attendees-one',
  ({ getState, id }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.calendar
      .fetchOneEmployeeRecord({
        workspaceId,
        id
      })
      .then(({ data }) => data);
  }
);

export const updateEmployeeRecordStatus = createActionThunk(
  'calendar/record-attendees/status/update',
  ({ getState, id, data }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.calendar
      .updateEmployeeRecordStatus({
        workspaceId,
        id,
        data
      })
      .then(({ data: result }) => result);
  }
);

export const setEmployeeCalendarFilterEmployee = createAction(
  'calendar/set-employee-filter-employee'
);

export const setEmployeeCalendarFilterView = createAction(
  'calendar/set-employee-filter-view'
);

export const clearEmployeeCalendarFilter = createAction(
  'calendar/clear-employee-filter'
);
