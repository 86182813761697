import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
  TYPE_DATE_START,
  TYPE_DATE_END,
  DATE_PICKER_TIME_FORMAT
} from 'constants/index';

import Button from 'components/common/button';
// eslint-disable-next-line import/no-cycle
import { FormDatePicker } from 'components/common/hook-form';
import { CloseIcon } from 'components/common/icon/icons';

import useMinMaxTime from 'hooks/common/use-min-max-time';

import styles from '../styles.module.scss';

const getDateEndRules = ({
  startDate,
  checkSameDates,
  checkSameFormat,
  t
}) => ({
  required: t('RequiredField', { ns: 'Errors' }),
  validate: value => {
    if (checkSameDates && moment(startDate).isSame(value, checkSameFormat)) {
      return t('DateStartCannotBeEqualDateEnd', { ns: 'Errors' });
    }

    if (moment(startDate).isAfter(value)) {
      return t('DateStartCannotBeAfterDateEnd', { ns: 'Errors' });
    }

    return true;
  }
});

export const DateFields = ({
  dateEndState,
  startDate,
  endDate,
  visibleDateStart,
  onRemoveStartDate,
  methods,
  defaultValues
}) => {
  const { t } = useTranslation([
    'AddTask',
    'Requests',
    'AddTaskType',
    'Errors',
    'TaskLinks',
    'Common'
  ]);

  const [minTimeStart, maxTimeStart, minTimeEnd, maxTimeEnd] = useMinMaxTime({
    startDate,
    endDate
  });

  const renderStartDate = () => (
    <div className={styles.dateStart}>
      <FormDatePicker
        name={TYPE_DATE_START}
        label={t('StartDate')}
        rules={{
          required: true,
          message: t('RequiredField', { ns: 'Errors' })
        }}
        dateFormat={DATE_PICKER_TIME_FORMAT}
        wrapperClassname={styles.datePicker}
        allowSetBeforeCurrentDate={false}
        minDate={new Date()}
        maxDate={endDate}
        minTime={minTimeStart}
        maxTime={maxTimeStart}
      />
      <Button
        icon={<CloseIcon />}
        type="text"
        className={styles.btnDelete}
        onClick={onRemoveStartDate}
      />
    </div>
  );

  const renderEndDate = () => (
    <FormDatePicker
      name={TYPE_DATE_END}
      label={t('TaskDueDate')}
      style={{ maxWidth: 180 }}
      rules={getDateEndRules({
        startDate,
        checkSameDates: true,
        checkSameFormat: 'minute',
        t
      })}
      dateFormat={DATE_PICKER_TIME_FORMAT}
      wrapperClassname={styles.datePicker}
      minDate={startDate || new Date()}
      maxDate={
        defaultValues?.maxDateEnd && moment(defaultValues?.maxDateEnd).toDate()
      }
      disabled={dateEndState.isDisabled}
      minTime={minTimeEnd}
      maxTime={maxTimeEnd}
    />
  );

  return (
    <div className={classnames(styles.dates)}>
      <div className={styles.dateContainer}>
        {visibleDateStart && renderStartDate()}
        {renderEndDate()}
      </div>
    </div>
  );
};
