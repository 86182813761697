import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { tickUIGlobalInterval } from 'store/ui';

export const GlobalIntervalProvider = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    let interval = null;

    interval = setInterval(() => dispatch(tickUIGlobalInterval()), 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};
