import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { FormProvider, useForm } from 'react-hook-form';

import {
  RESPONSIBLE,
  TITLE,
  TYPE_TASK,
  TEMPLATES,
  BACKLOG
} from 'constants/index';

import Typography from 'components/common/typography';
import Modal from 'components/common/modal';
import TaskCreatorDrawer from 'components/tasks-view/view/drawers/creator';
import { TemplateView } from 'components/tasks-view/template-view';
import Button from 'components/common/button';
import {
  FormCheckbox,
  FormEmployeeSelect,
  FormInput,
  validateRequired
} from 'components/common/hook-form';
import FormProjectParticipantsSelect from 'components/common/hook-form/select/project-participants';

import { createTask } from 'store/tasks';

import getSubtaskValue from 'utils/get-subtask-value';
import { useAmplitude } from 'hooks/amplitude/use-amplitude';

import styles from './styles.module.scss';

const { Title } = Typography;

export const getKindSubTask = ({ kind, projectId, status }) => {
  if (status === BACKLOG) {
    return TYPE_TASK;
  }

  return projectId && kind === TYPE_TASK ? TYPE_TASK : null;
};

const CreatorForm = ({ task, onCallback, onShowFullForm }) => {
  const { t } = useTranslation(['AddSubtask', 'Errors']);
  const dispatch = useDispatch();
  const amplitude = useAmplitude();

  const [isLoading, setIsLoading] = useState(false);
  const [copyBtnVisible, setCopyBtnVisible] = useState(true);

  const parentAccessType = task.accessType;

  const getInitialResponsible = () => ({
    value: task.responsible.id,
    label: task.responsible
  });

  const { handleSubmit, ...methods } = useForm({
    defaultValues: {
      [TEMPLATES]: false,
      [RESPONSIBLE]: getInitialResponsible(),
      [TITLE]: ''
    }
  });

  const isFromTemplate = methods.watch(TEMPLATES);
  const titleWatch = methods.watch(TITLE);
  const responsibleWatch = methods.watch(RESPONSIBLE);

  const getDateStart = dateStart =>
    dateStart && moment(dateStart).isAfter(moment())
      ? moment(dateStart).toDate()
      : undefined;

  const onCreate = async values => {
    try {
      setIsLoading(true);

      const data = getSubtaskValue({
        subtask: values,
        task: { ...task, kind: TYPE_TASK },
        isSubTask: true
      });

      await dispatch(
        createTask({
          task: data
        })
      );

      amplitude.addTaskEvent({
        value: data
      });

      amplitude.accessToTaskEvent({ type: task.accessType });

      onCallback?.();
    } finally {
      setIsLoading(false);
    }
  };

  const onCreateFromTemplate = template => {
    const initialData = {
      ...template,
      parent: task.id,
      dateStart: getDateStart(task.dateStart),
      maxDateEnd: task.dateEnd,
      projectId: (task.project || {}).id,
      kind: template.kind,
      isFromTemplate: true,
      parentAccessType: task.accessType
    };
    onShowFullForm(initialData);
    onCallback?.();
  };

  const onOpenFullForm = () => {
    const initialData = {
      parent: task.id,
      title: titleWatch,
      dateStart: getDateStart(task.dateStart),
      maxDateEnd: task.dateEnd,
      responsible: [(responsibleWatch || {}).label || task.responsible],
      project: task.project,
      kind: getKindSubTask(task),
      isFromTemplate: task.status === BACKLOG,
      parentAccessType: task.accessType
    };
    onShowFullForm(initialData);
    onCallback?.();
  };

  const setTitle = () => {
    methods.setValue(TITLE, task.title, { shouldValidate: true });
    setCopyBtnVisible(false);
  };

  const Select = (task.project || {}).id
    ? FormProjectParticipantsSelect
    : FormEmployeeSelect;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onCreate)}
        style={{ display: 'flex', flexDirection: 'column', padding: 20 }}
        data-qa="qa-zjjlq387zowt2gj"
      >
        <FormCheckbox name={TEMPLATES}>
          {t('ChooseFromTemplatesChckbx')}
        </FormCheckbox>

        {isFromTemplate && (
          <TemplateView
            onSubmit={onCreateFromTemplate}
            drawerVisible={isFromTemplate}
            onlyTaskKindAllowed={task.status === BACKLOG}
            parentAccessType={parentAccessType}
          />
        )}

        {!isFromTemplate && (
          <>
            <FormInput
              label={t('SubtaskName')}
              name={TITLE}
              rules={{
                required: validateRequired()
              }}
              itemProps={{
                style: {
                  marginBottom: copyBtnVisible ? 4 : 16
                }
              }}
              autoFocus
              placeholder={t('SubtaskNamePlaceholder')}
              autoComplete="off"
            />

            {copyBtnVisible && (
              <Button
                type="link"
                style={{
                  padding: 0,
                  fontSize: 12,
                  fontWeight: 400,
                  height: 'auto',
                  alignSelf: 'flex-start',
                  marginBottom: 16
                }}
                onClick={setTitle}
              >
                {t('CopyFromMainTaskBtn')}
              </Button>
            )}

            <Select
              name={RESPONSIBLE}
              rules={{
                required: validateRequired()
              }}
              projectId={(task.project || {}).id}
              data-qa="qa-agw5c5wbm4hhvdr"
              hideWorkspace
              params={{ excludeBench: false }}
            />
          </>
        )}

        <div className={styles.btnsWrapper} data-qa="qa-4ieo0a1gqbdx169">
          <Button
            type="link"
            size="large"
            style={{ paddingLeft: 0 }}
            onClick={onOpenFullForm}
          >
            {t('OpenFullTaskFormBtn')}
          </Button>

          {!isFromTemplate && (
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              style={{ fontSize: 16 }}
              data-qa="qa-mb8gqu9w8472dlo"
            >
              {t('SaveBtn')}
            </Button>
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export const CreateSubtaskModal = ({ onClose, task, ...props }) => {
  const { t } = useTranslation('AddSubtask');

  const [visibleFullForm, setVisibleFullForm] = useState();

  const fullFormInitialPage = (visibleFullForm || {}).isFromTemplate ? 1 : 0;

  return (
    <>
      <Modal
        width={610}
        title={`${t('AddSubtaskHeading')} ${task.id}`}
        onClose={onClose}
        destroyOnClose
        {...props}
      >
        <CreatorForm
          task={task}
          onCallback={onClose}
          onShowFullForm={setVisibleFullForm}
        />
      </Modal>

      <TaskCreatorDrawer
        title={
          <Title level={4} style={{ marginBottom: 0, lineHeight: '24px' }}>
            {t('AddSubtaskHeading')} {task.id}
          </Title>
        }
        visible={visibleFullForm !== undefined}
        value={visibleFullForm}
        onClose={() => setVisibleFullForm(undefined)}
        initialPage={fullFormInitialPage}
      />
    </>
  );
};

export default CreateSubtaskModal;
