import React from 'react';

// eslint-disable-next-line import/no-cycle
import WebsiteForm from 'components/contacts-view/views/integrations/forms/website-form';

import styles from './preview.module.scss';

const WebsiteFormPreview = ({ values }) => (
  <div
    className={styles.page}
    style={{
      backgroundColor: values.pageColor
    }}
  >
    {values.pageBackground && (
      <img
        alt="background"
        src={values.pageBackground}
        className={styles.backgroundImage}
      />
    )}

    <WebsiteForm isPreview values={values} />
  </div>
);

export default WebsiteFormPreview;
