import React, { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-cycle
import { FormInput, FormSwitch } from 'components/common/hook-form';
import Switch from 'components/common/controls/switch';
import ActionsDropdown from 'components/common/actions';
import Button from 'components/common/button';
import Icon from 'components/common/icon';

import AddFieldModal from './add-field-modal';
import { getLocalizedText } from '../utils';

import styles from './editable-inputs.module.scss';

const EditableInputs = ({ language, setFields, isOnlyView }) => {
  const [visibleModal, setVisibleModal] = useState(false);

  const { t } = useTranslation('ConnectForm');

  const { control, setValue } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'formElements'
  });

  const fieldsWatch = useWatch({ name: 'formElements', control });

  const transformFields = fields.map(item => ({
    ...item,
    placeholder: getLocalizedText(item.name, language) || item.placeholder
  }));

  const getFieldByName = useCallback(
    name => fieldsWatch.find(field => field.name === name),
    [fieldsWatch]
  );

  const getIndexByName = useCallback(
    name => fieldsWatch.findIndex(field => field.name === name),
    [fieldsWatch]
  );

  const phone = getFieldByName('phone');
  const email = getFieldByName('email');

  const phoneIndex = getIndexByName('phone');
  const emailIndex = getIndexByName('email');

  const isPhoneRequired = phone && phone.required;
  const isEmailRequired = email && email.required;

  const actions = [
    {
      icon: 'phone',
      title: 'PhoneNumber',
      ns: 'ConnectForm',
      onClick: () =>
        append({
          placeholder: getLocalizedText('phone', language),
          name: 'phone',
          required: true
        }),
      allow: !fields.find(({ name }) => name === 'phone') && !isOnlyView
    },
    {
      icon: 'mail',
      title: 'Email',
      ns: 'ConnectForm',
      onClick: () =>
        append({
          placeholder: getLocalizedText('email', language),
          name: 'email',
          required: true
        }),
      allow: !fields.find(({ name }) => name === 'email') && !isOnlyView
    },
    {
      icon: 'form',
      title: 'Message',
      ns: 'ConnectForm',
      onClick: () =>
        append({
          placeholder: getLocalizedText('message', language),
          name: 'message',
          required: true
        }),
      allow: !fields.find(({ name }) => name === 'message') && !isOnlyView
    },
    {
      icon: 'paper-clip',
      title: 'File',
      ns: 'ConnectForm',
      onClick: () =>
        append({
          placeholder: getLocalizedText('file', language),
          name: 'file',
          required: false
        }),
      allow: !fields.find(({ name }) => name === 'file') && !isOnlyView
    },
    {
      icon: 'appstore-add',
      title: 'OwnField',
      ns: 'ConnectForm',
      onClick: () => setVisibleModal(true),
      allow: !isOnlyView
    }
  ];

  useEffect(() => {
    setFields(fieldsWatch);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsWatch]);

  useEffect(() => {
    if (phoneIndex === -1 || (phone && !phone.required)) {
      setValue(`formElements.${emailIndex}.required`, true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPhoneRequired, emailIndex]);

  useEffect(() => {
    if (emailIndex === -1 || (email && !email.required)) {
      setValue(`formElements.${phoneIndex}.required`, true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmailRequired, phoneIndex]);

  return (
    <>
      <div className={styles.root}>
        <FormInput
          name="name"
          placeholder={getLocalizedText('name', language)}
          disabled
          rules={{
            required: false
          }}
          itemProps={{ className: styles.fieldName }}
        />

        <Switch label={t('RequiredToggle')} disabled value />
      </div>

      {transformFields.map((field, index) => (
        <div key={field.key} className={styles.fieldsWrap}>
          <div style={{ width: '100%' }}>
            <FormInput
              name={`formElements.${index}.name`}
              placeholder={field.placeholder}
              itemProps={{
                className: styles.input
              }}
              disabled={isOnlyView}
            />

            <FormInput
              placeholder={field.placeholder}
              disabled
              name={`formElements.${index}.value`}
              defaultValue={field.value}
            />
          </div>

          <Button
            type="text"
            mood="none"
            className={styles.remove}
            onClick={() => remove(index)}
            disabled={
              (field.name === 'email' && phoneIndex === -1) ||
              (field.name === 'phone' && emailIndex === -1) ||
              isOnlyView
            }
          >
            <Icon type="close" color="black-55" />
          </Button>

          <FormSwitch
            name={`formElements.${index}.required`}
            label={t('RequiredToggle')}
            itemProps={{
              className: styles.switch
            }}
            disabled={
              (field.name === 'email' && phoneIndex === -1) ||
              (field.name === 'phone' && emailIndex === -1) ||
              isOnlyView
            }
          />
        </div>
      ))}

      <ActionsDropdown
        actions={actions}
        placement="bottomLeft"
        btnClassName={styles.dropdownOverlay}
      >
        <Button type="link" className={styles.append}>
          <Icon type="plus" /> {t('AddFieldBtn')}
        </Button>
      </ActionsDropdown>

      <AddFieldModal
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
        onSubmit={append}
      />
    </>
  );
};

export default EditableInputs;
