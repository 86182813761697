export const TABLE_VISUALIZATION_TYPE = 'TABLE';
export const CHART_VISUALIZATION_TYPE = 'CHART';
export const COHORT_VISUALIZATION_TYPE = 'COHORT';
export const COUNTER_VISUALIZATION_TYPE = 'COUNTER';
export const DETAILS_VISUALIZATION_TYPE = 'DETAILS';
export const FUNNEL_VISUALIZATION_TYPE = 'FUNNEL';
export const CHOROPLETH_VISUALIZATION_TYPE = 'CHOROPLETH';
export const MAP_VISUALIZATION_TYPE = 'MAP';
export const PIVOT_VISUALIZATION_TYPE = 'PIVOT';
export const SANKEY_VISUALIZATION_TYPE = 'SANKEY';
export const SUNBURST_SEQUENCE_VISUALIZATION_TYPE = 'SUNBURST_SEQUENCE';
export const WORD_CLOUD_VISUALIZATION_TYPE = 'WORD_CLOUD';

export const TABLE_VISUALIZATION_OPTION = {
  value: TABLE_VISUALIZATION_TYPE,
  label: 'TableView',
  ns: 'Report',
  iconType: 'list'
};

export const LINE_CHART_VISUALIZATION_OPTION = {
  value: `${CHART_VISUALIZATION_TYPE}-line`,
  label: 'LineChartView',
  ns: 'Report',
  iconType: 'line-chart'
};

export const BAR_CHART_VISUALIZATION_OPTION = {
  value: `${CHART_VISUALIZATION_TYPE}-column`,
  label: 'BarView',
  ns: 'Report',
  iconType: 'bar-chart'
};

export const AREA_CHART_VISUALIZATION_OPTION = {
  value: `${CHART_VISUALIZATION_TYPE}-area`,
  label: 'AreaChartView',
  ns: 'Report',
  iconType: 'area-chart'
};

export const PIE_CHART_VISUALIZATION_OPTION = {
  value: `${CHART_VISUALIZATION_TYPE}-pie`,
  label: 'PieChartView',
  ns: 'Report',
  iconType: 'pie-chart'
};

export const SCATTER_CHART_VISUALIZATION_OPTION = {
  value: `${CHART_VISUALIZATION_TYPE}-scatter`,
  label: 'ScatterChartView',
  ns: 'Report',
  iconType: 'dot-chart'
};

export const BUBBLE_CHART_VISUALIZATION_OPTION = {
  value: `${CHART_VISUALIZATION_TYPE}-bubble`,
  label: 'BubbleChartView',
  ns: 'Report',
  iconType: 'bubble-chart'
};

export const HEATMAP_CHART_VISUALIZATION_OPTION = {
  value: `${CHART_VISUALIZATION_TYPE}-heatmap`,
  label: 'HeatMapView',
  ns: 'Report',
  iconType: 'heat-map'
};

export const BOX_CHART_VISUALIZATION_OPTION = {
  value: `${CHART_VISUALIZATION_TYPE}-box`,
  label: 'BoxChartView',
  ns: 'Report',
  iconType: 'box-plot'
};

export const COHORT_VISUALIZATION_OPTION = {
  value: COHORT_VISUALIZATION_TYPE,
  label: 'CohortAnalysisView',
  ns: 'Report',
  iconType: 'sliders'
};

export const COUNTER_VISUALIZATION_OPTION = {
  value: COUNTER_VISUALIZATION_TYPE,
  label: 'CounterView',
  ns: 'Report',
  iconType: 'field-binary'
};

export const DETAILS_VISUALIZATION_OPTION = {
  value: DETAILS_VISUALIZATION_TYPE,
  label: 'DetailsView',
  ns: 'Report',
  iconType: 'bars'
};

export const FUNNEL_VISUALIZATION_OPTION = {
  value: FUNNEL_VISUALIZATION_TYPE,
  label: 'FunnelView',
  ns: 'Report',
  iconType: 'funnel-plot'
};

export const CHOROPLETH_VISUALIZATION_OPTION = {
  value: CHOROPLETH_VISUALIZATION_TYPE,
  label: 'ChoroplethView',
  ns: 'Report',
  iconType: 'choropleth-map'
};

export const MAP_VISUALIZATION_OPTION = {
  value: MAP_VISUALIZATION_TYPE,
  label: 'MapView',
  ns: 'Report',
  iconType: 'map'
};

export const PIVOT_VISUALIZATION_OPTION = {
  value: PIVOT_VISUALIZATION_TYPE,
  label: 'PivotTableView',
  ns: 'Report',
  iconType: 'table'
};

export const SANKEY_VISUALIZATION_OPTION = {
  value: SANKEY_VISUALIZATION_TYPE,
  label: 'SankeyView',
  ns: 'Report',
  iconType: 'snakey-chart'
};

export const SUNBURST_SEQUENCE_VISUALIZATION_OPTION = {
  value: SUNBURST_SEQUENCE_VISUALIZATION_TYPE,
  label: 'SunburstSequenceView',
  ns: 'Report',
  iconType: 'sunburst'
};

export const WORD_CLOUD_SEQUENCE_VISUALIZATION_OPTION = {
  value: WORD_CLOUD_VISUALIZATION_TYPE,
  label: 'WordCloudView',
  ns: 'Report',
  iconType: 'cloud'
};

export const VISUALIZATION_OPTIONS = [
  TABLE_VISUALIZATION_OPTION,
  LINE_CHART_VISUALIZATION_OPTION,
  BAR_CHART_VISUALIZATION_OPTION,
  AREA_CHART_VISUALIZATION_OPTION,
  PIE_CHART_VISUALIZATION_OPTION,
  SCATTER_CHART_VISUALIZATION_OPTION,
  BUBBLE_CHART_VISUALIZATION_OPTION,
  HEATMAP_CHART_VISUALIZATION_OPTION,
  BOX_CHART_VISUALIZATION_OPTION,
  COHORT_VISUALIZATION_OPTION,
  COUNTER_VISUALIZATION_OPTION,
  DETAILS_VISUALIZATION_OPTION,
  FUNNEL_VISUALIZATION_OPTION,
  CHOROPLETH_VISUALIZATION_OPTION,
  MAP_VISUALIZATION_OPTION,
  PIVOT_VISUALIZATION_OPTION,
  SANKEY_VISUALIZATION_OPTION,
  SUNBURST_SEQUENCE_VISUALIZATION_OPTION,
  WORD_CLOUD_SEQUENCE_VISUALIZATION_OPTION
];

const DAY = 'day';
const WEEK = 'week';
const MONTH = 'month';
const QUARTER = 'quarter';
const YEAR = 'year';

export const REPORT_TERMS_OPTIONS = [
  { value: DAY, label: 'BuildByDay', ns: 'Common' },
  { value: WEEK, label: 'BuildByWeeks', ns: 'Common' },
  { value: MONTH, label: 'BuildByMonth', ns: 'Common' },
  { value: QUARTER, label: 'BuildByQuarter', ns: 'Common' },
  { value: YEAR, label: 'BuildByYear', ns: 'Common' }
];

export const REPORT_GRADE_OPTIONS = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 }
];

export const REPORT_FILTER_EMPLOYEE = 'employee_id';
export const REPORT_FILTER_EMPLOYEE_2 = 'employee_id_2';
export const REPORT_FILTER_EMPLOYEE_3 = 'employee_id_3';
export const REPORT_FILTER_PROJECT = 'project_id';
export const REPORT_FILTER_PERIOD_FROM = 'from';
export const REPORT_FILTER_PERIOD_TO = 'to';
export const REPORT_FILTER_PERIOD_TO_EXCLUDE_TODAY = 'to_exclude_today';
export const REPORT_FILTER_WORKSPACE = 'workspace_id';
export const REPORT_FILTER_TIME_ZONE = 'time_zone';
export const REPORT_FILTER_TIMEZONE_OFFSET = 'timezone_offset';
export const REPORT_FILTER_COMPANY = 'company_id';
export const REPORT_FILTER_FUNNEL_TYPE = 'funnel_type';
export const REPORT_FILTER_TAG_IDS = 'tag_id';
export const REPORT_FILTER_TAGS_CONDITION = 'tags_condition';
export const REPORT_FILTER_CONTACT_STATUS = 'contact_status';
export const REPORT_FILTER_OPERATOR = 'channel_operator';
export const REPORT_FILTER_CHANNEL_KIND = 'channel_kind';
export const REPORT_FILTER_CHANNEL_ID = 'channel_id';
export const REPORT_FILTER_CONTACT_COMPANY_IS_INDIVIDUAL =
  'contact_company_is_individual';
export const REPORT_FILTER_CONTACT_FIRST_PAGE_LINK = 'contact_first_page_link';
export const REPORT_FILTER_TASK_KIND = 'task_kind';
export const REPORT_FILTER_ACTIVITY_TYPE = 'activity';
export const REPORT_FILTER_SPRINT = 'sprint';
export const REPORT_FILTER_TASK_STATUS = 'task_status';
export const REPORT_FILTER_CONTACT = 'contact_id';
export const REPORT_FILTER_REPORTS_TERMS = 'report_terms';
export const REPORT_FILTER_SHOW_ONLY_OVERDUE_ISSUES =
  'show_only_overdue_issues';
export const REPORT_FILTER_CURRENCY = 'currency';
export const REPORT_FILTER_HIDE_CONVERSION = 'hide_conversion';
export const REPORT_FILTER_HIDE_EARLIER_CONTACTS = 'hide_earlier_contacts';
export const REPORT_FILTER_GRADE = 'grade';
export const REPORT_FILTER_IS_FEEDBACK = 'is_feedback';
export const REPORT_FILTER_RETURNED_TO_WORK = 'returned_to_work';
export const REPORT_FILTER_ORDER_TAGS_CONDITION = 'order_tags_condition';
export const REPORT_FILTER_ORDER_TAG_IDS = 'order_tag_id';
export const REPORT_FILTER_PRICE = 'price';
export const REPORT_FILTER_ALLOW_BACKLOG = 'allow_backlog';
export const REPORT_FILTER_ORDER = 'order';
export const REPORT_FILTER_ORDER_STATUS = 'order_status';
export const REPORT_FILTER_ORDER_TYPE = 'order_type';
