// COMMON
export const AI_ASSISTANT_MENTION_FEATURE_FLAG = 'ai-assistant-mention';
export const FILTER_PANE_OPENED_STATE_FEATURE_FLAG = 'filter-pane-opened-state';
export const SHOW_NEWYEAR_CONTENT_FEATURE_FLAG = 'show-newyear-content';
export const AGENT_SUPPORT_WIDGET_FEATURE_FLAG = 'agent-support-widget';
export const SHOW_VALENTINE_DAY_CONTENT_FEATURE_FLAG =
  'show-valentine-day-content';
export const SHOW_MAN_DAY_CONTENT_FEATURE_FLAG = 'show-man-day-content';
export const SYSTEM_CHANNELS_ASSISTANT_FEATURE_FLAG =
  'system-channels-assistant';

// TASKS
export const SET_REMINDER_FEATURE_FLAG = 'set-reminder';
export const GANTT_CHART_FEATURE_FLAG = 'gantt-chart';
export const TASK_ACCESS_TYPE_FEATURE_FLAG = 'task-access-type';

// MENU
export const MENU_ANDROID_AND_IOS_FEATURE_FLAG = 'menu-android-and-ios';
export const MENU_REPORTS_FEATURE_FLAG = 'menu-reports';

// REPORTS
export const AD_SOURCES_REPORT_FEATURE_FLAG = 'ad-sources-report';
export const QUALITY_OF_SERVICE_PURCHASES_REPORT_FEATURE_FLAG =
  'quality-of-service-purchases-report';
export const QUALITY_OF_SERVICE_ORDERS_REPORT_FEATURE_FLAG =
  'quality-of-service-orders-report';
export const QUALITY_OF_SERVICE_ISSUES_REPORT_FEATURE_FLAG =
  'quality-of-service-issues-report';
export const REJECTED_ORDERS_REPORT_FEATURE_FLAG = 'rejected-orders-report';
export const APPLICATION_REPORT_FEATURE_FLAG = 'application-report';
export const CUSTOMER_REQUEST_REACTION_SPEED_FEATURE_FLAG =
  'customer-request-reaction-speed';
export const PLAN_ESTIMATION_REPORT_FEATURE_FLAG = 'plan-estimation-report';
export const EXECUTIVE_DISCIPLINE_REPORT_FEATURE_FLAG =
  'executive-discipline-report';
export const TIME_TRACK_REPORT_FEATURE_FLAG = 'time-track-report';
export const TIME_TRACK_EXTEND_REPORT_FEATURE_FLAG = 'time-track-extend-report';
export const CONTACT_AND_CHATS_REPORT_FEATURE_FLAG = 'contact-and-chats-report';
export const REPORTS_REVENUE_REPORT_FEATURE_FLAG = 'reports-revenue-report';

// CHANNELS
export const CHANNELS_WHATSAPP_FEATURE_FLAG = 'channels-whatsapp';

// WORKSPACE
export const WORKSPACE_OWN_GOOGLE_DRIVE_INTEGRATION_FLAG =
  'workspace-own-google-drive-integration';
export const WORKSPACE_COMMON_GOOGLE_DRIVE_INTEGRATION_LEGACY_FLAG =
  'workspace-common-google-drive-integration-legacy';

// CHAT
export const DISCUSSION_CREATING_CONVERSATION_FEATURE_FLAG =
  'discussion-creating-conversation';

// PROFILE SETTINGS
export const PROFILE_SETTINGS_PUBLIC_API_KEYS_FEATURE_FLAG =
  'profile-settings-public-api-keys';
export const PROFILE_SETTINGS_GOOGLE_CALENDAR_INTEGRATION_FEATURE_FLAG =
  'profile-settings-google-calendar-integration';

// CONTACTS
export const CONTACTS_CHANNELS_AUTOREPLY_SETTINGS_FEATURE_FLAG =
  'contacts-channels-autoreply-settings';
export const CONTACTS_MERGE_DUPLICATE_FEATURE_FLAG = 'contacts-merge-duplicate';
export const CONTACTS_CHANNELS_FEEDBACK_SETTINGS_FEATURE_FLAG =
  'contacts-channels-feedback-settings';

// REQUESTS
export const REQUESTS_CREATE_SLA_DEALS_FEATURE_FLAG =
  'requests-create-sla-deals';
export const REQUESTS_EXPENSES_AMOUNT_FEATURE_FLAG = 'requests-expenses-amount';
