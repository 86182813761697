import React from 'react';

import { EMAIL_SERVICE_OPTIONS } from 'constants/index';

// eslint-disable-next-line import/no-cycle
import { FormCustomSelect } from 'components/common/hook-form';

import Option from './option';

const ServiceSelect = props => (
  <FormCustomSelect
    {...props}
    options={EMAIL_SERVICE_OPTIONS}
    Option={Option}
  />
);

export default ServiceSelect;
