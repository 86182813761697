import React, { useState, Fragment } from 'react';
import { Divider, Spin } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Translation, useTranslation } from 'react-i18next';

import {
  CALENDAR_RECORD_STATUS_ACCEPTED,
  CALENDAR_RECORD_STATUS_POSSIBLE,
  CALENDAR_RECORD_STATUS_REJECTED,
  STATUS_CANCELLED,
  STATUS_DONE,
  STATUS_FOR_EXECUTION,
  STATUS_IN_WORK,
  TASK_STATUS,
  TASK_STATUSES,
  TYPE_MEETING,
  TYPE_TEMPLATE
} from 'constants/index';

// eslint-disable-next-line import/no-cycle
import { IconId } from 'components/tasks-view/components';
import EventStatus from 'components/common/event-status';
import Button from 'components/common/button';
import Icon from 'components/common/icon';
import Typography from 'components/common/typography';
import Dates from 'components/tasks-view/view/drawers/components/dates';
import { renderDescriptions } from 'components/common/comments/converters';
import GoogleMeetButton from 'components/common/google-meet-button';
import CustomCollapse from 'components/tasks-view/view/drawers/components/collapse';
import ContactAvatar from 'components/common/avatar/contact';
import EmployeeAvatar from 'components/common/avatar/employee';
import Location from 'components/common/location';
import EditorWorklogModal from 'components/tasks-view/view/drawers/details/editor-worklog-modal';

import { getUserEmployee } from 'store/workspace';

import { getFullName } from 'utils/get-fio';
import useModalsService from 'services/modals';
import useWorklogModalData from 'hooks/tasks/useWorklogModal';

import AcceptSchedulerMeetingModal from '../../../accept-scheduler-meeting';
import RecordDetailsSkeleton from '../../skeleton';

import styles from './task.module.scss';

const transformRecordStatusToTask = recordStatus => {
  const matchRecordStatusToTask = {
    [CALENDAR_RECORD_STATUS_POSSIBLE]: STATUS_FOR_EXECUTION,
    [CALENDAR_RECORD_STATUS_ACCEPTED]: STATUS_IN_WORK,
    [CALENDAR_RECORD_STATUS_REJECTED]: STATUS_CANCELLED
  };

  return matchRecordStatusToTask[recordStatus];
};

const View = ({
  record,
  task,
  isLoading,
  isStatusLoading,
  hasError,
  Actions,
  onChangeStatus
}) => {
  const currentEmployee = useSelector(getUserEmployee);

  const [isCheckWorklogLoading, setIsCheckWorklogLoading] = useState(false);
  const [visibleAcceptMeetingModal, setVisibleAcceptMeetingModal] =
    useState(false);

  const modals = useModalsService();
  const modalUrls = useModalsService({ returnUrl: true });

  const { visibleWorklog, setVisibleWorklog, checkIsWorklog, onSaveWorklog } =
    useWorklogModalData(task);

  const { t } = useTranslation(['MeetingPreviewCalendar', 'Task']);

  const {
    relation,
    status: recordStatus,
    title,
    gEventUrl,
    attendees,
    dateStart,
    dateEnd,
    employee,
    description,
    location,
    recurringRecordId
  } = record;
  const { id: relationId, type: relationType, templateId } = relation;

  const isTemplate = relationType === TYPE_TEMPLATE;

  const status =
    (task || {}).status || transformRecordStatusToTask(recordStatus);
  const activeStatus = TASK_STATUSES.find(s => s.id === status);

  const isResponsible = employee.id === currentEmployee.id;
  const isOutdated = moment(new Date()).isAfter(dateEnd);

  const showActionsButtons =
    isResponsible &&
    !!(activeStatus.actionsMeeting || []).length &&
    !isOutdated;

  const goToTask = () => modals.tasks.showDetails({ id: relationId });
  const getTaskLink = id =>
    `${window.location.href}${modalUrls.tasks.showDetails({ id })}`;

  const handleChangeStatus = ({ status: newStatus }) => {
    if (newStatus === STATUS_IN_WORK && templateId && !recurringRecordId) {
      return setVisibleAcceptMeetingModal(true);
    }

    return onChangeStatus({ status: newStatus });
  };

  const acceptSchedulerMeeting = async ({ acceptMeeting }) => {
    await onChangeStatus({ status: STATUS_IN_WORK, acceptMeeting });

    setVisibleAcceptMeetingModal(false);
  };

  const handleStatus = async newStatus => {
    try {
      setIsCheckWorklogLoading(true);

      if (task && newStatus !== STATUS_CANCELLED) {
        await checkIsWorklog(handleChangeStatus)({ status: newStatus });
      } else {
        handleChangeStatus({ status: newStatus });
      }
    } finally {
      setIsCheckWorklogLoading(false);
    }
  };

  if (isLoading) {
    return <RecordDetailsSkeleton />;
  }

  return (
    <>
      <div className={styles.root}>
        <div className={styles.header}>
          {!isTemplate && !hasError && (
            <IconId
              object={{ id: relationId, kind: TYPE_MEETING, parent: null }}
              link={getTaskLink(relationId)}
              className={styles.icon}
              copyable={{ label: t('CopyTaskLinkTip', { ns: 'Task' }) }}
            />
          )}

          <EventStatus
            id={relationId}
            statuses={TASK_STATUS}
            activeStatus={status}
            bordered
            className={styles.status}
          />

          {!hasError && (
            <div className={styles.actionButtons}>
              {!isTemplate && (
                <Button
                  type="text"
                  mood="none"
                  className={styles.toTaskButton}
                  onClick={goToTask}
                >
                  <Icon type="open-in-new-widnow" />
                </Button>
              )}

              <Actions />
            </div>
          )}
        </div>

        <div className={styles.content}>
          <Typography.Title level={3} className={styles.title}>
            {title}
          </Typography.Title>

          <Dates taskValues={{ dateStart, dateEnd }} />

          {location && (
            <Location location={location} className={styles.location} />
          )}

          {!!(description || []).length && (
            <Typography.Paragraph>
              {renderDescriptions(description)}
            </Typography.Paragraph>
          )}

          {gEventUrl && (
            <GoogleMeetButton link={gEventUrl} className={styles.googleMeet} />
          )}

          <Divider className={styles.divider} />

          <CustomCollapse defaultActiveKey="attendees">
            <CustomCollapse.Panel
              header={
                <Typography.Title level={3} className={styles.collapseTitle}>
                  {t('ParticipantsHeading')}
                </Typography.Title>
              }
              key="attendees"
            >
              {attendees.map(attendee => {
                const statusData = {
                  [CALENDAR_RECORD_STATUS_ACCEPTED]: {
                    label: t('AcceptedStatus'),
                    color: 'brand'
                  },
                  [CALENDAR_RECORD_STATUS_POSSIBLE]: {
                    label: t('MaybeStatus'),
                    color: 'black-55'
                  },
                  [CALENDAR_RECORD_STATUS_REJECTED]: {
                    label: t('DeclinedStatus'),
                    color: 'red'
                  }
                };

                const attendeeStatus = statusData[attendee.status];
                const user = attendee.employee || attendee.contact;
                const isContact = !!attendee.contact;
                const firstName = user.firstName || user.email;

                return (
                  <div
                    key={`$attendee-${attendee.id}`}
                    className={styles.attendee}
                  >
                    <div className={styles.employeeInfo}>
                      {isContact ? (
                        <ContactAvatar contact={{ ...user, firstName }} />
                      ) : (
                        <EmployeeAvatar employee={user} />
                      )}

                      <Typography.Text ellipsis>
                        {getFullName({ ...user, firstName })}
                      </Typography.Text>
                    </div>

                    <Typography.Text color={attendeeStatus.color}>
                      {attendeeStatus.label}
                    </Typography.Text>
                  </div>
                );
              })}
            </CustomCollapse.Panel>
          </CustomCollapse>
        </div>

        {showActionsButtons && (
          <Spin
            spinning={isCheckWorklogLoading || isStatusLoading}
            wrapperClassName={styles.spinWrapper}
          >
            <div className={styles.statusesBtns}>
              {activeStatus.actionsMeeting
                .filter(a => !isTemplate || a.status !== STATUS_DONE)
                .map(action => (
                  <Button
                    key={`task-view-action-${action.status}`}
                    type="secondary"
                    size="large"
                    mood={action.mood}
                    onClick={() => handleStatus(action.status)}
                  >
                    <Translation ns={action.ns}>
                      {translate => translate(action.label)}
                    </Translation>
                  </Button>
                ))}
            </div>
          </Spin>
        )}
      </div>

      <AcceptSchedulerMeetingModal
        visible={visibleAcceptMeetingModal}
        isLoading={isStatusLoading}
        onSubmit={acceptSchedulerMeeting}
        onClose={() => setVisibleAcceptMeetingModal(false)}
      />

      <EditorWorklogModal
        task={task}
        visible={visibleWorklog}
        worklog={(task || {}).workLog}
        handleSubmit={onSaveWorklog}
        onClose={() => setVisibleWorklog(false)}
      />
    </>
  );
};

export default View;
