import { useCallback, useEffect, useMemo } from 'react';
import {
  $createParagraphNode,
  $createTextNode,
  $getSelection,
  CLEAR_EDITOR_COMMAND,
  COMMAND_PRIORITY_HIGH,
  FORMAT_TEXT_COMMAND,
  KEY_ENTER_COMMAND,
  KEY_MODIFIER_COMMAND
} from 'lexical';
import { mergeRegister } from '@lexical/utils';
import { useDispatch } from 'react-redux';
import { $convertFromMarkdownString, TRANSFORMERS } from '@lexical/markdown';

import { MESSAGE_TEMPLATES_DRAWER } from 'constants/drawers';

import { MENTION_AT } from 'components/common/comments/converters';
import { MESSAGE_TEMPLATES_LIST } from 'components/common/drawers/message-templates/constants';

import { setVisibleDrawer } from 'store/drawers';

import { MENTION_MARKDOWN } from '../../nodes/mention-node';
import { LOW_PRIORITY, STRIKETHROUGH_TEXT } from '../constants';

export const useToolbarControls = ({
  editor,
  fileList = [],
  value,
  setIsPrivate,
  isPrivate,
  onSubmit = () => {},
  descriptionValue
}) => {
  const dispatch = useDispatch();

  const hasValue = useMemo(
    () => (value && value.description) || !!fileList.length,
    [fileList.length, value]
  );

  const onFocusEditor = useCallback(() => {
    editor.focus();
  }, [editor]);

  const addMention = useCallback(() => {
    onFocusEditor();

    editor.update(() => {
      const selection = $getSelection();
      const myNode = $createTextNode(MENTION_AT);

      const p = $createParagraphNode();
      p.append(myNode);

      selection.insertNodes([p]);
    });
  }, [editor, onFocusEditor]);

  const addEmoji = useCallback(
    emojiData => {
      onFocusEditor();

      editor.update(() => {
        const selection = $getSelection();
        const myNode = $createTextNode(emojiData.native);

        const p = $createParagraphNode();
        p.append(myNode);
        selection.insertNodes([p]);
      });
    },
    [editor, onFocusEditor]
  );

  const onChangePrivate = useCallback(() => {
    editor.update(() => {
      if (!isPrivate) {
        addMention();
      }

      setIsPrivate(!isPrivate);
    });
  }, [isPrivate, setIsPrivate, editor, addMention]);

  const addTextToEditor = useCallback(
    text => {
      onFocusEditor();

      editor.update(() => {
        $convertFromMarkdownString(
          descriptionValue ? `${descriptionValue}\n\n${text}` : text,
          [MENTION_MARKDOWN, ...TRANSFORMERS]
        );
      });
    },
    [descriptionValue, editor, onFocusEditor]
  );

  const openMessageTemplatesDrawer = () =>
    dispatch(
      setVisibleDrawer({
        drawer: MESSAGE_TEMPLATES_DRAWER,
        data: { initialComponent: MESSAGE_TEMPLATES_LIST, addTextToEditor }
      })
    );

  useEffect(
    () =>
      mergeRegister(
        editor.registerCommand(
          KEY_ENTER_COMMAND,
          event => {
            if (event.ctrlKey) {
              event.preventDefault();

              onSubmit();

              return true;
            }

            return false;
          },
          COMMAND_PRIORITY_HIGH
        ),
        editor.registerCommand(
          KEY_MODIFIER_COMMAND,
          event => {
            if (
              (event.ctrlKey || event.metaKey) &&
              event.shiftKey &&
              event.keyCode === 88
            ) {
              event.preventDefault();

              editor.dispatchCommand(FORMAT_TEXT_COMMAND, STRIKETHROUGH_TEXT);
            }

            return false;
          },
          LOW_PRIORITY
        )
      ),
    [editor, onSubmit]
  );

  useEffect(() => {
    if (!hasValue) {
      editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasValue]);

  return {
    addMention,
    addEmoji,
    onChangePrivate,
    addTextToEditor,
    openMessageTemplatesDrawer
  };
};

export default useToolbarControls;
