import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { TYPE_TASK, TYPE_TEMPLATE } from 'constants/index';

import { fetchOneEmployeeRecord } from 'store/calendar';

// eslint-disable-next-line import/no-cycle
import { TaskEntityView, TaskTemplateView } from './views/task';

const useRecordDetails = ({
  data,
  visible,
  onClose,
  updateRecordInList,
  fetchRecords
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(null);
  const [record, setRecord] = useState(undefined);

  const location = useLocation();

  const updateRecord = useCallback(
    async id => {
      const newRecord = await updateRecordInList(id);

      setRecord({
        ...newRecord,
        dateStart: record.dateStart,
        dateEnd: record.dateEnd
      });
    },
    [record, updateRecordInList]
  );

  const deleteRecord = useCallback(async () => {
    await fetchRecords();

    onClose();
  }, [fetchRecords, onClose]);

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);

      const result = await dispatch(fetchOneEmployeeRecord({ id: data.id }));
      setRecord({ ...result, ...data });
    } finally {
      setIsLoading(false);
    }
  }, [data, dispatch]);

  const viewByType = {
    [TYPE_TASK]: (
      <TaskEntityView
        record={record}
        updateRecord={updateRecord}
        updateRecordInList={updateRecordInList}
        deleteRecord={deleteRecord}
      />
    ),
    [TYPE_TEMPLATE]: (
      <TaskTemplateView record={record} updateRecord={updateRecord} />
    )
  };

  const view = record ? viewByType[record.relation.type] : null;

  useEffect(() => {
    if (visible && data.id) {
      fetch();
    }

    return () => setRecord(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, data]);

  useEffect(() => {
    if (visible) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return { isLoading, view, record };
};

export default useRecordDetails;
