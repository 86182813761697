import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ASSETS } from 'constants/index';

// eslint-disable-next-line import/no-cycle
import AssetCreatorDrawer from 'components/assets-view/drawers/creator';

import { fetchAssetListLocal } from 'store/calendar';

import CustomSelect from '../custom-select';
import CommonOption from '../custom-select/common-option';

const mapValue = value => ({
  value: value.id,
  label: { ...value, isDisabled: value.isBooked }
});

export const BookingAssetsSelect = ({ isMulti, value, onChange, ...props }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation(['ChooseAsset', 'Common', 'Journals']);

  const [visibleCreator, setVisibleCreator] = useState(false);

  const addEntityButtonData = {
    title: t('AddAssetBtn', { ns: 'Journals' }),
    onClick: () => setVisibleCreator(true)
  };

  const creatableCallback = values => {
    const transfromed = [values].map(mapValue)[0];
    onChange(isMulti ? [...value, transfromed] : transfromed);
  };

  const fetchData = params =>
    dispatch(
      fetchAssetListLocal({
        params
      })
    ).then(({ results, count, ...res }) => ({
      ...res,
      totalItems: count,
      entries: results.map(mapValue)
    }));

  return (
    <>
      <CustomSelect
        isAsync
        isSearchable
        isClearable
        selectType={ASSETS}
        placeholder={t('SearchForAssets', { ns: 'Common' })}
        valueText={t('ChooseAsset')}
        fetchData={fetchData}
        Option={CommonOption}
        addEntityButtonData={addEntityButtonData}
        value={value}
        isMulti={isMulti}
        onChange={onChange}
        {...props}
      />

      <AssetCreatorDrawer
        visible={visibleCreator}
        showDetailsAfterSubmit={false}
        callback={creatableCallback}
        onClose={() => setVisibleCreator(false)}
      />
    </>
  );
};

BookingAssetsSelect.propTypes = {
  onCreate: PropTypes.func
};

BookingAssetsSelect.defaultProps = {
  onCreate: undefined
};

export default BookingAssetsSelect;
