import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ASSETS } from 'constants/index';

import CustomSelect from '../custom-select';
import AssetOption from '../custom-select/asset-option';
import AssetsSelectModal from './modal';

export const AssetsSelect = ({
  isMulti,
  value,
  onChange,
  isDisabled,
  ...props
}) => {
  const [visibleModal, setVisibleModal] = useState(false);

  const { t } = useTranslation('Common');

  return (
    <CustomSelect
      selectType={ASSETS}
      placeholder={t('SearchForAssets')}
      valueText={t('ChooseAsset')}
      Option={AssetOption}
      value={value}
      isMulti={isMulti}
      isDisabled={isDisabled}
      onChange={onChange}
      visibleDropdownMenu={visibleModal && !isDisabled}
      popoverProps={{
        onClick: () => setVisibleModal(true),
        destroyTooltipOnHide: true
      }}
      getPopupContainer={() => document.body}
      renderContent={
        <AssetsSelectModal
          visible={visibleModal && !isDisabled}
          onClose={() => setVisibleModal(false)}
          isMulti={isMulti}
          value={value}
          onChange={onChange}
        />
      }
      {...props}
    />
  );
};

AssetsSelect.propTypes = {
  onCreate: PropTypes.func
};

AssetsSelect.defaultProps = {
  onCreate: undefined
};

export default AssetsSelect;
