import * as amplitude from '@amplitude/analytics-browser';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import {
  TYPE_CONTACT,
  TYPE_PROJECT,
  TYPE_ORDER,
  // COMPLETE_CLOSE_ORDER,
  COMPLETE_MEDIA,
  TASK_AMPLITUDE_POSITIONS,
  ADD_CONTACT_EVENT,
  ADD_DEAL_COMMENT_EVENT,
  ADD_DEAL_EVENT,
  EDIT_DEAL_EVENT,
  FINISH_DEAL_EVENT,
  EDIT_DEAL_RATING_EVENT,
  ADD_DEAL_RATING_EVENT,
  ADD_SUBTASK_EVENT,
  ADD_TASK_COMMENT_EVENT,
  ADD_TASK_EVENT,
  ADD_TASK_TEMPLATE_EVENT,
  COLLEAGUE_INVITATION_EVENT,
  CONTACT_INVITATION_EVENT,
  DELETE_SUBTASK_EVENT,
  DELETE_TASK_EVENT,
  DELETE_TASK_TEMPLATE_EVENT,
  EDIT_SUBTASK_EVENT,
  EDIT_TASK_EVENT,
  EDIT_TASK_TEMPLATE_EVENT,
  FINISH_SUBTASK_EVENT,
  FINISH_TASK_EVENT,
  JOIN_REQUEST_SENT_EVENT,
  SIGN_UP_EVENT,
  STATUS_ACTIVE,
  STATUS_CLOSING,
  STATUS_COMPLETED,
  STATUS_DECLINED,
  STATUS_NEGOTIATION,
  STATUS_NEW,
  STATUS_RECENT,
  TYPE_AGREEMENT_TASK,
  TYPE_TASK,
  MANAGER,
  EMPLOYEE,
  LOGIN_EVENT,
  ADD_TASK_DESCRIPTION_EVENT,
  MAIN_MENU_CLICK,
  ASSISTANT_RICHARD_INVOCATION,
  EDITABLE_FILE_UPLOAD,
  CHAT_HISTORY_OPEN,
  AI_CHAT_PROMPT,
  ACCESS_LEVEL_EVENT,
  AI_TECHSUPPORT_AGENT,
  WS_CONNECTION_LIMITED_WARNING_SHOWN,
  ADD_REQUEST_WITH_OBJECT_SLA,
  ADD_ASSET_EVENT,
  EDIT_ASSET_EVENT,
  DELETE_ASSET_EVENT,
  CLICK_SUPPORT_SECTION_EVENT,
  OUTGOING,
  INCOMING,
  HIRING,
  DISCUSSION_CREATED_EVENT,
  REQUEST_CLOSED_EVENT,
  CHANNELS_ASSISTANT_CREATED,
  CHANNELS_ASSISTANT_ADD_DOCUMENTS
} from 'constants/index';

import {
  getAllWorkspaces,
  getUserEmployee,
  getWorkspaceId
} from 'store/workspace';
import { getUser } from 'store/user';

import { useLocalStorage } from 'hooks';

const AMPLITUDE_FIELD_ACTION = {
  ADD: 'add',
  DELETE: 'delete',
  EDIT: 'edit',
  CHANGE: 'change'
};

export const useAmplitude = () => {
  const user = useSelector(getUser);
  const workspaces = useSelector(getAllWorkspaces);
  const activeWorkspaceId = useSelector(getWorkspaceId);

  const userEmployee = useSelector(getUserEmployee);

  const [, setLastActivityDate] = useLocalStorage(
    'lastActivityDate',
    new Date()
  );

  const checkIsEmpty = value => {
    if ((isEmpty(value) && !(typeof value === 'number')) || !value) {
      return true;
    }

    return false;
  };

  const setAmplitudeProperties = ({
    workspaceId,
    property,
    defaultUserProperties
  }) => {
    const workspace = workspaces[workspaceId || activeWorkspaceId];

    let userId = user.id;

    if (workspace || property) {
      userId = workspace.user.userId;
    }

    amplitude.setUserId(userId);

    const identify = new amplitude.Identify();

    for (const [key, value] of Object.entries(defaultUserProperties)) {
      identify.set(key, value);
    }

    amplitude.identify(identify);
  };

  const getDefaultUserProperties = ({
    workspaceId,
    property,
    analyticsProperty = {}
  } = {}) => {
    const workspace = workspaces[workspaceId || activeWorkspaceId];
    let defaultUserProperties = {};

    if (workspace || property) {
      const isManager = workspace.user.roles.find(r => r.type === MANAGER);
      const roles = workspace.user.roles.map(el => el.type);

      defaultUserProperties = property || {
        workspace_id: workspace.id,
        workspace_role: isManager ? roles.filter(r => r !== EMPLOYEE) : roles,
        workspace_type: workspace.isIndividual ? 'individual' : 'company',
        number_of_users: workspace.countEmployee,
        workspace_verification: `${!workspace.isNotVerified}`, // Android/iOS pass this props like a string type
        ...analyticsProperty
      };
    }

    return defaultUserProperties;
  };

  const logEvent = ({
    workspaceId,
    property,
    analyticsProperty,
    event,
    params
  }) => {
    const defaultUserProperties = getDefaultUserProperties({
      workspaceId,
      property,
      analyticsProperty
    });

    setAmplitudeProperties({
      workspaceId,
      property,
      defaultUserProperties
    });

    amplitude.track(event, { ...params, ...defaultUserProperties });
  };

  const registrationEvent = ({
    workspaceId,
    isInvite,
    inviteType,
    analyticsProperty
  }) =>
    logEvent({
      workspaceId,
      analyticsProperty,
      event: SIGN_UP_EVENT,
      params: isInvite
        ? {
            invitation_registration: inviteType
          }
        : {}
    });

  const loginEvent = workspaceId => {
    logEvent({ workspaceId, event: LOGIN_EVENT });
    setLastActivityDate(new Date());
  };

  const logoutEvent = () => {
    amplitude.setUserId(null);
    amplitude.setDeviceId('');
  };

  const getCustomTypeTask = type => {
    switch (type) {
      case TYPE_TASK:
      case TYPE_AGREEMENT_TASK:
        return 'simple_task';
      default:
        return type;
    }
  };

  const getCommonTaskParams = task => ({
    task_type: getCustomTypeTask(task.kind),
    task_status: task.status
  });

  const dataMatchingFields = {
    controller: {
      name: 'task_controller'
    },
    approvingManager: {
      name: 'approving_person'
    },
    members: {
      name: 'co_executor',
      isArray: true
    },
    location: {
      name: 'task_address'
    },
    tags: {
      name: 'task_tag'
    },
    dateStart: {
      name: 'task_date_start'
    },
    estimation: {
      name: 'plan_labor_costs',
      editable: true
    }
  };

  const getAdditionalTaskParams = (prevValue, newValue) => {
    const params = {};

    if (newValue.completeRequire) {
      params.task_result = getTaskResult(newValue.completeRequire);
    }

    Object.keys(dataMatchingFields).forEach(el => {
      if (newValue[el] === undefined) {
        return null;
      }

      const { editable, isArray, name } = dataMatchingFields[el];

      const isAdd =
        checkIsEmpty((prevValue || {})[el]) &&
        !checkIsEmpty((newValue || {})[el]);

      const isDelete =
        !checkIsEmpty((prevValue || {})[el]) &&
        checkIsEmpty((newValue || {})[el]) &&
        !editable;

      const isEdit = editable && prevValue[el] !== newValue[el];

      if (isArray) {
        if ((prevValue || {})[el].length > (newValue || {})[el].length) {
          params[name] = AMPLITUDE_FIELD_ACTION.DELETE;
        }

        if ((prevValue || {})[el].length < (newValue || {})[el].length) {
          params[name] = AMPLITUDE_FIELD_ACTION.ADD;
        }

        return null;
      }

      if (isDelete) {
        params[name] = AMPLITUDE_FIELD_ACTION.DELETE;
      }

      if (isAdd) {
        params[name] = AMPLITUDE_FIELD_ACTION.ADD;
      }

      if (isEdit) {
        params[name] = AMPLITUDE_FIELD_ACTION.EDIT;
      }

      return null;
    });

    return params;
  };

  const getRelationFiled = el => {
    switch (el.type) {
      case TYPE_PROJECT:
        return 'link_with_project';
      case TYPE_ORDER:
        return 'link_with_deal';
      case TYPE_CONTACT:
        return 'link_with_contact';
      default:
        return null;
    }
  };

  const getRelations = (relations, newRelations) => {
    const result = {};

    const typesRelationsIds = (relations || []).map(el => el.objectId);
    const newTypesRelationsIds = (newRelations || []).map(el => el.id);

    const addedRelations = newTypesRelationsIds.filter(
      n => typesRelationsIds.indexOf(n) === -1
    );
    const deletedRelations = typesRelationsIds.filter(
      n => newTypesRelationsIds.indexOf(n) === -1
    );

    addedRelations.forEach(el => {
      const findEl = newRelations.find(item => item.id === el);
      if (findEl) {
        result[getRelationFiled(findEl)] = AMPLITUDE_FIELD_ACTION.ADD;
      }
    });

    deletedRelations.forEach(el => {
      const findEl = relations.find(item => item.objectId === el);
      if (findEl) {
        result[getRelationFiled(findEl)] = AMPLITUDE_FIELD_ACTION.DELETE;
      }
    });

    return result;
  };

  const changeWorklog = ({ value, task, isDelete }) => {
    if (!value) {
      return null;
    }

    const params = {
      ...getCommonTaskParams(task),
      fact_labor_costs: isDelete
        ? AMPLITUDE_FIELD_ACTION.DELETE
        : AMPLITUDE_FIELD_ACTION.ADD
    };

    return logEvent({
      event: task.parent ? EDIT_SUBTASK_EVENT : EDIT_TASK_EVENT,
      params
    });
  };

  const toggleFavoriteTask = ({ value, task }) => {
    const params = {
      ...getCommonTaskParams(task),
      favorite_task: !value
        ? AMPLITUDE_FIELD_ACTION.ADD
        : AMPLITUDE_FIELD_ACTION.DELETE
    };

    logEvent({ event: EDIT_TASK_EVENT, params });
  };

  const deleteTaskEvent = task => {
    const params = getCommonTaskParams(task);

    if (task.parent) {
      params.task_user_role = getRoleUser(task);
    }

    return logEvent({
      event: task.parent ? DELETE_SUBTASK_EVENT : DELETE_TASK_EVENT,
      params
    });
  };

  const updateTaskArrayEvent = ({ task, added, deleted, paramName }) => {
    const params = getCommonTaskParams(task);

    if (added.length > deleted.length) {
      params[paramName] = AMPLITUDE_FIELD_ACTION.ADD;
    }

    if (added.length < deleted.length) {
      params[paramName] = AMPLITUDE_FIELD_ACTION.DELETE;
    }

    logEvent({
      event: task.parent ? EDIT_SUBTASK_EVENT : EDIT_TASK_EVENT,
      params
    });
  };

  const updateTaskEvent = ({ task, value, isTemplate }) => {
    const params = {
      ...getCommonTaskParams(task),
      ...getAdditionalTaskParams(task, value),
      ...getRelations(task.relations, value.relations)
    };

    if (isTemplate) {
      delete params.plan_labor_costs;
      params.template_type = value.isScheduler
        ? 'recurring_template'
        : 'simple_template';

      return logEvent({ event: EDIT_TASK_TEMPLATE_EVENT, params });
    }

    const prevFile = (task.fileList || []).length;
    const newFile = ((value.description || {}).fileList || []).length;

    if (prevFile > newFile) {
      params.task_file = AMPLITUDE_FIELD_ACTION.DELETE;
    }

    if (prevFile < newFile) {
      params.task_file = AMPLITUDE_FIELD_ACTION.ADD;
    }

    if (
      value.responsible &&
      task.responsible &&
      (task.responsible || {}).id !== value.responsible
    ) {
      params.task_responsible = AMPLITUDE_FIELD_ACTION.CHANGE;
    }

    if (task.parent) {
      params.task_user_role = getRoleUser({
        ...task,
        author: (task.author || {}).id,
        responsible: (task.responsible || {}).id,
        controller: (task.controller || {}).id,
        approvingManager: (task.approvingManager || {}).id
      });
    }

    return logEvent({
      event: task.parent ? EDIT_SUBTASK_EVENT : EDIT_TASK_EVENT,
      params
    });
  };

  const taskFields = [
    {
      appField: 'controller',
      amplitudeField: 'task_controller'
    },
    {
      appField: 'approvingManager',
      amplitudeField: 'approving_person'
    },
    {
      appField: 'members',
      amplitudeField: 'co_executor',
      type: 'array'
    },
    {
      appField: 'location',
      amplitudeField: 'task_address'
    },
    {
      appField: 'tags',
      amplitudeField: 'task_tag',
      type: 'array'
    },
    {
      appField: 'dateStart',
      amplitudeField: 'task_date_start'
    },
    {
      appField: 'fileList',
      amplitudeField: 'task_file',
      type: 'array'
    }
  ];

  const getTaskResult = value => {
    switch (value) {
      // case COMPLETE_CLOSE_ORDER:
      //   return 'finish_deal';
      case COMPLETE_MEDIA:
        return 'media_file';
      default:
        return value;
    }
  };

  const addTaskEvent = ({ value, template = {} }) => {
    const params = getCommonTaskParams(value);

    if (value.isCopy) {
      const propName = value.parent ? 'copy_subtask' : 'copy_task';
      params[propName] = true;
    }

    if (value.parent) {
      params.task_user_role = getRoleUser({
        ...value,
        author: userEmployee.id
      });
    }

    taskFields.forEach(el => {
      const condition =
        el.type === 'array'
          ? (value[el.appField] || []).length
          : value[el.appField];
      if (condition) {
        params[el.amplitudeField] = AMPLITUDE_FIELD_ACTION.ADD;
      }
    });

    if (+value.estimation) {
      params.plan_labor_costs = AMPLITUDE_FIELD_ACTION.ADD;
    }

    params.task_result = getTaskResult(value.completeRequire);

    if (!checkIsEmpty(template) || value.isFromTemplate) {
      params.template_type =
        template.isScheduler || value.isScheduler
          ? 'recurring_template'
          : 'simple_template';
    }

    (value.relations || []).forEach(el => {
      switch (el.relationType) {
        case TYPE_PROJECT:
          params.link_with_project = AMPLITUDE_FIELD_ACTION.ADD;
          break;
        case TYPE_ORDER:
          params.link_with_deal = AMPLITUDE_FIELD_ACTION.ADD;
          break;
        case TYPE_CONTACT:
          params.link_with_contact = AMPLITUDE_FIELD_ACTION.ADD;
          break;
        default:
          break;
      }
    });

    logEvent({
      event: value.parent ? ADD_SUBTASK_EVENT : ADD_TASK_EVENT,
      params: {
        ...params,
        task_status: value.parent ? undefined : params.status
      }
    });
  };

  const getRoleUser = value => {
    const roles = [];

    if (userEmployee.id === value.author) {
      roles.push(TASK_AMPLITUDE_POSITIONS.TASK_AUTHOR);
    }

    const isResponsible = Array.isArray(value.responsible)
      ? (value.responsible || []).find(el => (el || {}).id === userEmployee.id)
      : value.responsible === userEmployee.id;

    if (isResponsible) {
      roles.push(TASK_AMPLITUDE_POSITIONS.TASK_RESPONSIBLE);
    }

    if (userEmployee.id === value.controller) {
      roles.push(TASK_AMPLITUDE_POSITIONS.TASK_CONTROLLER);
    }

    const isMember = (value.members || []).find(
      el => el.id === userEmployee.id
    );

    if (isMember) {
      roles.push(TASK_AMPLITUDE_POSITIONS.TASK_EXECUTOR_DIRECT);
    }

    if (userEmployee.id === value.approvingManager) {
      roles.push(TASK_AMPLITUDE_POSITIONS.TASK_APPROVING_PERSON);
    }

    if (!roles.length) {
      roles.push(TASK_AMPLITUDE_POSITIONS.TASK_EXECUTOR_NOT_DIRECT);
    }

    return roles;
  };

  const addTaskTemplate = isScheduler =>
    logEvent({
      event: ADD_TASK_TEMPLATE_EVENT,
      params: {
        template_type: isScheduler ? 'recurring_template' : 'simple_template'
      }
    });

  const deleteTaskTemplateEvent = isScheduler =>
    logEvent({
      event: DELETE_TASK_TEMPLATE_EVENT,
      params: {
        template_type: isScheduler ? 'recurring_template' : 'simple_template'
      }
    });

  const addTaskCommentEvent = ({ task, comment }) => {
    const commentParams = [];

    if (comment.content.find(el => el['@'])) {
      commentParams.push('tag_colleague');
    }

    if (comment.is_private) {
      commentParams.push('private_comment');
    }

    if ((comment.fileList || []).length) {
      commentParams.push('add_file');
    }

    const params = {
      ...getCommonTaskParams(task),
      task_user_role: getRoleUser({
        ...task,
        author: task.author.id,
        responsible: (task.responsible || {}).id,
        controller: (task.controller || {}).id,
        approvingManager: (task.approvingManager || {}).id
      }),
      task_comment_parameters: commentParams.join(', ')
    };

    logEvent({ event: ADD_TASK_COMMENT_EVENT, params });
  };

  const finishTaskEvent = task => {
    const params = getCommonTaskParams(task);

    logEvent({
      event: task.parent ? FINISH_SUBTASK_EVENT : FINISH_TASK_EVENT,
      params
    });
  };

  const logAddTaskDescriptionEvent = trigger => {
    logEvent({
      event: ADD_TASK_DESCRIPTION_EVENT,
      params: { trigger }
    });
  };

  const getOrderType = ({ kind } = { kind: '' }) => {
    switch (kind) {
      case OUTGOING:
        return 'sale_deal';
      case INCOMING:
        return 'purchase_deal';
      case HIRING:
        return 'hiring_deal';
      default:
        return '';
    }
  };

  const getOrderDealMembers = (
    { isMarketplace, contractor } = { isMarketplace: false, contractor: {} }
  ) => {
    if (isMarketplace) {
      return 'online_deal';
    }

    return contractor?.workspaceId && contractor?.employeeId
      ? 'online_deal'
      : 'offline_deal';
  };

  const getOrderStatus = ({ status } = { status: '' }) => {
    switch (status) {
      case STATUS_NEGOTIATION:
        return 'deal_negotiation';
      case STATUS_NEW:
        return 'deal_new';
      case STATUS_RECENT:
        return 'deal_response';
      case STATUS_ACTIVE:
        return 'deal_in_progress';
      case STATUS_CLOSING:
        return 'deal_closing';
      case STATUS_COMPLETED:
        return 'deal_completed';
      case STATUS_DECLINED:
        return 'deal_rejected';
      default:
        return '';
    }
  };

  const additionalOrderField = {
    fileList: 'deal_file',
    location: 'deal_address',
    dateStart: 'deal_date_start',
    price: 'price',
    contractor: 'deal_responsible',
    customer: 'deal_responsible'
  };

  const getCommonOrderParams = ({ value, withoutLead, withoutStatus }) => {
    const params = {
      deal_type: getOrderType(value)
    };

    if (!withoutLead) {
      params.deal_members = getOrderDealMembers(value);
    }

    if (!withoutStatus) {
      params.deal_status = getOrderStatus(value);
    }

    return params;
  };

  const getAdditionalOrderParams = (value, newValue) => {
    const params = {};

    Object.keys(additionalOrderField).forEach(k => {
      if (newValue?.[k] === undefined) {
        return null;
      }

      if (
        additionalOrderField[k] === 'deal_responsible' &&
        value?.[k] !== newValue?.[k]
      ) {
        params[additionalOrderField[k]] = AMPLITUDE_FIELD_ACTION.CHANGE;
      }

      if (checkIsEmpty(value[k]) && !checkIsEmpty(newValue?.[k])) {
        params[additionalOrderField[k]] = AMPLITUDE_FIELD_ACTION.ADD;
      }

      if (!checkIsEmpty(value[k]) && checkIsEmpty(newValue?.[k])) {
        params[additionalOrderField[k]] = AMPLITUDE_FIELD_ACTION.DELETE;
      }

      return null;
    });

    return params;
  };

  const addOrderEvent = value => {
    const params = {
      ...getCommonOrderParams({ value, withoutStatus: true }),
      ...getAdditionalOrderParams({}, value)
    };

    if (value?.isCopy) {
      params.copy_deal = true;
    }

    logEvent({ event: ADD_DEAL_EVENT, params });
  };

  const editOrderFavoriteStatus = value => {
    const params = getCommonOrderParams({ value });

    params.favorite_deal = !value.isFavorite
      ? AMPLITUDE_FIELD_ACTION.ADD
      : AMPLITUDE_FIELD_ACTION.DELETE;

    logEvent({ event: EDIT_DEAL_EVENT, params });
  };

  const changeOrderWorklog = ({ value, isDelete }) => {
    const params = getCommonOrderParams({ value });

    params.fact_labor_costs = isDelete
      ? AMPLITUDE_FIELD_ACTION.DELETE
      : AMPLITUDE_FIELD_ACTION.ADD;

    logEvent({ event: EDIT_DEAL_EVENT, params });
  };

  const editOrderEvent = ({ value, newValue }) => {
    const params = {
      ...getCommonOrderParams({ value }),
      ...getAdditionalOrderParams(value, newValue)
    };

    if (value?.isCopy) {
      params.copy_deal = true;
    }

    logEvent({ event: EDIT_DEAL_EVENT, params });
  };

  const addOrderCommentEvent = ({ value, file }) => {
    const params = getCommonOrderParams({ value, withoutLead: true });

    if (file) {
      params.add_file = true;
    }

    logEvent({ event: ADD_DEAL_COMMENT_EVENT, params });
  };

  const finishOrderEvent = value => {
    const params = getCommonOrderParams({ value });

    logEvent({ event: FINISH_DEAL_EVENT, params });
  };

  const orderRatingEvent = value => {
    const params = {
      ...getCommonOrderParams({
        value,
        withoutLead: true,
        withoutStatus: true
      }),
      deal_rating: `rate_${value.feedback.rate}`,
      deal_review: !!(value.feedback || {}).content,
      deal_user_role: value.isCustomer ? 'customer' : 'contractor'
    };

    logEvent({
      event: value.isFeedbackExists
        ? EDIT_DEAL_RATING_EVENT
        : ADD_DEAL_RATING_EVENT,
      params
    });
  };

  const addContactEvent = value => {
    const params = {
      first_name_contact: !!value.firstName,
      last_name_contact: !!value.lastName,
      middle_name_contact: !!value.middleName,
      phone_contact: value.phone,
      email_contact: value.email,
      comment_contact: !!value.comments
    };

    if (!value.company.isIndividual) {
      params.contact_type = 'company_contact';
      params.position_contact = !!value.position;
      params.country_contact = value.company.country.name;
    } else {
      params.contact_type = 'individual_contact';
    }

    logEvent({ event: ADD_CONTACT_EVENT, params });
  };

  const contactInvitationEvent = ({ value, type }) => {
    const { isIndividual } = value.company || {};

    const params = {
      first_name_contact: !!value.firstName,
      last_name_contact: !!value.lastName,
      middle_name_contact: !!value.middleName,
      phone_contact: value.phone,
      email_contact: value.email,
      comment_contact: !!value.comment,
      contact_invitation_type: type
    };

    if (!isIndividual) {
      params.country_contact = value.company.country.name;
      params.position_contact = !!value.position;
    }

    params.contact_type = isIndividual
      ? 'individual_contact'
      : 'company_contact';

    logEvent({ event: CONTACT_INVITATION_EVENT, params });
  };

  const colleagueInvitationEvent = colleagueInvitationFlow => {
    const params = {
      colleague_invitation_flow: []
    };

    if (colleagueInvitationFlow) {
      colleagueInvitationFlow.forEach(el => {
        params.colleague_invitation_flow.push(el);
      });
    }

    logEvent({ event: COLLEAGUE_INVITATION_EVENT, params });
  };

  const joinRequestSentEvent = ({ id, analyticsProperty }) => {
    const property = {
      workspace_id: id,
      workspace_type: 'company',
      workspace_role: ['join_request'],
      ...analyticsProperty
    };

    logEvent({ property, event: JOIN_REQUEST_SENT_EVENT });
  };

  const mainMenuClickEvent = ({ type }) => {
    const params = {
      menu_type: type
    };
    logEvent({ event: MAIN_MENU_CLICK, params });
  };

  const trackAssistanRichardInvocationEvent = () => {
    const params = {
      trigger: 'send'
    };

    logEvent({ event: ASSISTANT_RICHARD_INVOCATION, params });
  };

  const trackEditableFileUploadEvent = () => {
    const params = {
      trigger: 'upload'
    };

    logEvent({ event: EDITABLE_FILE_UPLOAD, params });
  };

  const chatHistoryActionClickEvent = () => {
    logEvent({ event: CHAT_HISTORY_OPEN });
  };

  const aiChatPromptClickEvent = ({ type }) => {
    const params = {
      prompt_type: type
    };

    logEvent({ event: AI_CHAT_PROMPT, params });
  };

  const accessToTaskEvent = ({ type }) => {
    const params = {
      type_access_task: type
    };

    logEvent({ event: ACCESS_LEVEL_EVENT, params });
  };

  const agentSupportSendMessageEvent = () => {
    logEvent({ event: AI_TECHSUPPORT_AGENT });
  };

  const wsConnectionLimitedWarningShown = ({ code, reason }) => {
    const params = {
      code,
      reason
    };

    logEvent({ event: WS_CONNECTION_LIMITED_WARNING_SHOWN, params });
  };

  const addRequestWithObjectSlaEvent = ({ slaObjectType }) => {
    const params = {
      slaObjectType
    };
    logEvent({ event: ADD_REQUEST_WITH_OBJECT_SLA, params });
  };

  const addAssetEvent = () => logEvent({ event: ADD_ASSET_EVENT });

  const editAssetEvent = () => logEvent({ event: EDIT_ASSET_EVENT });

  const deleteAssetEvent = () => logEvent({ event: DELETE_ASSET_EVENT });

  const clickSupportSectionEvent = () =>
    logEvent({ event: CLICK_SUPPORT_SECTION_EVENT });

  const discussionCreatedEvent = () =>
    logEvent({ event: DISCUSSION_CREATED_EVENT });

  const requestClosedEvent = ({ closedWithExpenses }) => {
    logEvent({
      event: REQUEST_CLOSED_EVENT,
      params: closedWithExpenses && { result: 'request_closed_expenses' }
    });
  };

  const channelsAssistantCreatedEvent = () =>
    logEvent({ event: CHANNELS_ASSISTANT_CREATED });

  const channelsAssistantAddDocumentsEvent = ({
    assistantName,
    assistantResponsible,
    documentCount,
    documentFormats
  }) => {
    const params = {
      assistant_name: assistantName,
      assistant_responsible: assistantResponsible,
      document_count: documentCount,
      document_formats: documentFormats
    };

    return logEvent({ event: CHANNELS_ASSISTANT_ADD_DOCUMENTS, params });
  };

  return {
    registrationEvent,
    loginEvent,
    addTaskEvent,
    addTaskTemplate,
    updateTaskEvent,
    updateTaskArrayEvent,
    toggleFavoriteTask,
    changeWorklog,
    deleteTaskEvent,
    deleteTaskTemplateEvent,
    addTaskCommentEvent,
    finishTaskEvent,
    addOrderEvent,
    editOrderEvent,
    changeOrderWorklog,
    logoutEvent,
    addOrderCommentEvent,
    finishOrderEvent,
    addContactEvent,
    contactInvitationEvent,
    orderRatingEvent,
    colleagueInvitationEvent,
    logEvent,
    editOrderFavoriteStatus,
    joinRequestSentEvent,
    logAddTaskDescriptionEvent,
    mainMenuClickEvent,
    trackAssistanRichardInvocationEvent,
    trackEditableFileUploadEvent,
    chatHistoryActionClickEvent,
    aiChatPromptClickEvent,
    accessToTaskEvent,
    agentSupportSendMessageEvent,
    wsConnectionLimitedWarningShown,
    addRequestWithObjectSlaEvent,
    addAssetEvent,
    editAssetEvent,
    deleteAssetEvent,
    clickSupportSectionEvent,
    discussionCreatedEvent,
    requestClosedEvent,
    channelsAssistantCreatedEvent,
    channelsAssistantAddDocumentsEvent
  };
};

export default useAmplitude;
