import React from 'react';
import { useTranslation } from 'react-i18next';

import { GoogleDriveNotConnectedIcon } from 'components/common/icons';
import Typography from 'components/common/typography';
// eslint-disable-next-line import/no-cycle
import { FormUpload } from 'components/common/hook-form/upload';

import { ASSISTANT_ATTACHMENTS } from '../../../utils';

import styles from '../AddAttachmentsModal/AddAttachmentsModal.module.scss';

const { Text } = Typography;

const MAX_SIZE_FILE = 50 * 1024 * 1024; // 50mb
const ACCEPT_FORMATS = '.docx, .doc, .pdf, .csv, .txt';

export const AttachmentUpload = () => {
  const { t } = useTranslation('ChannelsAssistant');

  return (
    <div>
      <Text className={styles.description}>{t('AssistantFilesStepDesc')}</Text>

      <FormUpload
        hasWorkspaceGoogleDriveSupport
        name={ASSISTANT_ATTACHMENTS}
        rules={{
          required: t('AssistantAtLeastOneFile')
        }}
        multiple
        itemProps={{
          className: styles.upload
        }}
        icon={GoogleDriveNotConnectedIcon}
        accept={ACCEPT_FORMATS}
        attachmentProps={{
          hideValidityDateAction: true
        }}
        maxSizeFile={MAX_SIZE_FILE}
      />
    </div>
  );
};
