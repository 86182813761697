import { useContext } from 'react';

// eslint-disable-next-line import/no-cycle
import { UpserviceWidgetContext } from '.';

export const useUpserviceWidgetContext = () => {
  const values = useContext(UpserviceWidgetContext);

  return values;
};

export default useUpserviceWidgetContext;
