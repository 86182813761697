import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import Modal from 'components/common/modal';
import Button from 'components/common/button';
import Steps from 'components/common/steps';
// eslint-disable-next-line import/no-cycle
import {
  FormEmployeeSelect,
  FormInput,
  validateRequired
} from 'components/common/hook-form';
import Typography from 'components/common/typography';

import { createChannelsAssistant } from 'store/channels-assistant';

import { useAmplitude } from 'hooks/amplitude/use-amplitude';
import { getFileIdsV2 } from 'hooks/common/use-file-upload/get-file-ids';

import {
  ASSISTANT_ATTACHMENTS,
  ASSISTANT_NAME,
  ASSISTANT_RESPONSIBLE,
  STEPS
} from '../../utils';
import { AttachmentUpload } from '../AssistantEdit/AttachmentUpload';

import styles from './AssistantStepsModal.module.scss';

const { Text } = Typography;

export const AssistantStepsModal = ({ open, onClose, onSuccessSubmit }) => {
  const { t } = useTranslation(['ChannelsAssistant', 'Common']);

  const dispatch = useDispatch();

  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [validSteps, setValidSteps] = useState({ 0: true, 1: true, 2: true });

  const amplitude = useAmplitude();

  const isStepValid = useCallback(
    stepIndex => validSteps[stepIndex],
    [validSteps]
  );

  const methods = useForm({
    defaultValues: {
      [ASSISTANT_NAME]: t('AssistantNameInitial'),
      [ASSISTANT_ATTACHMENTS]: []
    }
  });

  const isAssistantNameStep = STEPS[step].key === ASSISTANT_NAME;
  const isAssistantResponsibleStep = STEPS[step].key === ASSISTANT_RESPONSIBLE;
  const isAssistantAttachmentsStep = STEPS[step].key === ASSISTANT_ATTACHMENTS;

  const assistantResponsibleWatch = methods.watch(ASSISTANT_RESPONSIBLE);
  const assistantAttachmentsWatch = methods.watch(ASSISTANT_ATTACHMENTS);

  const nextStep = () => {
    setStep(prev => prev + 1);
  };

  const prevStep = async () => {
    setStep(prev => prev - 1);
    setValidSteps({ 0: true, 1: true, 2: true });
  };

  const handleValidation = (stepKey, isValid) => {
    setValidSteps(prev => ({ ...prev, [stepKey]: isValid }));
  };

  useEffect(() => {
    methods.trigger().then(isValid => handleValidation(1, isValid));
  }, [assistantResponsibleWatch]);

  useEffect(() => {
    methods.trigger().then(isValid => handleValidation(2, isValid));
  }, [assistantAttachmentsWatch]);

  const handleAfterCloseModal = () => {
    methods.reset();
    setStep(0);
    setValidSteps({ 0: true, 1: true, 2: true });
  };

  const onSubmit = methods.handleSubmit(async values => {
    try {
      setIsLoading(true);

      await dispatch(
        createChannelsAssistant({
          payload: {
            name: values[ASSISTANT_NAME],
            responsible: values[ASSISTANT_RESPONSIBLE].value,
            fileList: getFileIdsV2(values[ASSISTANT_ATTACHMENTS])
          }
        })
      );

      amplitude.channelsAssistantCreatedEvent();

      onClose();

      await onSuccessSubmit();
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <Modal
      width={660}
      title={t('AddAssistant')}
      open={open}
      centered
      destroyOnClose
      onClose={onClose}
      contentClassName={styles.root}
      afterClose={handleAfterCloseModal}
    >
      <div>
        <FormProvider {...methods}>
          <form className={styles.form}>
            <Steps current={step} steps={STEPS} className={styles.steps} />

            <div className={styles.stepsContent}>
              {isAssistantNameStep && (
                <>
                  <Text className={styles.description}>
                    {t('AssistantNameStepDesc')}
                  </Text>

                  <FormInput
                    name={ASSISTANT_NAME}
                    label={t('AssistantNameLabel')}
                    itemProps={{
                      className: styles.field
                    }}
                    rules={{
                      required: validateRequired()
                    }}
                    onChange={() =>
                      methods
                        .trigger()
                        .then(isValid => handleValidation(0, isValid))
                    }
                  />
                </>
              )}

              {isAssistantResponsibleStep && (
                <>
                  <Text className={styles.description}>
                    {t('AssistantResponsibleStepDesc')}
                  </Text>

                  <FormEmployeeSelect
                    name={ASSISTANT_RESPONSIBLE}
                    label={t('AssistantResponsibleLabel')}
                    rules={{
                      validate: {
                        required: value => {
                          if (!value?.value) return validateRequired();
                          return true;
                        }
                      }
                    }}
                    params={{
                      excludeBench: true
                    }}
                    itemProps={{
                      className: styles.field
                    }}
                  />
                </>
              )}

              {isAssistantAttachmentsStep && <AttachmentUpload />}
            </div>

            <div className={styles.stepsActions}>
              {step > 0 && (
                <Button
                  type="link"
                  onClick={() => prevStep()}
                  disabled={isLoading}
                >
                  {t('Back')}
                </Button>
              )}

              {step < STEPS.length - 1 && (
                <Button
                  type="primary"
                  className={styles.rightButton}
                  width="expanded"
                  onClick={() => {
                    methods.trigger().then(isValid => {
                      if (isAssistantNameStep) handleValidation(0, isValid);
                      if (isAssistantResponsibleStep)
                        handleValidation(1, isValid);
                      if (isValid) nextStep();
                    });
                  }}
                  disabled={
                    (!isStepValid(0) && isAssistantNameStep) ||
                    (!isStepValid(1) && isAssistantResponsibleStep)
                  }
                >
                  {t('Next')}
                </Button>
              )}

              {step === STEPS.length - 1 && (
                <Button
                  onClick={() => {
                    methods.trigger().then(isValid => {
                      if (isAssistantAttachmentsStep)
                        handleValidation(2, isValid);
                      if (isValid) onSubmit();
                    });
                  }}
                  type="primary"
                  width="expanded"
                  className={styles.rightButton}
                  loading={isLoading}
                  disabled={
                    (!isStepValid(2) && isAssistantAttachmentsStep) || isLoading
                  }
                >
                  {t('DoneBtn', { ns: 'Common' })}
                </Button>
              )}
            </div>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};
