import { useEffect } from 'react';

import { useMountedState } from 'hooks';

import { useExecutorSlaFetch } from './useExecutorSlaFetch';
import { TaskInfo } from '../entities/TaskInfo';

export const useSlaAndChannelLogic = ({ values }) => {
  const [executorSla, setExecutorSla] = useMountedState(null);
  const getExecutorSla = useExecutorSlaFetch();

  const slaObjectId =
    values?.slaObject &&
    (values.slaObject?.item?.label?.id || values.slaObject?.label?.id);

  const slaObjectType = values?.slaObject?.type;

  useEffect(() => {
    const fetchSla = async () => {
      if (slaObjectId && slaObjectType) {
        const objectExecutorSla = await getExecutorSla(
          slaObjectType,
          slaObjectId
        );

        const taskInfoData = new TaskInfo(
          TaskInfo.extractFormData(
            values,
            objectExecutorSla
          )
        );

        setExecutorSla(taskInfoData?.executorSla || null);
      } else {
        setExecutorSla(null);
      }
    };

    fetchSla();
  }, [slaObjectId]);

  return { executorSla };
};
