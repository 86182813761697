import React from 'react';
import { Translation } from 'react-i18next';

import {
  COMMENT,
  EMAIL,
  ONLINE_CHAT,
  TELEGRAM,
  WEBSITE,
  WHATSAPP
} from 'constants/index';

import {
  TelegramIcon,
  WebsiteIcon,
  EmailChannelIcon,
  OnlineChatIcon,
  WhatsAppChannelIcon
} from 'components/common/icons';

const DATA_BY_KIND = {
  [ONLINE_CHAT]: {
    iconComponent: OnlineChatIcon,
    text: (
      <Translation ns="Contacts">{t => t('UpserviceChatChannel')}</Translation>
    ),
    index: 1
  },
  [TELEGRAM]: {
    iconComponent: TelegramIcon,
    text: <Translation ns="Contacts">{t => t('TelegramChannel')}</Translation>,
    index: 2
  },
  [WEBSITE]: {
    iconComponent: WebsiteIcon,
    text: (
      <Translation ns="Contacts">{t => t('WebsiteChatChannel')}</Translation>
    ),
    index: 3
  },
  [EMAIL]: {
    iconComponent: EmailChannelIcon,
    text: <Translation ns="Contacts">{t => t('EmailChannel')}</Translation>,
    index: 4
  },
  [WHATSAPP]: {
    iconComponent: WhatsAppChannelIcon,
    text: <Translation ns="Contacts">{t => t('WhatsAppChannel')}</Translation>,
    index: 5
  },
  [COMMENT]: {
    iconType: 'eye-invisible',
    text: <Translation ns="Contacts">{t => t('MyCommentChannel')}</Translation>,
    index: 6
  }
};

export const getIsCustomKindByValue = value => [COMMENT].includes(value);

export const getToChatOptionByRoomUuid = ({ roomUuid, chats, customKind }) => {
  let chat = {};

  if (customKind) {
    chat = chats.find(c => c.channelKind === ONLINE_CHAT);
  } else {
    chat = chats.find(
      c => c.uuid === roomUuid && (c.isPaid || c.isPaid === null)
    );
  }

  return convertChatToOption(chat, chats, customKind);
};

// eslint-disable-next-line default-param-last
export const convertChatToOption = (chat = {}, chats, customKind) => {
  const hasSameChannelKind =
    chats.filter(c => c.channelKind === chat.channelKind).length > 1;

  const getChatsCountByChannelKind = kind =>
    chats.filter(({ channelKind }) => channelKind === kind).length;

  const data = DATA_BY_KIND[customKind || chat.channelKind];

  const renderText = () => {
    if (!data) return '';

    if (chat.channelKind === EMAIL && getChatsCountByChannelKind(EMAIL) > 1) {
      return (
        <Translation ns="Contacts">
          {t =>
            t('EmailFromToWeb', { from: chat.channelSource, to: chat.email })
          }
        </Translation>
      );
    }

    if (
      chat.channelKind === WHATSAPP &&
      getChatsCountByChannelKind(WHATSAPP) > 1
    ) {
      return (
        <Translation ns="Contacts">
          {t =>
            t('WhatsAppFromToWeb', {
              from: chat.channelSource,
              to: chat.source
            })
          }
        </Translation>
      );
    }

    if (hasSameChannelKind && !customKind) {
      return (
        <>
          {data.text}
          {chat.source ? ` (${chat.source})` : ''}
        </>
      );
    }

    return data.text;
  };

  return {
    label: {
      ...data,
      channelUuid: chat.channelUuid,
      channelKind: chat.channelKind,
      text: renderText()
    },
    value: customKind || chat.uuid
  };
};

export const filterOptionsByKind = (options, kind) =>
  options.filter(item => item.label.channelKind === kind);

export const isMultipleChatsOfType = options => options.length > 1;

export const isMultipleKeysWithPrefix = (contact, keys, prefix) => {
  const filteredKeys = keys.filter(
    key => key.startsWith(prefix) && contact[key]
  );

  return filteredKeys.length > 1;
};
