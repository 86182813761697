import { combineActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';

import { CONTACTS } from 'constants/api';
import {
  CHANNEL_CHAT_CREATED,
  CHANNEL_CHAT_REOPENED,
  CHATS,
  CONTACT_ACTIVE_STATUSES,
  CONTACT_STATUS_ARCHIVED,
  CONTACT_STATUS_UNASSEMLED,
  CONTAINS_ANY_OPTION,
  CONTRACTOR_FILTER_OPTIONS
} from 'constants/index';

// eslint-disable-next-line import/no-cycle
import { COLLAPSED_CONTACT_PANEL } from 'components/contacts-view/views/chats/contact-panel/use-collapsed-contact-panel';
import { COLLAPSED_CONTACTS_FILTER_PANEL } from 'components/contacts-view/views/use-collapsed-contacts-filter-panel';

import { setActiveId } from 'store/workspace';
import { chatMessageNew } from 'store/operator';

import handleActions from 'utils/redux-actions';
import { getInitialValueFilterStorage } from 'hooks/common/use-filter-storage';
import getUrlSearchParams from 'utils/get-url-search-params';
import { getInitialValueStorage } from 'hooks';

import {
  fetchContacts,
  createEmailChat,
  restoreContact,
  createContact,
  setContactsFilterSearch,
  setContactsFilterResponsible,
  setContactsFilterStatus,
  setContactsFilterCreatedDateRange,
  clearFilter,
  fetchOneContact,
  editContact,
  archiveContact,
  fetchContactHistory,
  sendContactComment,
  setContactsFilterTag,
  setContactsFilterRelations,
  clearEntries,
  setCollapsedContactPanel,
  addContactToEntries,
  fetchMergedContact,
  setContactsFilterChannel,
  setContactsFilterChannelKind,
  setContactsFilterContactCompanyResponsible,
  setContactsFilterIsIndividual,
  setContactsFilterDefer,
  setCollapsedContactsFilterPanel,
  setContactsIsLoading,
  fetchContactByChat,
  toggleNotify,
  fetchStatusDuplicateContacts,
  editContactByRoomUuid,
  createDefer,
  removeDeferral,
  createWhatsAppChat,
  setContactsFilterFunnelKind,
  setContactsFilterFunnelStatus,
  setContactsFilterSignatory,
  setContactsFilterPosition
} from './actions';

export const initialFilter = {
  responsible: [],
  status: CONTACT_ACTIVE_STATUSES,
  channel: [],
  channelKind: [],
  contactCompanyResponsible: [],
  isIndividual: CONTRACTOR_FILTER_OPTIONS[0],
  createdDateRange: {},
  tag: {
    ids: [],
    condition: CONTAINS_ANY_OPTION
  },
  relations: {
    task: [],
    request: [],
    orderStatus: [],
    asset: [],
    contact: []
  },
  channelChat: [],
  search: '',
  isDeferred: false,
  funnelKind: [],
  funnelStatus: [],
  funnelSignatoryIds: [],
  position: []
};

const initialState = {
  isLoading: false,
  isLoaded: false,

  filter: {
    ...initialFilter,
    ...getInitialValueFilterStorage(CONTACTS, initialFilter)
  },

  pageData: {
    totalItems: 0,
    hasMore: false,
    offset: 0,
    itemsPerPage: 10
  },

  collapsedContactPanel: getInitialValueStorage(COLLAPSED_CONTACT_PANEL, true),
  collapsedFilterPanel: getInitialValueStorage(
    COLLAPSED_CONTACTS_FILTER_PANEL,
    {
      opened: true,
      width: 380
    }
  ),

  entries: [],

  statusDuplicate: {}
};

export default handleActions(
  {
    [LOCATION_CHANGE]: (state, { payload }) => {
      const { pathname } = payload.location;
      const urls = pathname.split('/').slice(1);
      const [, , subview] = urls;

      if (subview !== CHATS || payload.isFirstRendering) {
        state.filter.search = '';
        state.filter.channelChat = [];
      }

      return state;
    },

    [setActiveId]: () => initialState,

    [combineActions(
      setContactsFilterResponsible,
      setContactsFilterStatus,
      setContactsFilterChannel,
      setContactsFilterChannelKind,
      setContactsFilterContactCompanyResponsible,
      setContactsFilterIsIndividual,
      setContactsFilterSearch,
      setContactsFilterCreatedDateRange,
      setContactsFilterTag,
      setContactsFilterRelations,
      setContactsFilterDefer,
      setContactsFilterFunnelStatus,
      setContactsFilterFunnelKind,
      setContactsFilterPosition,
      clearEntries
    )]: state => {
      state.pageData.offset = 0;
      state.entries = [];

      return state;
    },

    [setContactsFilterResponsible]: (state, { payload }) => {
      state.filter.responsible = payload;

      return state;
    },

    [setContactsFilterStatus]: (state, { payload }) => {
      state.filter.status = payload;

      return state;
    },

    [setContactsFilterChannel]: (state, { payload }) => {
      state.filter.channel = payload;

      return state;
    },

    [setContactsFilterChannelKind]: (state, { payload }) => {
      state.filter.channelKind = payload;

      return state;
    },

    [setContactsFilterContactCompanyResponsible]: (state, { payload }) => {
      state.filter.contactCompanyResponsible = payload;

      return state;
    },

    [setContactsFilterIsIndividual]: (state, { payload }) => {
      state.filter.isIndividual = payload;

      return state;
    },

    [setCollapsedContactPanel]: (state, { payload }) => {
      state.collapsedContactPanel = payload;

      return state;
    },

    [setCollapsedContactsFilterPanel]: (state, { payload }) => {
      state.collapsedFilterPanel = {
        ...state.collapsedFilterPanel,
        opened: payload.opened
      };

      return state;
    },

    [setContactsFilterSearch]: (state, { payload }) => {
      state.filter.search = payload.search;

      return state;
    },

    [setContactsFilterRelations]: (state, { payload }) => {
      state.filter.relations[payload.key] = payload.value;

      return state;
    },

    [setContactsFilterCreatedDateRange]: (state, { payload }) => {
      state.filter.createdDateRange = payload;

      return state;
    },

    [setContactsFilterTag]: (state, { payload }) => {
      state.filter.tag = payload;

      return state;
    },

    [setContactsFilterDefer]: (state, { payload }) => {
      state.filter.isDeferred = payload;

      return state;
    },

    [setContactsFilterFunnelKind]: (state, { payload }) => {
      state.filter.funnelKind = payload;

      return state;
    },

    [setContactsFilterFunnelStatus]: (state, { payload }) => {
      state.filter.funnelStatus = payload;

      return state;
    },

    [setContactsFilterSignatory]: (state, { payload }) => {
      state.filter.funnelSignatoryIds = payload;

      return state;
    },

    [setContactsFilterPosition]: (state, { payload }) => {
      state.filter.position = payload;

      return state;
    },

    [clearFilter]: state => {
      state.filter = initialFilter;

      return state;
    },

    [addContactToEntries]: (state, { payload }) => {
      state.entries = [payload, ...state.entries];

      return state;
    },

    [setContactsIsLoading]: (state, { payload }) => {
      state.isLoading = payload;

      return state;
    },

    [editContactByRoomUuid]: (state, { payload }) => {
      const { data, roomUuid } = payload;

      const index = state.entries.findIndex(item =>
        item.chats.find(c => c.uuid === roomUuid)
      );

      if (index !== -1) {
        Object.keys(data).forEach(key => {
          state.entries[index][key] = data[key];
        });
      }

      return state;
    },

    [fetchContacts.START]: state => {
      state.isLoading = true;
      state.isLoaded = false;

      return state;
    },

    [fetchContacts.ENDED]: state => {
      state.isLoading = false;

      return state;
    },

    [fetchContacts.FAILED]: (state, { payload }) => {
      state.error = payload;

      return state;
    },

    [fetchContacts.SUCCEEDED]: (state, { payload }) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.pageData.totalItems = payload.count;
      state.pageData.hasMore = !!payload.next;
      state.pageData.offset = payload.next
        ? getUrlSearchParams(payload.next).offset
        : 0;

      const results = payload.results.filter(
        contact => !state.entries.map(c => c.id).includes(contact.id)
      );

      state.entries = [...state.entries, ...results];

      return state;
    },

    [combineActions(fetchOneContact.SUCCEEDED, fetchContactByChat.SUCCEEDED)]: (
      state,
      { payload }
    ) => {
      const index = state.entries.findIndex(item => item.id === payload.id);

      if (index !== -1) {
        Object.keys(payload).forEach(key => {
          state.entries[index][key] = payload[key];
        });
        state.entries[index].isFromChatCreated = false;
      } else {
        state.totalItems += 1;
        state.entries = [payload, state.entries];
      }

      state.isLoaded = true;
      return state;
    },

    [createContact.SUCCEEDED]: (state, { payload }) => {
      state.entries = [{ ...payload, isRaised: true }, ...state.entries];

      return state;
    },

    [combineActions(editContact.SUCCEEDED, createDefer.SUCCEEDED)]: (
      state,
      { payload }
    ) => {
      const index = state.entries.findIndex(
        contact => contact.id === payload.id
      );

      if (index !== -1) {
        state.entries[index] = {
          ...state.entries[index],
          ...payload
        };
      }

      return state;
    },

    [archiveContact.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        contact => contact.id === payload.id
      );

      if (index !== -1) {
        state.entries[index].status = CONTACT_STATUS_ARCHIVED;
        state.entries[index].isDeferred = false;
      }

      return state;
    },

    [removeDeferral.SUCCEEDED]: (state, { args }) => {
      const index = state.entries.findIndex(
        contact => contact.id === args.contactId
      );

      if (index !== -1) {
        state.entries[index] = {
          ...state.entries[index],
          isDeferred: false,
          deferUntil: null
        };
      }

      return state;
    },

    [restoreContact.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(c => c.id === payload.id);

      if (index !== -1) {
        state.entries[index] = payload;
      }

      return state;
    },

    [fetchContactHistory.SUCCEEDED]: (state, { payload, args }) => {
      const index = state.entries.findIndex(
        contact => contact.id === +(args || {}).id
      );

      if (index === -1) {
        return state;
      }

      state.entries[index].history = payload.results.reverse();

      return state;
    },

    [sendContactComment.SUCCEEDED]: (state, { payload, args }) => {
      const index = state.entries.findIndex(
        contact => contact.id === +(args || {}).id
      );

      if (index === -1) {
        return state;
      }

      state.entries[index].history = [
        ...(state.entries[index].history || []),
        payload
      ];

      return state;
    },

    [combineActions(createEmailChat.SUCCEEDED, createWhatsAppChat.SUCCEEDED)]: (
      state,
      { payload }
    ) => {
      const index = state.entries.findIndex(c => c.id === payload.id);

      if (index !== -1) {
        state.entries[index] = payload;
      }

      return state;
    },

    [fetchMergedContact.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        contact => contact.id === payload.initialContact
      );

      if (index !== -1) {
        state.entries[index] = payload.newContact;
      }

      state.entries = state.entries.filter(
        (contact, idx) =>
          (contact.id !== payload.toContact &&
            contact.id !== payload.fromContact) ||
          index === idx
      );

      return state;
    },

    [toggleNotify.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(c => c.id === payload.id);

      if (index !== -1) {
        state.entries[index].notify = payload.notify;
      }

      return state;
    },

    // ENTITY CHECK
    [chatMessageNew]: (state, { payload }) => {
      const { contact = {} } = payload;

      if (!contact.id) {
        return state;
      }

      const index = state.entries.findIndex(c => c.id === contact.id);

      if (index !== -1) {
        state.entries = [
          contact,
          ...state.entries.filter(c => c.id !== contact.id)
        ];
      } else {
        state.entries = [{ ...contact, isRaised: true }, ...state.entries];
      }

      return state;
    },

    [CHANNEL_CHAT_REOPENED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        contact => contact.id === payload.contactId
      );

      if (index !== -1) {
        state.entries[index] = {
          ...state.entries[index],
          status: CONTACT_STATUS_UNASSEMLED,
          responsible: null
        };
      }

      return state;
    },

    [CHANNEL_CHAT_CREATED]: (state, { payload, store }) => {
      const index = state.entries.findIndex(
        contact => contact.id === payload.contactId
      );

      if (index === -1 && store.routerUrl.view === CONTACTS) {
        state.entries = [
          {
            id: payload.contactId,
            status: CONTACT_STATUS_UNASSEMLED,
            firstName: payload.firstName,
            responsible: null,
            chats: [
              {
                uuid: payload.uuid
              }
            ],
            isFromChatCreated: true,
            permissions: {}
          },
          ...state.entries
        ];
      }

      return state;
    },

    [fetchStatusDuplicateContacts.SUCCEEDED]: (state, { payload }) => {
      state.statusDuplicate = payload;

      return state;
    }
  },
  initialState
);
