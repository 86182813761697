import React from 'react';

import { ORDER_STATUS_STATES } from 'constants/index';

import CustomSelect from '../custom-select';

const getOrderStatuses = () =>
  Array.from(ORDER_STATUS_STATES.entries()).map(([id, value]) => ({
    label: value.label,
    ns: value.ns,
    value: id
  }));

export const OrderStatusSelect = props => {
  const { value } = props;

  return <CustomSelect value={value} options={getOrderStatuses()} {...props} />;
};
