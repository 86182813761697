import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { STATUS_CANCELLED, STATUS_DONE } from 'constants/index';

import ActionsDropdown from 'components/common/actions';
import ModalDeleteConfirm from 'components/common/modal-delete-confirm';
import { DeleteTask } from 'components/common/icons';
// eslint-disable-next-line import/no-cycle
import TaskEditorDrawer from 'components/tasks-view/view/drawers/editor';

import { deleteTaskLocal } from 'store/tasks';

import { showNoticeMessage } from 'services/notice';

const Actions = ({ parentTask, deleteRecord, updateTaskAndRecord }) => {
  const dispatch = useDispatch();

  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [isLoadingDeletingTask, setIsLoadingDeletingTask] = useState(false);
  const [visibleEditorDrawer, setVisibleEditorDrawer] = useState(false);

  const { t } = useTranslation(['DeleteTask', 'Toast']);

  const { permissions, workLogTotal, status, id } = parentTask;

  const isStatusCancelled = status === STATUS_CANCELLED;
  const isStatusDone = status === STATUS_DONE;

  const allowEdit =
    permissions.updateTask && !isStatusCancelled && !isStatusDone;
  const allowDelete =
    permissions.deleteTask && !workLogTotal && !isStatusCancelled;

  const actions = [
    {
      title: 'EditAction',
      ns: 'MeetingPreviewCalendar',
      onClick: () => setVisibleEditorDrawer(true),
      allow: allowEdit
    },
    {
      title: 'DeleteAction',
      ns: 'MeetingPreviewCalendar',
      onClick: () => setVisibleDeleteModal(true),
      allow: allowDelete
    }
  ];

  const onDelete = async () => {
    try {
      setIsLoadingDeletingTask(true);

      await dispatch(
        deleteTaskLocal({
          id
        })
      );

      await deleteRecord();

      showNoticeMessage({ customContent: t('TaskDeleted', { ns: 'Toast' }) });
    } finally {
      setIsLoadingDeletingTask(false);
    }
  };

  return (
    <>
      <ActionsDropdown actions={actions} />

      <ModalDeleteConfirm
        open={visibleDeleteModal}
        isLoading={isLoadingDeletingTask}
        title={t('DeleteTaskHeading', { ns: 'DeleteTask' })}
        description={t('DeleteTaskDesc', { ns: 'DeleteTask' })}
        deleteBtnText={t('DeleteBtn', { ns: 'DeleteTask' })}
        cancelBtnText={t('CancelBtn', { ns: 'DeleteTask' })}
        data-qa="qa-003694170708539"
        onConfirm={onDelete}
        iconModal={DeleteTask}
        onClose={() => setVisibleDeleteModal(false)}
      />

      <TaskEditorDrawer
        visible={visibleEditorDrawer}
        taskId={id}
        callback={() => updateTaskAndRecord()}
        onClose={() => setVisibleEditorDrawer(false)}
      />
    </>
  );
};

export default Actions;
