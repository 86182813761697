import React from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal';
import Typography from 'components/common/typography';
// eslint-disable-next-line import/no-cycle
import { FormDatePicker, withoutBubbling } from 'components/common/hook-form';
import Button from 'components/common/button';

import useMinMaxTime from 'hooks/common/use-min-max-time';

import styles from './access-settings.module.scss';

const Form = ({ defaultValues, onSubmit }) => {
  const methods = useForm({
    defaultValues
  });

  const { t } = useTranslation(['LimitAccessTime', 'Errors']);

  const toFieldWatch = useWatch({
    name: 'to',
    control: methods.control
  });

  const [minTimeStart, maxTimeStart] = useMinMaxTime({
    startDate: toFieldWatch || new Date()
  });

  return (
    <FormProvider {...methods}>
      <form
        className={styles.modalForm}
        onSubmit={event =>
          withoutBubbling(event, () => methods.handleSubmit(onSubmit))
        }
      >
        <FormDatePicker
          name="to"
          label={t('CloseAccess')}
          minDate={new Date()}
          minTime={minTimeStart}
          maxTime={maxTimeStart}
          rules={{
            required: t('RequiredFeild', { ns: 'Errors' })
          }}
          wrapperClassname={styles.modalDatePicker}
        />

        <Button
          htmlType="submit"
          type="primary"
          width="expanded"
          className={styles.modalSubmit}
        >
          {t('SaveBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

const ChangeDateModal = ({ visible, defaultValues, onClose, onSubmit }) => {
  const { t } = useTranslation('LimitAccessTime');

  return (
    <Modal
      width={508}
      title={t('LimitAccessTimeHeading')}
      open={visible}
      contentClassName={styles.modal}
      onClose={onClose}
    >
      <Typography.Paragraph>{t('LimitAccessTimeDesc')}</Typography.Paragraph>

      <Form defaultValues={defaultValues} onSubmit={onSubmit} />
    </Modal>
  );
};

export default ChangeDateModal;
