import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-cycle
import FormNewEditor from 'components/common/hook-form/markdown';
import Button from 'components/common/button';
import { FormInput } from 'components/common/hook-form';

import styles from './components.module.scss';

const MessageTemplatesForm = ({
  template,
  submitBtnText,
  onSubmit,
  isLoading
}) => {
  const { t } = useTranslation(['AnswerTemplates', 'Errors']);

  const methods = useForm({
    defaultValues: {
      title: template.title,
      text: {
        description: (template && template.text) || '',
        fileList: []
      }
    }
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className={styles.form}>
        <Alert
          type="warning"
          message={t('AddNewAnswerTemplateWarning')}
          className={styles.alert}
        />

        <FormInput
          label={t('Name')}
          name="title"
          maxLength={256}
          rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
        />

        <FormNewEditor
          name="text"
          label={t('Message')}
          placeholder={t('EnterMessage')}
          showItems={{
            upload: false,
            emoji: true,
            mention: true,
            topToolbar: true
          }}
          rules={{
            validate: value => {
              if (!value.description.trim()) {
                return t('RequiredField', { ns: 'Errors' });
              }

              return true;
            }
          }}
        />

        <Button
          htmlType="submit"
          type="primary"
          width="expanded"
          size="large"
          className={styles.btnSubmit}
          loading={isLoading}
        >
          {submitBtnText}
        </Button>
      </form>
    </FormProvider>
  );
};

MessageTemplatesForm.propTypes = {
  template: PropTypes.object,
  submitBtnText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool
};

MessageTemplatesForm.defaultProps = {
  template: {},
  onSubmit: () => {},
  isLoading: false
};

export default MessageTemplatesForm;
