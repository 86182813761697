import { createSelector } from 'reselect';

const getChannelsAssistant = state => state.channelsAssistant;

export const getCreatedAssistant = createSelector(
  getChannelsAssistant,
  channelsAssistant => channelsAssistant.createdAssistant
);

export const getIsLoading = createSelector(
  getChannelsAssistant,
  channelsAssistant => channelsAssistant.isLoading
);

export const getIsLoadingAttachments = createSelector(
  getChannelsAssistant,
  channelsAssistant => channelsAssistant.isLoadingAttachments
);

export const getIsAssistantCreated = createSelector(
  getChannelsAssistant,
  channelsAssistant =>
    Object.keys(channelsAssistant.createdAssistant).length > 0
);

export const getAssistantAttachments = createSelector(
  getChannelsAssistant,
  channelsAssistant => channelsAssistant.attachments
);
