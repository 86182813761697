import React, { useEffect, useMemo, useState } from 'react';
import { Tabs, Empty, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-cycle
import Attachments from 'components/common/attachments';
import Drawer from 'components/common/drawer';
import Icon from 'components/common/icon';
import { DocsNotFoundIcon } from 'components/common/icons';
import { updateAttachmentSubscription } from 'components/common/subscriptions/utils/update-attachment-subscription';
import { updateAttachmentSubscribers } from 'components/common/subscriptions/utils/update-attachment-subscribers';
import { updateAttachmentValidityDate } from 'components/common/validity-date/utils/update-attachment-validity-date';

import { fetchAttachments } from 'store/tasks';

import { getIsFileOnOfType } from 'utils/get-file-by-type';
import {
  PDF_TYPE,
  EDITABLE_FILE_TYPES,
  MEDIA_FILE_TYPES,
  LINK_TYPE
} from 'hooks/common/use-file-upload/types';

import styles from './upload-drawers.module.scss';

const filterdMediaTypes = MEDIA_FILE_TYPES.filter(t => t !== PDF_TYPE);

export const UploadsDrawer = ({
  allowDelete,
  object,
  visible,
  ...drawerProps
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [attachments, setAttachments] = useState([]);
  const [afterChangeVisible, setAfterChangeVisible] = useState(false);

  const { t } = useTranslation(['TaskAttachments', 'Common']);

  const tabs = useMemo(
    () => [
      {
        id: 'media',
        label: t('AttachmentsMediaTab'),
        getFilteredFiles: files =>
          (files || []).filter(file =>
            getIsFileOnOfType(file, filterdMediaTypes)
          )
      },
      {
        id: 'documents',
        label: t('AttachmentsDocsTab'),
        getFilteredFiles: files =>
          (files || []).filter(file =>
            getIsFileOnOfType(file, [...EDITABLE_FILE_TYPES, PDF_TYPE])
          )
      },
      {
        id: 'links',
        label: t('AttachmentsLinksTab'),
        getFilteredFiles: files =>
          (files || []).filter(file => getIsFileOnOfType(file, [LINK_TYPE]))
      }
    ],
    [t]
  );

  const fetchAllAttachments = async () => {
    try {
      setIsLoading(true);
      const results = await dispatch(fetchAttachments({ id: object.id }));
      setAttachments(results);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubscription = ({ data, isSubscribed }) =>
    updateAttachmentSubscription({
      attachments,
      setAttachments,
      data,
      isSubscribed
    });

  const handleAfterChangeManageSubscription = ({
    added,
    deleted,
    userId,
    entityId
  }) => {
    updateAttachmentSubscribers({
      attachments,
      setAttachments,
      data: { added, deleted, entityId, userId }
    });
  };

  const handleAfterChangeValidityDateCallback = ({ attachment }) => {
    updateAttachmentValidityDate({
      attachments,
      setAttachments,
      data: attachment
    });
  };

  useEffect(() => {
    if (object && afterChangeVisible) {
      fetchAllAttachments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afterChangeVisible]);

  const tabItems = useMemo(
    () =>
      tabs.map(tab => ({
        key: tab.id,
        label: tab.label,
        children: (
          <div className={styles.tab} data-qa="qa-oeg2gw4b0ud8cgd">
            {tab.getFilteredFiles(attachments).length > 0 ? (
              <Attachments
                fileList={tab.getFilteredFiles(attachments)}
                showDownloadAllButton
                subscribeCallback={handleSubscription}
                unsubscribeCallback={handleSubscription}
                changeManageSubscribersCallback={
                  handleAfterChangeManageSubscription
                }
                changeValidityDateCallback={
                  handleAfterChangeValidityDateCallback
                }
                actionsDeps={{
                  entityId: object.id,
                  entityType: object.kind
                }}
              />
            ) : (
              <Empty
                image={<Icon component={DocsNotFoundIcon} />}
                className={styles.image}
                description={t('NotFound', { ns: 'Common' })}
              />
            )}
          </div>
        )
      })),
    [attachments, object, tabs]
  );

  return (
    <Drawer
      title={
        <>
          <Drawer.Back onClick={drawerProps.onClose} />
          <Drawer.Title>{t('AttachmentsHeading')}</Drawer.Title>
        </>
      }
      data-qa="qa-93ouq80fvd4wd8c"
      closable={false}
      open={visible}
      afterOpenChange={setAfterChangeVisible}
      bodyStyle={{ padding: 0 }}
      {...drawerProps}
    >
      {!isLoading && (
        <Tabs
          defaultActiveKey={tabs[0].id}
          tabBarGutter={1}
          className={styles.tabs}
          data-qa="qa-g5vxpa8zyskmbyz"
          items={tabItems}
        />
      )}

      <Spin spinning={isLoading} />
    </Drawer>
  );
};

UploadsDrawer.propTypes = {
  allowDelete: PropTypes.bool,
  onClose: PropTypes.func,
  object: PropTypes.object
};

UploadsDrawer.defaultProps = {
  object: {},
  allowDelete: false,
  onClose: () => {}
};

export default UploadsDrawer;
