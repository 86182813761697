import i18n from 'i18n-config';

import { chatMessageNew } from 'store/operator';

import handleActions from '../../utils/redux-actions';
import {
  closeQueueNotification,
  acceptNotification,
  disableIsArrived,
  setDrawerVisible,
  setChannelChatNewMessagesPush,
  fetchActiveNotifications
} from './actions';
import {
  NEW_USER_NOTIFICATION,
  WORKSPACES,
  RECORD_REMINDER,
  NOTIFICATION,
  TYPE_DISCUSSION
} from '../../constants';
import { getInitialValueStorage } from '../../hooks';

const initialState = {
  queue: [],

  drawerVisible: false,

  isArrived: false,

  active: {
    isLoading: false,
    isLoaded: false,
    // byWorkspaces: { {[workspaceId]: {entries: [], totalItems: 0}} }
    byWorkspaces: {}
  }
};

export default handleActions(
  {
    [fetchActiveNotifications.START]: state => {
      state.active.isLoaded = false;
      state.active.isLoading = true;

      return state;
    },

    [fetchActiveNotifications.ENDED]: state => {
      state.active.isLoading = false;

      return state;
    },

    [fetchActiveNotifications.SUCCEEDED]: (state, { payload }) => {
      const { results, totalItems, workspaceId } = payload;

      if (!state.active.byWorkspaces[workspaceId]) {
        state.active.byWorkspaces[workspaceId] = {};
      }

      state.active.byWorkspaces[workspaceId].totalItems = totalItems;
      state.active.byWorkspaces[workspaceId].entries = results;

      state.active.isLoaded = true;

      return state;
    },

    [setChannelChatNewMessagesPush]: (state, { payload }) => {
      state.isArrived = true;
      const notification = {
        sender: null,
        action: null,
        content: [{ text: payload.text }],
        id: payload.id,
        templateUid: 130,
        info: {
          workspaceId: payload.workspaceId,
          contactId: payload.contactId
        }
      };

      state.queue = [notification, ...state.queue];

      return state;
    },

    [RECORD_REMINDER]: (state, { payload }) => {
      state.isArrived = true;

      const notification = {
        sender: null,
        action: null,
        content: [
          {
            text: i18n.t('UntilMeeting', {
              ns: 'NotificationsBell',
              title: payload.title
            })
          }
        ],
        id: payload.id,
        templateUid: 433,
        info: {
          workspaceId: payload.workspaceId,
          recordId: payload.id
        }
      };

      state.queue = [notification, ...state.queue];

      return state;
    },

    [acceptNotification.SUCCEEDED]: (state, { payload, args }) => {
      const { workspaceId } = payload;

      if (state.active.byWorkspaces[workspaceId]) {
        state.active.byWorkspaces[workspaceId].entries =
          state.active.byWorkspaces[workspaceId].entries.filter(
            ({ id }) => id !== args.id
          );

        state.active.byWorkspaces[workspaceId].totalItems -= 1;
      }

      return state;
    },

    [closeQueueNotification]: (state, { payload }) => {
      state.queue = state.queue.filter(item => item?.id !== payload.id);

      return state;
    },

    [NEW_USER_NOTIFICATION]: (state, { payload }) => {
      state.isArrived = true;

      const newNotification = {
        ...payload,
        sender: {
          ...payload.sender,
          avatar: (
            payload.sender.workspaceLogo ||
            payload.sender.avatarFile ||
            {}
          ).url
        }
      };

      const workspaces = getInitialValueStorage(WORKSPACES, {});

      const workspace =
        Object.values(workspaces).find(
          ({ user }) => user.id === newNotification.receiverId
        ) || {};

      const wid = workspace.id;

      if (newNotification.isPushSent) {
        state.queue = [newNotification, ...state.queue];
      }

      if (state.active.byWorkspaces[wid]) {
        const index = state.active.byWorkspaces[wid].entries.findIndex(
          ({ id }) => id === payload.id
        );

        if (index !== -1) {
          state.active.byWorkspaces[wid].entries[index] = newNotification;

          return state;
        }

        state.active.byWorkspaces[wid].entries = [
          newNotification,
          ...(state.active.byWorkspaces[wid].entries || [])
        ];
        state.active.byWorkspaces[wid].totalItems += 1;
      }

      return state;
    },

    [chatMessageNew]: (state, { payload }) => {
      const { message, isMyMessage } = payload;

      if (message.kind === NOTIFICATION) {
        if (!(isMyMessage && message.entityType === TYPE_DISCUSSION)) {
          state.isArrived = true;
        }

        if (message.notificationData.isPushSent) {
          const newNotification = {
            ...message,
            id: message.uuid,
            content: message.text,
            templateUid: message.notificationData.templateUid,
            info: message.notificationData
          };

          state.queue = [newNotification, ...state.queue];
        }
      }

      return state;
    },

    [disableIsArrived]: state => {
      state.isArrived = false;

      return state;
    },

    [setDrawerVisible]: (state, { payload }) => {
      state.drawerVisible = payload;

      return state;
    }
  },
  initialState
);
