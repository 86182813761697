import { useState, useEffect } from 'react';

import { MODAL, CREATE_PROJECT } from '../../constants';
// eslint-disable-next-line import/no-cycle
import { useQueryParam } from '../common';

export const useProjectCreator = (defaultState = false) => {
  const [visible, setVisible] = useState(defaultState);

  const modal = useQueryParam(MODAL);

  useEffect(() => {
    setVisible(!!(modal === CREATE_PROJECT));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  return [visible, setVisible];
};

export default useProjectCreator;
