import React from 'react';
import { useTranslation } from 'react-i18next';

import CustomCollapse from 'components/requests-view/view/drawers/components/collapse';
import Typography from 'components/common/typography';

import styles from './ExpensesAmountPanel.module.scss';

export const ExpensesAmountPanel = ({ price, currency }) => {
  const { t } = useTranslation('Requests');

  return (
    <CustomCollapse>
      <CustomCollapse.Panel
        key="expenses-amount"
        header={
          <Typography.Title level={3} className={styles.title}>
            {t('TotalExpenses')}
          </Typography.Title>
        }
      >
        <Typography.Text>
          {price} {currency}
        </Typography.Text>
      </CustomCollapse.Panel>
    </CustomCollapse>
  );
};
