import React from 'react';
import { Menu, Divider } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './filter-menu.module.scss';

export const FilterMenu = ({ menu, className, ...props }) => {
  const items = menu.map(({ type, ...item }) => {
    if (type === 'divider') {
      return {
        key: item.key,
        label: (
          <div className={classnames(styles.item, styles.divider)}>
            <Divider style={{ margin: 0, height: 26 }} type="vertical" />
          </div>
        ),
        onClick: item.onClick
      };
    }

    return {
      key: item.key,
      label: item.label,
      onClick: item.onClick,
      className: styles.item
    };
  });

  return (
    <Menu
      className={classnames(styles.root, className)}
      mode="horizontal"
      items={items}
      {...props}
    />
  );
};

FilterMenu.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      value: PropTypes.any,
      onClick: PropTypes.func
    })
  ).isRequired,
  className: PropTypes.string
};

FilterMenu.defaultProps = {
  className: undefined
};

export default FilterMenu;
