import React, { useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';

import Modal from 'components/common/modal';
import Typography from 'components/common/typography';
import Button from 'components/common/button';
import FormNewEditor from 'components/common/hook-form/markdown';
import {
  validateRequired,
  validateMaxLength
} from 'components/common/hook-form';

const RejectedForm = ({ isLoading, onSubmit }) => {
  const { t } = useTranslation(['ReasonModal', 'Task']);

  const { handleSubmit, ...methods } = useForm({
    defaultValues: {
      reason: {
        description: '',
        fileList: []
      }
    }
  });

  const submitForm = values => {
    onSubmit(values.reason);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submitForm)}>
        <FormNewEditor
          label={t('DeclineTaskReasonHeading')}
          data-qa="qa-sfabk809mmvg9dl"
          name="reason"
          placeholder={t('EnterText', { ns: 'Task' })}
          rules={{
            validate: {
              requiredDesc: value => {
                if (!value.description.trim()) return validateRequired();
                return true;
              },
              maxLength: value => {
                if (
                  value.description.trim() &&
                  value.description.length > 500
                ) {
                  return validateMaxLength(500).message;
                }
                return true;
              }
            }
          }}
          allowAttach={false}
          showItems={{
            upload: false,
            mention: false,
            emoji: false,
            topToolbar: true
          }}
        />

        <Button
          type="primary"
          size="large"
          htmlType="submit"
          loading={isLoading}
          style={{ width: '100%' }}
          data-qa="qa-q82zdnfon2kt3ox"
        >
          {t('SendBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

export const RejectedModal = ({ onSubmit, title, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async reason => {
    try {
      setIsLoading(true);
      await onSubmit(reason);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      width={644}
      destroyOnClose
      contentStyle={{ padding: 24 }}
      title={
        <Typography.Title
          level={2}
          style={{ marginBottom: 0, lineHeight: 1.2 }}
        >
          {title}
        </Typography.Title>
      }
      {...props}
    >
      <RejectedForm isLoading={isLoading} onSubmit={handleSubmit} />
    </Modal>
  );
};

RejectedModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

RejectedModal.defaultProps = {
  title: <Translation ns="Task">{t => t('RejectAgreementReason')}</Translation>
};

export default RejectedModal;
