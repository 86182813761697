import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal';

import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

// eslint-disable-next-line import/no-cycle
import Form from './form';

export const RenameFileModal = ({ visible, title, onSubmit, onClose }) => {
  const { t } = useTranslation('RenameFileModal');

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async ({ name }) => {
    try {
      setIsLoading(true);

      await onSubmit({
        name,
        format: title.substr(title.lastIndexOf('.') + 1)
      });

      showNoticeMessage({ number: NOTICE_NUMBER.fileRenamed });
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={visible}
      destroyOnClose
      title={t('RenameFileHeading')}
      width={540}
      contentStyle={{ padding: 25 }}
      onClose={onClose}
    >
      <Form
        defaultValues={{ name: title.substring(0, title.lastIndexOf('.')) }}
        isLoading={isLoading}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

RenameFileModal.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func
};

RenameFileModal.defaultProps = {
  visible: undefined,
  title: '',
  onClose: () => {}
};

export default RenameFileModal;
