import { removeURLParameter } from 'utils/removeURLParameter';

export const OPEN_FILE = 'openFile';

export const getLocationSearchWithoutOpenFile = search =>
  removeURLParameter(search, OPEN_FILE);

export const getFileLocation = (location, fileId) => ({
  ...location,
  search: `${getLocationSearchWithoutOpenFile(
    location.search
  )}&${OPEN_FILE}=${fileId}`
});
