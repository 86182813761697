import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import {
  TITLE,
  EDIT_PROJECT_EVENT,
  ADD_PROJECT_EVENT,
  TYPE_TAGS
} from 'constants/index';

import { Icon } from 'components/common/icon';
import Tooltip from 'components/common/tooltip';
import Button from 'components/common/button';
// eslint-disable-next-line import/no-cycle
import {
  FormEmployeeSelect,
  FormInput,
  FormTagsSelect,
  validateMaxLength,
  validateMinLength,
  validateRequired,
  withoutBubbling
} from 'components/common/hook-form';
import FormNewEditor from 'components/common/hook-form/markdown';

import { useAmplitude } from 'hooks/amplitude/use-amplitude';

import styles from './styles.module.scss';

export const CreatorForm = ({
  isLoading,
  btnLabel,
  className,
  values = {},
  onSubmit,
  isEdit
}) => {
  const amplitude = useAmplitude();

  const { t } = useTranslation([
    'AddEditProject',
    'Errors',
    'Filters',
    'Projects'
  ]);

  const { handleSubmit, ...methods } = useForm({
    defaultValues: {
      [TITLE]: values.title,
      description: { description: values.description || '', fileList: [] },
      managers: values.managers,
      members: values.members,
      [TYPE_TAGS]: values.initialTags
    }
  });

  const submitForm = valueFields => {
    const eventName = isEdit ? EDIT_PROJECT_EVENT : ADD_PROJECT_EVENT;
    amplitude.logEvent({ event: eventName });
    onSubmit(valueFields);
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={event =>
          withoutBubbling(event, () => handleSubmit(submitForm))
        }
        className={classnames(styles.root, className)}
        data-qa="qa-h7yadv63r7hmun2"
      >
        <div className={styles.infoWrap} data-qa="qa-e3ooe1o3ibbh8ey">
          <FormInput
            name={TITLE}
            label={
              <span>
                {t('ProjectName')}{' '}
                <Tooltip title={t('ProjectNameLengthRange', { ns: 'Errors' })}>
                  <Icon type="question-circle" size={12} />
                </Tooltip>
              </span>
            }
            allowClear
            autoFocus
            autoComplete="off"
            placeholder={t('EnterProjectName')}
            data-qa="qa-3p5rkngkv7qt0rg"
            rules={{
              minLength: validateMinLength(2),
              maxLength: validateMaxLength(512)
            }}
          />

          <FormNewEditor
            label={
              <span>
                {t('ProjectDesc')}{' '}
                <Tooltip title={t('ProjectDescLengthRange', { ns: 'Errors' })}>
                  <Icon type="question-circle" size={12} />
                </Tooltip>
              </span>
            }
            name="description"
            placeholder={t('EnterProjectDesc')}
            rules={{
              validate: {
                requiredDesc: value => {
                  if (!value.description.trim()) return validateRequired();
                  return true;
                },
                minLength: value => {
                  if (
                    value.description.trim() &&
                    value.description.length < 2
                  ) {
                    return validateMinLength(2).message;
                  }
                  return true;
                },
                maxLength: value => {
                  if (
                    value.description.trim() &&
                    value.description.length > 2500
                  ) {
                    return validateMaxLength(2500).message;
                  }
                  return true;
                }
              }
            }}
            showItems={{
              upload: false,
              mention: false,
              emoji: true,
              topToolbar: true
            }}
          />

          <FormEmployeeSelect
            name="managers"
            label={t('ProjectManagers', { ns: 'Projects' })}
            rules={{
              required: validateRequired()
            }}
            isMulti
          />

          <FormEmployeeSelect
            name="members"
            label={t('Members')}
            rules={{
              required: validateRequired()
            }}
            placeholder={t('SelectMembers', { ns: 'Filters' })}
            data-qa="qa-sk7qgscnffxh17w"
            isMulti
            allowSelectAll
          />

          <FormTagsSelect label={t('Tags')} name={TYPE_TAGS} />
        </div>

        <Button
          htmlType="submit"
          type="primary"
          loading={isLoading}
          className={styles.submit}
          data-qa="qa-w52r52ogycbtgqw"
        >
          {btnLabel || t('CreateBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

export default CreatorForm;
