import React from 'react';
import Icon from 'components/common/icon';

import PropTypes from 'prop-types';

import Modal from 'components/common/modal';
import TimerIcon from 'components/common/icons/multicolor/timer';
import Typography from 'components/common/typography';

// eslint-disable-next-line import/no-cycle
import EstimateForm from './form';

const { Title, Text } = Typography;

export const EstimateModal = ({
  onClose,
  title,
  description,
  estimateFormProps,
  children,
  resetReorderingTasks,
  ...props
}) => (
  <Modal
    width={440}
    onClose={() => {
      resetReorderingTasks();
      onClose();
    }}
    contentStyle={{
      padding: '60px 24px 24px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center'
    }}
    {...props}
  >
    <Icon component={TimerIcon} style={{ marginBottom: 20 }} />

    <Title level={1} weight="bold">
      {title}
    </Title>

    {description && (
      <Text style={{ opacity: 0.7, marginBottom: 20, maxWidth: 310 }}>
        {description}
      </Text>
    )}

    <div style={{ maxWidth: 240 }}>
      <EstimateForm
        {...estimateFormProps}
        submitStyle={{
          width: '100%',
          fontSize: 16
        }}
      />
    </div>

    {children}
  </Modal>
);

EstimateModal.propTypes = {
  estimateFormProps: PropTypes.shape({
    defaultValues: PropTypes.object,
    isLoading: PropTypes.bool,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func
  }).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  resetReorderingTasks: PropTypes.func
};

EstimateModal.defaultProps = {
  resetReorderingTasks: () => {},
  description: ''
};

export default EstimateModal;
