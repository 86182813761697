import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EMAIL, STATUS_CONNECTED, STATUS_ERROR } from 'constants/index';

import VideoBtn from 'components/common/video-btn';
// eslint-disable-next-line import/no-cycle
import {
  FormEmployeeSelect,
  FormInput,
  FormSwitch,
  LabelWithTooltip,
  SubmitWrapper,
  validateMaxLength,
  withoutBubbling
} from 'components/common/hook-form';
import Button from 'components/common/button';
import Typography from 'components/common/typography';

import scrollToFirstError from 'utils/scroll-to-first-error';

import UpdateEmailPasswordModal from '../../modals/update-password-email';

import styles from './common-channel.module.scss';

const CommonChannelForm = ({
  videoSlug,
  defaultValues,
  children,
  isLoading,
  isEditor,
  isOnlyView,
  onSubmit
}) => {
  const methods = useForm({ defaultValues });

  const { t } = useTranslation(['ConnectEmail', 'Errors', 'ConnectWidget']);

  const isActiveScheduleConfigWatch = methods.watch('scheduleConfig.isActive');

  const [visibleModal, setVisibleModal] = useState(false);

  const isEmailError =
    defaultValues.kind === EMAIL && defaultValues.status === STATUS_ERROR;

  useEffect(() => {
    scrollToFirstError(methods);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.formState]);

  return (
    <>
      <FormProvider {...methods}>
        <form
          className={styles.root}
          onSubmit={event =>
            withoutBubbling(event, () => methods.handleSubmit(onSubmit))
          }
        >
          {videoSlug && (
            <VideoBtn slug={videoSlug} style={{ marginBottom: 24 }} />
          )}

          {isEditor && defaultValues.status === STATUS_CONNECTED && (
            <FormSwitch
              name="isActive"
              label={t('GetMessagesFromChannelToggle')}
              itemProps={{
                style: {
                  className: styles.mb24
                }
              }}
              disabled={isOnlyView}
            />
          )}

          <FormInput
            name="name"
            label={
              <LabelWithTooltip
                label={t('ChannelName')}
                tooltip={t('ChannelNameTip')}
              />
            }
            rules={{
              required: t('RequiredField', { ns: 'Errors' }),
              maxLength: validateMaxLength(250)
            }}
            placeholder={t('EnterChannelName', { ns: 'ConnectWidget' })}
            disabled={isOnlyView}
          />

          <FormInput
            name="source"
            label={t('Source')}
            disabled
            rules={{
              required: t('RequiredField', { ns: 'Errors' })
            }}
            itemProps={{
              className: isEmailError ? styles.source : undefined
            }}
          />

          {isEmailError && (
            <div className={styles.statusEmailError}>
              <Typography.Text color="red" size="small">
                {t('AuthorizationFailTip')}{' '}
                <Button
                  onClick={() => setVisibleModal(true)}
                  className={styles.updateAuthBtn}
                  type="link"
                  disabled={isOnlyView}
                >
                  {t('UpdateSourceDataBtn')}
                </Button>
              </Typography.Text>
            </div>
          )}

          <FormEmployeeSelect
            name="responsible"
            label={t('ChannelAdmin')}
            rules={{
              required: t('RequiredField', { ns: 'Errors' })
            }}
            isDisabled={isOnlyView}
          />

          <FormEmployeeSelect
            name="operators"
            label={t('ChannelOperators')}
            isMulti
            rules={{
              required: t('RequiredField', { ns: 'Errors' })
            }}
            isDisabled={isOnlyView}
          />

          {children({ isActiveScheduleConfigWatch })}

          <FormSwitch
            name="notify"
            label={t('SoundNotificationsToggle')}
            disabled={isOnlyView}
          />

          <SubmitWrapper>
            <Button
              type="primary"
              size="large"
              width="expanded"
              htmlType="submit"
              loading={isLoading}
              disabled={isOnlyView}
              className={styles.button}
            >
              {t('SaveBtn')}
            </Button>
          </SubmitWrapper>
        </form>
      </FormProvider>

      <UpdateEmailPasswordModal
        channelId={defaultValues.id}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      />
    </>
  );
};

CommonChannelForm.propTypes = {
  videoSlug: PropTypes.string,
  defaultValues: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  isEditor: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

CommonChannelForm.defaultProps = {
  videoSlug: null,
  defaultValues: {},
  isEditor: false
};

export default CommonChannelForm;
