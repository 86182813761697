import React from 'react';

export const LogoCircleManDayIcon = ({ style = { width: 38, height: 38 } }) => (
  <svg
    style={style}
    width="101"
    height="100"
    viewBox="0 0 101 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_106239_60913)">
      <rect
        x="0.599121"
        width="100"
        height="100"
        rx="50"
        fill="url(#paint0_linear_106239_60913)"
      />
      <path
        d="M24.8015 53.5018C25.0171 54.2011 25.5154 54.9048 26.5118 56.3123C31.2074 62.945 41.6848 75.9586 42.5763 77.1316C43.4677 78.3046 47.4791 78.3779 48.706 76.8028C49.2493 76.1052 49.6578 75.6038 50.5025 74.1626L75.0598 32.2626C75.9078 30.8158 76.3317 30.0924 76.4879 29.3987C76.8187 27.9291 76.4017 26.3925 75.3711 25.283C74.8847 24.7594 74.1512 24.3423 72.6843 23.508C71.2231 22.6769 70.4925 22.2614 69.7918 22.1086C68.3073 21.7847 66.7561 22.1952 65.6345 23.2086C65.105 23.687 64.6827 24.4076 63.838 25.8488L44.391 59.0295L37.2291 48.9132C36.2301 47.502 35.7305 46.7964 35.1381 46.3558C33.9454 45.4688 32.3949 45.1988 30.9669 45.6295C30.2577 45.8434 29.5439 46.3378 28.1163 47.3264C26.6925 48.3125 25.9806 48.8055 25.5358 49.3898C24.6403 50.5661 24.3675 52.094 24.8015 53.5018Z"
        fill="white"
      />
      <path
        d="M54.7345 104.082L51.0996 86.7063L47.2153 68.1348L45.6572 68.4606L49.7507 88.0297L53.6 106.438L60.3212 138.571C60.9569 139.174 61.6192 139.747 62.3079 140.291L54.7345 104.082Z"
        fill="black"
      />
      <path
        d="M65.1849 62.0467C64.9282 61.2884 64.4742 60.5646 63.785 60.158C63.7148 60.1165 63.6424 60.0794 63.5689 60.0451C63.5691 60.0451 63.5701 60.0455 63.5701 60.0455C63.5645 60.0426 63.5589 60.0405 63.5533 60.0377C63.5443 60.0336 63.5355 60.0292 63.5265 60.0253C61.5397 59.0654 59.5777 60.5965 59.5777 60.5965C60.08 60.4324 60.6879 60.7963 60.8446 61.3148C61.0143 61.8717 60.7438 62.4952 60.2975 62.8695C59.8522 63.2437 59.2629 63.404 58.684 63.4662C55.2955 63.8305 52.0379 61.2051 48.6897 61.8453C47.5064 62.0714 46.4272 62.7368 45.6476 63.6534C44.609 63.0456 43.3698 62.774 42.1781 62.9515C38.8073 63.4527 36.5985 67.0068 33.2799 67.7819C32.7137 67.9143 32.1045 67.9575 31.5598 67.7516C31.0149 67.5456 30.5536 67.0465 30.53 66.4646C30.5075 65.9233 30.9611 65.379 31.4894 65.368C31.4894 65.368 29.135 64.5719 27.5766 66.1298C27.5658 66.14 27.5553 66.1505 27.5447 66.1608C27.5428 66.1627 27.5408 66.1644 27.539 66.1663L27.5394 66.166C27.4814 66.2225 27.4257 66.2813 27.3733 66.3435C26.857 66.955 26.6667 67.7882 26.6756 68.5886C26.6963 70.5717 27.8654 72.444 29.5011 73.565C31.1367 74.686 33.1837 75.1067 35.1641 75.0105C37.1444 74.9133 39.0723 74.327 40.9069 73.5751C42.7019 72.84 44.4697 71.9122 45.778 70.4792C46.068 70.1611 46.3615 69.81 46.6302 69.4381C47.0066 69.7004 47.3987 69.9351 47.7774 70.1396C49.4857 71.06 51.4607 71.3521 53.3986 71.453C55.3788 71.5569 57.3911 71.4737 59.2932 70.9115C61.1944 70.3483 62.9866 69.2755 64.1603 67.6772C65.3341 66.0789 65.8193 63.9255 65.1849 62.0467Z"
        fill="#CB7000"
      />
      <path
        d="M67.569 33.2714C67.5791 33.2767 67.5894 33.2819 67.5995 33.2872L67.4902 33.2305C67.5193 33.2455 67.5469 33.2599 67.569 33.2714Z"
        fill="#B3B2B0"
      />
      <path
        d="M61.8605 27.095L75.3768 34.11L75.3689 34.1244C76.9021 31.4195 78.5719 28.7957 80.3658 26.275C80.7414 25.7473 80.7265 25.037 80.3275 24.5343C78.682 22.461 69.4838 17.6872 66.8412 17.5349C66.2005 17.498 65.6111 17.8947 65.3958 18.5056C64.3677 21.4216 63.1841 24.2956 61.8556 27.1047L61.8589 27.0981L61.8605 27.095Z"
        fill="#1A1A1A"
      />
      <path
        d="M67.0412 32.9973C67.0632 33.0087 67.0909 33.0231 67.12 33.0381L67.0107 32.9814C67.0209 32.9867 67.031 32.992 67.0412 32.9973Z"
        fill="#B3B2B0"
      />
      <path
        d="M62.5406 30.4015C63.9684 31.3591 65.4991 32.1963 67.0105 32.9812L67.1191 33.0376C67.2057 33.0825 67.3048 33.1336 67.3048 33.1336C67.3048 33.1336 67.4036 33.1851 67.4901 33.2301L67.5987 33.2865C69.1105 34.0706 70.6762 34.8404 72.2811 35.4568C72.8901 35.6906 73.5628 35.9685 74.254 36.1636L75.3688 34.1242L75.3767 34.1097L61.8604 27.0947L61.8587 27.0979L61.8554 27.1045L60.8271 29.195C61.3844 29.6479 61.9989 30.0381 62.5406 30.4015Z"
        fill="#B90600"
      />
      <path
        d="M78.4738 35.5055C77.9335 35.4059 77.5213 35.991 77.0631 36.1771C76.1683 36.5404 75.1942 36.4288 74.2544 36.1636C73.5631 35.9686 72.8904 35.6907 72.2814 35.4569C70.6765 34.8405 69.1108 34.0707 67.599 33.2866C67.589 33.2814 67.5788 33.2761 67.5688 33.2709C67.5468 33.2595 67.5193 33.2452 67.4905 33.2302C67.4039 33.1852 67.3051 33.1337 67.3051 33.1337C67.3051 33.1337 67.2061 33.0825 67.1194 33.0377C67.0906 33.0227 67.0631 33.0085 67.0411 32.997C67.031 32.9918 67.0209 32.9865 67.0108 32.9813C65.4995 32.1963 63.9687 31.3592 62.5409 30.4015C61.9992 30.0382 61.3847 29.648 60.8274 29.195C60.0696 28.5791 59.4177 27.8468 59.1998 26.906C59.0882 26.4242 59.3294 25.7503 58.9369 25.3659C58.3459 24.787 57.6295 25.6424 57.4176 26.0997C56.9844 27.0351 57.3008 28.2709 57.73 29.1414C58.2778 30.2525 59.1801 31.1839 60.096 31.9932C61.7631 33.4662 63.7015 34.6219 65.6681 35.6425C65.7536 35.6869 65.9256 35.7749 65.9256 35.7749C65.9256 35.7749 66.1101 35.8719 66.1957 35.9164C68.1623 36.937 70.223 37.8569 72.3871 38.3723C73.5761 38.6555 74.8572 38.8572 76.081 38.6656C77.0398 38.5155 78.2326 38.0629 78.7481 37.1702C79.0002 36.7338 79.2873 35.6556 78.4738 35.5055Z"
        fill="#1A1A1A"
      />
      <path
        d="M15.8003 27.2976C21.8055 27.2976 26.6751 32.1642 26.6751 38.1724C26.6751 32.1642 31.5446 27.2976 37.5498 27.2976C31.5446 27.2976 26.6751 22.4281 26.6751 16.4229C26.6752 22.4279 21.8055 27.2976 15.8003 27.2976Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M7.62109 40.6116C10.9914 40.6116 13.7243 43.3428 13.7243 46.7148C13.7243 43.3428 16.4573 40.6116 19.8276 40.6116C16.4573 40.6116 13.7243 37.8786 13.7243 34.5083C13.7244 37.8785 10.9914 40.6116 7.62109 40.6116Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M67.9619 78.0002C71.3322 78.0002 74.0652 80.7315 74.0652 84.1035C74.0652 80.7315 76.7981 78.0002 80.1684 78.0002C76.7981 78.0002 74.0652 75.2673 74.0652 71.897C74.0653 75.2672 71.3322 78.0002 67.9619 78.0002Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M84.5073 63.72C86.7223 63.72 88.5183 65.515 88.5183 67.731C88.5183 65.515 90.3144 63.72 92.5293 63.72C90.3144 63.72 88.5183 61.9239 88.5183 59.709C88.5184 61.9239 86.7223 63.72 84.5073 63.72Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M53.0918 8.74782C55.3067 8.74782 57.1028 10.5428 57.1028 12.7588C57.1028 10.5428 58.8988 8.74782 61.1138 8.74782C58.8988 8.74782 57.1028 6.95177 57.1028 4.73682C57.1029 6.9517 55.3067 8.74782 53.0918 8.74782Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M20.5879 89.1145C22.8028 89.1145 24.5989 90.9095 24.5989 93.1255C24.5989 90.9095 26.3949 89.1145 28.6099 89.1145C26.3949 89.1145 24.5989 87.3185 24.5989 85.1035C24.599 87.3184 22.8028 89.1145 20.5879 89.1145Z"
        fill="white"
        fillOpacity="0.2"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_106239_60913"
        x1="0.599121"
        y1="100"
        x2="38.4606"
        y2="-19.661"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0386217" stopColor="#46BEA6" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
      <clipPath id="clip0_106239_60913">
        <rect x="0.599121" width="100" height="100" rx="50" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
