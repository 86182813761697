import { apiV2 } from 'api/v2';

import { getWorkspaceId } from 'store/workspace';

import createActionThunk from '../../actions-thunk';

export const changeStateOrderStatus = createActionThunk(
  'orders/order-statuses/hiring/change-state',
  ({ id, state, fileList, getState }) => {
    const currentState = getState();
    const workspaceId = getWorkspaceId(currentState);

    return apiV2.hiring
      .changeState({
        workspaceId,
        id,
        state,
        fileList
      })
      .then(({ data }) => data);
  }
);

export const cancelOrderStatus = createActionThunk(
  'orders/order-statuses/hiring/cancel',
  ({ id, reason, fileList, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.hiring
      .cancel({
        workspaceId,
        id,
        reason,
        fileList
      })
      .then(({ data }) => data);
  }
);

export const reopenOrderStatus = createActionThunk(
  'orders/order-statuses/hiring/reopen',
  ({ id, reason, fileList, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.hiring
      .reopen({
        workspaceId,
        id,
        reason,
        fileList
      })
      .then(({ data }) => data);
  }
);

export default cancelOrderStatus;
