import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  REG_BOSS_INVITATION_EVENT,
  REG_SUBORDINATE_INVITATION_EVENT
} from 'constants/index';

import Button from 'components/common/button';
import { LayoutAuth } from 'components/common/layout/template';
import Typography from 'components/common/typography';
import {
  FormInput,
  LabelWithTooltip,
  FormAntdSelect,
  validateMaxLength,
  validateEmail
} from 'components/common/hook-form';

import { useAmplitude } from 'hooks/amplitude/use-amplitude';
import { getValidEmails } from 'utils/validators';

import styles from './styles.module.scss';

const { Title, Paragraph } = Typography;

const splitValues = values => values.map(value => value.split(/[ ,]+/)).flat();

const MANAGER_EMAIL = 'managerEmail';
const EMPLOYEE_EMAILS = 'employeeEmails';

const CreateTeamForm = ({
  className,
  data,
  isCreateWorkspace,
  allowInviteOwner,
  isLoading,
  onSubmit,
  onSkip
}) => {
  const methods = useForm();
  const amplitude = useAmplitude();
  const { t } = useTranslation('InviteColleguesSignUp');

  const [isShownMessageRed, setIsShownMessageRed] = useState(false);

  const employeeEmailsWatch = useWatch({
    name: EMPLOYEE_EMAILS,
    control: methods.control
  });

  const managerEmailWatch = useWatch({
    name: MANAGER_EMAIL,
    control: methods.control
  });

  const hasEmployees = !!(employeeEmailsWatch || []).length;
  const hasManager = allowInviteOwner ? managerEmailWatch : true;
  const options = (employeeEmailsWatch || []).map(email => ({
    label: email,
    value: email,
    disabled: email === data.inviter,
    id: email
  }));

  useEffect(() => {
    if (data.inviter && allowInviteOwner) {
      methods.setValue(EMPLOYEE_EMAILS, [data.inviter]);
    }
  }, [data.inviter, allowInviteOwner, methods]);

  const handleEmployeeEmailsChange = values => {
    const splitedValues = splitValues(values);
    const validEmails = getValidEmails(splitedValues);

    methods.setValue(EMPLOYEE_EMAILS, validEmails);
  };

  const handleSubmit = values => {
    if (!!values.managerEmail || hasEmployees) {
      const amplitudeData = [REG_BOSS_INVITATION_EVENT];

      if ((values.employeeEmails || []).length) {
        amplitudeData.push(REG_SUBORDINATE_INVITATION_EVENT);
      }

      amplitude.colleagueInvitationEvent(amplitudeData);

      onSubmit(values);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          data-qa="qa-xdqaj7pyetihcsd"
          className={classnames(styles.form, className)}
          onSubmit={methods.handleSubmit(handleSubmit)}
        >
          {!isCreateWorkspace && (
            <Title
              level={1}
              className={styles.title}
              data-qa="qa-bai1ruinf7e4ex9"
            >
              {t('InviteColleguesSignUpHeading')}
            </Title>
          )}
          <Paragraph
            align={isCreateWorkspace ? 'left' : 'center'}
            color="black-35"
            style={{ marginBottom: isCreateWorkspace ? 0 : 24 }}
            data-qa="qa-pu7jo3kf6o7hdv8"
          >
            {t('InviteColleguesSignUpDesc')}
          </Paragraph>

          {allowInviteOwner && (
            <FormInput
              label={
                <LabelWithTooltip
                  label={t('YourImmediateSupervisor')}
                  tooltip={t('YourImmediateSupervisorTip')}
                />
              }
              name={MANAGER_EMAIL}
              type="email"
              placeholder={t('EnterEmail')}
              data-qa="qa-17vgfbei99mbh6h"
              rules={{
                validate: value => validateEmail(value)
              }}
              onBlur={() => methods.trigger(MANAGER_EMAIL)}
            />
          )}

          <FormAntdSelect
            name={EMPLOYEE_EMAILS}
            data-qa="qa-2y348av4qymecss"
            size="large"
            label={
              <LabelWithTooltip
                label={t('YourSubordinates')}
                tooltip={t('YourSubordinatesTip')}
              />
            }
            placeholder={t('EnterEmail')}
            mode="tags"
            notFoundContent={null}
            tokenSeparators={[',', ' ']}
            allowClear
            value={employeeEmailsWatch}
            options={options}
            itemProps={{
              style: { marginBottom: hasEmployees ? 16 : 24 },
              'data-qa': 'qa-7qltrtklouyk1o6'
            }}
            onChange={handleEmployeeEmailsChange}
            className={styles.select}
          />

          {hasEmployees && hasManager && (
            <FormInput
              label={t('YourDepartment')}
              name="name"
              size="large"
              placeholder={t('YourDepartment')}
              data-qa="qa-bbnmjymft9lv1f9"
              autoComplete="off"
              rules={{
                required: t('RequiredField', { ns: 'Errors' }),
                maxLength: validateMaxLength(50)
              }}
            />
          )}

          <div
            className={classnames({
              [styles.btnsSubmitContainer]: isCreateWorkspace
            })}
            data-qa="qa-ggfy15ermnpkt24"
          >
            <div
              className={classnames(styles.column, {
                [styles.btnsSubmit]: isCreateWorkspace
              })}
              data-qa="qa-at4olzz200sun5e"
            >
              <Button
                disabled={!managerEmailWatch && !hasEmployees}
                type="primary"
                htmlType="submit"
                size="large"
                className={styles.btnSubmit}
                data-qa="qa-19sjestwbix5evy"
                loading={isLoading}
              >
                {t('SendInviteBtn')}
              </Button>

              <Button
                type="link"
                className={styles.btnSkip}
                data-qa="qa-yqwoomudah5c9il"
                onClick={onSkip}
                onMouseMove={() => setIsShownMessageRed(true)}
                onMouseLeave={() => setIsShownMessageRed(false)}
              >
                {t('SkipBtn')}
              </Button>
            </div>
            <div className={styles.messages} data-qa="qa-ynpkdhazhuk8owc">
              {!isShownMessageRed && (
                <div
                  className={classnames(styles.message, styles.messageGreen)}
                  data-qa="qa-9deqzyg8und63nk"
                >
                  {t('GreenWarning')}
                </div>
              )}
              {isShownMessageRed && (
                <div
                  className={classnames(styles.message, styles.messageRed)}
                  data-qa="qa-p1uf090mnrri5qn"
                >
                  {t('RedWarning')}
                </div>
              )}
            </div>
          </div>
        </form>
      </FormProvider>

      {/* В layout сделал ширину больше, если есть footer, по другому не придумал как (нужно по дизайну) */}
      <LayoutAuth.Footer>
        <div />
      </LayoutAuth.Footer>
    </>
  );
};

CreateTeamForm.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  allowInviteOwner: PropTypes.bool,
  isCreateWorkspace: PropTypes.bool,
  data: PropTypes.shape({
    inviter: PropTypes.string
  }),
  onSubmit: PropTypes.func.isRequired
};

CreateTeamForm.defaultProps = {
  className: undefined,
  isLoading: false,
  isCreateWorkspace: false,
  allowInviteOwner: true,
  data: {}
};

export default CreateTeamForm;
