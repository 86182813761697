import { FormProvider, useForm, useWatch } from 'react-hook-form';
import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/button';
// eslint-disable-next-line import/no-cycle
import {
  FormRadio,
  validateMaxLength,
  validateMinLength
} from 'components/common/hook-form';
import FormNewEditor from 'components/common/hook-form/markdown';
import Typography from 'components/common/typography';
import FormAttachFile from 'components/common/hook-form/attach-file';
import Attachments from 'components/common/attachments';
import Icon from 'components/common/icon';

import markdownToPlainText from 'utils/markdown-to-plain-text';

import styles from './reason-for-return-issue.module.scss';

const { Paragraph } = Typography;

const MAX_SIZE_UPLOAD_FILE = 200 * 1024 * 1024;

export const Form = ({ isLoading, options, onSubmit }) => {
  const methods = useForm({
    defaultValues: {
      reasonType: options[0].value,
      reason: {
        description: ''
      },
      files: {}
    }
  });

  const reasonTypeField = useWatch({
    name: 'reasonType',
    control: methods.control
  });
  const fileField = useWatch({
    name: 'files',
    control: methods.control
  });

  const showReason = reasonTypeField === options[options.length - 1].value;

  const { t } = useTranslation(['BackTaskToWorkServiceDesc', 'Errors']);

  return (
    <FormProvider {...methods}>
      <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit)}>
        <Paragraph className={styles.subtitle}>
          {t('BackTaskToWorkDesc')}
        </Paragraph>

        <FormRadio
          name="reasonType"
          options={options}
          className={styles.options}
        />

        {showReason && (
          <>
            <FormNewEditor
              name="reason"
              placeholder={t('ReturnReasonPlchldr')}
              showItems={{
                upload: true,
                emoji: true,
                mention: false,
                topToolbar: true
              }}
              uploadFileComponent={
                <FormAttachFile
                  name="files"
                  itemProps={{
                    className: styles.paperButton
                  }}
                  isHideAttachment
                  isImage={false}
                  isMultiple
                  maxSize={MAX_SIZE_UPLOAD_FILE}
                >
                  <Tooltip title={t('AttachFile')} mouseEnterDelay={0.5}>
                    <Icon type="paper-clip" size={16} />
                  </Tooltip>
                </FormAttachFile>
              }
              rules={{
                validate: value => {
                  const withoutMarkdownText = markdownToPlainText(
                    value.description
                  );

                  if (!withoutMarkdownText.trim()) {
                    return t('RequiredField', { ns: 'Errors' });
                  }

                  if (withoutMarkdownText.length > 1000) {
                    return validateMaxLength(1000);
                  }

                  if (withoutMarkdownText.length < 15) {
                    return validateMinLength(15);
                  }

                  return true;
                }
              }}
            />

            <Attachments
              attachmentProps={{
                onDelete: file => {
                  const updatedFileList = fileField.fileList.filter(
                    f => f.uid !== file.uid
                  );
                  methods.setValue('files', { fileList: updatedFileList });
                }
              }}
              fileList={(fileField.fileList || []).map(f => ({
                uid: f.uid,
                name: f.name,
                size: f.size,
                type: f.type,
                isSystem: true
              }))}
              className={styles.attachment}
            />
          </>
        )}

        <Button
          type="primary"
          width="expanded"
          htmlType="submit"
          loading={isLoading}
          className={styles.submitBtn}
        >
          {t('SendBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  options: PropTypes.array
};

Form.defaultProps = {
  isLoading: false,
  onSubmit: () => {},
  options: []
};

export default Form;
