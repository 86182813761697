import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  REQUEST_FIELD_CONTACT,
  REQUEST_FIELD_CHANNEL,
  TYPE_CHANNEL,
  TYPE_ASSET,
  TYPE_ORDER_STATUS
} from 'constants/index';

import Typography from 'components/common/typography';
import DetailsField from 'components/common/details-field';
import Tooltip from 'components/common/tooltip';

import { FieldEditorItem } from 'providers/field-editor';
import useRoutesService from 'services/routes';
import { getFullName } from 'utils/get-fio';
import useModalsService from 'services/modals';

import styles from './ContactChannelInfo.module.scss';

const OBJECT_SLA_TYPES = {
  [TYPE_CHANNEL]: {
    id: TYPE_CHANNEL,
    fieldName: REQUEST_FIELD_CHANNEL,
    icon: 'phone-and-message',
    titleKey: 'ChannelField',
    ns: 'Requests',
    getRoute: null,
    getName: item => item.name
  },
  [TYPE_ASSET]: {
    id: TYPE_ASSET,
    fieldName: 'REQUEST_FIELD_ASSET',
    icon: 'book',
    titleKey: 'AssetField',
    ns: 'Requests',
    getRoute: ({ routes, id, modals }) =>
      `${routes.toAssets()}${modals.assets.showDetails({
        id
      })}`,
    getName: item => item.title || item.name
  },
  [TYPE_ORDER_STATUS]: {
    id: TYPE_ORDER_STATUS,
    fieldName: 'REQUEST_FIELD_ORDER_STATUS',
    icon: 'funnel-plot',
    titleKey: 'OrderStatusField',
    ns: 'Requests',
    getRoute: ({ routes, id, modals, contactId }) =>
      routes.toNewOrderStatus({
        contactId,
        orderStatusId: id
      }),
    getName: item => item.name
  }
};

export const ContactChannelInfo = ({ request }) => {
  const { t } = useTranslation('Requests');

  const routes = useRoutesService({ returnUrl: true });
  const modals = useModalsService({ returnUrl: true });

  const contact = request.taskInfo?.contact || null;
  const slaObject = request.taskInfo?.slaObject || null;
  const slaObjectType = request.taskInfo?.slaObjectType;

  if (!contact && !slaObject) {
    return null;
  }

  const renderSlaObject = () => {
    if (!slaObject) return null;

    const slaType = OBJECT_SLA_TYPES[slaObjectType];

    if (!slaType) return null;

    const hasRoute = !!slaType.getRoute;

    const content = (
      <Tooltip title={slaType.getName(slaObject)}>
        <Typography.Text
          color={hasRoute ? 'brand' : 'black'}
          style={{
            marginRight: 8,
            whiteSpace: 'nowrap',
            display: 'block'
          }}
          ellipsis
        >
          {slaType.getName(slaObject)}
        </Typography.Text>
      </Tooltip>
    );

    const wrappedContent = hasRoute ? (
      <a
        href={slaType.getRoute({
          routes,
          id: slaObject.id,
          modals,
          contactId: contact?.id
        })}
        rel="noopener noreferrer"
        target="_blank"
      >
        {content}
      </a>
    ) : (
      content
    );

    return (
      <FieldEditorItem
        field={slaType.fieldName}
        defaultValues={{ [slaType.fieldName]: slaObject.id }}
        className={styles.item}
      >
        <DetailsField
          iconType={slaType.icon}
          title={t(slaType.titleKey, { ns: slaType.ns })}
          className={styles.detailsField}
        >
          {wrappedContent}
        </DetailsField>
      </FieldEditorItem>
    );
  };

  return (
    <div className={styles.root}>
      {contact && (
        <FieldEditorItem
          field={REQUEST_FIELD_CONTACT}
          defaultValues={{ [REQUEST_FIELD_CONTACT]: contact.id }}
          className={styles.item}
        >
          <DetailsField
            iconType="user-outlined"
            title={t('ContactField')}
            className={styles.detailsField}
          >
            <a
              href={routes.toContact({ id: contact.id })}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Typography.Text
                color="brand"
                style={{
                  marginRight: 8,
                  whiteSpace: 'nowrap',
                  display: 'block'
                }}
                ellipsis
              >
                <Tooltip title={getFullName(contact)}>
                  {getFullName(contact)}
                </Tooltip>
              </Typography.Text>
            </a>
          </DetailsField>
        </FieldEditorItem>
      )}

      {renderSlaObject()}
    </div>
  );
};
