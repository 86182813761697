import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Translation, useTranslation } from 'react-i18next';
import i18n from 'i18n-config';

import Modal from 'components/common/modal';
import {
  FormAttachDocuments,
  FormRadio,
  FormTextarea
} from 'components/common/hook-form';
import Button from 'components/common/button';
import Typography from 'components/common/typography';

import { getFileIdsV2 } from 'hooks/common/use-file-upload/get-file-ids';

import styles from './cancellation.module.scss';

const CUSTOM_REASON = 'OwnOptionRadio';

const Form = ({ isLoading, options, onSubmit }) => {
  const { t } = useTranslation(['CancelOrder', 'Errors', 'AddFile']);

  const methods = useForm({
    defaultValues: {
      reason: options[0].value,
      customReason: undefined,
      fileList: []
    }
  });

  const reasonFeild = useWatch({ name: 'reason', control: methods.control });

  const showCustomReason = reasonFeild === options[options.length - 1].value;

  return (
    <FormProvider {...methods}>
      <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit)}>
        <FormRadio
          name="reason"
          options={options}
          className={styles.radioButtons}
        />

        {showCustomReason && (
          <FormTextarea
            name="customReason"
            placeholder={t('CancelReason')}
            rules={{
              required: t('RequiredField', { ns: 'Errors' }),
              minLength: {
                value: 4,
                message: t('ValidateMinLength', { ns: 'Errors', value: 4 })
              },
              maxLength: {
                value: 2500,
                message: t('ValidateMaxLength', { ns: 'Errors', value: 2500 })
              }
            }}
            autoSize={{
              minRows: 1
            }}
          />
        )}

        {showCustomReason && (
          <FormAttachDocuments
            label={t('AddFileDrawerTitle', { ns: 'AddFile' })}
            name="fileList"
            buttonProps={{ type: 'link', className: styles.fileButton }}
          />
        )}

        <Button
          type="primary"
          width="expanded"
          htmlType="submit"
          loading={isLoading}
          className={styles.button}
        >
          {t('SaveBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

const CancellationModal = ({
  visible,
  title,
  description,
  options,
  onSubmit,
  onClose,
  callback
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const resultOptions = [
    ...options,
    {
      label: CUSTOM_REASON,
      ns: 'CancelOrder',
      value: CUSTOM_REASON
    }
  ];

  const handleSubmit = async ({ reason, fileList, customReason }) => {
    try {
      setIsLoading(true);

      const fileListValue = getFileIdsV2(fileList);
      const resultReason =
        reason === CUSTOM_REASON
          ? customReason
          : i18n.t(reason, { ns: options.find(o => o.value === reason).ns });

      await onSubmit({
        reason: resultReason,
        fileList: fileListValue,
        attachments: fileList
      });
      callback();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={title}
      width={508}
      open={visible}
      destroyOnClose
      onClose={onClose}
      contentClassName={styles.root}
      centered
    >
      <>
        {description && (
          <Typography.Paragraph className={styles.description}>
            {description}
          </Typography.Paragraph>
        )}

        <Form
          isLoading={isLoading}
          options={resultOptions}
          onSubmit={handleSubmit}
        />
      </>
    </Modal>
  );
};

CancellationModal.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  callback: PropTypes.func
};

CancellationModal.defaultProps = {
  visible: false,
  description: null,
  title: (
    <Translation ns="CancelOrder">
      {t => t('CancelOrderReasonHeading')}
    </Translation>
  ),
  callback: () => {}
};

export default CancellationModal;
