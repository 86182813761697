import React, { useEffect, useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Dropdown, Spin } from 'antd';

import {
  TYPE_ORDER,
  TYPE_TASK,
  TYPE_ASSET,
  TYPE_CONTACT,
  TYPE_CHANNEL_CHAT,
  TYPE_ORDER_TEMPLATE,
  TYPE_TASK_TEMPLATE,
  TYPE_ATTACHMENT,
  TYPE_PROJECT,
  TYPE_ORDER_STATUS,
  TYPE_REQUEST
} from 'constants/index';

// eslint-disable-next-line import/no-cycle
import TagOption from 'components/common/controls/custom-select/custom-select/tag-option';
import Icon from 'components/common/icon';
import Typography from 'components/common/typography';

import { deleteTag, fetchTagsByEntity, getTagsByEntity } from 'store/tags';

import AddTagButton from '../add-button';

import styles from './list.module.scss';

export const TagList = ({
  entityType,
  entityId,
  needFetchTags,
  className,
  displayedCount,
  allowAddTag,
  allowDeleteTag,
  shortAddButton
}) => {
  const dispatch = useDispatch();

  const dropdownArrowRef = useRef();

  const tags = useSelector(state =>
    getTagsByEntity(state)({ entityType, entityId })
  );

  const { displayedTags = [], dropdownTags = [] } = useMemo(() => {
    if (tags === undefined) {
      return {};
    }

    if (!displayedCount) {
      return {
        displayedTags: tags
      };
    }

    return {
      displayedTags: tags.slice(0, displayedCount),
      dropdownTags: tags.slice(displayedCount)
    };
  }, [displayedCount, tags]);

  const remove = tag => dispatch(deleteTag({ tag }));

  const addCallback = useCallback(() => {
    if (dropdownArrowRef.current && displayedCount && dropdownTags.length) {
      dropdownArrowRef.current.classList.remove(styles.addAnimation);

      setTimeout(
        () => dropdownArrowRef.current.classList.add(styles.addAnimation),
        100
      );
    }
  }, [displayedCount, dropdownTags.length]);

  useEffect(() => {
    if (needFetchTags && tags === undefined && entityId) {
      dispatch(fetchTagsByEntity({ entityType, entityIds: [entityId] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId]);

  const dropdownRender = menu => (
    <div className={styles.dropdown}>
      {dropdownTags.map(t => (
        <TagOption
          className={styles.tag}
          key={t.id}
          option={t}
          isOption
          isClearable={allowDeleteTag}
          onRemove={() => remove(t)}
        />
      ))}
      {menu}
    </div>
  );

  if (tags === undefined) {
    return <Spin size="small" />;
  }

  if (!tags.length && !allowAddTag) {
    return <Typography.Text>-</Typography.Text>;
  }

  return (
    <div className={classnames(styles.root, className)}>
      {displayedTags.map(t => (
        <TagOption
          className={styles.tag}
          key={t.id}
          option={t}
          isClearable={allowDeleteTag}
          onRemove={() => remove(t)}
        />
      ))}

      {!!dropdownTags.length && (
        <Dropdown
          dropdownRender={dropdownRender}
          trigger={['click']}
          placement="bottomRight"
        >
          <div
            className={styles.arrowWrap}
            ref={dropdownArrowRef}
            onClick={e => e.stopPropagation()}
          >
            <Icon
              type="arrow"
              size={20}
              color="black-55"
              className={styles.arrow}
            />
          </div>
        </Dropdown>
      )}

      {allowAddTag && (
        <AddTagButton
          entityType={entityType}
          entityId={entityId}
          attachedTags={tags}
          shortAddButton={shortAddButton}
          addCallback={addCallback}
        />
      )}
    </div>
  );
};

TagList.propTypes = {
  entityType: PropTypes.oneOf([
    TYPE_ORDER,
    TYPE_TASK,
    TYPE_REQUEST,
    TYPE_ASSET,
    TYPE_CONTACT,
    TYPE_CHANNEL_CHAT,
    TYPE_ORDER_TEMPLATE,
    TYPE_TASK_TEMPLATE,
    TYPE_ATTACHMENT,
    TYPE_PROJECT,
    TYPE_ORDER_STATUS
  ]).isRequired,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  needFetchTags: PropTypes.bool,
  className: PropTypes.string,
  displayedCount: PropTypes.number,
  allowAddTag: PropTypes.bool,
  allowDeleteTag: PropTypes.bool,
  shortAddButton: PropTypes.bool
};

TagList.defaultProps = {
  needFetchTags: false,
  className: undefined,
  displayedCount: null,
  allowAddTag: true,
  allowDeleteTag: true,
  shortAddButton: false
};

export default TagList;
