import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-cycle
import ManageSubscribersWrapper from 'components/common/subscriptions/manage-subscribers-wrapper';
import ValidityDateWrapper from 'components/common/validity-date/validity-date-wrapper';
import { WarningMessageBlock } from 'components/common/StatusMessageBlock';
import Typography from 'components/common/typography';

import { useWorkspaceGoogleDriveSupport } from 'hooks/workspace/useWorkspaceGoogleDriveSupport';
import { getIsEditableFile } from 'hooks/common/use-file-upload/types';
import { useAmplitude } from 'hooks/amplitude/use-amplitude';

import Attachment from './attachment';
import DownloadAllFiles from './download-all-files';

import styles from './attachments.module.scss';

export const Attachments = ({
  className,
  style,
  fileList,
  changeFileList,
  attachmentProps,
  actionsDeps,
  classLastElement,
  rootClassName,
  showDownloadAllButton,
  subscribeCallback,
  unsubscribeCallback,
  onChangeFileList,
  changeManageSubscribersCallback,
  changeValidityDateCallback,
  hasWorkspaceGoogleDriveSupport,
  maxSizeFile
}) => {
  const { t } = useTranslation();
  const amplitude = useAmplitude();

  const {
    isWorkspaceOwnGoogleDriveIntegrationEnabled,
    hasWorkspaceGoogleDriveIntegration,
    getFileHasWorkspaceGoogleDriveSupport
  } = useWorkspaceGoogleDriveSupport();

  const hasWorkspaceGoogleDriveIntegrationSupport =
    hasWorkspaceGoogleDriveSupport !== undefined
      ? hasWorkspaceGoogleDriveSupport
      : isWorkspaceOwnGoogleDriveIntegrationEnabled;

  const hasWarningMessageBlock =
    hasWorkspaceGoogleDriveIntegrationSupport &&
    !hasWorkspaceGoogleDriveIntegration &&
    fileList
      .filter(getFileHasWorkspaceGoogleDriveSupport)
      .some(getIsEditableFile);

  const allowDownloadAll = showDownloadAllButton && (fileList || []).length > 1;

  useEffect(() => {
    if (hasWarningMessageBlock) {
      amplitude.trackEditableFileUploadEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasWarningMessageBlock]);

  if (!(fileList || []).length) {
    return null;
  }

  const MAX_SIZE_FILE_MB = maxSizeFile / (1024 * 1024);

  return (
    <div
      className={classnames(styles.root, rootClassName, {
        [styles.highlight]: attachmentProps.highlightAttachmentsBackground
      })}
    >
      {hasWarningMessageBlock && (
        <WarningMessageBlock
          content={t('WorkspaceGoogleDriveIntegrationIsDisabled', {
            ns: 'Common'
          })}
        />
      )}
      {allowDownloadAll && (
        <DownloadAllFiles fileList={fileList} actionsDeps={actionsDeps} />
      )}

      <div className={classnames(styles.attachments, className)} style={style}>
        {fileList.map((file, i) => (
          <div
            className={classnames(styles.attachmentWrap, {
              [styles.fileSizeExceeded]: file?.isSizeExceeded
            })}
            key={file.fileId || file.id || file.uid || file.uuid}
          >
            <Attachment
              file={{ ...file, orderId: actionsDeps.orderId }}
              actionsDeps={actionsDeps}
              {...attachmentProps}
              changeFileList={changeFileList}
              className={classnames(styles.file, attachmentProps.className, {
                [classLastElement]: fileList.length - 1 === i
              })}
              subscribeCallback={subscribeCallback}
              unsubscribeCallback={unsubscribeCallback}
              changeManageSubscribersCallback={changeManageSubscribersCallback}
              changeValidityDateCallback={changeValidityDateCallback}
              hasWorkspaceGoogleDriveSupport={hasWorkspaceGoogleDriveSupport}
            />

            {file?.isSizeExceeded && (
              <Typography.Text
                size="small"
                color="red"
                style={{ marginTop: 4 }}
              >
                {t('FileSizeTooLarge', {
                  ns: 'Common',
                  size: MAX_SIZE_FILE_MB
                })}
              </Typography.Text>
            )}

            <ManageSubscribersWrapper
              attachmentProps={attachmentProps}
              file={file}
              fileList={fileList}
              onChangeFileList={onChangeFileList}
              index={i}
            />

            <ValidityDateWrapper
              attachmentProps={attachmentProps}
              file={file}
              index={i}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

Attachments.propTypes = {
  className: PropTypes.string,
  attachmentProps: PropTypes.object,
  fileList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      uid: PropTypes.string,
      name: PropTypes.string,
      contentType: PropTypes.string,
      type: PropTypes.string,
      status: PropTypes.string,
      error: PropTypes.any,
      size: PropTypes.number,
      url: PropTypes.string
    })
  ),
  classLastElement: PropTypes.string,
  actionsDeps: PropTypes.shape({
    statusId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    contactId: PropTypes.number,
    taskId: PropTypes.number,
    assetId: PropTypes.number,
    sendCopyToComment: PropTypes.bool,
    messageUuid: PropTypes.string,
    allowDeleteFrom: PropTypes.bool,
    isPrivate: PropTypes.bool,
    entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    entityType: PropTypes.string,
    isFromOtherWorkspace: PropTypes.bool
  }),
  changeFileList: PropTypes.func,
  rootClassName: PropTypes.string,
  showDownloadAllButton: PropTypes.bool,
  subscribeCallback: PropTypes.func,
  unsubscribeCallback: PropTypes.func,
  changeManageSubscribersCallback: PropTypes.func,
  changeValidityDateCallback: PropTypes.func
};

Attachments.defaultProps = {
  className: undefined,
  attachmentProps: {},
  fileList: [],
  actionsDeps: {},
  classLastElement: '',
  changeFileList: () => {},
  rootClassName: undefined,
  showDownloadAllButton: false,
  subscribeCallback: () => {},
  unsubscribeCallback: () => {},
  changeManageSubscribersCallback: () => {},
  changeValidityDateCallback: () => {}
};

export default memo(Attachments);
