import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  TYPE_TASK,
  TYPE_ASSET,
  TYPE_CONTACT,
  TYPE_ORDER_STATUS,
  ONLINE_CHAT,
  TYPE_ORDER,
  DIALOG_TYPE_MESSAGE
} from 'constants/index';

import Drawer from 'components/common/drawer';
import { useViewFile } from 'components/attachments-view/hooks';
import useManageSubscribers from 'components/common/subscriptions/use-manage-subscribers';
import useValidityDate from 'components/common/validity-date/use-validity-date';

import { fetchAttachments } from 'store/attachments';
import { fetchOneContactLocal } from 'store/contacts';
import { setSendingMessageData } from 'store/operator';
import { fetchOne } from 'store/assets';
import { fetchTaskLocal } from 'store/tasks';

// eslint-disable-next-line import/no-cycle
import Tabs from './tabs';

const FETCH_ATTACHMENTS_TIMEOUT = 2000;

export const getFile = async ({
  relationId,
  relationType,
  onlineChat,
  contactId,
  fileList,
  dispatch
}) => {
  let file;
  let chat = onlineChat;

  if (relationType === TYPE_ASSET) {
    if (!chat) {
      const asset = await dispatch(fetchOne({ id: relationId }));
      chat = asset.chats.find(c => c.channelKind === ONLINE_CHAT);
    }

    dispatch(
      setSendingMessageData({
        entityType: relationType,
        entityId: relationId,
        onlineChat: chat,
        fileList,
        kind: DIALOG_TYPE_MESSAGE
      })
    );
  }

  if (relationType === TYPE_TASK) {
    if (!chat) {
      const task = await dispatch(fetchTaskLocal({ id: relationId }));
      chat = task.chats.find(c => c.channelKind === ONLINE_CHAT);
    }

    dispatch(
      setSendingMessageData({
        entityType: relationType,
        entityId: relationId,
        onlineChat: chat,
        fileList,
        kind: DIALOG_TYPE_MESSAGE
      })
    );
  }

  if (relationType === TYPE_CONTACT) {
    dispatch(
      setSendingMessageData({
        destination: {
          entityType: TYPE_CONTACT,
          entityId: contactId
        },
        entityType: TYPE_CONTACT,
        entityId: contactId,
        onlineChat: chat,
        fileList
      })
    );
  }

  if (relationType === TYPE_ORDER_STATUS || relationType === TYPE_ORDER) {
    const contact = await dispatch(fetchOneContactLocal({ id: contactId }));
    const contactOnlineChat = contact.chats.find(
      c => c.channelKind === ONLINE_CHAT
    );

    dispatch(
      setSendingMessageData({
        destination: {
          entityType: TYPE_ORDER,
          entityId: relationId
        },
        entityType: TYPE_CONTACT,
        entityId: contactId,
        onlineChat: contactOnlineChat,
        fileList
      })
    );
  }

  return file;
};

export const AddFileDrawer = ({ visible, onClose }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation('AddFile');

  const [isLoading, setIsLoading] = useState(false);

  const { viewFile } = useViewFile();

  const { manageBulkSubscribers } = useManageSubscribers();
  const { handleChangeBulkValidityDates } = useValidityDate();

  const onSubmit = async ({
    fileList,
    relation,
    subscribersForAttachments,
    validityDates
  }) => {
    try {
      setIsLoading(true);

      const { relationId, relationType, onlineChat, contactId } = Array.isArray(
        relation
      )
        ? relation[0]
        : relation;

      const file = await getFile({
        relationId,
        relationType,
        fileList,
        onlineChat,
        contactId,
        dispatch
      });

      if (subscribersForAttachments.length > 0) {
        await manageBulkSubscribers(subscribersForAttachments);
      }

      if (validityDates.length > 0) {
        await handleChangeBulkValidityDates({ validityDates });
      }

      onClose();

      // TODO: setTimeout is a workaround for the #1877154 issue, which is on the backend side until it does a refactoring of attachments
      setTimeout(() => {
        dispatch(fetchAttachments({ isTrash: false, ignoreCache: true }));
      }, FETCH_ATTACHMENTS_TIMEOUT);

      if (file) {
        viewFile(file);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      title={<Drawer.Title>{t('AddFileHeading')}</Drawer.Title>}
      destroyOnClose
      open={visible}
      bodyStyle={{
        padding: 0
      }}
      onClose={onClose}
    >
      <Tabs isLoading={isLoading} onSubmit={onSubmit} />
    </Drawer>
  );
};

AddFileDrawer.defaultProps = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default AddFileDrawer;
