import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal';

import { partialUpdate } from 'store/assets';

import { showNoticeMessage } from 'services/notice';

// eslint-disable-next-line import/no-cycle
import { ExpirationNoticeForm } from './expiration-notice-form';

export const ExpirationNoticeModal = ({ visible, onClose, asset = {} }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('Asset');

  const [isLoading, setIsLoading] = useState(false);

  const { id, dateEnd, expirationDays, expirationTaskCreatedAt } = asset;

  const onSubmit = async values => {
    try {
      setIsLoading(true);

      await dispatch(
        partialUpdate({
          id,
          asset: {
            expirationDays: Number(values.expirationDays)
          }
        })
      );

      showNoticeMessage();

      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={t('DaysSelectorTitle')}
      contentStyle={{ padding: 24 }}
      centered
      open={visible}
      width={460}
      onClose={onClose}
      destroyOnClose
    >
      <ExpirationNoticeForm
        onSubmit={onSubmit}
        isLoading={isLoading}
        validityDate={dateEnd}
        defaultValues={{
          expirationDays: expirationTaskCreatedAt ? 1 : expirationDays
        }}
      />
    </Modal>
  );
};
