import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TYPE_CHANNEL_CHAT } from 'constants/index';

// eslint-disable-next-line import/no-cycle
import { FormInput, FormRelationsSelect } from 'components/common/hook-form';
import Button from 'components/common/button';

import styles from './copy.module.scss';

const CopyForm = ({ defaultValues, onSubmit }) => {
  const methods = useForm({ defaultValues });

  const { t } = useTranslation(['CreateDocumentCopy', 'Errors', 'AddFile']);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async values => {
    try {
      setIsLoading(true);

      await onSubmit(values);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        className={styles.form}
        onSubmit={methods.handleSubmit(handleSubmit)}
      >
        <FormInput
          name="name"
          label={t('FileTitle')}
          rules={{
            required: t('RequiredField', { ns: 'Errors' })
          }}
        />

        <FormRelationsSelect
          name="relation"
          label={t('Link', { ns: 'AddFile' })}
          rules={{
            required: t('RequiredField', { ns: 'Errors' })
          }}
          hiddenTypes={[TYPE_CHANNEL_CHAT]}
          allowToSelectOne
        />

        <Button
          type="primary"
          htmlType="submit"
          size="large"
          width="expanded"
          loading={isLoading}
          className={styles.button}
        >
          {t('NextBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

CopyForm.propTypes = {
  defaultValues: PropTypes.shape({
    name: PropTypes.string
  }),
  onSubmit: PropTypes.func.isRequired
};

CopyForm.defaultProps = {
  defaultValues: {}
};

export default CopyForm;
