import { handleActions } from 'redux-actions';

import {
  changeStateOrderStatus,
  cancelOrderStatus,
  reopenOrderStatus
} from './actions';

const initialState = {
  entries: [],
  isLoading: false
};

export default handleActions(
  {
    [changeStateOrderStatus.fulfilled]: (state, { payload }) => {
      const updatedEntries = state.entries.map(order =>
        order.id === payload.id ? payload : order
      );

      return {
        ...state,
        entries: updatedEntries
      };
    },

    [cancelOrderStatus.fulfilled]: (state, { payload }) => {
      const updatedEntries = state.entries.map(order =>
        order.id === payload.id ? payload : order
      );

      return {
        ...state,
        entries: updatedEntries
      };
    },

    [reopenOrderStatus.fulfilled]: (state, { payload }) => {
      const updatedEntries = state.entries.map(order =>
        order.id === payload.id ? payload : order
      );

      return {
        ...state,
        entries: updatedEntries
      };
    }
  },
  initialState
);
