import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Translation } from 'react-i18next';

import { LINK_TYPE } from 'constants/router';

import Tooltip from 'components/common/tooltip';
import Icon from 'components/common/icon';
import {
  getTaskAccessType,
  getTaskIconData,
  isLimitedAccessType
} from 'components/tasks-view/utils';

import useRoutesService from 'services/routes';
import useModalsService from 'services/modals';

import { IconLimitedAccessType } from 'components/tasks-view/components/icon-limited-access-type';

import Option from '../option';

import styles from './common-option.module.scss';

const { Paragraph, Text } = Typography;

export const CommonOption = ({
  option,
  className,
  showId,
  showIcon,
  isLinkToElement,
  selectType,
  showAccessTypeDetails,
  ...props
}) => {
  const routes = useRoutesService({ returnUrl: true });
  const modals = useModalsService({ returnUrl: true });

  const urlBySelectOption = {
    [LINK_TYPE.PROJECT]: routes.toProject(option.id),
    [LINK_TYPE.TASK]: `${routes.toTasks()}${modals.tasks.showDetails({
      id: option.id
    })}`,
    [LINK_TYPE.REQUEST]: `${routes.toRequests()}${modals.requests.showDetails({
      id: option.id
    })}`,
    [LINK_TYPE.ASSETS]: `${routes.toAssets()}${modals.assets.showDetails({
      id: option.id
    })}`
  };

  if (typeof option === 'string') {
    return option;
  }

  const title = option.title || option.name;

  const CommonOptionContent = () => {
    const { iconData } = getTaskIconData({
      kind: option?.kind
    });

    return (
      <div className={styles.commonOption}>
        {option.icon && showIcon && (
          <img src={option.icon} className={styles.icon} alt={title} />
        )}

        {showAccessTypeDetails && iconData && (
          <Tooltip
            title={
              <Translation ns={iconData.ns}>
                {translate => translate(iconData.label)}
              </Translation>
            }
            mouseEnterDelay={0.5}
          >
            <Icon className={styles.typeIcon} component={iconData.icon} />
          </Tooltip>
        )}

        <Paragraph
          ellipsis={{ rows: 1 }}
          style={{ marginBottom: 0, flexGrow: 1 }}
        >
          {option.id && showId && (
            <Text
              className={classnames(styles.textPrimary, {
                [styles.noAccess]:
                  showAccessTypeDetails && !getTaskAccessType(option.hasAccess)
              })}
            >
              ID {option.id}
            </Text>
          )}
          <span className={isLinkToElement ? styles.textPrimary : ''}>
            <Tooltip title={title}>
              <span
                className={classnames({
                  [styles.noAccess]:
                    showAccessTypeDetails &&
                    !getTaskAccessType(option.hasAccess)
                })}
              >
                <>
                  {showAccessTypeDetails &&
                    isLimitedAccessType(option?.accessType) && (
                      <IconLimitedAccessType isRightMargin />
                    )}
                  {title}
                </>
              </span>
            </Tooltip>
          </span>
        </Paragraph>
      </div>
    );
  };

  return (
    <Option
      className={classnames(className, styles.root)}
      title={title}
      {...props}
    >
      {isLinkToElement ? (
        <span>
          <Link to={urlBySelectOption[selectType]}>
            <CommonOptionContent />
          </Link>
        </span>
      ) : (
        <CommonOptionContent />
      )}
    </Option>
  );
};

CommonOption.propTypes = {
  option: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string
  }).isRequired,
  showId: PropTypes.bool,
  showIcon: PropTypes.bool,
  isLinkToElement: PropTypes.bool,
  selectType: PropTypes.string
};

CommonOption.defaultProps = {
  showId: true,
  showIcon: true,
  isLinkToElement: false,
  selectType: ''
};

export default CommonOption;
