import React, { createRef, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import Icon from '../icon';
import Button from '../button';
// eslint-disable-next-line import/no-cycle
import { FormTextarea } from '../hook-form';

import styles from './feedback-modal.module.scss';

const Form = ({
  rating,
  onSubmit,
  starCount,
  setRating,
  btnTitle,
  inputProps,
  btnProps,
  disabledStars
}) => {
  const { t } = useTranslation(['ScreenErrors', 'Errors']);

  const methods = useForm();

  const stars = [...new Array(starCount).keys()].map(item => item + 1);

  const refs = useMemo(
    () =>
      stars.reduce((acc, curr) => {
        acc[curr] = createRef();

        return acc;
      }, {}),
    [stars]
  );

  const onMouseEnter = key => {
    Object.keys(refs).forEach(k => {
      if (k <= key) {
        if (refs[k].current.className.includes(styles.hovered)) {
          refs[k].current.classList.add(styles.checkedHovered);
        }

        refs[k].current.classList.add(styles.hovered);
      }
    });
  };

  const onMouseLeave = () =>
    Object.keys(refs).forEach(k => {
      refs[k].current.classList.remove(styles.checkedHovered);

      if (!rating || k > rating) {
        refs[k].current.classList.remove(styles.hovered);
      }
    });

  const onClick = key => {
    if (rating) {
      Object.keys(refs).forEach(k => {
        if (k > key) {
          refs[k].current.classList.remove(styles.hovered);
        } else {
          refs[k].current.classList.add(styles.hovered);
        }
      });
    }

    setRating(key);
  };

  useEffect(() => {
    if (rating === starCount) {
      methods.clearErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rating]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <div style={{ display: 'flex', marginBottom: rating ? 24 : 0 }}>
          {stars.map(key => (
            <div
              key={key}
              ref={refs[key]}
              className={classnames(styles.star, {
                [styles.disabledStars]: disabledStars
              })}
              onClick={() => onClick(key)}
              onMouseEnter={() => onMouseEnter(key)}
              onMouseLeave={onMouseLeave}
            >
              <Icon
                dataTestId="feedback-form-start"
                size={32}
                type="star"
                theme="filled"
              />
            </div>
          ))}
        </div>

        {rating && (
          <FormTextarea
            name="content"
            placeholder={t('YourComment')}
            autoSize={{
              minRows: 3,
              maxRows: 3
            }}
            itemProps={{ style: { marginBottom: 24, width: '100%' } }}
            style={{ resize: 'none' }}
            rules={{
              required:
                rating < starCount
                  ? t('RequiredField', { ns: 'Errors' })
                  : false
            }}
            {...inputProps}
          />
        )}

        {rating && (
          <Button
            type="primary"
            width="expanded"
            size="large"
            htmlType="submit"
            disabled={!rating}
            {...btnProps}
          >
            {btnTitle || t('RateBtn')}
          </Button>
        )}
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  rating: PropTypes.number,
  starCount: PropTypes.number,
  onSubmit: PropTypes.func,
  setRating: PropTypes.func,
  inputProps: PropTypes.object,
  btnProps: PropTypes.object,
  disabledStars: PropTypes.bool
};

Form.defaultProps = {
  rating: 0,
  starCount: 5,
  onSubmit: () => {},
  setRating: () => {},
  inputProps: {},
  btnProps: {},
  disabledStars: false
};

export default Form;
