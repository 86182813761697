import React from 'react';
import ReactPlayer from 'react-player';

export const VideoItem = ({ style, url }) => (
  <ReactPlayer
    controls
    playing
    width="100%"
    height="600px"
    style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '1000px',
      ...(style || {})
    }}
    url={url}
  />
);

export default VideoItem;
