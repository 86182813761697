const SLA_TYPES = {
  ASSET: 'asset',
  CHANNEL: 'channel',
  ORDER_STATUS: 'order_status'
};

const SLA_ALERT_MESSAGES = {
  start: {
    ns: 'Requests',
    key: 'RequestEditSLAWarningStart'
  },
  content: {
    CONTACT: {
      ns: 'Common',
      key: 'GenitiveFormContact'
    },
    AND: {
      ns: 'SignUp',
      key: 'AndPrivacyPolicyChckbx'
    },
    CHANNEL: {
      ns: 'Requests',
      key: 'EditSlaWarningChannel'
    },
    ASSET: {
      ns: 'Requests',
      key: 'EditSlaWarningAsset'
    },
    ORDER: {
      ns: 'Requests',
      key: 'EditSlaWarningOrder'
    }
  },
  end: {
    ns: 'Requests',
    key: 'RequestEditSLAWarningEnd'
  }
};

export const generateWarningMessage = params => {
  const { slaEntityType, hasContact } = params;
  const messages = [];

  messages.push({
    key: SLA_ALERT_MESSAGES.start.key,
    ns: SLA_ALERT_MESSAGES.start.ns,
    addComma: hasContact && slaEntityType
  });

  if (hasContact && !slaEntityType) {
    messages.push({
      key: SLA_ALERT_MESSAGES.content.AND.key,
      ns: SLA_ALERT_MESSAGES.content.AND.ns
    });
    messages.push({
      key: SLA_ALERT_MESSAGES.content.CONTACT.key,
      ns: SLA_ALERT_MESSAGES.content.CONTACT.ns
    });
  }

  if (hasContact && !!slaEntityType) {
    messages.push({
      key: SLA_ALERT_MESSAGES.content.CONTACT.key,
      ns: SLA_ALERT_MESSAGES.content.CONTACT.ns
    });
  }

  if (slaEntityType) {
    messages.push({
      key: SLA_ALERT_MESSAGES.content.AND.key,
      ns: SLA_ALERT_MESSAGES.content.AND.ns
    });

    switch (slaEntityType) {
      case SLA_TYPES.CHANNEL:
        messages.push({
          key: SLA_ALERT_MESSAGES.content.CHANNEL.key,
          ns: SLA_ALERT_MESSAGES.content.CHANNEL.ns
        });
        break;
      case SLA_TYPES.ASSET:
        messages.push({
          key: SLA_ALERT_MESSAGES.content.ASSET.key,
          ns: SLA_ALERT_MESSAGES.content.ASSET.ns
        });
        break;
      case SLA_TYPES.ORDER_STATUS:
        messages.push({
          key: SLA_ALERT_MESSAGES.content.ORDER.key,
          ns: SLA_ALERT_MESSAGES.content.ORDER.ns
        });
        break;
      default:
        return [];
    }
  }

  messages.push({
    key: SLA_ALERT_MESSAGES.end.key,
    ns: SLA_ALERT_MESSAGES.end.ns
  });

  return messages;
};
