import React, { useEffect, useState } from 'react';
import { Form, Spin, Alert } from 'antd';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TYPE_MEETING, TYPE_MEMBERS } from 'constants/index';

// eslint-disable-next-line import/no-cycle
import { EmployeeSelect } from 'components/common/controls/custom-select';

import { fetchAssignments } from 'store/tasks/actions';

import { getMembers } from 'utils/get-members';
import { usePrevious } from 'hooks/common';

import styles from './members-field.module.scss';

const MembersField = ({
  getFieldDecorator,
  values,
  currentTaskType,
  label
}) => {
  const dispatch = useDispatch();

  const [isLoadingAssignments, setIsLoadingAssignments] = useState(false);

  const prevTaskId = usePrevious((values || {}).id);

  const { t } = useTranslation('AddTask');

  const fetchData = async () => {
    setIsLoadingAssignments(true);
    try {
      await dispatch(
        fetchAssignments({ id: values.id, directMembersOnly: true })
      );
    } finally {
      setIsLoadingAssignments(false);
    }
  };

  useEffect(() => {
    if (
      !Object.keys(values).length ||
      (values || {}).id === prevTaskId ||
      values.assignments
    )
      return;

    fetchData();
    //  eslint-disable-next-line
  }, [values]);

  return (
    <div className={styles.withHint} data-qa="qa-i5xbtzxahlokbi8">
      <Form.Item label={label}>
        {getFieldDecorator(TYPE_MEMBERS, {
          initialValue: values && getMembers(values)
        })(
          <EmployeeSelect
            data-qa="qa-3php7du15o7y1rl"
            params={{
              excludeSelf: true
            }}
            isMulti
            reloadAfterOpen
            allowSelectAll
            showSelectedOptionsOnList={false}
          />
        )}
      </Form.Item>

      {currentTaskType === TYPE_MEETING && (
        <Alert
          type="warning"
          message={t('MembersWarning')}
          style={{ fontSize: 12 }}
        />
      )}

      {isLoadingAssignments && <Spin className={styles.spin} />}
    </div>
  );
};

MembersField.propTypes = {
  getFieldDecorator: PropTypes.func,
  values: PropTypes.shape({
    id: PropTypes.number,
    assignments: PropTypes.arrayOf(
      PropTypes.shape({
        employee: PropTypes.object,
        roles: PropTypes.array,
        id: PropTypes.number
      })
    )
  }),
  currentTaskType: PropTypes.string,
  label: PropTypes.node
};

MembersField.defaultProps = {
  getFieldDecorator: () => {},
  values: {},
  currentTaskType: undefined,
  label: undefined
};

export default MembersField;
